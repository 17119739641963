// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".player--1e4qI{position:absolute;width:100%;height:100%;justify-content:center;align-items:center;left:50%;top:50%;transform:translate(-50%, -50%);z-index:1}.playIcon--32ai_{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);z-index:2;width:calc(var(--rootFontSize) * 6)}@media screen and (min-width: 400px){.playIcon--32ai_{width:calc(var(--rootFontSize) * 4.2)}}.playIcon--32ai_:hover{cursor:pointer}.blurPlayer--1wAfT{filter:blur(5px)}.thumbnail--3yfJF{position:absolute;top:0;left:0;height:100%;width:100%;cursor:pointer;display:block;background-position:center;background-size:cover;background-repeat:no-repeat;z-index:2}.vpaidOverlay--3Bxnb{background-image:none;z-index:1}.preventClickThrough--1fc8s{position:absolute;top:0;left:0;height:100%;width:100%;z-index:1}", ""]);
// Exports
exports.locals = {
	"player": "player--1e4qI",
	"playIcon": "playIcon--32ai_",
	"blurPlayer": "blurPlayer--1wAfT",
	"thumbnail": "thumbnail--3yfJF",
	"vpaidOverlay": "vpaidOverlay--3Bxnb thumbnail--3yfJF",
	"preventClickThrough": "preventClickThrough--1fc8s"
};
module.exports = exports;
