import React, {useMemo} from "react";

import {LOCATOR_PIN_TYPE} from 'utils/constants';

import StoreLocatorRenderer from "./renderer";
import {useStoreLocatorEvents} from "./useStoreLocatorEvents";
import {useConfig} from "utils/ConfigProvider";

function StoreLocator({overlay, userInfo, onEvent, fireJourneyTrigger, inVideo = false, fireCallback}) {

  const {environment} = useConfig();

  const isCreativeStudioPreviewMode = environment.isCreativeStudioPreviewMode();

  const userCurrentLocation = useMemo(() => ({
    lat: userInfo?.latitude,
    lng: userInfo?.longitude
  }), [userInfo]);

  const {
    onPhoneClick,
    onDirectionClick,
    onMarkerClick
  } = useStoreLocatorEvents(fireJourneyTrigger, inVideo, onEvent, fireCallback, overlay?.inSessionTriggerDetails?.triggerElementId);

  const {creativeCard} = overlay
  const rendererProps = useMemo(() => getProps(creativeCard), [creativeCard]);

  return (
    <StoreLocatorRenderer
      {...rendererProps}
      isFullScreen={!inVideo}
      userCurrentLocation={userCurrentLocation}
      onPhoneClick={!isCreativeStudioPreviewMode ? onPhoneClick : null}
      onDirectionClick={!isCreativeStudioPreviewMode ? onDirectionClick : null}
      onMarkerClick={!isCreativeStudioPreviewMode ? onMarkerClick : null}
    />
  )
}

const getProps = (creativeCard) => {
  const {mapEngagement} = creativeCard;
  const {
    locatorPin,
    locatorPinType,
    title,
    font,
    button,
    locationMapJsonUrl,
  } = mapEngagement;

  const titleFontSettings = {font: font.font , color: title.color};
  const vgDefaultMapPin = locatorPinType === LOCATOR_PIN_TYPE.DEFAULT;

  return {
    iconColor: locatorPin?.color,
    mapToggleButtonBackgroundColor: button?.color,
    title: title?.text,
    titleFontSettings,
    vgDefaultMapPin,
    locationMapJsonUrl,
    mapLocatorImage: locatorPinType === LOCATOR_PIN_TYPE.CUSTOM && locatorPin?.image ? {url: locatorPin.image.linkURL} : null
  };
}
export default StoreLocator;
