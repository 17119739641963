import {
  MEDIA_TYPE,
  PLAYER_HOSTS,
  CREATIVE_CARD_VARIATION_TYPE,
  IN_VIDEO_CREATIVE_CARD_VARIATION_TYPE_MAP,
  CREATIVE_CARD_TYPE
} from './constants';

export const getVideoHost = (mediaType) => {
  return mediaType === MEDIA_TYPE.RAW_VIDEO_VPAID || mediaType === MEDIA_TYPE.RAW_VIDEO ? PLAYER_HOSTS.JW : PLAYER_HOSTS.VAST;
}

export const ensureHttps = (url) => {
  return url.replace('http://','https://');
}


// variationFilterParams = {creativeCardType, finaleEnabled, inVideoEngagementEnabled, inVideoEngagementPosition, isDisplay, creativeHeight, creativeWidth}
export const getCreativeCardFromSingleSetting = (singleCreativeCardSettings, variationFilterParams) => {

  if (!singleCreativeCardSettings) {
    return
  }
  if (singleCreativeCardSettings.isSharedCard && singleCreativeCardSettings?.sharedCard?.creativeCard) {
    let sharedCard = copyShareCardNameToCreativeCard(singleCreativeCardSettings.sharedCard)
    sharedCard = setDefaultBgImageInVideo(sharedCard)
    
    if (variationFilterParams) {
      sharedCard = applyCreativeCardVariation(sharedCard, variationFilterParams);
    }

    return sharedCard.creativeCard
  } else { 
    return singleCreativeCardSettings.creativeCard
  }
}  

const copyBgImageCardToVideo= (engagementKey, creativeCard) => {
  return creativeCard[engagementKey] ? {
    [engagementKey]: {
      ...creativeCard[engagementKey],
      ...(creativeCard[engagementKey].bgImage ? {
        bgImage: {
          ...creativeCard[engagementKey].bgImage,
          inVideo: {
            ...creativeCard[engagementKey].bgImage.card
          }
        }
      } : {}),
    }
  } : {}
}

// Shared cards do not have a base bgImage.inVideo, so copy bgImage.card 
const setDefaultBgImageInVideo = (sharedCard) => {
  return {
      ...sharedCard,
      creativeCard: {
        ...sharedCard.creativeCard,
        ...(copyBgImageCardToVideo("customSurveyEngagement", sharedCard.creativeCard)),
        ...(copyBgImageCardToVideo("multipleChoiceEngagement", sharedCard.creativeCard)),
        ...(copyBgImageCardToVideo("starSurveyEngagement", sharedCard.creativeCard)),
        ...(copyBgImageCardToVideo("imageSurveyEngagement", sharedCard.creativeCard))
      }
    }
}

export const copyShareCardNameToCreativeCard = (sharedCard) => {
  return {
    ...sharedCard,
    ...(sharedCard.creativeCard ? {
        creativeCard: {
          ...sharedCard.creativeCard,
          name: sharedCard.name, // copy shareCard name, sharedCard.creativeCard.name has a dummy name
        } 
      } : {})
  }
}

export const getApplicableCreativeCardVariation = (sharedCard, {creativeCardType,
  finaleEnabled=false, inVideoEngagementEnabled=false, inVideoEngagementPosition, 
  isDisplay=false, creativeHeight, creativeWidth}) => {

  if (!sharedCard || sharedCard.creativeCard.creativeCardType !== creativeCardType || !sharedCard.creativeCardVariations) {
    return null;
  }
  
  return sharedCard.creativeCardVariations.find((variation) => {
    if (isDisplay) {
      return (variation.variationTypeAndDisplaySizeMappingField === "CUSTOM" && creativeHeight === variation.creativeHeight && creativeWidth === variation.creativeWidth) || 
        (variation.variationTypeAndDisplaySizeMappingField?.includes(`${creativeWidth}x${creativeHeight}`))
    } else {
      return (finaleEnabled && variation.variationTypeAndDisplaySizeMappingField === CREATIVE_CARD_VARIATION_TYPE.FINALE) || 
        (inVideoEngagementEnabled && variation.variationTypeAndDisplaySizeMappingField === IN_VIDEO_CREATIVE_CARD_VARIATION_TYPE_MAP[inVideoEngagementPosition])
    }
  })

}


const applyImageOverride = (entity, imageObjectKey, imageOverride, targetFileNameKey = "filename") => {
  return imageObjectKey && entity[imageObjectKey] && imageOverride ? {
    [imageObjectKey]: {
      ...entity[imageObjectKey],
      mediaId: imageOverride.mediaType,
      [targetFileNameKey]: imageOverride["filename"] || imageOverride["fileName"], 
      linkURL: imageOverride.linkURL,
      opacity: imageOverride.opacity,
      ratio: imageOverride.ratio,
    }
  } : {}
}

const applyOverride = (entity, entityOverrideMap, textObjectKey) => {
  const override = entityOverrideMap[entity.overrideId];
  if (override) {
    return {
      ...entity,
      positionX: override.positionX === undefined ? entity.positionX : override.positionX,
      positionY: override.positionY === undefined ? entity.positionY : override.positionY,
      height: override.height === undefined ? entity.height : override.height,
      width: override.width === undefined ? entity.width : override.width,
      hotspotTextHeight: override.hotspotTextHeight === undefined ? entity.hotspotTextHeight : override.hotspotTextHeight,
      hotspotTextWidth: override.hotspotTextWidth === undefined ? entity.hotspotTextWidth : override.hotspotTextWidth,
      hotspotTextPosition: override.hotspotTextPosition === undefined ? entity.hotspotTextPosition : override.hotspotTextPosition,
      iconSize: override.iconSize === undefined ? entity.iconSize : override.iconSize,
      [textObjectKey]: {
        ...entity[textObjectKey],
        fontSize: override.fontSize,
      },
      ...(applyImageOverride(entity, "customMarker", override.customMarker)),
      ...(applyImageOverride(entity, "surveyOptionImage", override.image, "fileName")) // for image survey
    }
  } else {
    return entity
  }
}

const getOverridenEngagement = (engagementKey, sharedCard, entityOverrideMap) => {
  const cardOverride = sharedCard.creativeCard[engagementKey] ? entityOverrideMap[sharedCard.creativeCard[engagementKey].overrideId] : null;  // card level override only has background image

  return sharedCard.creativeCard[engagementKey] ? {
    [engagementKey]: {
      ...sharedCard.creativeCard[engagementKey],
      ...(sharedCard.creativeCard[engagementKey].bgImage && cardOverride?.bgImage ? {
        // Override both card and inVideo since we are generating separate variations for each and phoenix will pick the relevant one and ignore the other
        bgImage: {
          ...sharedCard.creativeCard[engagementKey].bgImage,
          ...(applyImageOverride(sharedCard.creativeCard[engagementKey].bgImage, "card", cardOverride.bgImage.card, "fileName")),
          ...(applyImageOverride(sharedCard.creativeCard[engagementKey].bgImage, "inVideo", cardOverride.bgImage.inVideo, "fileName")),
        }
      } : {}),
      ...(!sharedCard.creativeCard[engagementKey].bgImage && cardOverride?.bgImage ? {
        bgImage : {
          ...cardOverride.bgImage
        }
      } : {}),
      ...(cardOverride?.bgColor ? {bgColor: cardOverride.bgColor} : {}),
      ...(cardOverride?.bgStyle ? {bgStyle: cardOverride.bgStyle} : {}),
      ...(cardOverride?.bgGradient ? {
          bgGradient: {
            ...cardOverride.bgGradient
          }
        } : {}),
      questions: sharedCard.creativeCard[engagementKey].questions
        .map((question) => {
          const updatedQuestion = applyOverride(question, entityOverrideMap, "text")
          // TODO: I know this is bad but MC survey has bg color in question domain, instead of inside the card, just like other cards.
          //  God knows why? Will have to refactor that code. This is a temp fix.
          if (sharedCard.creativeCard.creativeCardType === CREATIVE_CARD_TYPE.MULTIPLE_CHOICE_SURVEY) {
            return {
              ...updatedQuestion,
              'text': {
                ...updatedQuestion.text,
                ...(cardOverride?.bgColor ? {bgColor: cardOverride.bgColor} : {}),
              }
            }
          }
          return updatedQuestion
        })
        .map((question) => ({
          ...question,
          options: question.options.map((option) => applyOverride(option, entityOverrideMap, "title"))
        })) 

    }
  } : {}
}

export const applyCreativeCardVariation = (sharedCard, variationFilterParams) => {
  const creativeCardVariation = getApplicableCreativeCardVariation(sharedCard, variationFilterParams);

  if (creativeCardVariation && creativeCardVariation.variationOverrides && creativeCardVariation.variationOverrides.length > 0) {
    const entityOverrideMap = creativeCardVariation.variationOverrides.reduce((acc, override) => {
      acc[override.overrideId] = override;
      return acc;
    }, {})

    return {
      ...sharedCard,
      creativeCard: {
        ...sharedCard.creativeCard,
        hotspots: sharedCard.creativeCard.hotspots.filter((hotspot)=> isHotspotVisible(hotspot, entityOverrideMap)).map((hotspot) => applyOverride(hotspot, entityOverrideMap, "hotspotText")),
        ...(getOverridenEngagement("customSurveyEngagement", sharedCard, entityOverrideMap)),
        ...(getOverridenEngagement("multipleChoiceEngagement", sharedCard, entityOverrideMap)),
        ...(getOverridenEngagement("starSurveyEngagement", sharedCard, entityOverrideMap)),
        ...(getOverridenEngagement("imageSurveyEngagement", sharedCard, entityOverrideMap))
      }
    }
  } else {
    return sharedCard;
  }
}

export const isHotspotVisible = (hotspot, entityOverrideMap) => {
  const override = entityOverrideMap[hotspot.overrideId];
  if (!override) {
    return true
  }
 return override.hotspotVisible;
}