import React, {useEffect, useMemo, useState} from "react";
import {ClickAwayListener, Popper} from '@material-ui/core';

import style from './style.module.scss';

import classnames from "classnames";
import {isMobileDevice} from "utils/environmentHelper";
import {useConfig} from "utils/ConfigProvider";

function Popover({children, content, open, onClose, creativeDimensions}) {
  const {appRootId} = useConfig();
  const [position, setPosition] = useState('bottom');
  const [ref, setRef] = useState(null);

  useEffect(() => {
    if (!open || !ref) {
      return
    }

    const availableHeight = parseFloat(creativeDimensions?.height);
    const availableWidth = parseFloat(creativeDimensions?.width);
    const {left: appX, top: appY} = document.getElementById(appRootId)?.getBoundingClientRect() ?? {left: 0, top: 0};
    let {left, top} = ref.getBoundingClientRect();

    const {height} = ref.getBoundingClientRect();
    left -= appX;
    top -= appY;

    const topR = (top + height / 2) / availableHeight;
    const leftR = (left + height / 2) / availableWidth;

    if (topR < .3) {
      setPosition('bottom');
    } else if (topR > .62) {
      setPosition('top');
    } else if (leftR < .4) {
      setPosition('right');
    } else if (leftR > .6) {
      setPosition('left');
    } else {
      setPosition('right');
    }
  }, [ref, open, creativeDimensions, appRootId]);

  const arrowClass = useMemo(() => {
    switch (position) {
      case 'top': {
        return style.arrowTop;
      }
      case 'bottom': {
        return style.arrowBottom;
      }
      case 'left': {
        return style.arrowLeft;
      }
      case 'right': {
        return style.arrowRight;
      }
      default: {
        return null;
      }
    }
  }, [position]);

  const popperExtraClass = useMemo(() => {
    switch (position) {
      case 'top': {
        return style.topPopper;
      }
      case 'bottom': {
        return style.bottomPopper;
      }
      case 'left': {
        return style.leftPopper;
      }
      case 'right': {
        return style.rightPopper;
      }
      default: {
        return null;
      }
    }
  }, [position]);

  return <>
    <div ref={setRef}>
      {children}
    </div>
    {
      open ?
        <ClickAwayListener onClickAway={onClose}>
          <Popper
            open={open}
            anchorEl={ref}
            container={document.querySelector(isMobileDevice() ? 'body' : '#app')}
            placement={position}
            className={classnames(style.popover, popperExtraClass)}
            transition
          >
            <div className={classnames(style.content)}>
              <div className={classnames(style.arrowBase, arrowClass)}/>
              {content}
            </div>
          </Popper>
        </ClickAwayListener> :
        <React.Fragment/>
    }
  </>
}

export default Popover;
