import React, {useMemo} from 'react';
import classnames from "classnames";
import {getElementBackgroundStyle} from "utils/engagement-style-helper";
import style from './style.module.scss';

export function CardBackground(
  {
    className,
    bgStyle,
    bgImage,
    bgGradient,
    backgroundColor,
    isInVideo
  }
) {
  const backgroundStyle = useMemo(() => {
    return getElementBackgroundStyle({bgStyle, bgImage, bgGradient, backgroundColor, isInVideo});
  }, [backgroundColor, bgGradient, bgImage, bgStyle, isInVideo]);

  return <div className={classnames(style.container, className)} style={backgroundStyle}/>
}
