import React, {createContext, useCallback, useContext, useState} from 'react';

const noOp = (() => {
});

const AutoResizeGroupGet = createContext(noOp);
const AutoResizeGroupSet = createContext(noOp);

export const AUTO_RESIZE_GROUPS = {
  EXPLORER_BUTTON: 'EXPLORER_BUTTON',
  EXPLORER_OVERLAY: 'EXPLORER_OVERLAY',
  MULTIPLE_CHOICE_OPTIONS: 'MULTIPLE_CHOICE_OPTIONS',
  SLIDER_QUESTION_LABEL: 'SLIDER_QUESTION_LABEL'
}

export const useGroupResize = () => {
  const getGroupFontSize = useContext(AutoResizeGroupGet);
  const setGroupFontSize = useContext(AutoResizeGroupSet);
  return {
    getGroupFontSize,
    setGroupFontSize
  }
}

export function AutoResizeProvider({children}) {
  const [groupState, setGroupState] = useState({});

  const setGroupFontSize = useCallback((group, fontSize, reset) => {
    setGroupState((groupState) => {
      let groupFontSize = groupState[group];

      if (reset) {
        return {...groupFontSize, [group]: fontSize};
      }

      if (groupFontSize === undefined || groupFontSize === null) {
        groupFontSize = Infinity;
      }

      if (fontSize < groupFontSize) {
        return {...groupFontSize, [group]: fontSize};
      }
      return groupState;
    })
  }, [setGroupState]);

  const getGroupFontSize = useCallback((group) => {
    let groupFontSize = groupState[group];
    if (groupFontSize === undefined || groupFontSize === null) {
      groupFontSize = Infinity;
    }
    return groupFontSize;
  }, [groupState]);

  return <AutoResizeGroupGet.Provider value={getGroupFontSize}>
    <AutoResizeGroupSet.Provider value={setGroupFontSize}>
      {children}
    </AutoResizeGroupSet.Provider>
  </AutoResizeGroupGet.Provider>
}
