module.exports = {
    //vgpServerUrl: 'https://odc.kube.viralgains.com/api/v1/player-service',// this is the player server that return the player settings as json
    vgpServerUrl: 'https://player.kube.viralgains.com',// this is the player server that return the player settings as json
    //vgaServerUrl: 'https://yconib4i3i.execute-api.us-east-1.amazonaws.com/vg/events/capture', // old pipeline - can temporarily revert to this direct link if there is an issue with DNS
    // vgaServerUrl: 'https://events.viralgains.com/events/capture', // old pipeline - can temporarily revert to this if the new pipeline has an issue.  Check kinesis shards/scale up.
    vgaServerUrl: 'https://events.viralgains.com/capture',
    adChoicesBaseUrl: 'http://optout.networkadvertising.org', // this is specifically mentioned for adchoices to pick its own url
    adChoicesNoParams: true, // this is for adChoices URL. If true, does not send any params.
    fbApiKey: '774402896082169', // api key could be overriden for different domains/env/local
    fbAccessToken: '774402896082169|Tul7YouZnim_34NXsU94osp0JP8', // facebook viralgains app access_token (see Safs for un/pw if needed)
    pExelateParam: 752, //exelate dmp intergration id
    exelateTrackingEnabled: true, // exelate param to turn off tracking only enabled in prod
    vpaidAdStoppedTimeoutSeconds: 7, // timeout seconds to wait for sending adStopped event if splash is displayed
    removeAdWhenStopped: false, // remove ad from page if ad stopped is sent
    gExelateParam: 2, // exelate param
    sentryUrl: 'https://35edfa76edfb4b3abd5ca2ebcd94ef88@sentry.kube.viralgains.com/5658710', // sentry url,
    adChoicesOptURL: '/', // this is for adChoices opt in/opt out page
    odcGatewayUrl: 'https://odc.kube.viralgains.com/api',
    odcReportingService: 'v1/reporting-service',
    odcPlayerService: '/v1/player-service',
    googleAnalyticsSampleRate: 20, // the sample rate for google analytics (currently set to 1 out of every 20)
    googleAnalyticsAccountId: 'UA-31656371-2', // the google analytics account to use,
    googleMapsApiKey: 'AIzaSyDzrX_enhXad9YpUCL0Auvvk8wcp06StSA', // the google api key to use with google maps
    liveRampIdFetchUrl: 'https://api.rlcdn.com/api/identity/idl?pid=13702',
    isRealLiveRampIdFetchEnabled: true,
    clientHintsGetHighEntropy: true
};
