import React, {useMemo} from "react";
import {getOpacityStyle} from "utils/engagement-helper";
import style from './style.module.scss';

export function BackgroundImage({url, cta = '#', onClick, opacity = 100}) {
  const imgStyle = useMemo(() => {
    return {
      ...getOpacityStyle(opacity),
      backgroundImage: `url(${url})`,
      ...(!cta && {cursor: 'default'})
    }
  }, [opacity, url, cta]);

  return <a
    href={cta || '#'}
    target={cta ? '_blank' : '_self'}
    className={style.customBackgroundImage}
    style={imgStyle}
    onClick={(e) => onClick(e)}
    data-testid={'custom-image'}
    rel="noreferrer"
  />;
}

  // adding a custom href to be picked by the onclick function later
export function ImageTag({src, onClick, ctaUrlImage, forceOnClick}) {
  return <img
      src={src}
      href={ctaUrlImage}
      onClick={forceOnClick || ctaUrlImage ? onClick : null}
      className={style.customImage}
      />
}
