import {
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import ResizeObserver from "resize-observer-polyfill";

// AnimationFrame is not Supported in IE

// Polyfill requestAnimationFrame
const requestAnimationFrame = window.requestAnimationFrame
  || window.mozRequestAnimationFrame
  || window.webkitRequestAnimationFrame
  || window.msRequestAnimationFrame
  || function (f) {
    return setTimeout(f, 1000 / 60)
  }

// Polyfill cancelAnimationFrame
const cancelAnimationFrame = window.cancelAnimationFrame
  || window.mozCancelAnimationFrame
  || function (requestID) {
    clearTimeout(requestID)
  }

const isOverflown = (element, isSafari) => {
  let scrollDelta = 0;
  /*
  * `scrollDelta` is being used, because at certain zoom levels due to some rounding issues in Safari the `scrollHeight` is 1px more than the `clientHeight`.
  * Even though the content is not actually overflowing the container. This is same for `scrollWidth` and `clientWidth` too.
  *
  * Depending on the browser, we subtract 1px from `scrollHeight` and `scrollWidth` for overflow checking.
  * */
  if (isSafari) {
    scrollDelta = 1;
  }
  return ((element.scrollHeight - scrollDelta) > element.clientHeight) || ((element.scrollWidth - scrollDelta) > element.clientWidth);
};

const MAX_ITERATIONS = 50;
const DEFAULT_STEP_SIZE = -1;
const DEFAULT_MIN_FONT_SIZE = 0;

const MAX_TIME_OUT_FOR_ANIMATION_FRAME = 50;

const useFitText = (
  {
    minFontSize = DEFAULT_MIN_FONT_SIZE,
    getMaxFontSize,
    stepSize = DEFAULT_STEP_SIZE,
    onFinish,
    onStart,
    isSafari
  }
) => {
  const ref = useRef(null);
  const [fontSize, setFontSize] = useState(null);
  const [counter, setCounter] = useState(null);
  const [isResizeStarted, setResizeStarted] = useState(false);

  /*
  * Hack for Safari -
  * Some times in Safari - the AnimationFrame is not called.
  * So the initial resize is not done. - Adding a max timeout in case callback is not called.
  * */
  useLayoutEffect(() => {
    if (!isResizeStarted) {
      setTimeout(() => {
        setResizeStarted(true);
      }, MAX_TIME_OUT_FOR_ANIMATION_FRAME);
    }
  }, [isResizeStarted])

  useLayoutEffect(() => {
    const element = ref.current;
    let animationFrameId = null;
    const ro = new ResizeObserver(() => {
      onStart(ref);
      setResizeStarted(false);
      // Set Default font size - ie - That is set through its parent;
      const fontSize = parseFloat(getComputedStyle(element.parentElement).fontSize);
      setFontSize(fontSize);

      animationFrameId && cancelAnimationFrame(animationFrameId);
      animationFrameId = requestAnimationFrame(() => {
        setResizeStarted(true);
        setCounter(0);
      });
    });
    if (element) {
      ro.observe(element);
    }
    return () => {
      animationFrameId && cancelAnimationFrame(animationFrameId);
      ro.disconnect();
    };
  }, [onStart, ref]);

  // Check overflow and resize font
  useLayoutEffect(() => {
    const element = ref.current;
    if (!element || !fontSize || !isResizeStarted) {
      return;
    }

    // Kill Switch
    if (counter > MAX_ITERATIONS) {
      return onFinish && onFinish(fontSize, ref);
    }

    const isOverflow = isOverflown(element, isSafari);

    if (isOverflow) {
      const newFontSize = fontSize + stepSize;
      if (newFontSize > minFontSize) {
        setFontSize(newFontSize);
        setCounter(counter + 1);
      } else {
        onFinish && onFinish(fontSize, ref);
      }
    } else {
      onFinish && onFinish(fontSize, ref);
    }

  }, [ref, fontSize, onFinish, stepSize, minFontSize, counter, isResizeStarted, isSafari]);

  useLayoutEffect(() => {
    const maxFontSize = (getMaxFontSize && getMaxFontSize()) || Infinity
    if (fontSize > maxFontSize) {
      setFontSize(maxFontSize);
    }
  }, [fontSize, getMaxFontSize])

  return {fontSize, ref};
};

export default useFitText;
