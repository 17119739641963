/* global google, Image */
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {InfoWindow} from "elements/infoWindow";

const MAX_IMAGE_WIDTH = 30;
const MAX_IMAGE_HEIGHT = 30;

export const MAX_ITEM_TO_RENDER = 10;

export const MAX_NUMBER_OF_STORES_TO_ADD_NUMBER_TO = 99;

function fetchImage(url) {
  return new Promise((resolve) => {
    if (url) {
      const imageToLoad = new Image();
      imageToLoad.src = url;
      imageToLoad.onload = function () {
        resolve(imageToLoad);
      };
      imageToLoad.onerror = function (e) {
        console.log('Error Loading Image', e);
        resolve(null);
      };
    } else {
      resolve(null);
    }
  });
}

export async function getImageSize(url) {
  const imageToLoad = await fetchImage(url);
  if (!imageToLoad || !imageToLoad.width || !imageToLoad.height) {
    return undefined;
  }

  const imageWidth = imageToLoad.width;
  const imageHeight = imageToLoad.height;

  let outputWidth;
  let outputHeight;
  const imageRatio = imageWidth / imageHeight;

  if (imageWidth >= imageHeight) {
    outputWidth = Math.min(imageWidth, MAX_IMAGE_WIDTH);
    outputHeight = outputWidth / imageRatio;
  } else {
    outputHeight = Math.min(imageHeight, MAX_IMAGE_HEIGHT);
    outputWidth = (outputHeight * imageRatio);
  }

  return new google.maps.Size(outputWidth, outputHeight);
}


export function createMapIcon(backgroundColor, fontColor, text) {
  const vgDefaultPinIconTemplate = `data:image/svg+xml;utf-8, <svg aria-hidden="true" role="img" width="22.5" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
    <path fill="${encodeURIComponent(backgroundColor)}" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"></path>
    <text x="190" y="275" font-size="230px" font-family="Verdana" text-anchor="middle" fill="${encodeURIComponent(fontColor)}">${text}</text>
    </svg>`;

  return {
    url: vgDefaultPinIconTemplate
  };
}

export const STORE_LOCATOR_TABS = {
  MAP: 'MAP',
  LIST: 'LIST'
};

export function resetBounds(map, markerPins = [], lat, lng) {
  const markerPosition = markerPins[0]?.getPosition();
  if (markerPosition) {
    const bounds = new google.maps.LatLngBounds();
    const currentPoint = new google.maps.LatLng(lat, lng);
    bounds.extend(currentPoint);
    bounds.extend(markerPosition);
    map.fitBounds(bounds);

    // auto open first store (after fitting bounds)
    google.maps.event.addListenerOnce(map, 'idle', () => {
      map.setCenter(markerPosition);
    });
  }
}


export function getInfoWindowHtml(store, onPhoneClick, onDirectionClick) {
  return ReactDOMServer.renderToStaticMarkup(<InfoWindow store={store} onPhoneClick={onPhoneClick} onDirectionClick={onDirectionClick}/>)
}

export function openInfoWindow(map, marker, markerPin, setCurrentlyOpenInfoWindow, setLastMarker, onPhoneClick, onDirectionClick) {
  // lazy load the info window only when it's needed (to prevent adding thousands of unused elements to the DOM)
  const infoWindow = new google.maps.InfoWindow({
    content: getInfoWindowHtml(marker)
  });


  infoWindow.addListener("domready", function () {
    const phoneLink = document.getElementById("vgInfoWindowPhoneNumberLink");
    const directionLink = document.getElementById("vgInfoWindowDirectionLink");
    if (phoneLink) {
      phoneLink.onclick = onPhoneClick;
    }

    if (directionLink) {
      directionLink.onclick = onDirectionClick;
    }
  });

  infoWindow.open(map, markerPin);
  setCurrentlyOpenInfoWindow(infoWindow);
  google.maps.event.addListener(infoWindow, 'closeclick', function () {
    setLastMarker(null);
    setCurrentlyOpenInfoWindow(null);
  });
}

export function recenterMap(map, markerPin, marker, centerLat, centerLng, callback) {
  if (!markerPin || !marker) {
    return;
  }

  const bounds = new google.maps.LatLngBounds();
  if (!centerLng || !centerLng) {
    console.error('No user location found!');
  }
  const currentPoint = new google.maps.LatLng(centerLat, centerLng);
  bounds.extend(currentPoint);
  bounds.extend(markerPin.getPosition());
  map.fitBounds(bounds);
  callback();
}

