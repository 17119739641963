import {useCallback} from 'react';

import {getTarget, openUrl} from 'utils/url-helper';
import {CAROUSEL_ITEM_TYPE, CLICK_SRC_WIDGET, JourneyTriggerInteractiveEvent, AdUnitEvent} from 'utils/constants';

function useExplorerEngagementEvents(onEvent, isInVideo, fireJourneyTrigger, onClickThrough, triggerElementId, parentCreativeId, 
  setVideoExploreCampaign, setVideoExploreParentCampaignId, setVideoExploreCampaignId, setVideoExploreOptionPosition) {
  const onClick = useCallback((itemId, adUnitId, position, optionIndex, destinationURL, itemType) => {
    if (itemType === CAROUSEL_ITEM_TYPE.PRODUCT) {
      const target = getTarget(destinationURL);
      openUrl(destinationURL, target);
      onEvent({
        type: AdUnitEvent.ClickEvent,
        engagementitemid: itemId,
        optionPosition: position,
        srcWidget: CLICK_SRC_WIDGET.VIDEO_EXPLORER_ENGAGE_CARD
      });

      const jtPosition = optionIndex === null || optionIndex === undefined ? position : optionIndex;
      // Note: helper event for in-session journey, is converted to interactiveExplorerClickThrough before sending to event ingestion/druid
      fireJourneyTrigger(JourneyTriggerInteractiveEvent[`interactiveExplorerOption${jtPosition}ClickThrough`], triggerElementId);
      
      fireJourneyTrigger(JourneyTriggerInteractiveEvent.interactiveEngagementExperienceClickThrough, triggerElementId);

      if (isInVideo) {
        fireJourneyTrigger(JourneyTriggerInteractiveEvent.interactiveInVideoClickThrough, triggerElementId);
      }
      if (onClickThrough) {
        onClickThrough()
      }
    } else {
      // TODO: refactor to use state instead of mutating  global
      setVideoExploreCampaign(true);
      setVideoExploreParentCampaignId(parentCreativeId);
      setVideoExploreCampaignId(adUnitId);
      setVideoExploreOptionPosition(position);
      onEvent({}, adUnitId);

    }
  }, [onEvent, isInVideo, fireJourneyTrigger, onClickThrough, triggerElementId, setVideoExploreCampaign, setVideoExploreParentCampaignId, setVideoExploreCampaignId, setVideoExploreOptionPosition, parentCreativeId]);
  return {onClick};
}

export default useExplorerEngagementEvents;
