import React, {useMemo} from 'react';
import { v4 as uuidv4 } from 'uuid';

import useImageSurveyEvents from './useImageSurveyEvents';

import EngagementCarousel from 'compounds/engagement-carousel';
import {CAROUSEL_ITEM_TYPE, DESCRIPTION_LAYOUT_TYPE} from 'utils/constants';
import surveyHelpers from 'utils/survey-helper';
import {useConfig} from 'utils/ConfigProvider';

function ImageSurvey({onEvent, overlay, mode, inVideo,  fireJourneyTrigger, submitSurvey, trackSurveyClickThrough, onClickThrough}) {
  const {environment} = useConfig();
  const {onClick} = useImageSurveyEvents(onEvent, inVideo, fireJourneyTrigger, submitSurvey, trackSurveyClickThrough, onClickThrough, overlay?.inSessionTriggerDetails?.triggerElementId, environment.isVPAIDEnabled());
  const {creativeCard} = overlay
  const {imageSurveyEngagement} = creativeCard;
  const {questions} = imageSurveyEngagement;
  const surveyID = imageSurveyEngagement.id;
  const surveyQuestion = questions?.[0];
  const questionId = surveyQuestion?.id
  const surveyRules = surveyQuestion?.options
    ?.filter((option) => {
      const areImageTrackingCodesPresent = option.imageTrackingCodes && option.imageTrackingCodes.length > 0;
      if (areImageTrackingCodesPresent) { 
        const isNonEmptyImageTrackingCodePresent = option.imageTrackingCodes.find((code) => code);
        if (!option.imageCTALink && !isNonEmptyImageTrackingCodePresent) {
          return false
        }
      } else if (!option.imageCTALink) {
        return false;
      }
      return true;
    })
    ?.map((option) => ({
      jsCodes: option.imageTrackingCodes,
      optId: option.id,
      webUrl: option.imageCTALink 
    }));

  const rendererProps = useMemo(() => getProps(overlay), [overlay]);

  return (
    <EngagementCarousel
      mode = {mode}
      onClick = {!environment.isCreativeStudioPreviewMode() ? (selectedQuestionOptionID, adUnitId, position, optionIndex) => {
        onClick(surveyID, questionId, position, optionIndex, selectedQuestionOptionID, surveyHelpers.getSelectedQuestionOptionRule(surveyRules, selectedQuestionOptionID))
      } : null}
      inVideo={inVideo}
      {...rendererProps}
    />
  )
}

const getProps = (overlay) => {
  const {creativeCard} = overlay
  const {imageSurveyEngagement} = creativeCard;
  const titleFontSettings = {
    ...imageSurveyEngagement.font,
    color: imageSurveyEngagement.questions?.[0]?.text?.color || imageSurveyEngagement?.title?.color || "white"
  } 
  const {questions} = imageSurveyEngagement;
  const surveyQuestion = questions?.[0];
  const title = surveyQuestion?.text?.text
  const items = surveyQuestion?.options?.map((option) => {
    const optionId = option.id ? option.id : uuidv4();

    return {
      ...option,
      id: optionId,
      itemId: optionId,
      layoutType: option.type,
      itemType: CAROUSEL_ITEM_TYPE.PRODUCT,
      imageSrcUrl: option?.surveyOptionImage?.linkURL,
      title: option.type !== DESCRIPTION_LAYOUT_TYPE.NONE && option?.title?.text,
      buttonColor: option.title?.bgColor,
      titleFontSettings: {
        ...option.title,
        fontWeight: option.title.weight,
        fontSize: option.title.size
      }
    };
  }) || [];
  return {items, title, titleFontSettings}
}


export default ImageSurvey;
