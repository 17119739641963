// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container--mwr9b{max-width:100%;max-height:100%;display:block}.transparent--16xCi{color:rgba(0,0,0,0)}.hiddenElement--13rmA{opacity:0}", ""]);
// Exports
exports.locals = {
	"container": "container--mwr9b",
	"transparent": "transparent--16xCi",
	"hiddenElement": "hiddenElement--13rmA"
};
module.exports = exports;
