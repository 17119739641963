import React from "react";
import classnames from 'classnames';

import Engagement from "compounds/engagement";
import {CloseIcon} from 'elements/icons';
import style from './style.module.scss';
import {CardBackground} from "elements/cardBackground";

const noOp = () => {};

function OverlayFinale(
  {
    backgroundColor,
    bgStyle,
    bgImage,
    bgGradient,
    closeShowing = true,
    onClose,
    fontSettings,
    children,
    noPadding,
    isFull,
    renderHotspots = noOp,
    canRenderOutsideCard = false,
    removeZIndexFromContainer = false
  }
) {
  return <React.Fragment>
    <CardBackground
      className={classnames(style.backgroundLayer)}
      backgroundColor={backgroundColor}
      bgStyle={bgStyle}
      bgImage={bgImage}
      bgGradient={bgGradient}
    />
    <div className={classnames(style.finaleContainer, isFull ? style.fullFinaleContainer : null)} data-testid='finale'>
      {
        closeShowing ?
        <CloseIcon onClose={onClose} className={style.closeIconAbsolute}/> :
        <React.Fragment/>
      }
      <div
        className={classnames(style.engagementContentContainer, removeZIndexFromContainer ? style.engagementContentContainerWithoutZIndex : null)}>
        <Engagement
          onClose={onClose}
          fontSettings={fontSettings}
          noPadding={noPadding}
        >
          {children}
        </Engagement>
      </div>
      {
        canRenderOutsideCard ?
          renderHotspots() :
          <React.Fragment/>
      }
    </div>
  </React.Fragment>
}

export default OverlayFinale;
