// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../phoenix-app/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../images/email/gmail-30x30.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../images/email/aol-30x30.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../images/email/yahoo-30x30.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../images/email/outlook-30x30.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".iconContainer--22WT1{display:flex;flex-wrap:nowrap;font-size:1em;align-items:center;color:#007bff}.iconContainer--22WT1:hover,.iconContainer--22WT1:focus{cursor:pointer}.icon--3WYem{width:1.5em;height:1.5em;background-size:contain;background-repeat:no-repeat}.gmail--268BJ{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.aol--3YDJg{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.yahoo--2S7rh{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.outlook--TKUpa{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.iconContainer--22WT1 span{margin-left:.6em;font-size:.8em}.iconContainer--22WT1:focus,.iconContainer--22WT1:hover>span{text-decoration:underline}", ""]);
// Exports
exports.locals = {
	"iconContainer": "iconContainer--22WT1",
	"icon": "icon--3WYem",
	"gmail": "gmail--268BJ",
	"aol": "aol--3YDJg",
	"yahoo": "yahoo--2S7rh",
	"outlook": "outlook--TKUpa"
};
module.exports = exports;
