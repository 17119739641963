import React, {useCallback, useEffect, useMemo} from 'react';
import OverlayInVideo from "compounds/overlay-invideo";
import {useOverlayComponent} from "modules/overlay/useOverlayComponent";

import {getProps, renderHotspotOutsideCard} from "utils/engagement-helper";
import {ENGAGEMENT_DISPLAY_MODE, OVERLAY_INDEX} from "utils/constants";
import HotspotManager from "modules/hotspot-manager";
import {useOverlayHotspotHandler} from "./useOverlayHotspotHandler";
import {useConfig} from "utils/ConfigProvider";

export const InVideoOverlay = (
  {
    settings,
    overlay,
    onEngagementEvent,
    fireJourneyTrigger,
    fireCallback,
    onClickThrough,
    submitSurvey,
    onInVideoCollapseToggle,
    activeOverlay,
    setEngagementMode,
    showingOverride,
    getSelectedPosition,
    setSelectedOption,
    scrubBarShowing,
    onHotspotClickAway,
    cardHotspotFireJourneyTrigger,
    submitSurveyByHotspotClickThrough,
    creativeDimensions,
    hotspotProps,
    isSurveySubmitted,
    submissionDetails,
    setsubmissionDetailsTime,
    overlayState
  }
) => {
  const {environment} = useConfig();
  const mode = useMemo(() => overlay ? (['Left', 'Right'].includes(overlay.inVideoEngagementPosition) ? ENGAGEMENT_DISPLAY_MODE.VERTICAL : ENGAGEMENT_DISPLAY_MODE.HORIZONTAL) : null, [overlay]);

  useEffect(() => {
    return setEngagementMode(mode)
  }, [mode, setEngagementMode]);

  const isInVideoShowing = useMemo(() => {
    if (!overlay) {
      return false;
    }
    const {[OVERLAY_INDEX]: overlayIndex} = overlay;
    const {[OVERLAY_INDEX]: activeOverlayIndex} = activeOverlay || {};
    return environment.isCreativeStudioPreviewMode() ? settings.selectedOverlay === overlayIndex && overlayIndex === activeOverlayIndex : overlayIndex === activeOverlayIndex;
  }, [overlay, activeOverlay, settings.selectedOverlay, environment]);

  const {creativeCard, isSharedCard} = overlay
  const creativeCardType = creativeCard?.creativeCardType
  const {fireCardHotspotJourneyTrigger} = useOverlayHotspotHandler(overlay, cardHotspotFireJourneyTrigger, submitSurveyByHotspotClickThrough);
  const renderHotspots = useCallback(({renderWithoutContainer = false, hotspotContainer, interactProps, containerDimensions} = {}) => {
      const {creativeCard} = overlay
      return creativeCard.hotspots ?
      <HotspotManager
        hotspots={creativeCard.hotspots}
        onHotspotClickAway={onHotspotClickAway}
        fireJourneyTrigger={fireCardHotspotJourneyTrigger}
        onEvent={onEngagementEvent}
        showing={true} // always show when inVideo overlay is showing
        setHotspotOverlay={() => {}} // noop as no card hotspot allowed within card
        onClickThrough={onClickThrough}
        isCardHotspot={true}
        overlayID={creativeCard.id}
        isCardAndInVideoAndFinale={overlay.inVideoEngagementEnabled && overlay.finaleEnabled}
        isInVideoOverlay={true}
        hotspotProps={hotspotProps}
        renderWithoutContainer={renderWithoutContainer}
        hotspotContainer={hotspotContainer}
        interactProps={interactProps}
        containerDimensions={containerDimensions}
        isSharedCard={isSharedCard}
      /> :
      <React.Fragment/>
  }, [isSharedCard, fireCardHotspotJourneyTrigger, hotspotProps, onClickThrough, onEngagementEvent, onHotspotClickAway, overlay]);

  const Engagement = useOverlayComponent(overlay, settings, onEngagementEvent, true, mode, fireJourneyTrigger, fireCallback, submitSurvey, isInVideoShowing, onClickThrough, getSelectedPosition, setSelectedOption, renderHotspots, creativeDimensions, hotspotProps,scrubBarShowing, isSurveySubmitted, submissionDetails, setsubmissionDetailsTime, overlayState);

  const props = useMemo(() => getProps(overlay), [overlay]);

  const canRenderOutsideCard = useMemo(() => {
    return (creativeCardType && renderHotspotOutsideCard(creativeCardType));
  }, [creativeCardType]);

  return <OverlayInVideo
    {...props}
    onToggle={onInVideoCollapseToggle}
    showing={isInVideoShowing && showingOverride}
    scrubBarShowing={scrubBarShowing}
  >
    {showingOverride && Engagement}
    {
      canRenderOutsideCard ?
        renderHotspots() :
        <React.Fragment/>
    }
  </OverlayInVideo>
}
