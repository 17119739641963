/* global ga, IntersectionObserver */
import {constants} from "@phoenix/core";
const {ErrorType} = constants;


export default class ErrorHelper {
  static getSettingsErrorCode(error) {
    let  errorCode = null;
    if (error.status) {
      errorCode = error.status
    }

    return  ErrorType.FETCH_SETTINGS_ERROR +  (errorCode ? `_${errorCode}` : '');
  }

}
