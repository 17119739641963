const CACHE_BUSTING_MACRO = ['[VG_TIMESTAMP]', '[TIMESTAMP]', '[timestamp]', '[CACHEBUSTER]', '${VG_CACHEBUSTER}', '${CACHEBUSTER}'];
const VG_SRC_URL_MACRO = '${VG_SRC_URL}';

export const replaceCacheBustingMacro = (content) => {
  let updatedContent = content;
  const timestamp = Date.now();
  CACHE_BUSTING_MACRO.forEach((item) => {
    updatedContent = updatedContent.replaceAll(item, timestamp);
  });
  return updatedContent;
};

export const replaceVgMacro = (content, srcUrlWithoutQueryParam ) => {
  const encodedURI = encodeURIComponent(srcUrlWithoutQueryParam);
  const replacedMacroStr = content.replaceAll(VG_SRC_URL_MACRO, encodedURI)
  return replacedMacroStr.replaceAll(encodeURIComponent(VG_SRC_URL_MACRO), encodeURIComponent(encodedURI)); // replace encoded macro with double decoded url
};


