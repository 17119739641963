// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".height40--qsXpJ{height:40%}.maxWidth--2P1nT{max-width:100%}", ""]);
// Exports
exports.locals = {
	"height40": "height40--qsXpJ",
	"maxWidth": "maxWidth--2P1nT"
};
module.exports = exports;
