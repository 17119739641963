import {useEffect, useRef, useState} from 'react';
import {captureException} from "@sentry/browser";
import CustomError from "utils/customError";
import {useConfig} from "utils/ConfigProvider";
import {AUTOPLAY_MODE, CLICK_SRC_WIDGET, PLAYER_STATE, VAST_EVENTS, LOG_DETAILS_TYPE, AdUnitEvent, ErrorType} from 'utils/constants';
import MessageHelper from "utils/messageHelper";
import {doGet} from "utils/eventUtil";
import {openUrl} from "utils/url-helper";
import {isString} from "../../../../utils/helpers";

const AD_ERROR_LABEL = 'AdError ';
const VAST_ERROR_CODE = [1005, 100, 101, 102, 200, 201, 202, 203, 300, 301, 302, 303, 400, 401, 402, 403, 405, 500, 501, 502, 503, 600, 601, 602, 603, 604, 900];
const VAST_ERROR_MSG = ['Ad blocked'];
const VAST_ERROR_CODE_OR_MSG = [...VAST_ERROR_CODE.map((code)=>`${AD_ERROR_LABEL}${code}`), ...VAST_ERROR_MSG];

const noop = _ => {}

const logMissingCurrentTimeError = () => {
  captureException(new CustomError('Quartile fired without currentTime'), {extra: {errorType: ErrorType.QUARTILE_WITHOUT_CURRENT_TIME}})
}

function useEvents({vastEvents, onDurationFetch, onPlayerTimeChange, onPlayerStateChange, onMuteStateChange, isMute, playerState, track3rdPartyEvents, getCreativeElement,
  onClickThrough, modelDurationSeconds, onEvent, previewInitialPlayingHandler, autoPlayMode, setQuartile, setImpressionEvent, captureSentryError,
  shouldPhoenixSkipFiringStandardVastPixel, isVPAIDEnabled, isEditMode, isCreativeStudioPreviewMode, captureAssetLoadingTime, notifyOnAdVideoStart}) {

  const trackingRef = useRef({});
  const [previewReady, setPreviewReady] = useState(false);
  const { environment } = useConfig();

  const log = (...args) => console.log(...args);

  const [timer, setTimer] = useState(null);

  const stopTimer = _ =>{
    if(timer) {
      clearInterval(timer);
      setTimer(null);
    }
    onPlayerTimeChange(getCurrentTime()); // OC-1476 - avoid race conditions with trackingRef.current.completed and adRemainingTime
  };

  const startTimer = _ => {
    if(timer) {
      return;
    }

    const timerId = setInterval(() => {
      onPlayerTimeChange(getCurrentTime());
    }, 250);

    setTimer(timerId);
  }

  const sendToAll = data => window.parent.postMessage(JSON.stringify(data), '*');

  // TODO
  const onVolumeChange = _ => {}

  const sendVastEvent = (evType) => {
    if (!vastEvents) {
      log('vast event not captured due to vast events not being parsed', evType, this);
      return;
    }

    if (isVPAIDEnabled && shouldPhoenixSkipFiringStandardVastPixel(evType)) {
      return; // These events would be fired by odyssey-vpaid that's residing at publisher page.
    }

    const pixels = vastEvents[evType];
    if (pixels) {
      pixels.forEach((ev) => {
        if (!isEditMode) {
          doGet(ev);
        }
      });
    }
  }

  const trackClickThru = () => {
    onClickThrough();
  }


  const onStartAd = () => {
    log('onStartAd');
    if(environment.inHeavyAdsEmulation()) {
      MessageHelper.instance.sendToVg({code:"onStartAd"});
    }
    sendVastEvent('creativeView');
  }

  const onStopAd = () => {
    log('onStopAd');
    if(environment.inHeavyAdsEmulation()) {
      MessageHelper.instance.sendToVg({ code:"onStopAd"});
    }
    stopTimer();
    // if we get ad stop callback before ad video complete callback then need to inform publisher player that ad is stopped immediately.
    // in normal scenario we first get ad complete and then ad stop. in that case phoenix will take care of ad stop event after all engagement cards are shown.
    if (!trackingRef.current.completed) {
      captureException(new CustomError('Ad stop event fired before ad complete.'), {extra: {errorType: ErrorType.AD_STOPPED_BEFORE_COMPLETE}});
      onPlayerStateChange(PLAYER_STATE.STOPPED);
    }
  }

  const onSkipAd = () => {
    stopTimer();
    sendToAll({vgPlayerStatus: 'adSkipped'});
    log('onSkipAd');
    sendVastEvent('skip');
  }

  const onAdLoaded = () => {
    log('onAdLoaded');
    captureAssetLoadingTime(LOG_DETAILS_TYPE.VPAID_AD_LOAD, true);
    onPlayerStateChange(PLAYER_STATE.READY, previewReady);
    if (autoPlayMode === AUTOPLAY_MODE.NO_AUTOPLAY && !trackingRef.current.vastImpressionPixelFired) {
      trackingRef.current.vastImpressionPixelFired = true;
      sendVastEvent('impression'); // In case of ctp ad, fire vast impression pixel.
    }
    onDurationFetch(getDuration())
  }

  const onAdLinearChange = noop;
  const onAdSizeChange = noop;
  const onAdExpandedChange = noop;

  const onAdSkippableStateChange = _ => sendToAll({vgPlayerStatus: 'adSkippableStateChange'});


  const onAdDurationChange = noop;
  const onAdRemainingTimeChange = noop;

  const onAdVolumeChange = (event, volume) => {
    onVolumeChange();
    const isMuted = getCreativeElement().getAdVolume() === 0;
    onMuteStateChange(isMuted);
    sendVastEvent(isMuted ? VAST_EVENTS.MUTE : VAST_EVENTS.UNMUTE);
  }

  // Not used
  // const isAdLoaded = () => {
  //   return Boolean(playerState) && playerState !== PLAYER_STATE.READY;
  // }

  const trackImpression = () => {
    if (trackingRef.current.impression) {
      return;
    }
    trackingRef.current.impression = true;
    if (!isCreativeStudioPreviewMode) {
      setImpressionEvent(true);
    }
  }

  const onAdImpression = () => {
    log('onAdImpression');
    trackImpression();
    if (autoPlayMode !== AUTOPLAY_MODE.NO_AUTOPLAY && !trackingRef.current.vastImpressionPixelFired) {
      trackingRef.current.vastImpressionPixelFired = true;
      sendVastEvent(VAST_EVENTS.IMPRESSION);
    }
  }

  /**
   * @description A handler to respond to ad clicks
   * @param {String} [url] event data url (optional)
   * @param {any} [id] unused parameter (optional)
   * @param {Boolean} playerHandles when set to true and the url is:
   * a. Not defined: then the video player must use the VAST element, VideoClicks/ClickThrough.
   * b. Defined: then the video player must use url.
   *
   * When set to false, then the video player does not open the landing page URL. The ad unit is responsible
   * for opening the landing page URL in a new window in this case.
   *
   * Note: Regardless of the state of the event.data.handles, the video player must request the resource specified
   * by the URIs in the <VideoClicks> and <ClickTracking> elements of VAST when it receives an AdClickThru VPAID event.
   * @returns {void}
   * @see https://www.iab.com/wp-content/uploads/2015/06/VPAID_2_0_Final_04-10-2012.pdf
   */
  const onAdClickThru = (url, id, playerHandles) => {
    // capture druid event for the clickthru
    const payload = {
      type: AdUnitEvent.ClickEvent,
      srcWidget: CLICK_SRC_WIDGET.VPAID_CLICK_THRU,
      elapsedSeconds: getCurrentTime()
    };
    onEvent(payload);

    if (playerHandles) {
      const urlToOpen = url || vastEvents.clickThrough;

      if (urlToOpen && !isCreativeStudioPreviewMode) {
        openUrl(urlToOpen);
      }
    }
    sendVastEvent('clickTracking');
    trackClickThru();
  }

  const onAdInteraction = () => {
    log('onAdInteraction');
    // TODO phoenix
 //   $('#app').toggleClass('vast-ad-interacted', this.hidePlayerChromeWhenAdInteraction);
    // capture druid event for the adInteraction
    const payload = {
      type: AdUnitEvent.VPaidAdInteraction
    };

    onEvent(payload);
  }

  const onAdVideoStart = () => {
    log('onAdVideoStart');
    if (!getCreativeElement()) {
      return;
    }
    if (notifyOnAdVideoStart instanceof Function) {
      notifyOnAdVideoStart();
    }
    startTimer();
    sendVastEvent('start');

    if (isCreativeStudioPreviewMode && !previewReady) {
      previewInitialPlayingHandler();
      onDurationFetch(getDuration());
    } else {
      onPlayerStateChange(PLAYER_STATE.PLAYING);
    }
  }

  const getCurrentTime = () => {
    const duration = getDuration();
    const remTime = getAdRemainingTime();
    if (trackingRef.current.completed && duration > 0) {
      return duration;
    }
    if (duration <= 0 || remTime < 0) {
      if (duration <= 0) {
        captureException(new CustomError('Invalid Duration!'), {extra: {errorType: ErrorType.INVALID_DURATION, duration}})
      }
      return 0;
    }
    return Math.floor(duration - remTime);
  }

  const getAdRemainingTime = () => {
    const adRemainingTime = getCreativeElement() ? Math.floor(getCreativeElement().getAdRemainingTime()) : 0;
    if (trackingRef.current.completed && (adRemainingTime == null || adRemainingTime < 0)) {
      return 0;
    }
    return adRemainingTime;
  }



  const getDuration = () => {
    if (getCreativeElement()) {
      const creativeDuration = Math.floor(getCreativeElement().getAdDuration());
      if (creativeDuration > 0) {
        return creativeDuration;
      }
    }
    return modelDurationSeconds || 0;
  }

  const onAdVideoFirstQuartile = () => {
    const currentTime = getCurrentTime();
    const adRemainingTime = getAdRemainingTime();
    console.log('onAdVideoFirstQuartile called!! Current time:', currentTime, 'Ad Remaining time:', adRemainingTime);

    if (!currentTime) {
      logMissingCurrentTimeError()
    }
    setQuartile(1);
    sendVastEvent(VAST_EVENTS.FIRST_QUARTILE);
  }

  const onAdVideoMidpoint = () => {
    const currentTime = getCurrentTime();
    const adRemainingTime = getAdRemainingTime();
    console.log('onAdVideoMidpoint called!! Current time:', currentTime, 'Ad Remaining time:', adRemainingTime);

    if (!currentTime) {
      logMissingCurrentTimeError()
    }
    setQuartile(2);
    sendVastEvent(VAST_EVENTS.MIDPOINT);
  }

  const onAdVideoThirdQuartile = () => {
    const currentTime = getCurrentTime();
    const adRemainingTime = getAdRemainingTime();
    console.log('onAdVideoThirdQuartile called!! Current time:', currentTime, 'Ad Remaining time:', adRemainingTime);

    if (!currentTime) {
      logMissingCurrentTimeError()
    }
    setQuartile(3);
    sendVastEvent(VAST_EVENTS.THIRD_QUARTILE);
  }


  const onAdVideoComplete = () => {
    trackingRef.current.completed = true;
    console.log('onAdVideoComplete called!! Current time:', getCurrentTime(), 'Ad Remaining time:', getAdRemainingTime());
    stopTimer();
    onPlayerStateChange(PLAYER_STATE.COMPLETED);
    sendVastEvent(VAST_EVENTS.COMPLETE);

    // This will trigger sentry error if adCompletion is fired too early before video duration (taking 3s gap)
    const currentTime = getCurrentTime();
    const videoDuration = getDuration();
    const remainingTime = getAdRemainingTime();
    if (videoDuration - currentTime > 3 && currentTime > 0) { // Sometimes we get negative current time when ad is completed. So making sure currentTime is not negative
      const actualAdRemainingTime = getCreativeElement() ? Math.floor(getCreativeElement().getAdRemainingTime()) : 0;
      captureException(new CustomError('Calling onAdVideoComplete too early!!'), {extra: {currentTime, videoDuration, remainingTime, actualAdRemainingTime}});
    }
  }

  const onAdUserAcceptInvitation = () => {
    sendVastEvent('acceptInvitation');
  }

  const onAdUserMinimize = () =>  {
    sendVastEvent('collapse');
  }

  const onAdUserClose = () => {
    if (!trackingRef.current.trackClosed) {
      trackingRef.current.trackClosed = true;
    }
    log('onAdUserClose');
    sendVastEvent('close');
  }

  const onAdPaused = () => {
    log('onAdPaused');

    // In studio we play/pause the vpaid creative to get it to load, so ignore the first pause state change to keep the craetive in READY status to show thumbnail
    if (isCreativeStudioPreviewMode && !previewReady) {
      onPlayerStateChange(PLAYER_STATE.READY);
    } else {
      onPlayerStateChange(PLAYER_STATE.PAUSED);
    }
    stopTimer();
    setPreviewReady(true);
    sendVastEvent(VAST_EVENTS.PAUSE);
  }

  const onAdPlaying = () => {
    console.log('Ad playing started');
    startTimer();
    log('onAdPlaying');
    if (isCreativeStudioPreviewMode && !previewReady) {
      previewInitialPlayingHandler();
      onDurationFetch(getDuration());
    }
    onPlayerStateChange(PLAYER_STATE.PLAYING);
    MessageHelper.instance.playerStatus('playing', getCurrentTime(), getDuration(), isMute ? 0 : 1)
    sendVastEvent('resume');
  }

  const onAdError = (details) => {
    log('onAdError', details);
    sendVastEvent('error');
    const errorMsg = getErrorMsg(details);
    if(errorMsg === ErrorType.AD_ERROR) {
      // if generic capture stacktrace in sentry
      captureException(new CustomError(`vpaid ad error:: ${errorMsg}`), {extra: {errorType: errorMsg}});
    } else {
      // else only capture counts in druid
      captureSentryError({extra: {errorType: errorMsg}});
    }
    if(environment.inHeavyAdsEmulation()) {
      MessageHelper.instance.sendToVg({ code:'onAdError'});
    }
    stopTimer();
  }

  const getErrorMsg = (error) => {
    const errorMsg = ErrorType.AD_ERROR;
    if (error) {
      const errorCode = VAST_ERROR_CODE_OR_MSG.find((code) => error.indexOf(code) > -1);
      if (errorCode) {
        return `${errorMsg}_${errorCode.replace(AD_ERROR_LABEL, '')}`;
      }
    }
    return errorMsg;
  }

  const onAdLog = _ => log(arguments);


  const Callbacks = {
    AdStarted: onStartAd,
    AdStopped: onStopAd,
    AdSkipped: onSkipAd,
    AdLoaded: onAdLoaded,
    AdLinearChange: onAdLinearChange,
    AdSizeChange: onAdSizeChange,
    AdExpandedChange: onAdExpandedChange,
    AdSkippableStateChange: onAdSkippableStateChange,
    AdDurationChange: onAdDurationChange,
    AdRemainingTimeChange: onAdRemainingTimeChange,
    AdVolumeChange: onAdVolumeChange,
    AdImpression: onAdImpression,
    AdClickThru: onAdClickThru,
    AdInteraction: onAdInteraction,
    AdVideoStart: onAdVideoStart,
    AdVideoFirstQuartile: onAdVideoFirstQuartile,
    AdVideoMidpoint: onAdVideoMidpoint,
    AdVideoThirdQuartile: onAdVideoThirdQuartile,
    AdVideoComplete: onAdVideoComplete,
    AdUserAcceptInvitation: onAdUserAcceptInvitation,
    AdUserMinimize: onAdUserMinimize,
    AdUserClose: onAdUserClose,
    AdPaused: onAdPaused,
    AdPlaying: onAdPlaying,
    AdError: onAdError,
    AdLog: onAdLog
  };


  // add message listener from sub iframe
  useEffect(() => {
    const listener = (e) => {
      const jsonStr = e.data;
      if (isString(jsonStr) && jsonStr.indexOf('_vgvastvpaid_') > -1) {
        const obj = JSON.parse(jsonStr);
        if (Object.prototype.hasOwnProperty.call(obj, 'method') && !!getCreativeElement()) {
          Callbacks[obj.method].apply(null, obj.args);
        }
      }
    };
    window.addEventListener('message', listener);

    return () => window.removeEventListener('message', listener);

  }, [Callbacks, getCreativeElement]);

}




export default useEvents;
