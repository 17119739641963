import React, {useCallback, useState} from "react";
import classnames from 'classnames';
import {useGroupResize} from "utils/AutoResizeProvider";
import style from './style.module.scss';
import useFitText from "elements/autosize/useFitText";
import {captureException} from "@sentry/browser";
import CustomError from "../../utils/customError";
import {isSafari} from 'utils/environmentHelper';

function AutoSizeComponent(props) {
  const {
    getGroupFontSize,
    setGroupFontSize
  } = useGroupResize();

  const [isReady, setReady] = useState(true);
  const {children, className, resizeGroup, isMulti, style: propsStyles, hideElementsDuringResize, ...rest} = props;

  const getGroupFontSizeCallback = useCallback(() => {
    let groupFontSize = resizeGroup ? getGroupFontSize(resizeGroup) : null;
    if (groupFontSize === null) {
      groupFontSize = Infinity;
    }
    return groupFontSize;
  }, [resizeGroup, getGroupFontSize]);

  const onFinish = useCallback((fontSize, ref) => {
    setReady(true);
    if (resizeGroup && ref?.current && fontSize) {
      setGroupFontSize(resizeGroup, fontSize);
    }
  }, [resizeGroup, setGroupFontSize])

  const onStart = useCallback((ref) => {
    setReady(false)
    if (resizeGroup && ref?.current) {
      setGroupFontSize(resizeGroup, Infinity, true);
    }
  }, [resizeGroup, setGroupFontSize])

  const {fontSize, ref} = useFitText({
    getMaxFontSize: getGroupFontSizeCallback,
    onFinish,
    onStart,
    isSafari: isSafari()
  });

  const notReadyStyle = hideElementsDuringResize ? style.hiddenElement : style.transparent;

  return (
    <div className={classnames(style.container, isReady ? '' : notReadyStyle, className)}
         style={{...propsStyles, fontSize}}
         ref={ref} {...rest}>{children}</div>
  )
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    captureException(new CustomError('Maximum update depth exceeded for AutoSize'));
  }

  render() {
    if (this.state.hasError) {
      return <React.Fragment/>;
    }

    return this.props.children;
  }
}

export function AutoSize(props) {
  return <ErrorBoundary>
    <AutoSizeComponent {...props}/>
  </ErrorBoundary>
}
