// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".displayContainerOuter--3pg0W{width:100%;height:100%;overflow:hidden;display:flex;justify-content:center;align-items:center;background:rgba(0,0,0,0)}.displayContainer--snmTX{position:relative}.fullContainer--8QM7U{position:absolute;top:0;left:0;height:100%;width:100%}.displayContainerWithHotspot--2-kNS{height:100%;width:100%;display:flex}", ""]);
// Exports
exports.locals = {
	"displayContainerOuter": "displayContainerOuter--3pg0W",
	"displayContainer": "displayContainer--snmTX",
	"fullContainer": "fullContainer--8QM7U",
	"displayContainerWithHotspot": "displayContainerWithHotspot--2-kNS"
};
module.exports = exports;
