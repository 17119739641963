import React from 'react';

import ScrubIcon from 'elements/icons/scrub-icon';
import {SCRUB_ICONS} from 'utils/constants';

import style from '../style.module.scss';
import VolumeControl from '../volume-control';
import {isMobileDevice} from "utils/environmentHelper";

function MuteUnmute({isMute, handleOnClick, setVolume, isContentFlowCreative}) {
  return (
    <div className={style.scrubElementsContainer}>
      <div className={style.iconContainer}>
        <ScrubIcon variant={isMute ? SCRUB_ICONS.MUTE : SCRUB_ICONS.UNMUTE}  onClick={ () => handleOnClick(!isMute)}/>
        {isContentFlowCreative && !isMobileDevice() &&
          <div className={style.volumeControl}>
            <VolumeControl isMute={isMute} setVolume={setVolume}/>
          </div>
        }
      </div>
    </div>
  )
}

export default MuteUnmute;
