import React, {useMemo} from 'react';
import style from "common/styles/engagement-likert-survey.module.scss";
import classnames from "classnames";
import {ENGAGEMENT_DISPLAY_MODE, MARKER, MARKER_CONFIG} from "../../utils/constants";
import {StarAnimation} from "../starAnimation";

// We can add different optionClass, checkedClass, currentCheckedClass and animationComponent to different types of icons
export const CHECKED_ANIMATION_CONFIG = {
  [MARKER.STAR]: {
    animationComponent: StarAnimation
  },
  [MARKER.CUSTOM]: {
    optionClass: style.iconOption,
    currentCheckedClass: style.customIconOptionChecked
  }
}

export function LikertIcon(props) {
  const {
    option,
    setStarHover,
    isOptionChecked,
    isCurrentOptionChecked,
    starColor,
    mode,
    onClick,
    iconType,
    iconSize,
    customIcon,
    singleIcon,
    noPointer
  } = props;

  const {
    animationComponent: AnimationComponent,
    optionClass = style.option,
    checkedClass = style.optionChecked,
    currentCheckedClass
  } = useMemo(() => {
    return CHECKED_ANIMATION_CONFIG[iconType] || {}
  }, [iconType]);

  const optionImage = useMemo(() => {
    if (singleIcon) {
      return customIcon?.linkURL;
    }
    return option.customMarker?.linkURL;
  }, [customIcon, option, singleIcon]);

  const {hasIcon, icon, isCustom} = useMemo(() => {
    return MARKER_CONFIG[iconType] || MARKER_CONFIG[MARKER.STAR]; // Defaulting to Star Icon
  }, [iconType]);

  const {className: optionClassName, style: optionStyle} = useMemo(() => {
    const iconClassNames = [];
    if (hasIcon) {
      iconClassNames.push(icon.defaultStyle, icon.icon);
    }

    const scale = ((iconSize || 100) / 100).toFixed(2);

    const iconBoxSize = mode === ENGAGEMENT_DISPLAY_MODE.FULL_MODE ? style.fullIconBoxSize : style.iconBoxSize;

    return {
      className: classnames(isOptionChecked ? checkedClass : optionClass, isCurrentOptionChecked ? currentCheckedClass : null, ...iconClassNames, iconBoxSize),
      style: {
        color: starColor,
        transform: `scale(${scale})`,
        '--maxScale': scale,
        ...(
          (isCustom) ?
            {
              height: iconBoxSize
            } :
            {}
        )
      }
    }
  }, [checkedClass, currentCheckedClass, hasIcon, icon, iconSize, isCurrentOptionChecked, isCustom, isOptionChecked, mode, optionClass, starColor]);

  return <div
    className={classnames(style.starContainer)}
    onMouseEnter={() => setStarHover({isHovering: true, label: option.label})}
    onMouseLeave={() => setStarHover({isHovering: false})}
  >
    {
      isOptionChecked && AnimationComponent ?
        <AnimationComponent
          starColor={starColor}
        /> :
        <React.Fragment/>
    }
    <div
      key={option.id}
      className={classnames(optionClassName, {[style.noPointer]: noPointer})}
      style={optionStyle}
      onClick={onClick}
      data-testid={`likert-option-${option.id}`}
    >
      {
        (isCustom) ?
          (
            optionImage ?
              <img
                src={optionImage}
                alt={option.label}
                className={style.likertImageIcon}
              /> :
              <div className={style.iconPlaceholder}/>
          ) :
          <React.Fragment/>
      }
    </div>
  </div>
}
