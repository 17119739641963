import React, {useCallback, useEffect, useMemo, useState, useRef} from "react";
import {
  captureEvent,
  getOverlayPayload,
} from "utils/engagement-helper";
import useCardExposureEvents from "hooks/useCardExposureEvents";
import {useFullScreen} from "utils/FullScreenProvider";
import {InVideoOverlay} from "modules/overlay/InVideoOverlay";
import {FinaleOverlay} from "modules/overlay/FinaleOverlay";
import {ENGAGEMENT_SLIDE_DURATION} from "compounds/overlay-invideo";
import {journeyTriggerExposureEvents} from '../../utils/constants';

// Wrapper in order to wrap onEngagementEvent in a useCallback to prevent recomputation and interfere with mobile clicks in custom survey
const InVideoOverlayWrapper = ({
    settings,
    overlay,
    onEngagementEventBase,
    fireJourneyTrigger,
    fireCallback,
    onClickThrough,
    submitSurvey,
    onInVideoCollapseToggle,
    setEngagementMode,
    showingOverride,
    getSelectedPosition,
    setSelectedOption,
    scrubBarShowing,
    onHotspotClickAway,
    cardHotspotFireJourneyTriggerWrapper,
    submitSurveyByHotspotClickThrough,
    creativeDimensions,
    hotspotProps,
    isSurveySubmitted,
    inVideoOverlay,
    isInVideoShowing,
    submissionDetails,
    setsubmissionDetailsTime,
    overlayState
    
  }) => {
    
    const onEngagementEventWrapper = useCallback((evData, adSwitchId) => {
      return onEngagementEventBase(evData, adSwitchId, inVideoOverlay);
    }, [inVideoOverlay, onEngagementEventBase]);
    
    return <InVideoOverlay
      overlay={inVideoOverlay}
      activeOverlay={isInVideoShowing ? overlay : null}
      settings={settings}
      onEngagementEvent={onEngagementEventWrapper}
      fireJourneyTrigger={fireJourneyTrigger}
      fireCallback={fireCallback}
      submitSurvey={submitSurvey}
      submitSurveyByHotspotClickThrough={submitSurveyByHotspotClickThrough}
      onInVideoCollapseToggle={onInVideoCollapseToggle}
      setEngagementMode={setEngagementMode}
      onClickThrough={onClickThrough}
      showingOverride={showingOverride}
      getSelectedPosition={getSelectedPosition}
      setSelectedOption={setSelectedOption}
      scrubBarShowing={scrubBarShowing}
      onHotspotClickAway={onHotspotClickAway}
      cardHotspotFireJourneyTrigger={cardHotspotFireJourneyTriggerWrapper}
      creativeDimensions={creativeDimensions}
      hotspotProps={hotspotProps}
      isSurveySubmitted={isSurveySubmitted}
      submissionDetails={submissionDetails}
      setsubmissionDetailsTime={setsubmissionDetailsTime}
      overlayState={overlayState}
    />
}

/**
 * Responsible for rendering in-video and finale engagement cards
 * @param settings
 * @param onEvent
 * @param setRuntimeProperties
 * @param overlay
 * @param isInVideoShowing
 * @param isShowingOnPause
 * @param isShowingOnHotspot
 * @param isFinaleShowing
 * @param interval
 * @param onFinaleEEClose
 * @param onAdSwitch
 * @param setEngagementMode
 * @param onInVideoCollapseToggle
 * @param fireJourneyTrigger
 * @param submitSurvey
 * @param submitSurveyByHotspotClickThrough
 * @param track3rdPartyEvents
 * @param isCloseButtonShowing
 * @param onClickThrough
 * @param inVideoOverlays
 * @param resizeVersion
 * @param scrubBarShowing
 * @param onHotspotClickAway
 * @param setPixelFiringInProgress
 * @param creativeDimensions
 * @param hotspotProps
 * @returns {*}
 * @constructor
 */
function Overlay(
  {
    settings,
    onEvent,
    setRuntimeProperties,
    overlay,
    isInVideoShowing,
    isShowingOnPause,
    isShowingOnHotspot,
    isFinaleShowing,
    interval,
    onFinaleEEClose,
    onAdSwitch,
    setEngagementMode,
    onInVideoCollapseToggle,
    fireJourneyTrigger,
    submitSurvey,
    track3rdPartyEvents,
    isCloseButtonShowing,
    onClickThrough,
    inVideoOverlays,
    resizeVersion,
    scrubBarShowing,
    onHotspotClickAway,
    setPixelFiringInProgress,
    submitSurveyByHotspotClickThrough,
    creativeDimensions,
    hotspotProps,
    isSurveySubmitted,
    submissionDetails,
    setsubmissionDetailsTime,
    overlayState
  }
) {
  const fireCallback = useCallback((event) => track3rdPartyEvents(event, null, null, isShowingOnHotspot ? {hotspotId: overlay?.id} : {}), [isShowingOnHotspot, overlay, track3rdPartyEvents]);

  const {isFullScreen} = useFullScreen();

  const [showingOverride, setShowingOverride] = useState(true);
  const [selectedOption, setSelectedOption] = useState({});

  const isInitialMount = useRef(true);

    const getSelectedPosition = useCallback((surveyID) => {
        return selectedOption[surveyID];
    },[selectedOption])

  useEffect(() => {
      setSelectedOption({})
  },[overlay])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setShowingOverride(false);
    }
  }, [isFullScreen, resizeVersion]);

  useEffect(() => {
    let timeout = null;
    if (!showingOverride && (isFinaleShowing || isInVideoShowing)) {
      timeout = setTimeout(() => {
        setShowingOverride(true);
      }, isFinaleShowing ? 10 : (ENGAGEMENT_SLIDE_DURATION * 1.5));
    }
    return () => {
      clearTimeout(timeout);
    }
  }, [isFinaleShowing, isFullScreen, isInVideoShowing, showingOverride]);

  const onEngagementEvent = useCallback((evData, adSwitchId, currentOverlay = overlay) => {
    if (adSwitchId) {
      setRuntimeProperties(getOverlayPayload(currentOverlay, interval, isShowingOnHotspot));
      onAdSwitch(adSwitchId); // Note: id is only used in odc-ui integration
    } else {
      return onEvent(captureEvent(evData, currentOverlay, interval, isShowingOnHotspot));
    }
  }, [interval, onEvent, overlay, onAdSwitch, setRuntimeProperties, isShowingOnHotspot]);

  const onSurveyExposureEvents = useCallback((evData) => {
    onEvent({...getOverlayPayload(overlay, interval, isShowingOnHotspot), ...evData})
  }, [interval, isShowingOnHotspot, onEvent, overlay]);

  const isSurveyShowing = useMemo(() => isFinaleShowing || isInVideoShowing, [isFinaleShowing, isInVideoShowing]);

  const creativeCard = overlay?.creativeCard
  const creativeCardType = creativeCard?.creativeCardType;
  const fireJourneyExposureTrigger = useCallback(() => {
    if (journeyTriggerExposureEvents[creativeCardType]) {
      fireJourneyTrigger(journeyTriggerExposureEvents[creativeCardType]);
    }
  }, [creativeCardType, fireJourneyTrigger]);

  useCardExposureEvents(creativeCard, isSurveyShowing, onSurveyExposureEvents, fireJourneyExposureTrigger);

  const cardHotspotFireJourneyTriggerWrapper = useCallback((event, elementTriggerId, suppressJourneyTrigger=false) => {
      fireJourneyTrigger(`${event}_${elementTriggerId}`, overlay?.inSessionTriggerDetails?.triggerElementId, suppressJourneyTrigger)
  }, [fireJourneyTrigger, overlay]);

  return (
    <>
      {
        /* NOTE: we only allow 1 overlay at any given time but we render all of them to allow slide in/out to animate properly */
        !isFinaleShowing && inVideoOverlays.map((inVideoOverlay, index) => <InVideoOverlayWrapper
            key={`${index}_${inVideoOverlay.inVideoEngagementPosition}`}
            overlay={overlay}
            inVideoOverlay={inVideoOverlay}
            isInVideoShowing={isInVideoShowing}
            settings={settings}
            onEngagementEventBase={onEngagementEvent}
            fireJourneyTrigger={fireJourneyTrigger}
            fireCallback={fireCallback}
            submitSurvey={submitSurvey}
            submitSurveyByHotspotClickThrough={submitSurveyByHotspotClickThrough}
            onInVideoCollapseToggle={onInVideoCollapseToggle}
            setEngagementMode={setEngagementMode}
            onClickThrough={onClickThrough}
            showingOverride={showingOverride}
            getSelectedPosition={getSelectedPosition}
            setSelectedOption={setSelectedOption}
            scrubBarShowing={scrubBarShowing}
            onHotspotClickAway={onHotspotClickAway}
            cardHotspotFireJourneyTriggerWrapper={cardHotspotFireJourneyTriggerWrapper}
            creativeDimensions={creativeDimensions}
            hotspotProps={hotspotProps}
            isSurveySubmitted={isSurveySubmitted}
            submissionDetails={submissionDetails}
            setsubmissionDetailsTime={setsubmissionDetailsTime}
            overlayState={overlayState}
          />
        )
      }
      {
        isFinaleShowing &&
        <FinaleOverlay
          overlay={overlay}
          settings={settings}
          onEngagementEvent={onEngagementEvent}
          fireJourneyTrigger={fireJourneyTrigger}
          fireCallback={fireCallback}
          submitSurvey={submitSurvey}
          submitSurveyByHotspotClickThrough={submitSurveyByHotspotClickThrough}
          isCloseButtonShowing={isCloseButtonShowing}
          isShowingOnHotspot={isShowingOnHotspot}
          onFinaleEEClose={onFinaleEEClose}
          setEngagementMode={setEngagementMode}
          onClickThrough={onClickThrough}
          showingOverride={showingOverride}
          getSelectedPosition={getSelectedPosition}
          setSelectedOption={setSelectedOption}
          onHotspotClickAway={onHotspotClickAway}
          cardHotspotFireJourneyTrigger={cardHotspotFireJourneyTriggerWrapper}
          setPixelFiringInProgress = {setPixelFiringInProgress}
          creativeDimensions={creativeDimensions}
          hotspotProps={hotspotProps}
          scrubBarShowing={scrubBarShowing}
          isSurveySubmitted={isSurveySubmitted}
          submissionDetails={submissionDetails}
          setsubmissionDetailsTime={setsubmissionDetailsTime}
          overlayState={overlayState}
        />
      }
    </>
  )
}

export default Overlay;
