import {useState, useEffect, useCallback} from 'react';
import {AUTOPLAY_MODE, LOG_DETAILS_TYPE} from '../utils/constants';
import {shouldTryCanary, testCanary} from "../utils/testCanary";
import {testHeavyAdEncodings} from "../utils/testHeavyAdEncodings";

export function useAutoPlayState(autoplay=false, autoPlayMuted=false, settings, inHeavyAdsEmulation, isChrome, isInScreenShooterEmulation, setBrowserAutoplayDisabled, captureAssetLoadingTime) {
  const [ref, setRef] = useState();

  const [extraParams, setExtraParams] = useState({});

  const processAutoPlayMode = useCallback(async (videoElement, autoPlayMuted) => {
    captureAssetLoadingTime(LOG_DETAILS_TYPE.CANARY, false);

    const canarySuggestedAutoPlayMode = await testCanary(videoElement, autoplay, autoPlayMuted);
    if (autoplay && AUTOPLAY_MODE.NO_AUTOPLAY === canarySuggestedAutoPlayMode) {
      // TODO: refactor to be state driven
      setBrowserAutoplayDisabled(true);
    }
    captureAssetLoadingTime(LOG_DETAILS_TYPE.CANARY, true)
    captureAssetLoadingTime(LOG_DETAILS_TYPE.HEAVY_AD, false)

    const extraParams = await testHeavyAdEncodings(settings, canarySuggestedAutoPlayMode, inHeavyAdsEmulation, isChrome, isInScreenShooterEmulation);

    captureAssetLoadingTime(LOG_DETAILS_TYPE.HEAVY_AD, true)

    return extraParams;
  }, [autoplay, settings, inHeavyAdsEmulation, isChrome, isInScreenShooterEmulation, captureAssetLoadingTime, setBrowserAutoplayDisabled])

  useEffect(() => {
    if (ref || !shouldTryCanary(autoplay, autoPlayMuted)) {
      captureAssetLoadingTime(LOG_DETAILS_TYPE.PLAYER_MOUNT, true);
      processAutoPlayMode(ref, autoPlayMuted)
        .then((extraParams) => {
          setExtraParams(extraParams);
        })
        .finally(() => {
          captureAssetLoadingTime(LOG_DETAILS_TYPE.PLAYER_INITIALIZATION, false);
        });
    }
  }, [autoPlayMuted, autoplay, processAutoPlayMode, ref, captureAssetLoadingTime]);

  return {ref: setRef, ...extraParams};
}
