import React, {useCallback, useEffect, useMemo, useState} from 'react';
import style from './style.module.scss';
import classnames from "classnames";
import {ENGAGEMENT_DISPLAY_MODE} from "../../utils/constants";

// Using these value to make sliding smooth
const MIN = 0;
const MAX = 10000;
const STEP = 1;

export function LikertSlider(props) {
  const {
    value,
    optionClickHandler,
    totalOptions,
    sliderColors,
    options,
    setStarHover,
    starHover,
    mode,
    isDisplayCreative,
    height,
    width
  } = props;

  const [mouseDown, setMouseDown] = useState(false);
  const [interimValue, setInterimValue] = useState(value);

  const validStepSize = useMemo(() => MAX / (totalOptions - 1), [totalOptions]);

  const normalizeValueFromSlider = useCallback((value) => {
    return Math.round(value / validStepSize);
  }, [validStepSize]);

  const normalizeValueToSlider = useCallback((value) => {
    return value * validStepSize
  }, [validStepSize]);

  useEffect(() => {
    setInterimValue(normalizeValueToSlider(value));
  }, [normalizeValueToSlider, value]);

  const onChange = useCallback((e) => {
    setInterimValue(Number(e.target.value));
  }, []);

  const setOptionValue = useCallback((value) => {
    optionClickHandler(value);
    setInterimValue(normalizeValueToSlider(value));
  }, [normalizeValueToSlider, optionClickHandler])

  const onStart = useCallback(() => {
    setMouseDown(true);
  }, []);

  const onMove = useCallback((e) => {
    let value = e.target.value;
    if (!mouseDown) {
      value = (e.nativeEvent.offsetX / e.target.clientWidth) * parseInt(e.target.getAttribute('max'), 10);
    }
    const selectedIndex = normalizeValueFromSlider(Number(value));
    setStarHover({isHovering: true, label: options[selectedIndex].label, index: selectedIndex})
  }, [mouseDown, normalizeValueFromSlider, options, setStarHover])

  const onStop = useCallback((e) => {
    setMouseDown(false);
    setStarHover({isHovering: false})
    const selectedIndex = normalizeValueFromSlider(Number(e.target.value));
    setOptionValue(selectedIndex);
  }, [normalizeValueFromSlider, setOptionValue, setStarHover]);

  const onMouseOut = useCallback(() => {
    setStarHover({isHovering: false})
  }, [setStarHover]);

  const inputStyle = useMemo(() => {
    return {
      '--currentPercentage': `${((interimValue / MAX) * 100).toFixed(2)}%`,
      '--sliderColor1': sliderColors[0] || '#ffffff',
      '--sliderColor2': sliderColors[1] || '#ffffff',
    }
  }, [interimValue, sliderColors]);

  const containerClass = useMemo(() => {
    if (isDisplayCreative) {
      return style.sliderContainer;
    }
    if (mode === ENGAGEMENT_DISPLAY_MODE.HORIZONTAL) {
      return style.sliderContainerHorizontal;
    }
    if (mode === ENGAGEMENT_DISPLAY_MODE.VERTICAL) {
      return style.sliderContainerVertical;
    }

    return style.sliderContainerFull;
  }, [mode, isDisplayCreative]);

  const sliderClass = useMemo(() => {
    if (isDisplayCreative) {
      if ((parseFloat(height) / parseFloat(width)) < 1) {
        return style.sliderHorizontal;
      }
    }
    return style.slider;
  }, [isDisplayCreative, height, width]);

  return <div className={containerClass}>
    <div className={style.optionMarkerContainer}>
      {
        options.map((option, index) => {
          return <div
            className={classnames(style.optionMarker, {
              [style.optionMarkerHighlighted]: (starHover?.index === index) || (value === index)
            })}
            key={index}
            onClick={() => setOptionValue(index)}
            onMouseEnter={() => setStarHover({
              isHovering: true,
              label: option.label,
              index
            })}
            onMouseLeave={() => setStarHover({
              isHovering: false
            })}
          >
            {index + 1}
          </div>
        })
      }
    </div>
    <input
      onChange={onChange}
      type={'range'}
      min={MIN}
      max={MAX}
      value={interimValue}
      step={STEP}
      className={sliderClass}
      onMouseDown={onStart}
      onTouchStart={onStart}
      onMouseMove={onMove}
      onTouchMove={onMove}
      onMouseUp={onStop}
      onTouchEnd={onStop}
      onMouseOut={onMouseOut}
      style={inputStyle}
    />
  </div>
}
