// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".creativeBorder--1eEaC{border-style:solid;box-sizing:border-box;position:absolute;height:100%;width:100%;top:0;left:0;z-index:3;pointer-events:none}", ""]);
// Exports
exports.locals = {
	"creativeBorder": "creativeBorder--1eEaC"
};
module.exports = exports;
