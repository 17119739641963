import {captureException} from '@sentry/browser';
import CustomError from 'utils/customError';
import {ErrorType} from 'utils/constants';

export const getEvents = xml => {
  const vastEvents = {};

  const addToEvents = (node, key) => {
    return Array.from(node).forEach(el => {
      const pixel = getContentOfTag(el)
      const k = key || getAttribute(el, 'event')
      const trackingPixel = vastEvents[k]
      if (typeof trackingPixel === 'undefined') {
        vastEvents[k] = [pixel]
      } else {
        vastEvents[k].push(pixel);
      }
    })
  }

  addToEvents(xml.getElementsByTagName('Impression'), 'impression');
  addToEvents(xml.getElementsByTagName('Tracking'));
  addToEvents(xml.getElementsByTagName('ClickTracking'), 'clickTracking');
  addToEvents(xml.getElementsByTagName('ClickThrough'), 'clickThrough');

  return vastEvents;
}

export const getJSAd = xml => {
  const mediaFiles = xml.getElementsByTagName('MediaFile');
  const jsMF =  Array.from(mediaFiles).find(it => getAttribute(it, 'type') === "application/javascript");
  if (!jsMF) {
    captureException(new CustomError(ErrorType.MEDIA_FILE_NOT_SUPPORTED), {extra: {errorType: ErrorType.MEDIA_FILE_NOT_SUPPORTED}});
  }
  return jsMF;
}
export const getTagByName = (xml, name) => xml.getElementsByTagName(name);
export const getAttribute = (tag, attr) => tag.attributes?.[attr]?.value;

export const getContentOfTag = tag => tag.textContent || tag.innerText;

