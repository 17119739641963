import uuid from "uuid/v4";
import effectStyle from 'common/styles/effects.module.scss';
import { EFFECT_PREFIX, EFFECT_TYPE } from "./constants";

export const hotspotFieldDecimalScale = 2;

export const isNotEmpty = (value) => {
  return (value !== null && value !== undefined)
}

export const getHotspotSelectors = () => {
  const selectedHotspotSelector = `selectedHotspotClass-${uuid()}`;
  const hotspotPopperSelector = `selectedHotspotPopperClass-${uuid()}`;
  const snappingHotspotSelector = `snappingHotspotClass-${uuid()}`;
  const cssSelector = `.${selectedHotspotSelector}`;
  const hotspotPopperCSSSelector = `.${hotspotPopperSelector}`;
  const snappingHotspotCSSSelector = `.${snappingHotspotSelector}`;

  return {
    selectedHotspotSelector,
    hotspotPopperSelector,
    snappingHotspotSelector,
    cssSelector,
    hotspotPopperCSSSelector,
    snappingHotspotCSSSelector,
  }
}

export const ensureMarkerRatio = (width, height, ratio, maxWidth = Infinity, maxHeight = Infinity, offsetX = 0, offsetY = 0) => {
  let updatedWidth = width;
  let updatedHeight = height;
  if (ratio) {
    const currentRatio = width / height;
    if (currentRatio > ratio) {
      updatedWidth = ratio * height;
    } else if (currentRatio < ratio) {
      updatedHeight = width / ratio;
    }
  }

  if ((updatedWidth + offsetX) <= maxWidth && (updatedHeight + offsetY) <= maxHeight && offsetX >= 0 && offsetY >= 0) {
    return {offsetX, offsetY, width: updatedWidth, height: updatedHeight};
  } else {
    if ((updatedHeight + offsetY) > maxHeight) {
      return ensureMarkerRatio(updatedWidth, maxHeight - offsetY, ratio, maxWidth, maxHeight, offsetX, offsetY);
    }
    if ((updatedWidth + offsetX) > maxWidth) {
      return ensureMarkerRatio(maxWidth - offsetX, updatedHeight, ratio, maxWidth, maxHeight, offsetX, offsetY);
    }

    return ensureMarkerRatio(updatedWidth, updatedHeight, ratio, maxWidth, maxWidth, Math.max(offsetX, 0), Math.max(offsetY, 0));
  }
}

export const removeGrowEffect = (target) => {
  const grow = EFFECT_TYPE.GROW.toLowerCase()
  if (target && target.className.includes(grow)) {
    target.classList.remove(effectStyle[`${EFFECT_PREFIX.BASE}${grow}`])
    target.classList.remove(effectStyle[`${EFFECT_PREFIX.HOVER}${grow}`])
    // without removing transition theres a 'second' grow effect seen when initially selecting the hotspot
    target.classList.add(effectStyle.vgFxNoTransition)
    // remove the vgFxNoTransition style after shortest possible delay, this allows the moveable selection box to size appropriately
    setTimeout(() => {
      target.classList.remove(effectStyle.vgFxNoTransition)
    }, 0)
  }
  return target
}
