import {fetchCSS} from "utils/resource-loader";
import FontFaceObserver from "fontfaceobserver";
import {isObject} from "./helpers";

// Simple lookup table for fonts -- Most are identical, but some have spaces
// isExternal true means google font (for now) otherwise its a font we host
export const fontLookup = {
  'Aldrich': { name : 'Aldrich', isExternal : true },
  'AlegreyaSansSC': { name : 'Alegreya Sans SC', isExternal : true, weights: [300, 400, 700, 800] },
  'Amaranth': { name : 'Amaranth', isExternal : true, weights: [400, 700] },
  'Arvo': { name : 'Arvo', isExternal : true, weights: [400, 700] },
  'DroidSans': { name : 'Droid Sans', isExternal : true },
  'GothamBook': { name : 'gotham book', isExternal : false },
  'GothamMedium': { name : 'gotham medium', isExternal : false },
  'Helvetica': { name : 'helvetica neue, helvetica, sans-serif', isWebSafe : true },
  'Monda': { name : 'Monda', isExternal : true, weights: [400, 700] },
  'Oswald': { name : 'Oswald', isExternal : true, weights: [300, 500, 700] },
  'Radley': { name : 'Radley', isExternal : true },
  'Raleway': { name : 'Raleway', isExternal : true, weights: [300, 400, 700, 800] },
  'OpenSansLight': { name : 'open sans light', isExternal : false },
  'OpenSansSemiBold': { name : 'open sans semibold', isExternal : false },
  'RedHatDisplayMedium': { name : 'Red Hat Display', isExternal : true, weights : [500] },
  'RedHatDisplayBold': { name : 'Red Hat Display', isExternal : true, weights : [700] },
  'RedHatDisplay': { name : 'Red Hat Display', isExternal : true, weights: [400, 700, 900] },
  'Roboto': { name : 'Roboto', isExternal : true, weights: [300, 400, 700] },
  'Lato': { name : 'Lato', isExternal : true, weights: [300, 400, 700, 900] },
  'MontserratRegular': { name : 'Montserrat', isExternal : true },
  'MontserratMedium': { name : 'Montserrat', isExternal : true, weights : [500] },
  'Montserrat': { name : 'Montserrat', isExternal : true, weights: [300, 400, 700, 800] },
  'PTSansNarrow': { name : 'PT Sans Narrow', isExternal : true, weights: [400, 700] },
  'Dekko': { name : 'Dekko', isExternal : true },
};

// This needs to synced with UI
export const HOTSPOT_FONT_OVERRIDE = [
  {
    key: {
      font: 'Gotham',
      fontWeight: '400',
    },
    value: {
      font: 'GothamBook',
      fontWeight: '400'
    }
  },
  {
    key: {
      font: 'Gotham',
      fontWeight: '500',
    },
    value: {
      font: 'GothamMedium',
      fontWeight: '400'
    }
  },
  {
    key: {
      font: 'OpenSans',
      fontWeight: '300',
    },
    value: {
      font: 'OpenSansLight',
      fontWeight: '300'
    }
  },
  {
    key: {
      font: 'OpenSans',
      fontWeight: '700',
    },
    value: {
      font: 'OpenSansSemiBold',
      fontWeight: '700'
    }
  },
];

const EXTERNAL_FONT_URL = 'https://fonts.googleapis.com/css2?';

export default class FontAndColorHelper {

  // static applyFont(fontSettings, selectors, useWhiteAsDefault, isHotspot) {
  //   // Checking for Font Override (Has to be done to merge GothamBook and GothamMedium into 1) - Refer VGO-884
  //   if (isHotspot) {
  //     const override = HOTSPOT_FONT_OVERRIDE.find(({key: {font, fontWeight}}) => font === fontSettings.font && fontWeight === fontSettings.weight);
  //     if (override) {
  //       fontSettings = Object.assign({}, fontSettings);
  //       fontSettings.font = override.value.font;
  //       fontSettings.weight = override.value.fontWeight;
  //     }
  //   }
  //   const fontAndColor = this.getFontAndColor(fontSettings, useWhiteAsDefault);
  //   const fontWeightAndSize = this.getFontWeightAndSize(fontSettings, isHotspot);
  //   selectors.forEach(selector => {
  //     selector.css({'font-family': fontAndColor.font, 'color': fontAndColor.color});
  //     if (fontWeightAndSize) {
  //       if (fontWeightAndSize.size) {
  //         selector.css({'font-size': fontWeightAndSize.size});
  //       }
  //       if (fontWeightAndSize.weight) {
  //         selector.css({'font-weight': fontWeightAndSize.weight});
  //       }
  //     }
  //   });
  // }

  static getFontAndColor(fontSettings = {}, useWhiteAsDefault = false) {
    let font = 'gotham medium';
    let color = useWhiteAsDefault ? '#ffffff' : '#303030';
    if (isObject(fontSettings) && (fontSettings.color || fontSettings.fontColor)) {
      font = fontLookup[fontSettings.font] ? fontLookup[fontSettings.font].name : 'gotham medium';
      color = fontSettings.color || fontSettings.fontColor;
    }
    return {font, color};
  }

  static getFontWeightAndSize(fontSettings = {}, isHotspot) {
    if (!isObject(fontSettings)) {
      return null;
    }

    if (!isHotspot) {
      if (fontSettings.font === 'RedHatDisplayBold') {
        return {fontWeight: 700, fontSize: fontSettings.fontSize};
      }
      if (fontSettings.font === 'RedHatDisplayMedium') {
        return {fontWeight: 500, fontSize: fontSettings.fontSize};
      }
      if (fontSettings.font === 'MontserratMedium') {
        return {fontWeight: 500, fontSize: fontSettings.fontSize};
      }
    }

    const {fontWeight, fontSize} = fontSettings;
    return {fontWeight, fontSize};
  }

  static initFonts(fonts) {
    const loadFontPromises = [];
    for (const f of Array.from(fonts)) {
      // Do not need to attempt to load a font if it's name is null/empty
      if (f.font) {
        if (fontLookup[f.font]) {
          if (fontLookup[f.font].isWebSafe) {
            // We do not need to download web safe fonts
          } else {
            loadFontPromises.push(this.loadFont(f.font));
          }
        } else {
          const override = HOTSPOT_FONT_OVERRIDE.find(({key: {font, fontWeight}}) => font === f.font && fontWeight === f.weight);
          if (override) {
            loadFontPromises.push(this.loadFont(override.value.font, false));
          } else {
            console.error('Error in identifying how to load font ', f);
          }
        }
      }
    }

    return Promise.all(loadFontPromises);
  }

  /*
  * Lazy load font css files: either webpack managed chunk or an external (google only for now) font css
  * */
  static loadFont(fontKey) {
    const fontDetails = fontLookup[fontKey];
    let cssLoadPromise;

    if (fontDetails.isExternal) {
      let familyString = 'family=';
      familyString = familyString.concat(fontDetails.name);
      familyString = familyString.replace(/ /g, '+');
      if (fontDetails.weights) {
        familyString = familyString.concat(':wght@').concat(fontDetails.weights.join(';'));
      }
      const fullyFormedFontUrl = EXTERNAL_FONT_URL.concat(familyString);
      cssLoadPromise = fetchCSS(fullyFormedFontUrl)
        .catch(error =>
          console.error('Error loading external font ', fontKey)
        );
    } else {
      cssLoadPromise = import( /* webpackMode: "lazy" */ `../fonts/${fontKey}.css`).then()
        .catch(error =>
          console.error('Error loading internal font ', fontKey)
        );
    }
    // Once css file is loaded, attach observer to know when underlying @Font-Face (i.e woff) files are loaded
    return cssLoadPromise.then(() => {
      if (fontDetails.weights) {
        // each font weight needs it's own observer
        return Promise.all(
          fontDetails.weights.map((weight) => {
            return new FontFaceObserver(fontDetails.name, {weight}).load();
          })
        );
      } else {
        return new FontFaceObserver(fontDetails.name).load();
      }
    })
  }
}
