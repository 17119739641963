import React, {useCallback, useState} from "react";
import style from './style.module.scss';
import {CloseIcon} from 'elements/icons';
import {PLAYER_EVENT} from 'utils/constants';
import {useConfig} from "utils/ConfigProvider";
import {useEventCapture}  from "utils/EventCaptureProvider";

export const AdStoppedIcon = ({onClick, isContentFlowCreative}) => {
  const [iconClicked, setIconClicked] = useState(false);
  const { environment } = useConfig();

  const { capture } = useEventCapture();

  const onHotSpotClick = useCallback(() => {
    if (!iconClicked) {
      setIconClicked(true)
      const payload = {action: PLAYER_EVENT.CLOSE.toLowerCase()}

      capture(payload).then(() => {
        onClick(); // Sending ad stopped event after, druid pixel firing is finished.
      });
    }
  }, [iconClicked, onClick, capture]);

  return (
     <CloseIcon
       onClose = {() => {
          if (environment.isVPAIDEnabled()) {
            onHotSpotClick();
          } else if (environment.isCreativeStudioEngagementPreviewEnabled() || environment.isShareLandingPage() || isContentFlowCreative) {
            // Preview or shared landing page should hide the creative on adstop click``
            onClick();
          }
       }}
       className={style.adStoppedIcon}/>
  )
}

