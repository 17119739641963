import React from 'react';
import Functions from 'utils/functions';
import style from '../style.module.scss';
import {PLAYER_STATE} from "utils/constants";

function TimeRemaining({duration, currentTime, playerState, isContentFlowCreative}) {
  const formattedCurrentTime = Functions.getFormattedAdRemainingTime(Math.floor(currentTime), currentTime !== 0 && Boolean(playerState) && playerState !== PLAYER_STATE.READY)
  const formattedDuration = Functions.getFormattedAdRemainingTime(Math.floor(duration), currentTime !== 0 && Boolean(playerState) && playerState !== PLAYER_STATE.READY)
  const adText = isContentFlowCreative ? '': 'Ad.'
  return (
    <div className={style.timeLeft}> {adText} {formattedCurrentTime}/{formattedDuration} </div>
  )
}

export default TimeRemaining;
