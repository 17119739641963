import React from 'react';
import SocialIcon from "elements/social-icon";
import style from './style.module.scss';


function SocialIconsGroup({icons = ['facebook'], boxStyle = 'circle', iconStyle= 'default', fontSize = '1em', onEmailToggle, shareUrlConfig, shareIconPosition, inVideoEngagementPosition, mode, creativeDimensions, ...rest}) {
  return <div className={style.container} style={{fontSize}}>
    {icons.map(icon =>
      <div key={icon} className={style.icon}>
        <SocialIcon
          variant={icon}
          boxStyle={boxStyle}
          iconStyle={iconStyle}
          onEmailToggle={onEmailToggle}
          shareUrlConfig={shareUrlConfig}
          shareIconPosition={shareIconPosition}
          inVideoEngagementPosition={inVideoEngagementPosition}
          mode={mode}
          creativeDimensions={creativeDimensions}
          {...rest}/>
      </div>)}
  </div>
}

export default SocialIconsGroup;
