// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".checkmarkContainer--3PbLi{position:relative}.checkmarkBackground--xqk5a{position:absolute;top:0;left:0;height:100%;width:100%;padding:20%;z-index:-1}.checkmarkColor--1lS9v{height:100%;width:100%}", ""]);
// Exports
exports.locals = {
	"checkmarkContainer": "checkmarkContainer--3PbLi",
	"checkmarkBackground": "checkmarkBackground--xqk5a",
	"checkmarkColor": "checkmarkColor--1lS9v"
};
module.exports = exports;
