import {useEffect} from "react";
import {eventUtil, helpers} from "@phoenix/core";
import Environment from 'utils/environment';
import {captureException} from "@sentry/browser";

export default function useFireDspUserIdFetchPixels(settings) {

  useEffect(() => {
    if(!settings) {
      return;
    }
    const fireDspUserIdFetchPixel = () => {
      const PIXEL_FIRED_QUERY_PARAM = '&pixelFiredTime=';
      if (Environment.isEditMode()) {
        return;
      }
      const dspUserIdFetchPixels = settings.dspUserIdFetchPixelList;
      if (helpers.isEmpty(dspUserIdFetchPixels)) {
        return;
      }
      try {
        const epocTimeString = encodeURIComponent(PIXEL_FIRED_QUERY_PARAM + Date.now());
        dspUserIdFetchPixels.forEach((url) => {
          url = `${url}${epocTimeString}`;
          eventUtil.doGet(url);
        });
      } catch (e) {
        console.error('Exception in firing  dspUserIdFetchPixels', e);
        captureException(e);
      }
    }

    fireDspUserIdFetchPixel();
  }, [settings]);

}
