import {useEffect} from "react";
import {captureException} from '@sentry/browser';
/**
 * Cleanup (remove) Phoenix after timeout
 * Has a test Timeout Mode which allows QA to test this without waiting hours :)
 */
export default function useCleanupCounter(props) {

  const timeout = props.isTestTimeoutMode ? props.testCleanupTimeout : props.cleanupTimeout;
  const removeAd = props.removeAd;

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log('SO LONG AND THANKS FOR ALL THE FISH!', timeout);
      try{
        removeAd();
      } catch(e) {
        console.error('Trouble cleaning up Phoenix');
        captureException(e);
      }
    }, timeout*1000);
    return () => clearTimeout(timer);
  }, [timeout, removeAd]);
}
