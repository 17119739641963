import {useEffect, useState, useRef, useCallback} from 'react';
import {captureException} from "@sentry/browser";
import MessageHelper from "utils/messageHelper";
import {
  PLAYER_EVENT,
  PLAYER_STATE,
  THIRD_PARTY_EVENTS,
  ErrorType,
  JourneyTriggerInteractiveEvent
} from 'utils/constants';
import CustomError from "utils/customError";
import Functions from '../../utils/functions';
import {useConfig} from "utils/ConfigProvider";
import {useEventCapture}  from "utils/EventCaptureProvider";

function PlayerEventRecorder({state, time, duration, isMute, deliveredViewDurationSeconds, track3rdPartyEvents, onAdCompleteEventFired, fireAdStoppedEvent, q1, q2, q3, impressionEvent, videoTimeToPercentageMapping, scrubbedOnce, resetQuartile, fireJourneyTrigger, isContentFlowCreative}) {

  const { environment } = useConfig();
  const { capture, capturePlayerLoadTime } = useEventCapture();

  // event tracker so that we dont double count;
  const [completeEventDone, setCompleteEventDone] = useState(false);
  const [countsAsViewEventDone, setCountsAsViewEventDone] = useState(false);
  const [countsAsViewEventInitiated, setCountsAsViewEventInitiated] = useState(false);
  const [completeEventInitiated, setCompleteEventInitiated] = useState(false);
  const loadEventDone = useRef(false);
  const startEventDone = useRef(false);
  const firstQEventDone = useRef(false);
  const midEventDone = useRef(false);
  const thirdQEventDone = useRef(false);
  const impressionEventDone = useRef(false);
  const stopEventDone = useRef(false);
  const lastPercentageSent = useRef(0);
  const previousTime = useRef(0);


  /**
   * It returns a promise, that is resolved when pixel has actually been sent
   * @param ev
   */
  const send = useCallback((ev, params) => {
    const data = {action: ev.toLowerCase()}
    let payload = data;
    if (params) {
      payload = {
        ...data,
        ...params
      }
    }
    return capture(payload);
  }, [capture]);


  const fireJourneyTriggersEventsWrapper = useCallback((event) => {
    // Fire journey trigger only in case of content flow or  interactiveCustomView event
    if (event === JourneyTriggerInteractiveEvent.interactiveCustomView || isContentFlowCreative) {
      fireJourneyTrigger(event);
    }
  }, [fireJourneyTrigger, isContentFlowCreative]);

  const resetQuartileEvents = useCallback ( (time) => {
    const [q1, q2, q3] = Functions.getAllQuarters(duration)
    if (!time) {
      return;
    }
    if (time <= q1) {
      resetQuartile(1)
      firstQEventDone.current = false;
      midEventDone.current = false;
      thirdQEventDone.current = false;
    } else if (time <= q2) {
      resetQuartile(2)
      firstQEventDone.current = true;
      midEventDone.current = false;
      thirdQEventDone.current = false;
    } else if (time <= q3) {
      resetQuartile(3)
      firstQEventDone.current = true;
      midEventDone.current = true;
      thirdQEventDone.current = false;
    } else {
      firstQEventDone.current = true;
      midEventDone.current = true;
      thirdQEventDone.current = true;
    }
  }, [resetQuartile, duration]);

  const getRetentionPercentagesForTime = useCallback((timeSeconds) => {
    if (isContentFlowCreative && videoTimeToPercentageMapping) {
      return videoTimeToPercentageMapping.get(timeSeconds);
    }
    return null;
  }, [videoTimeToPercentageMapping, isContentFlowCreative]);

  useEffect( () => {
    if (isContentFlowCreative && time && time > 0) {
      const timeSeconds = Math.floor(time);
      if (previousTime.current > timeSeconds) { // It means video is scrubbed back.
        resetQuartileEvents(timeSeconds)
        if(!isNaN(deliveredViewDurationSeconds) && timeSeconds <= deliveredViewDurationSeconds) {
          setCountsAsViewEventDone(false);
          setCountsAsViewEventInitiated(false)
        }
        setCompleteEventDone(false);
        setCompleteEventInitiated(false);
      } else if (scrubbedOnce && (timeSeconds - previousTime.current) > 1) { // means video has been scrubbed forward
        resetQuartileEvents(timeSeconds);
      }
      previousTime.current = timeSeconds
    }
  }, [time, resetQuartileEvents, scrubbedOnce, deliveredViewDurationSeconds, isContentFlowCreative])

  useEffect(() => {
     if (isContentFlowCreative && time && time > 0) {
       const timeSeconds = Math.floor(time)
       if (timeSeconds >= duration) { // we will send videoretention metric data for time = duration with done event.
         return;
       }
       const percentagesToSend = getRetentionPercentagesForTime(timeSeconds);
       if (percentagesToSend && lastPercentageSent.current !== percentagesToSend) {
         send(PLAYER_EVENT.VIDEO_RETENTION, {'retentionPerc': percentagesToSend});
         lastPercentageSent.current = percentagesToSend
       }
     }
  }, [time, getRetentionPercentagesForTime, duration, send, isContentFlowCreative]);


  useEffect(() => {
    if(state === PLAYER_STATE.STOPPED && !stopEventDone.current) {
      stopEventDone.current = true;
      fireAdStoppedEvent();
    }
  }, [state, fireAdStoppedEvent]);

  useEffect(() => {
    if(countsAsViewEventDone && completeEventDone) {
      onAdCompleteEventFired();
    }
  }, [countsAsViewEventDone, completeEventDone, onAdCompleteEventFired]);

  // quartile events
  useEffect(() => {

    const sendQ1 = () => {
      if(!firstQEventDone.current) {
        firstQEventDone.current = true;
        send(PLAYER_EVENT.FIRST_QUARTILE);
        track3rdPartyEvents(THIRD_PARTY_EVENTS.FIRST_QUARTILE);
        fireJourneyTriggersEventsWrapper(JourneyTriggerInteractiveEvent.firstQuartile);
      }
    }

    const sendQ2 = () => {
      if (!isContentFlowCreative) {
        sendQ1();
      }
      if(!midEventDone.current) {
        midEventDone.current = true;
        send(PLAYER_EVENT.MIDPOINT);
        track3rdPartyEvents(THIRD_PARTY_EVENTS.MIDPOINT);
        fireJourneyTriggersEventsWrapper(JourneyTriggerInteractiveEvent.midpoint);
      }
    }

    const sendQ3 = () => {
      if (!isContentFlowCreative) {
        sendQ2()
      }
      if(!thirdQEventDone.current) {
        thirdQEventDone.current = true;
        send(PLAYER_EVENT.THIRD_QUARTILE);
        track3rdPartyEvents(THIRD_PARTY_EVENTS.THIRD_QUARTILE);
        fireJourneyTriggersEventsWrapper(JourneyTriggerInteractiveEvent.thirdQuartile);
      }
    }

    if (q1) {
      sendQ1();
    }
    if (q2) {
      sendQ2();
    }
    if (q3) {
      sendQ3();
    }
  }, [track3rdPartyEvents, fireJourneyTriggersEventsWrapper, q1, q2, q3, send, isContentFlowCreative]);


  // load
  useEffect(() => {
    if(state === PLAYER_STATE.READY && !loadEventDone.current) {
      loadEventDone.current = true;
      send(PLAYER_EVENT.LOAD);
      capturePlayerLoadTime();
    }
  }, [state, capturePlayerLoadTime, send]);

  // impression
  useEffect(() => {
    if(!impressionEventDone.current && impressionEvent) {
        impressionEventDone.current = true
        send(PLAYER_EVENT.IMPRESSION);
        track3rdPartyEvents(THIRD_PARTY_EVENTS.IMPRESSION);
        fireJourneyTriggersEventsWrapper(JourneyTriggerInteractiveEvent.impression);
    }
  }, [track3rdPartyEvents, fireJourneyTriggersEventsWrapper, impressionEvent, send]);

  // start event
  useEffect(() => {
    if(!startEventDone.current) {
      if(state === PLAYER_STATE.PLAYING) {
        startEventDone.current = true;
        if(environment.inHeavyAdsEmulation()) {
          MessageHelper.instance.sendToVg({code:'onAdStarted'});
        }
        send(PLAYER_EVENT.START);
        fireJourneyTriggersEventsWrapper(JourneyTriggerInteractiveEvent.start);
        if(!loadEventDone.current) {
          loadEventDone.current = true
          capturePlayerLoadTime();
          send(PLAYER_EVENT.LOAD);
        }
      }
    }
  }, [state, fireJourneyTriggersEventsWrapper, capturePlayerLoadTime, send, environment]);

  // this if for emulation
  const postVideoCompletionToParentWindow = useCallback(() => {
    // TODO: maybe pass this function in as a prop
    if (window.parent && `${environment.getAdUnitId()}`) {
      MessageHelper.instance.sendToVg({ code: 'Completed', id :  `${environment.getAdUnitId()}`}, '*');
    }
  }, [environment]);

  // complete event
  useEffect(() => {
    if(state === PLAYER_STATE.COMPLETED) {
      postVideoCompletionToParentWindow();
    }

    if(!completeEventInitiated) {
      if((duration > 0 && time > 0 && time >= duration) || state === PLAYER_STATE.COMPLETED) {
        setCompleteEventInitiated(true)
        track3rdPartyEvents(THIRD_PARTY_EVENTS.COMPLETE);
        fireJourneyTriggersEventsWrapper(JourneyTriggerInteractiveEvent.complete);
        if (!thirdQEventDone.current && !isContentFlowCreative) {
          captureException(new CustomError('Missed Quartile Events!'), {extra: {errorType: ErrorType.MISSED_QUARTILE, firstQEventDone: firstQEventDone.current, midEventDone: midEventDone.current, state, time, duration}})
        }
        const percentagesToSend = getRetentionPercentagesForTime(duration);
        const params = percentagesToSend ? {'retentionPerc': percentagesToSend} : null;
        send(PLAYER_EVENT.COMPLETE, params).then(() => { // Fire ad stopped event once pixel has been fired successfully.
          setCompleteEventDone(true);
        }).catch(((error) => {
          captureException(new CustomError('Error in sending completion event'), {extra: {time, duration, error}})
        }));
        send(PLAYER_EVENT.CUSTOM_ONE);
      }
    }
  }, [state, time, completeEventInitiated, duration, track3rdPartyEvents, fireJourneyTriggersEventsWrapper, getRetentionPercentagesForTime, send, postVideoCompletionToParentWindow, isContentFlowCreative]);

  // pause
  useEffect(() => {
    if(state === PLAYER_STATE.PAUSED) {
      send(PLAYER_EVENT.PAUSE);
    }
  }, [state, send]);

  // mute/unmute
  useEffect(() => {
    if(isMute) {
      send(PLAYER_EVENT.MUTE);
    } else {
      send(PLAYER_EVENT.UNMUTE);
    }
  }, [isMute, send])


  const countsAsViewEventHandler = useCallback(() => {
    setCountsAsViewEventInitiated(true);
    send(PLAYER_EVENT.DELIVERED_VIEW).then(() => {
      track3rdPartyEvents(THIRD_PARTY_EVENTS.VG_VIEW);
      fireJourneyTriggersEventsWrapper(JourneyTriggerInteractiveEvent.interactiveCustomView);
      setCountsAsViewEventDone(true);
    }).catch(e => setCountsAsViewEventDone(true));
  }, [track3rdPartyEvents, fireJourneyTriggersEventsWrapper, send]);

  // counts as view
  useEffect(() => {
    if(isNaN(time) || isNaN(deliveredViewDurationSeconds) || countsAsViewEventInitiated) {
      return;
    }
    const timeSeconds = Math.floor(time);
    if(!scrubbedOnce && (deliveredViewDurationSeconds <= time || state === PLAYER_STATE.COMPLETED)) {
      countsAsViewEventHandler()
    } else if (scrubbedOnce && deliveredViewDurationSeconds === timeSeconds) {
      countsAsViewEventHandler();
    }


  }, [time, state, countsAsViewEventInitiated, deliveredViewDurationSeconds, track3rdPartyEvents, scrubbedOnce, countsAsViewEventHandler]);

  return null;

}

export default PlayerEventRecorder;
