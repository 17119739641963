import {AUTOPLAY_MODE} from 'utils/constants';

const log = (...args) => console.log(...args);

export function shouldTryCanary(autoPlay=false, autoPlayMuted=false) {
  return autoPlay && !autoPlayMuted;
}

export async function testCanaryMuted(video) {
  video.muted = true;
  const playResult = video.play();
  try {
    await playResult;
    // autoplay muted is allowed.
    log('autoplay muted only');
    return AUTOPLAY_MODE.AUTOPLAY_MUTED;
  } catch (err) {
    log('no autplay', err);
    return AUTOPLAY_MODE.NO_AUTOPLAY;
  }
}

export async function testCanary(videoElement, autoplay=false, autoPlayMuted=false) {
  const video = videoElement;
  /**
   * Initialize autoplay Mode;
   * If AUTOPLAY_MUTED true - and AUTOPLAY true - AUTOPLAY_MUTED
   * If AUTOPLAY false - NO_AUTOPLAY
   */
  if (!autoplay) {
    return AUTOPLAY_MODE.NO_AUTOPLAY;
  }
  if (autoPlayMuted) {
    log('autoplay muted requested');
    return AUTOPLAY_MODE.AUTOPLAY_MUTED;
  }
  try {
    const playResult = video.play();
    if (playResult !== undefined) {
      try {
        await playResult;
        // all is well, we can play with audio
        log('autoplay unmuted allowed');
        return AUTOPLAY_MODE.AUTOPLAY_UNMUTED;
      } catch (err) {
        log('testing for autoplay muted', err);
        return await testCanaryMuted(video);
      }
    } else {
      // all is well (but no promise returned), we can *hopefully* play with audio
      log('no promise returned, but no exception either. autoplay unmuted allowed');
      return AUTOPLAY_MODE.AUTOPLAY_UNMUTED;
    }
  } catch (playException) {
    log('no promise returned, and exception occurred. autoplay muted only.');
    return AUTOPLAY_MODE.AUTOPLAY_MUTED;
  }
}
