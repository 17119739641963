import {useEffect, useState} from "react";
import {constants, FontAndColorHelper} from "@phoenix/core";
import LoggerHelper from '../utils/loggerHelper';

const {LOG_DETAILS_TYPE} = constants;
const logger = LoggerHelper.instance

export default function useFonts(settings) {
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    if(!settings) {
      return;
    }
    logger.captureAssetLoadingTime(LOG_DETAILS_TYPE.FONT_LOAD, false)
    const fonts = settings.fonts;
    FontAndColorHelper.initFonts(fonts).then(() => {
      logger.captureAssetLoadingTime(LOG_DETAILS_TYPE.FONT_LOAD, true)
      setFontsLoaded(true);
    }).catch((e) => {
      logger.error("font loading failed!", e);
      logger.captureAssetLoadingTime(LOG_DETAILS_TYPE.FONT_LOAD, true)
      setFontsLoaded(true); // attempt to continue with missing fonts
    });
  }, [settings]);

  return {fontsLoaded};
}
