import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import screenfull from 'screenfull';

const noOp = (() => {
});

const FullScreenGet = createContext(screenfull.isFullscreen);
const FullScreenSet = createContext(noOp);

export const useFullScreen = () => {
  const isFullScreen = useContext(FullScreenGet);
  const setFullScreen = useContext(FullScreenSet);
  return {
    isFullScreen,
    setFullScreen,
    isFullScreenEnabled: screenfull.isEnabled
  }
};

export function FulLScreenProvider({children}) {
  const element = useMemo(() => {
    return document.querySelector('body');
  }, []);

  const [isFullScreen, setFullScreenState] = useState(screenfull.isEnabled && screenfull.isFullscreen);
  const setFullScreen = useCallback(async (open) => {
    if (screenfull.isEnabled) {
      if (open) {
        await screenfull.request(element);
      } else {
        await screenfull.exit();
      }
    }
  }, [element]);

  useEffect(() => {
    const setState = () => {
      setFullScreenState(screenfull.isFullscreen);
    }

    screenfull.isEnabled && screenfull.on('change', setState);

    return () => {
      screenfull.isEnabled && screenfull.off('change', setState);
    }
  }, []);


  return <FullScreenGet.Provider value={isFullScreen}>
    <FullScreenSet.Provider value={setFullScreen}>
      {children}
    </FullScreenSet.Provider>
  </FullScreenGet.Provider>
}
