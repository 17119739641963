import React from 'react';
import style from './style.module.scss';
import classnames from 'classnames';

export const VideoGradient = ({showing, gradient}) => {
  return <div
    style={gradient ? {background: gradient} : {}}
    className={classnames(style.gradientLayer, showing ? style.gradientLayerShowing : null)}
  />
}
