import React, {useState} from "react";
import classnames from 'classnames';

import {useFetchLocations} from "./useFetchLocations";

import {STORE_LOCATOR_TABS} from "utils/store-locator-helper";
import {isMobileDevice} from 'utils/environmentHelper';
import {Title} from "elements/typography";
import {MapManager} from "compounds/map-manager";
import {StoreList} from "compounds/store-list";

import style from "./style.module.scss";

import {useConfig} from "utils/ConfigProvider";
import staticMapImage from 'images/staticmap.png';
import staticMapImageVertical from 'images/staticmap_vertical.png';

function StoreLocatorRenderer(
  {
    iconColor,
    mapToggleButtonBackgroundColor,
    title,
    titleFontSettings,
    vgDefaultMapPin,
    locationMapJsonUrl,
    mapLocatorImage,
    isFullScreen,
    userCurrentLocation,
    onPhoneClick,
    onDirectionClick,
    onMarkerClick
  }
) {

  const {environment} = useConfig();
  const {stores, loading} = useFetchLocations(locationMapJsonUrl, userCurrentLocation, isMobileDevice());
  const [tab, setTab] = useState(STORE_LOCATOR_TABS.MAP);

  if (loading) {
    return React.Fragment
  }
  return (
    <div className={classnames(style.container, isFullScreen ? style.containerFullScreen : null)}>
      <div className={classnames(style.titleContainer)}
           data-testid={'store-title'}>
        <Title
          fontSettings={titleFontSettings}
          autoResize={true}
          fullHeight={true}
          containerStyle={{
            display: 'flex',
            flexWrap: 'wrap',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}>{title}</Title>
      </div>
      <div className={style.divider}/>
      <div
        className={classnames(style.storesContainer)}>
        <div className={style.tabs}>
          {
            Object.values(STORE_LOCATOR_TABS).map((key) => <div
                key={key}
                onClick={() => setTab(key)}
                className={classnames(style.tab, {[style.activeTab]: tab === key})}
                style={{backgroundColor: tab === key ? mapToggleButtonBackgroundColor : undefined}}
                data-testid={`store-tab-${key.toLowerCase()}`}
              >
                {key.toLowerCase()}
              </div>
            )
          }
        </div>
        {!environment.isCreativeStudioPreviewMode() && <React.Fragment>
          <div className={style.tabContent} style={{display: tab === STORE_LOCATOR_TABS.MAP ? 'inherit' : 'none'}}
               data-testid={'store-map'}>
            <MapManager
              markers={stores}
              markerColor={iconColor}
              markerFontColor={'#ffffff'}
              vgDefaultMapPin={vgDefaultMapPin}
              mapLocatorImage={mapLocatorImage}
              userCurrentLocation={userCurrentLocation}
              onMarkerClick={onMarkerClick}
            />
          </div>
        </React.Fragment>
         }
          <React.Fragment>
            <div className={style.tabContent} style={{display: tab === STORE_LOCATOR_TABS.LIST ? 'inherit' : 'none'}}
                 data-testid={'store-list'}>
              <StoreList
                stores={stores}
                iconFile={mapLocatorImage}
                iconColor={mapToggleButtonBackgroundColor}
                isFullScreen={isFullScreen}
                onPhoneClick={onPhoneClick}
                onDirectionClick={onDirectionClick}
              />
            </div>
        </React.Fragment>
        {
          (environment.isCreativeStudioPreviewMode() && tab === STORE_LOCATOR_TABS.MAP) &&
          <img alt="map preview" src={isFullScreen ? staticMapImage : staticMapImageVertical} className={style.staticMapImage}/>
        }
      </div>
    </div>
  )
}

export default StoreLocatorRenderer;
