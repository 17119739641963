export class PauseRequestedQueue {
  pauseRequested = false;
  adStarted = false;

  constructor(getCreativeElement) {
    this.getCreativeElement = getCreativeElement;
    this.onAdStarted = this.onAdStarted.bind(this);
  }

  pauseAd() {
    if (this.adStarted) {
      this.pauseRequested = false;
      if (this.getCreativeElement()) {
        this.getCreativeElement().pauseAd();
      }
    } else {
      this.pauseRequested = true;
    }
  }

  onAdStarted() {
    this.adStarted = true;
    if (this.pauseRequested) {
      this.pauseAd();
    }
  }
}
