import React, {useEffect, useState} from 'react';
import classnames from 'classnames';

import {CloseIcon} from 'elements/icons';
import Engagement from "compounds/engagement";

import style from './style.module.scss';

import expandIcon from "images/in-video-expand-btn.svg";
import {POSITION_OPTIONS} from "utils/constants";
import {CardBackground} from "elements/cardBackground";

export const ENGAGEMENT_SLIDE_DURATION = 600; // Ensure to sync with engagement_slide_duration

/**
 * wraps the given engagement component with the mentioned position (Left, Right, Bottom, Top) containers for In-Video EE cards
 * Responsible for managing local state of collapse until time range lapses
 * @param backgroundColor
 * @param onClose
 * @param fontFamily
 * @param position
 * @param children
 * @param showing
 * @param onToggle
 * @param noPadding
 * @param isFull
 * @param rest
 * @returns {*}
 * @constructor
 */
function OverlayInVideo(
  {
    backgroundColor,
    bgStyle,
    bgImage,
    bgGradient,
    onClose,
    fontSettings,
    position = '',
    children,
    showing,
    onToggle,
    noPadding,
    isFull,
    scrubBarShowing,
    ...rest
  }
) {
  const [collapsed, toggle] = useState(false);
  const [showChildren, setShowChildren] = useState(false);
  useEffect(() => {
    if (!showing) {
      onToggle && onToggle(false);
      toggle(false);
    }
  }, [onToggle, showing]);

  useEffect(() => {
    let timer = null;
    if (showing) {
      setShowChildren(true);
    } else {
      timer = setTimeout(() => {
        setShowChildren(false);
      }, ENGAGEMENT_SLIDE_DURATION * 1.5); // Adding some buffer
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    }
  }, [showing]);

  return (
    <>
      <div className={classnames(getClasses(showing, position, collapsed, isFull))} data-testid='invideo'>
        <CardBackground
          backgroundColor={backgroundColor}
          bgStyle={bgStyle}
          bgImage={bgImage}
          bgGradient={bgGradient}
          isInVideo={true}
        />
        <div className={classnames(getContainerClasses(position, isFull))}>
          <Engagement
            noPadding={(showing && !collapsed) ? noPadding : true}
            fontSettings={fontSettings}>
            {showChildren ? children : <React.Fragment/>}
          </Engagement>
        </div>
        <CloseIcon
          onClose={() => {
            toggle(true);
            onToggle && onToggle(true);
            onClose && onClose();
          }}
          className={style.inVideoCloseIcon}
        />
      </div>
      {
        collapsed &&
        <img
          alt="expand"
          src={expandIcon}
          className={(!scrubBarShowing && position === POSITION_OPTIONS.BOTTOM) ? style[`expandIconScrubCollapsedInVideoPosition${position}`] : style[`expandIconInVideoPosition${position}`]}
          onClick={() => {
            onToggle && onToggle(!collapsed);
            toggle(!collapsed)
          }}/>
      }
    </>
  )
}

function getClasses(showing, position, collapsed, isFull) {
  const classes = [];

  classes.push(style.container);
  classes.push(style[`inVideoPosition${position}`]);

  if (isFull) {
    classes.push(style.noPadding);
  }

  if (!showing || collapsed) {
    classes.push(style.collapse)
  }

  return classes;
}

function getContainerClasses(position, isFull) {
  const classes = [style.engagementContainer];
  if (isFull) {
    return classes;
  }

  if (['Left', 'Right'].includes(position)) {
    classes.push(style.engagementContainerHorizontalInVideo);
  }

  return classes;
}

export default OverlayInVideo;
