import React, {useCallback} from 'react';
import classnames from "classnames";

import {isMobileDevice} from 'utils/environmentHelper';
import {getVideoElapsedPercentage} from 'utils/scrub-bar-helper';
import VideoPositionBar from 'elements/video-position-bar';
import {useFullScreen} from 'utils/FullScreenProvider';
import {useEventCapture} from 'utils/EventCaptureProvider';

import style from './style.module.scss';
import PlayPause from "elements/scrub-elements/play-pause";
import MuteUnmute from "elements/scrub-elements/mute-unmute";
import FullScreen from "elements/scrub-elements/fullscreen";
import TimeRemaining from "elements/scrub-elements/time-remaining";
import {useConfig} from "utils/ConfigProvider";
import {AdChoices} from "elements/adChoices";
import ScrubEnabledVideoPositionBar from "elements/video-position-bar/ScrubEnabledVideoPositionBar";

function ScrubBar({playerDuration, isShowing, playerTime, playerState, onMuteUnmute, isMute, togglePlayPause, handleReplay, adChoicesEnabled, scrubHandle, setVolume, onScrub, creativeDimensions, settingsDimensions, isContentFlowCreative, ...rest}) {
  const {environment} = useConfig();
  const videoElapsedPercentage = getVideoElapsedPercentage(playerDuration, playerTime, playerState);
  const {isFullScreenEnabled} = useFullScreen();
  const {capture} = useEventCapture();

  const replayAd = useCallback(() => {
    capture({'action': 'replays'});
    handleReplay();
  }, [handleReplay, capture]);

  return (
      <div className={style.scrubAdchoicesContainer}>

          <div data-testid={'scrub-bar-container'}  className={classnames(!isMobileDevice() ? style.container : style.containerMobile, style.scrubShowing)} {...rest}>
              <div className={isShowing ? style.scrubElementsShowing : style.scrubElementsHiding}>
                <PlayPause playerState={playerState} handleReplay= {replayAd} handleOnClick={togglePlayPause} isContentFlowCreative={isContentFlowCreative}/>
                <TimeRemaining duration={playerDuration} currentTime={playerTime} playerState={playerState} isContentFlowCreative={isContentFlowCreative}/>
              </div>
              <div className={isShowing ? style.videoBarShowingFull : style.videoBarShowingOnBottom}>
                  {(environment.isInCreativeStudioPreviewOrPreviewPage() ||  isContentFlowCreative)?
                  <ScrubEnabledVideoPositionBar
                      data-testid={'video-position-bar'}
                      videoElapsedPercentage={videoElapsedPercentage}
                      fullScrubShowing={isShowing}
                      duration={playerDuration}
                      currentTime={playerTime}
                      playerState={playerState}
                      scrubHandle={scrubHandle}
                      onScrub={onScrub}
                  /> :
                      <VideoPositionBar
                      data-testid={'video-position-bar'}
                      videoElapsedPercentage={videoElapsedPercentage}
                      fullScrubShowing={isShowing}
                      />}
              </div>

              <div className={isShowing ? style.scrubElementsShowing : style.scrubElementsHiding}>
                  <MuteUnmute isMute={isMute} handleOnClick={onMuteUnmute} setVolume={setVolume} isContentFlowCreative={isContentFlowCreative}/>
                  {isFullScreenEnabled && !environment.isCreativeStudioPreviewMode() && <FullScreen playerState={playerState}/>}
              </div>
          </div>
          {adChoicesEnabled && <AdChoices creativeDimensions={creativeDimensions} settingsDimensions={settingsDimensions} isDisplayCreative={false}/>}
      </div>
  )
}

export default ScrubBar;
