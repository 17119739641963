import {useConfig} from "utils/ConfigProvider";
import {useCallback, useEffect, useMemo, useRef} from "react";
import {useHotspotInteractionHandler} from "hooks/useHotspotInteractionHandler";
import {getHotspotSelectors} from "utils/hotspotInteractionsHelper";
import {useSettings} from "utils/SettingsProvider";

export function useHotspotInteractions(
  {
    canInteract = true,
    hotspotProps,
    selectedHotspots,
    standaloneHotspots,
    onChangeDeltaOnly = false,
  } = {}
) {
  const {environment} = useConfig();
  const moduleRef = useRef(null);
  const disposeRef = useRef(null);

  const isCreativeStudioPreviewMode = useMemo(() => {
    return environment.isCreativeStudioPreviewMode instanceof Function ? environment.isCreativeStudioPreviewMode() : false;
  }, [environment]);

  const {
    selectedHotspotSelector,
    hotspotPopperSelector,
    snappingHotspotSelector,
    cssSelector,
    hotspotPopperCSSSelector,
    snappingHotspotCSSSelector,
  } = useMemo(() => getHotspotSelectors(), []);

  const {getSettings} = useSettings();

  const updateHotspot = useCallback(({updatedValues, config}) => {
    if (config.changeKey && hotspotProps?.changeWrapper instanceof Function) {
      // NOTE: getSettings doesn't apply overrides for shared cards so it will only return base card values. shared card form/changeWrapper handles splicing into the variation
      hotspotProps.changeWrapper(config.changeKey, {...(onChangeDeltaOnly ? {overrideId: getSettings(config.changeKey).overrideId} : getSettings(config.changeKey)), ...updatedValues});
    }
  }, [getSettings, hotspotProps, onChangeDeltaOnly]);

  const clearSelectedHotspots = useCallback(() => {
    if (hotspotProps?.clearSelectedHotspots instanceof Function) {
      return hotspotProps.clearSelectedHotspots([...(standaloneHotspots || []), ...(selectedHotspots ? [selectedHotspots[0]] : [])]);
    }
  }, [hotspotProps, standaloneHotspots, selectedHotspots]);

  const {
    interactWithElement,
    setHotspotContainer: setHotspotInteractionContainer
  } = useHotspotInteractionHandler({
    updateHotspot,
    clearSelectedHotspots,

    cssSelector,
    hotspotPopperCSSSelector,
    snappingHotspotCSSSelector
  });

  const initInteraction = useCallback(async () => {
    if (!canInteract) {
      return;
    }

    if (!isCreativeStudioPreviewMode) {
      return;
    }

    if (!moduleRef.current) {
      try {
        // Load Module if not already exists - Once the bundle is loaded, it will be cached in the browser for subsequent loads.
        moduleRef.current = (await import(/* webpackChunkName: "moveable" */ 'moveable')).default;
      } catch (e) {
        console.error('Error Loading Moveable Chunk, Cannot init interaction.', e);
        return;
      }
    }

    // Init Interaction
    disposeRef.current = interactWithElement({moduleRef});
  }, [canInteract, interactWithElement, isCreativeStudioPreviewMode]);

  const openHotspot = useCallback((hotspot, shiftKey) => {
    // If shiftKey is not pressed, clear selectedHotspots and select just one hotspot, else do multi-select
    if (shiftKey) {
      // Order of Standalone Hotspot Matters, as we pick the selectedHotspotIndex form 0th index.
      const defaultSelectedHotspots = (standaloneHotspots || selectedHotspots) ? [...(standaloneHotspots || []), ...(selectedHotspots || [])] : undefined;
      hotspotProps.addToSelectedHotspots(hotspot, defaultSelectedHotspots);
    } else {
      hotspotProps.clearAndAddToSelectedHotspots(hotspot, standaloneHotspots);
    }
  }, [hotspotProps, selectedHotspots, standaloneHotspots]);

  const isHotspotSelected = useCallback((hotspot) => {
    if (hotspotProps?.isHotspotSelected instanceof Function) {
      return hotspotProps.isHotspotSelected(hotspot, selectedHotspots);
    }

    return false;
  }, [hotspotProps, selectedHotspots]);

  useEffect(() => {
    if (disposeRef.current instanceof Function) {
      disposeRef.current();
      disposeRef.current = null;
    }

    // Init Interactions, whenever selectedHotspots are updated
    if (hotspotProps?.selectedHotspots && canInteract) {
      // OV-338 debounce like BaseHotspotEditor#resetMarkerRatio so moveable popper select shows in the correct place
      setTimeout(() => {
        initInteraction().finally();
      })
    }
  }, [initInteraction, hotspotProps?.selectedHotspots, canInteract])


  if (canInteract && hotspotProps && hotspotProps?.canEdit) {
    return {
      // Interaction Handler
      canInteract,
      setHotspotInteractionContainer,
      openHotspot,
      isHotspotSelected,

      // CSS Class for Hotspots
      selectedHotspotSelector,
      hotspotPopperSelector,
      snappingHotspotSelector,
    }
  } else {
    return {}
  }
}
