// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".storeItemContainer--rvLlQ{font-size:calc(var(--rootFontSize) * 1);line-height:1.5;color:#303030;font-weight:500;text-align:center;box-sizing:border-box;padding:1%;display:flex;flex-direction:row;background-color:#e7e6e6;border-radius:calc(var(--rootFontSize) * 0.2);margin-bottom:calc(var(--rootFontSize) * 0.2);width:100%}.storeIcon--1ywmL{display:flex;align-items:center;flex:1 1 3.5%;justify-content:center;margin:0 calc(var(--rootFontSize) * 0.25)}.storeIconFullScreen--2eqnL{margin:0 .25em 0 0}.iconImage--2x_2B{width:95%;height:95%;background-repeat:no-repeat;background-size:contain;background-position:center}.storeAddress--3aMps{line-height:1.5;font-weight:500;visibility:visible;box-sizing:border-box;text-align:left;padding-left:calc(var(--rootFontSize) * 0.1);font-size:calc(var(--rootFontSize) * 0.6);color:#000;flex:8 1 0%}.storeAddressFullScreen--3DFoK{flex:8 1 60%}.distance--2D9pZ{font-size:calc(var(--rootFontSize) * 0.5);color:#7d8085}.contactMethods--364O3{flex:2 1 10%;display:flex;justify-content:space-around;align-items:center;font-size:calc(var(--rootFontSize) * 0.8)}.circleNumber--20YPI{display:flex;justify-content:center;align-items:center;width:calc(var(--rootFontSize) * 1);height:calc(var(--rootFontSize) * 1);font-size:.5em;border-radius:50%;font-weight:bold;color:#fff}", ""]);
// Exports
exports.locals = {
	"storeItemContainer": "storeItemContainer--rvLlQ",
	"storeIcon": "storeIcon--1ywmL",
	"storeIconFullScreen": "storeIconFullScreen--2eqnL",
	"iconImage": "iconImage--2x_2B",
	"storeAddress": "storeAddress--3aMps",
	"storeAddressFullScreen": "storeAddressFullScreen--3DFoK",
	"distance": "distance--2D9pZ",
	"contactMethods": "contactMethods--364O3",
	"circleNumber": "circleNumber--20YPI"
};
module.exports = exports;
