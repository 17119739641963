import {useCallback, useRef} from 'react';

import surveyHelpers from 'utils/survey-helper';
import {ENGAGEMENT_TYPES, AdUnitEvent, JourneyTriggerInteractiveEvent} from 'utils/constants';

function useLikertSurveyEvents(onEvent, isInVideo, fireJourneyTrigger, submitSurvey, trackSurveyClickThrough, onClickThrough, triggerElementId, isVPAIDEnabled) {
  const surveyIdsSubmitted = useRef([]);

  const onClick = useCallback((surveyID, questionId, position, selectedQuestionOptionID, surveyRule) => {
    const engagementTemplate = ENGAGEMENT_TYPES.StarSurvey;

    const payload = surveyHelpers.getSurveyResponsePayload(surveyID, position, questionId, selectedQuestionOptionID, engagementTemplate);
    payload.action = AdUnitEvent.SurveyAttemptEvent;
    payload.shouldSendSurveySubmissionEvent = 'false';

    onEvent(payload);

    fireJourneyTrigger(JourneyTriggerInteractiveEvent[`interactive${position}StarResponse`], triggerElementId);
  }, [onEvent, fireJourneyTrigger, triggerElementId]);

  const onSubmit = useCallback((surveyID, questionId, position, selectedQuestionOptionID, surveyRule) => {
    // OV-790 - use local state to prevent refiring of onSubmit for a given surveyId, this is to account for the delayed firing of submitSurvey/fireJourneyTrigger in the vpaid/finale case below
    // OV-1669 - updated to useRef instead of useState, useState has closure issues (missing rerender) when wrapped in setTimeout, which we are doing for animation in mobile.
    if (surveyIdsSubmitted.current.includes(surveyID)) {
      console.log("Duplicate survey submission rejected")
      return
    }
    surveyIdsSubmitted.current = [...surveyIdsSubmitted.current, surveyID];

    const engagementTemplate = ENGAGEMENT_TYPES.StarSurvey;
    surveyHelpers.executeSurveyRules({surveyRule, surveyID, engagementTemplate, onEvent, isInVideo, fireJourneyTrigger, trackSurveyClickThrough,  onClickThrough, triggerElementId});

    const surveyResponsePromise = onEvent(surveyHelpers.getSurveyResponsePayload(surveyID, position, questionId, selectedQuestionOptionID, engagementTemplate))

    if (isVPAIDEnabled && !isInVideo) { // When vpaid is enabled then wait for all the submission and response pixel to be fired before sending ad stopped event.
      surveyResponsePromise.then(() => {
        // Note: helper event for in-session journey, is converted to interactiveLikertSubmission before sending to event ingestion/druid
        fireJourneyTrigger(JourneyTriggerInteractiveEvent[`interactive${position}StarSubmission`], triggerElementId);
        submitSurvey(surveyID);
      })
    } else { // This is the case of display creative where we dont need to wait for showing thankyou screen
      // Note: helper event for in-session journey, is converted to interactiveLikertSubmission before sending to event ingestion/druid
      fireJourneyTrigger(JourneyTriggerInteractiveEvent[`interactive${position}StarSubmission`], triggerElementId);
      submitSurvey(surveyID);
    }
  }, [onEvent, isInVideo, fireJourneyTrigger, submitSurvey, trackSurveyClickThrough,  onClickThrough, triggerElementId, isVPAIDEnabled]);

  return {onClick, onSubmit};
}

export default useLikertSurveyEvents;
