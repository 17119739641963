// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".rawImageContainer--2pO6f{height:100%;width:100%}.rawImage--3LIU7{display:block;max-width:100%;max-height:100%;height:100%;width:100%;background-size:contain;background-position:center;background-repeat:no-repeat}.pointer--FnOIA{cursor:pointer}", ""]);
// Exports
exports.locals = {
	"rawImageContainer": "rawImageContainer--2pO6f",
	"rawImage": "rawImage--3LIU7",
	"pointer": "pointer--FnOIA"
};
module.exports = exports;
