import {CLICK_SRC_WIDGET, JourneyTriggerInteractiveEvent} from 'utils/constants';
import {openUrl} from 'utils/url-helper';

function eventHandler(onEvent, fireJourneyTrigger, isInVideo, seeMoreLink, onClickThrough, triggerElementId) {

  const onShareIconClick = (e, variant) => {
    onEvent({
      type: 'ShareEvent',
      network: variant,
      action: 'Post',
      engagementTemplate: 'Default',
      srcWidget: CLICK_SRC_WIDGET.SPLASH_SCREEN_CLICK_FOR_MORE,
    });

    fireJourneyTrigger(JourneyTriggerInteractiveEvent.interactiveShare, triggerElementId);
  };

  const onCtaClick = () => {
    isInVideo && fireJourneyTrigger(JourneyTriggerInteractiveEvent.interactiveInVideoClickThrough, triggerElementId);
    fireJourneyTrigger(JourneyTriggerInteractiveEvent.interactiveEngagementExperienceClickThrough, triggerElementId);
    fireJourneyTrigger(JourneyTriggerInteractiveEvent.interactiveSocialConnectorClickThrough, triggerElementId);

    onEvent({
      type: 'ClickEvent',
      engagementTemplate: 'Default',
      srcWidget: CLICK_SRC_WIDGET.SPLASH_SCREEN_CLICK_FOR_MORE,
    });

    openUrl(seeMoreLink);
    onClickThrough();
  };

  return [onCtaClick, onShareIconClick, onClickThrough];
}

export default eventHandler;
