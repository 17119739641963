import {useCallback} from "react";
import {getProps} from "utils/engagement-helper";

export const useOverlayHotspotHandler = (overlay, cardHotspotFireJourneyTrigger, submitSurveyByHotspotClickThrough) => {

  const fireCardHotspotJourneyTrigger = useCallback((event, elementTriggerId, suppressJourneyTrigger = false, hasChildren = false) => {
    if (typeof cardHotspotFireJourneyTrigger === 'function') {
      cardHotspotFireJourneyTrigger(event, elementTriggerId, suppressJourneyTrigger);
    }
    const {surveyID} = getProps(overlay);
    if (surveyID && (typeof submitSurveyByHotspotClickThrough === 'function') && hasChildren) { // hide survey only if the path is set
      submitSurveyByHotspotClickThrough(surveyID);
    }
  }, [cardHotspotFireJourneyTrigger, overlay, submitSurveyByHotspotClickThrough]);

  return {fireCardHotspotJourneyTrigger};
}
