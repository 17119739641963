// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container--Xdz0F{display:flex;background:none;width:100%;-webkit-user-select:none;-ms-user-select:none;user-select:none;pointer-events:none;transition:all .5s ease-in-out}.containerMobile--PlzVN{width:100%}.scrubContainerBottom--2mfVj{display:flex;opacity:1;width:100%;transition:opacity .5s,height .5s,width .5s}.scrubShowing--3Pd4T{opacity:1;pointer-events:all;z-index:2}.scrubShowing--3Pd4T>*{transition:all 2s}.scrubHiding--zsnBm{opacity:0;height:0;transition:opacity .5s,height .5s}.scrubAdchoicesContainer--2Ongc{display:flex;align-items:flex-end}.scrubElementsShowing--2z1ir{display:flex;opacity:1;height:2.2em;flex:1 1 auto;transition:all .5s}.scrubElementsHiding--3FppK{display:flex;height:0;width:0;opacity:0;transition:width .5s,height .5s}.videoBarShowingFull--3qb8i{flex:15 1 auto;align-self:center;transition:width .5s,height .5s}.videoBarShowingOnBottom--1Ybc0{flex:1 1 auto;transition:width .5s,height .5s;align-self:center}@media screen and (min-width: 400px){.scrubShowing--3Pd4T{font-size:calc(var(--rootFontSize) * 0.7)}}", ""]);
// Exports
exports.locals = {
	"container": "container--Xdz0F",
	"containerMobile": "containerMobile--PlzVN container--Xdz0F",
	"scrubContainerBottom": "scrubContainerBottom--2mfVj",
	"scrubShowing": "scrubShowing--3Pd4T",
	"scrubHiding": "scrubHiding--zsnBm",
	"scrubAdchoicesContainer": "scrubAdchoicesContainer--2Ongc",
	"scrubElementsShowing": "scrubElementsShowing--2z1ir",
	"scrubElementsHiding": "scrubElementsHiding--3FppK",
	"videoBarShowingFull": "videoBarShowingFull--3qb8i",
	"videoBarShowingOnBottom": "videoBarShowingOnBottom--1Ybc0"
};
module.exports = exports;
