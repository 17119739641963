import {AUTOPLAY_MODE, PLAYER_HOSTS} from 'utils/constants';
import {getVideoHost} from 'utils/settingsHelper';

function getJwPlayerConfigs(videoMediaEncodings, canarySuggestedAutoPlayMode, jwMediaId, heavyAdClickToPlayEnabled, inHeavyAdsEmulation, isChrome) {
  if (!videoMediaEncodings || videoMediaEncodings.length === 0) {
    // JW video encodings are not available yet, thus respecting canary suggested autoplay mode
    return {autoPlayMode: canarySuggestedAutoPlayMode, jwMediaId}
  }

  const sortedEncodingsBySize = videoMediaEncodings?.sort((a, b) => (a.size < b.size) ? 1 : -1);

  if (inHeavyAdsEmulation) {
    const middleEncodingForEmulation = sortedEncodingsBySize[Math.floor(sortedEncodingsBySize.length / 2)];
    return {
      autoPlayMode: AUTOPLAY_MODE.AUTOPLAY_MUTED,
      fileUrl: middleEncodingForEmulation.url, // https is ensured in backend
      jwMediaId,
      encodingWidth: middleEncodingForEmulation.width
    }
  } else if (isChrome && heavyAdClickToPlayEnabled) {
    return {autoPlayMode: AUTOPLAY_MODE.NO_AUTOPLAY, preload: false, jwMediaId}
  } else {
    return {autoPlayMode: canarySuggestedAutoPlayMode, jwMediaId}
  }
}

export function testHeavyAdEncodings(settings, canarySuggestedAutoPlayMode, inHeavyAdsEmulation, isChrome, isInScreenShooterEmulation) {
  const {video, mediaType, heavyAdClickToPlayEnabled} = settings
  const {videoMediaEncodings, jwMediaId} = video
  const host = getVideoHost(mediaType);

  if (host === PLAYER_HOSTS.JW) {
    return getJwPlayerConfigs(videoMediaEncodings, canarySuggestedAutoPlayMode, jwMediaId, heavyAdClickToPlayEnabled, inHeavyAdsEmulation, isChrome);
  } else if (host === PLAYER_HOSTS.VAST) {
    if (inHeavyAdsEmulation) {
      return {autoPlayMode: AUTOPLAY_MODE.AUTOPLAY_MUTED}
    } else if (isInScreenShooterEmulation) {
      return {autoPlayMode: AUTOPLAY_MODE.NO_AUTOPLAY}
    } else {
      return isChrome && heavyAdClickToPlayEnabled ? {autoPlayMode: AUTOPLAY_MODE.NO_AUTOPLAY} : {autoPlayMode: canarySuggestedAutoPlayMode}
    }
  } else {
    return {autoPlayMode: canarySuggestedAutoPlayMode}
  }
}

