import {useEffect, useRef} from 'react';
import {PLAYER_STATE} from "../utils/constants";

export function usePlayerTimeLogger(playerTime, playerState) {
  const timeRef = useRef(playerTime);
  const lastLogRef = useRef(null);

  useEffect(() => {
    timeRef.current = playerTime;
  }, [playerTime]);

  useEffect(() => {
    let interval;
    if (playerState === PLAYER_STATE.PLAYING) {
      interval = setInterval(() => {
        if (timeRef.current !== lastLogRef.current) {
          console.log('Player Time', timeRef.current)
        }
        lastLogRef.current = timeRef.current;
      }, 1000);
    }
    return () => {
      interval && clearInterval(interval);
    }
  }, [playerState]);
}
