import React, {useCallback, useEffect, useMemo} from 'react';

import connectAutoStartModeChange from "../connectAutoStartModeChange";
import {AUTOPLAY_MODE, LOG_DETAILS_TYPE, PLAYER_STATE, DEFAULT_ASPECT_RATIO, ASPECT_RATIO} from 'utils/constants';
import getCachedScript from "utils/resource-loader";
import {useConfig} from "utils/ConfigProvider";
import {useEventCapture}  from "utils/EventCaptureProvider";
import Functions from '../../../../utils/functions';

function JWPlayer({state, settings, autoPlayMode, isMute = false, onPlayerTimeChange, onPlayerStateChange, onMuteStateChange, onDurationFetch, scrubToHandler, setPlayerControlWrapper, setAutoplayUnMutedError, getThumbnail, setImpressionEvent, setQuartile, preload = true, jwMediaId, fileUrl, encodingWidth = 720}) {
  const {environment} = useConfig();
  const {captureAssetLoadingTime} = useEventCapture();

  const aspectRatio = settings?.video?.videoAspectRatio || DEFAULT_ASPECT_RATIO;

  const getJWAspectRatio = () => {
    const ratio = aspectRatio;
    if (ratio === ASPECT_RATIO.SIXTEEN_BY_NINE) {
      return '16:9';
    }

    if (ratio === ASPECT_RATIO.NINE_BY_SIXTEEN) {
      return '9:16';
    }

    if (ratio === ASPECT_RATIO.FOUR_BY_THREE) {
      return '4:3';
    }

    return '16:9';
  }

  const playerAPIId = `jw-player-${jwMediaId}`;
  const player = useCallback(_ => {
    const api = window.jwplayer || (_ => {});
    return api(playerAPIId);
  }, [playerAPIId]);

  const registerPlayerEvents = (jwplayer) => {
    jwplayer.on('time', (props) => {
      const {currentTime, duration} = props;
      onDurationFetch(Math.floor(duration));
      onPlayerTimeChange(currentTime); // millis
      setQuartile(Functions.getQuarter(currentTime, duration));
    });

    jwplayer.on('complete', () => onPlayerStateChange(PLAYER_STATE.COMPLETED));

    jwplayer.on('pause', () => onPlayerStateChange(PLAYER_STATE.PAUSED));
    jwplayer.on('play', () => {
      setImpressionEvent(true);
      onPlayerStateChange(PLAYER_STATE.PLAYING)
    });
    jwplayer.on('mute', (muteObj) => onMuteStateChange(muteObj.mute));
    jwplayer.on('playAttemptFailed', () => console.error('playAttemptFailed'));
  }

  const init = () => {
    captureAssetLoadingTime(LOG_DETAILS_TYPE.JW_JS_LOAD, true);
    captureAssetLoadingTime(LOG_DETAILS_TYPE.JW_VIDEO_LOAD, false);
    const api = window.jwplayer;
    const jwplayer = api(playerAPIId);

    jwplayer.setup({
      ...(!fileUrl && jwMediaId && {playlist: `//cdn.jwplayer.com/v2/media/${jwMediaId}`}),
      ...(fileUrl && {file: fileUrl, image: `//cdn.jwplayer.com/thumbs/${jwMediaId}-${encodingWidth}.jpg`}),
      aspectratio: getJWAspectRatio(),
      // Studio preview mode is always autostart false + mute false since we no longer need the autoplay hack for seek
      mute: !environment.isCreativeStudioPreviewMode() && autoPlayMode === AUTOPLAY_MODE.AUTOPLAY_MUTED,
      autostart: !environment.isCreativeStudioPreviewMode() && (autoPlayMode === AUTOPLAY_MODE.AUTOPLAY_MUTED || autoPlayMode === AUTOPLAY_MODE.AUTOPLAY_MUTED),
      preload: preload ? 'auto' : 'metadata',
    });

    jwplayer.once('ready', () => {
      captureAssetLoadingTime(LOG_DETAILS_TYPE.JW_VIDEO_LOAD, true);
      onPlayerStateChange(PLAYER_STATE.READY);
    });

    registerPlayerEvents(jwplayer);

    // TODO: merge with playerControlWrapper?
    scrubToHandler(() => {
      return (time, playerState) => {
        const api = player();
        api.seek(time);
        // Note: first time jw seeks, it autoplays - but we are only using html player in studio now to avoid this, keeping the seek code in case we need to bring this back for some reason
        // prevent auto play when seeking 
        if (playerState === PLAYER_STATE.PLAYING) {
          api.play();
        } else {
          api.pause();
        }
      }
    })

    setPlayerControlWrapper({
      play: () => {
        if (environment.isCreativeStudioPreviewMode()) {
          player().seek(player().getPosition()); // hack... jw doesn't always like starting again after playing, perhaps it's internal timers are off and seeking helps get it back
        }
        player().play();
      },
      pause: () => {
        player().pause();
      },
      mute: (mute) => {
        player().setMute(mute);
      },
      setVolume: (volume) => {
        player().setVolume(parseInt(volume));
      }
    })
  }


  useEffect(() => {
    captureAssetLoadingTime(LOG_DETAILS_TYPE.PLAYER_INITIALIZATION, true)
    captureAssetLoadingTime(LOG_DETAILS_TYPE.JW_JS_LOAD, false);
    getCachedScript('//cdn.jwplayer.com/libraries/jMLiZQyA.js').then(init).catch(e => console.log('Unable to load JW lib'));
    return () => {
      const api = window.jwplayer;
      if (api) {
        const jwplayer = api(playerAPIId);
        if (jwplayer) {
          jwplayer.remove();
        }
      }
    };
  }, [playerAPIId, captureAssetLoadingTime]);

  const wrapper = useMemo(() => ({
    play: _ => player()?.play(),
    pause: _ => player()?.pause(),
    mute: m => player()?.setMute(m),
  }), [player]);


  connectAutoStartModeChange(autoPlayMode, state, setAutoplayUnMutedError, wrapper);
  
  return <React.Fragment>
    <div id={playerAPIId} data-testid='jw'/>
    {getThumbnail && getThumbnail()}
  </React.Fragment>
}
export default JWPlayer;
