import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {AutoSize} from "elements/autosize";
import {ENGAGEMENT_DISPLAY_MODE, LIKERT_OPTION_STYLE, POSITION_OPTIONS} from "utils/constants";
import surveyHelpers from "utils/survey-helper";

import style from "common/styles/engagement-likert-survey.module.scss";
import FontAndColorHelper from "utils/fontAndColorHelper";
import {SubmitButton} from "../../elements/submitButton";
import {LikertOptions} from "../likert-options";
import {SliderInstructions} from "../likert-options/sliderInstructions";
import {useConfig} from "utils/ConfigProvider";
import {usePrevious} from "../../hooks/usePrevious";

const DEFAULT_SLIDER_SELECTION_FOR_PREVIEW = 3;

function LikertSurveyRenderer(
  {
    submitEnabled,
    submitButtonText,
    submitButtonColor,
    starColor,
    question,
    mode,
    onClick,
    onSubmit,
    inVideoEngagementPosition,
    showing,
    submitFontSettings,
    selectedPosition,
    iconType,
    iconSize,
    optionStyle,
    sliderColors,
    customIcon,
    singleIcon,
    submitButtonType,
    iconColor,
    isDisplayCreative,
    height,
    width
  }
) {

  const prevOptionStyle = usePrevious(optionStyle);
  const {environment} = useConfig();

  const getDefaultSliderValue = useCallback(() => {
    if (environment.isCreativeStudioPreviewMode() && optionStyle === LIKERT_OPTION_STYLE.SLIDER) {
      return {
        id: question.options[DEFAULT_SLIDER_SELECTION_FOR_PREVIEW].id,
        position: DEFAULT_SLIDER_SELECTION_FOR_PREVIEW
      }
    }
    return null;
  }, [optionStyle, question.options, environment])

  const [selectedOptionForSubmit, setSelectedOptionForSubmit] = useState(getDefaultSliderValue());

  useEffect(() => {
    if (selectedPosition !== null && selectedPosition !== undefined) {
      const selectedOption = question.options[selectedPosition - 1]
      setSelectedOptionForSubmit({id: selectedOption.id, position: selectedPosition})
    }
  }, [selectedPosition, question]);

  useEffect(() => {
    if (
      (prevOptionStyle !== optionStyle) &&
      environment.isCreativeStudioPreviewMode()
    ) {
      setSelectedOptionForSubmit(getDefaultSliderValue());
    }
  }, [selectedPosition, question, prevOptionStyle, optionStyle, getDefaultSliderValue, environment]);

  const [starHover, setStarHover] = useState({isHovering: false});

  const renderLabel = useCallback(() => {
    if (starHover.isHovering) {
      return starHover.label;
    }

    const showSelectionLabel = optionStyle === LIKERT_OPTION_STYLE.SLIDER;

    if (showSelectionLabel && selectedOptionForSubmit?.position) {
      return question.options[(selectedOptionForSubmit?.position || 0) - 1]?.label || <React.Fragment/>;
    }
  }, [optionStyle, question, selectedOptionForSubmit, starHover]);

  const titleFontWeightAndSize = FontAndColorHelper.getFontWeightAndSize(question.fontSettings);

  const titleFontStyle = {
    fontFamily: FontAndColorHelper.getFontAndColor(question.fontSettings).font,
    color: FontAndColorHelper.getFontAndColor(question.fontSettings).color,
    ...titleFontWeightAndSize && titleFontWeightAndSize.fontWeight && {fontWeight: titleFontWeightAndSize.fontWeight}
  };

  const handleSurveySubmit = (selectedOptionForSubmit) => {
    if (environment.isCreativeStudioPreviewMode()) {
      return
    }

    if (!selectedOptionForSubmit) {
      surveyHelpers.showNoOptionSelectedAlert();
    } else {
      onSubmit(selectedOptionForSubmit.id, selectedOptionForSubmit.position);
    }
  }

  const handleStarSelection = (onClick, option, index) => {
    if (environment.isCreativeStudioPreviewMode()) {
      return
    }
    
    onClick(option.id, index + 1);
    if (submitEnabled) {
      setSelectedOptionForSubmit({id: option.id, position: index + 1});
    } else {
      onSubmit(option.id, index + 1);
    }
  }

  const renderVerticalTitle = (question) => {
    return (
      <div className={style.verticalTitle} style={titleFontStyle}>
        <AutoSize isMulti={true} >{question.text}</AutoSize>
      </div>
    )
  }

  const renderHorizontalTitle = () => {
    return optionStyle === LIKERT_OPTION_STYLE.SLIDER ? (
      <div className={style.horizontalTitleSlider}>
        <div style={titleFontStyle} className={style.horizontalTitleSliderText}>
          <AutoSize isMulti={true} className={style.horizontalTitleContainer}>{question.text}</AutoSize>
        </div>
        <SliderInstructions mode={mode} isDisplayCreative={isDisplayCreative}/>
      </div>
    ) : (
      <div className={style.horizontalTitle} style={titleFontStyle}>
        <AutoSize isMulti={true} className={style.horizontalTitleContainer}>{question.text}</AutoSize>
      </div>
    )
  }

  const likertContainerClassName = useMemo(() => {
    switch (mode) {
      case ENGAGEMENT_DISPLAY_MODE.HORIZONTAL:
        return inVideoEngagementPosition === POSITION_OPTIONS.TOP ? style.likertHorizontalContainer : style.likertHorizontalBottomContainer
      case ENGAGEMENT_DISPLAY_MODE.VERTICAL:
        return style.likertVerticalContainer;
      case ENGAGEMENT_DISPLAY_MODE.FULL_MODE:
        return style.likertFullContainer;
      default:
        return style.likertContainer;
    }
  }, [inVideoEngagementPosition, mode]);

  const submitClassName = useMemo(() => {
    if (optionStyle === LIKERT_OPTION_STYLE.SLIDER) {
      switch (mode) {
        case ENGAGEMENT_DISPLAY_MODE.HORIZONTAL:
          return style.submitButtonHorizontalForSlider;
        case ENGAGEMENT_DISPLAY_MODE.VERTICAL:
          return style.submitButtonVerticalForSlider;
        case ENGAGEMENT_DISPLAY_MODE.FULL_MODE:
          return style.submitButtonFullForSlider;
        default:
          return style.submitButton;
      }
    } else {
      switch (mode) {
        case ENGAGEMENT_DISPLAY_MODE.HORIZONTAL:
          return style.submitButtonHorizontal;
        case ENGAGEMENT_DISPLAY_MODE.VERTICAL:
          return style.submitButtonVertical;
        case ENGAGEMENT_DISPLAY_MODE.FULL_MODE:
          return style.submitButtonFull;
        default:
          return style.submitButton;
      }
    }
  }, [mode, optionStyle]);


  return (
    <div
      className={likertContainerClassName}>
      {mode === ENGAGEMENT_DISPLAY_MODE.HORIZONTAL ? renderHorizontalTitle(question) : renderVerticalTitle(question)}
      <div
        className={mode !== ENGAGEMENT_DISPLAY_MODE.HORIZONTAL ? style.verticalLikertContent : style.horizontalLikertContent}>
        <div
          className={mode === ENGAGEMENT_DISPLAY_MODE.HORIZONTAL ? style.starLabelHorizontal : style.starLabelVertical}
          style={titleFontStyle}>
          {renderLabel()}
        </div>
        <LikertOptions
          mode={mode}
          showing={showing}
          selectedPosition={selectedPosition}
          options={question.options}
          selectedOptionForSubmit={selectedOptionForSubmit}
          setStarHover={setStarHover}
          starHover={starHover}
          starColor={starColor}
          iconType={iconType}
          iconSize={iconSize}
          singleIcon={singleIcon}
          customIcon={customIcon}
          optionStyle={optionStyle}
          sliderColors={sliderColors}
          optionClickHandler={(index, option) => {
            handleStarSelection(onClick, option, index);
          }}
          isDisplayCreative={isDisplayCreative}
          height={height}
          width={width}
          noPointer={environment.isCreativeStudioPreviewMode()}
        />
        <SubmitButton
          onClick={() => {
            handleSurveySubmit(selectedOptionForSubmit)
          }}
          submitEnabled={submitEnabled}
          submitButtonText={submitButtonText}
          submitButtonColor={submitButtonColor}
          submitFontSettings={submitFontSettings}
          submitButtonType={submitButtonType}
          iconColor={iconColor}
          submitClassName={submitClassName}
          noPointer={environment.isCreativeStudioPreviewMode()}
        />
      </div>
    </div>
  )
}

export default LikertSurveyRenderer;
