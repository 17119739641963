import React from 'react';
import classnames from 'classnames';

import {useConfig} from "utils/ConfigProvider";
import useBrandLogoEvents from './useBrandLogoEvents';
import {getBrandLogoInfoFromSettings} from 'utils/engagement-helper';
import {BRAND_LOGO_SIZE, CREATIVE_MODE, POSITION_OPTIONS, STYLE_MATCH_DISPLAY_NONE_WITH_DELAY} from 'utils/constants';
import BrandLogoImage from 'elements/brand-logo';
import {Flex} from 'elements/containers';

import style from './style.module.scss'
import {useEventCapture}  from "utils/EventCaptureProvider";

function BrandLogoManager({settings, fireJourneyTrigger, position, showing, onClickThrough, overlayID, setPixelFiringInProgress, creativeDimensions, currentSlideId, hideBottomPadding = false}) {
  const {localConfig, environment} = useConfig();
  const isVideoCreative = settings.creativeType === CREATIVE_MODE.VIDEO;
  const [imageSrc, ctaUrlImage, triggerElementId] = getBrandLogoInfoFromSettings(settings, isVideoCreative, position, localConfig.generateImageSrcFromCache);
  const {capture} = useEventCapture();
  const {onClick} = useBrandLogoEvents(fireJourneyTrigger, position, isVideoCreative, onClickThrough, triggerElementId, overlayID, setPixelFiringInProgress, capture, currentSlideId, environment.isCreativeStudioPreviewMode());

  const height = parseFloat(creativeDimensions?.height);

  const getLogoHeight = () => {
    if (height < 280) {
      return BRAND_LOGO_SIZE.SMALL;
    }
    if (height >= 280 && height <= 600) {
      return BRAND_LOGO_SIZE.MEDIUM;
    }
    if (height > 600) {
      return BRAND_LOGO_SIZE.LARGE;
    }
  }

  const getBrandLogoShowClassNames = (isShowing) => {
    if(position === POSITION_OPTIONS.TOP.toLowerCase()) {
      return isShowing ? style.show : [style.hideFromTop, style.hide];
    } else {
      return isShowing ? style.show : [style.hideFromBottom, style.hide];
    }
  }

  // This make sures that the slide out animation completes before the brand logo disappears
  const getLogoStyleForDisplay = (isLogoShowing) => {
    if (!isLogoShowing) {
      return STYLE_MATCH_DISPLAY_NONE_WITH_DELAY;
    } else {
      return {
        // @TODO - To be confirmed for `maxHeight`.
        // maxHeight: getLogoHeight(),
        width: '100%'
      }
    }
  }

  return (
    <>
      <div style={getLogoStyleForDisplay(showing)}>
        <div data-testid={`brand-logo-${position}`} className={classnames(getBrandLogoShowClassNames(showing))}>
          <div className={classnames(style.brandLogoContainer)} style={getLogoStyleForDisplay(showing)}>
            <Flex.Column fullHeight={true} fullWidth={true}>
              <div data-testid={`${position}-image`} className={classnames(style.brandLogo, position === POSITION_OPTIONS.TOP.toLowerCase() ? '': style.flexEnd, hideBottomPadding ? style.hideBottomPadding : '', {[style.cursorPointer]: !environment.isCreativeStudioPreviewMode()})}>
                <BrandLogoImage
                  imageSrc={imageSrc}
                  ctaUrlImage={ctaUrlImage}
                  onClick={onClick}
                />
              </div>
            </Flex.Column>
          </div>
        </div>
      </div>
    </>
  )
}

export default BrandLogoManager;
