import React, {useMemo, useState,} from 'react';
import { v4 as uuidv4 } from 'uuid';
import MultipleChoiceSurveyRenderer from './renderer';
import useMultipleChoiceSurveyEvents from './useMultipleChoiceSurveyEvents';

import surveyHelpers from 'utils/survey-helper';
import {useConfig} from "utils/ConfigProvider";

function MultipleChoiceSurvey({
  overlay, onEvent, fireJourneyTrigger, mode, submitSurvey, trackSurveyClickThrough, 
  onClickThrough, inVideo = false, isDisplayCreative, getSelectedPosition,
  setSelectedOption, creativeDimensions, surveySubmitted, scrubBarShowing, submissionDetails, setsubmissionDetailsTime, rendererKey}) {

  const {environment} = useConfig();
  const [showPollingScreen, setShowPollingScreen] = useState(false);
  const {creativeCard} = overlay
  const {multipleChoiceEngagement} = creativeCard;
  const surveyID = multipleChoiceEngagement.id;
  const question = multipleChoiceEngagement.questions?.[0];
  const questionId = question?.id;
  const pollingEnabled = multipleChoiceEngagement?.pollingEnabled || false;
  const options = question?.options;
  const submissionsCount = options.reduce((total, option) => total + (option.responseChoiceCount || 0), 0);
  const {onClick, onSubmit} = useMultipleChoiceSurveyEvents(onEvent, inVideo, fireJourneyTrigger, submitSurvey, trackSurveyClickThrough, onClickThrough, overlay?.inSessionTriggerDetails?.triggerElementId, environment.isVPAIDEnabled(), setShowPollingScreen, pollingEnabled, setsubmissionDetailsTime, isDisplayCreative, surveyID, submissionDetails);
  const surveyRules = useMemo(() => {
    return multipleChoiceEngagement?.surveyRules
      ?.map((surveyRule) => ({...surveyRule, optId: question?.options?.find((option) => option.optionIndex === surveyRule.rating)?.id}));
  }, [multipleChoiceEngagement, question])
  const selectedPosition = useMemo(() => {
    return getSelectedPosition ? getSelectedPosition(surveyID) : null;
  },[getSelectedPosition, surveyID])

  // TODO: discuss this magic number with product... if we iframe display creatives, we can potentially use a media-query instead
  const widthVal = parseFloat(creativeDimensions?.width);
  const isNarrow = isDisplayCreative && widthVal <= 450;

  const handleOnClick = (selectedQuestionOptionID, position, optionIndex) => {
    if (setSelectedOption) {
      setSelectedOption({[surveyID]: position});
    }
    onClick(surveyID, questionId, position, optionIndex, selectedQuestionOptionID, surveyHelpers.getSelectedQuestionOptionRule(surveyRules, selectedQuestionOptionID));
  }

  const handleOnSubmit = (selectedQuestionOptionID, position, optionIndex) => {
    onSubmit(surveyID, questionId, position, optionIndex, selectedQuestionOptionID, surveyHelpers.getSelectedQuestionOptionRule(surveyRules, selectedQuestionOptionID));
  }

  const additionalProps = useMemo(() => getProps(creativeCard), [creativeCard])

  return (
    <MultipleChoiceSurveyRenderer
      key={rendererKey} // optional key to force re-render, used by studio - see OV-847
      mode={mode}
      onClick={handleOnClick}
      onSubmit={handleOnSubmit}
      isNarrow={isNarrow}
      isDisplayCreative={isDisplayCreative}
      surveySubmitted={surveySubmitted}
      selectedPosition={selectedPosition}
      isCreativeStudioPreviewMode={environment.isCreativeStudioPreviewMode()}
      pollingEnabled={pollingEnabled}
      submissionsCount={submissionsCount}
      showPollingScreen={showPollingScreen}
      scrubBarShowing={scrubBarShowing}
      submissionDetails={submissionDetails}
      surveyID={surveyID}
      {...additionalProps}
    />
  );
}

const getProps = (creativeCard) => {
  const {multipleChoiceEngagement} = creativeCard;
  const {submitEnabled, questions, button} = multipleChoiceEngagement;

  const question = questions?.[0];
  const isValidQuestion = !!question?.text?.text; 

  const options = isValidQuestion ? question.options.slice(0, question?.numberOfOptions || 0).map((option) => ({
    ...option,
    // studio preview support, studio preview does not randomize options
    id: option.id ? option.id : uuidv4(),

  })) : [];
  const title = question?.text?.text || "";
  const questionAndOptionsFontSettings = isValidQuestion ? {
    color: question.text?.color,
    font: question.text?.font 
  } : {color: 'white', font: 'RedHatDisplayMedium'};

  const submitButtonText = submitEnabled && button?.text;
  const submitButtonBackgroundColor = submitEnabled && button?.bgColor;
  const submitButtonFontSettings = submitEnabled && {
    font: question?.text?.font,
    color: button?.color
  }

  return { title, options, questionAndOptionsFontSettings, submitEnabled, submitButtonText,
    submitButtonFontSettings, submitButtonBackgroundColor };
}

export default MultipleChoiceSurvey;
