import React, {useCallback, useMemo, useRef, useState} from 'react';

import DisplayCreativeRenderer from "./renderer.js";
import BrandLogoManager from "modules/brand-logo-manager"; // TODO: should modules cross import or should this be a compound?
import {useJourneyTriggerEvents} from "hooks/useJourneyTriggerEvents";
import {useSurveys} from "hooks/useSurveys";

import {doGet} from "utils/eventUtil";
import {getJourneyTriggerDisplayEvent, getCurrentDisplayEngagement} from "utils/engagement-helper";
import surveyHelpers from "utils/survey-helper";
import {
  POSITION_OPTIONS,
  IN_SESSION_JOURNEY_EVENT_MAP,
  THIRD_PARTY_EVENTS,
  JourneyTriggerInteractiveEvent,
  isPathMaker,
  ENGAGEMENT_TEMPLATE_MAP,
  MC_POLLING_TIMEOUT,
  ErrorType
} from 'utils/constants';
import {getAllInSessionTriggerDetails, getNextTriggerElementIdsInPath, getNewTriggerState} from "utils/inSessionTriggerHelper";

import {AdChoices} from "elements/adChoices";
import {BottomBar, GroupRow, PositionLeft, PositionRight, TopBar} from 'elements/player-layout';


import style from "../../index.module.scss";
import {executeLiveRampIdPullFlow} from "utils/liveRampHelper";
import {useConfig} from "utils/ConfigProvider";
import {useEventCapture}  from "utils/EventCaptureProvider";
import {getCreativeCardFromSingleSetting} from 'utils/settingsHelper';
import {CreativeBorder} from "elements/creativeBorder";

function DisplayCreative(
  {
    settings: originalSettings,
    track3rdPartyEvents,
    creativeDimensions,
    isContentFlowCreative,
    hotspotProps
  }
) {

  const settings = useMemo(() => (originalSettings && {
    ...originalSettings,
    creativeCardSettings: (originalSettings.creativeCardSettings || [])
      .map((creativeCardSetting) => ({
        ...creativeCardSetting, 
        creativeCard: getCreativeCardFromSingleSetting(creativeCardSetting, 
          {creativeCardType: creativeCardSetting.creativeCard.creativeCardType, isDisplay: true, creativeHeight: originalSettings.height, creativeWidth: originalSettings.width}
        )
      })),
  }), [originalSettings]);

  const fireCallback = useCallback((event) => track3rdPartyEvents(event, null, null, {}), [track3rdPartyEvents]);
  const {localConfig, environment} = useConfig();
  const {fireJourneyTrigger} = useJourneyTriggerEvents(settings.journeyTriggers, environment.isVPAIDEnabled());
  const {isSurveySubmitted, submitSurvey} = useSurveys(environment.isODCEngagementPreviewEnabled());
  const [triggerState, setTriggerState] = useState({});
  const [triggerIdsInPath, setTriggerIdsInPath] = useState([]);

  const {capture, capturePlayerLoadTime} = useEventCapture();
  const [currentSlideId, setCurrentSlideId] = useState(null);
  const allInSessionTriggerDetails = useMemo(() => getAllInSessionTriggerDetails(settings), [settings]);
  const settingsDimensions = useMemo(() => ({width: settings.width, height: settings.height}), [settings]);
  const currentDisplayEngagement = useMemo(() => getCurrentDisplayEngagement(settings, triggerState, triggerIdsInPath,
    environment.ignoreLogicAndPath(), environment.isInScreenShooterEmulation(), environment.getEngagementItemId(), environment.isCreativeStudioPreviewMode(), environment.getAdUnitId()),
    [settings, triggerState, triggerIdsInPath, environment]);

  const mediaType = settings?.mediaType;
  const isPathMakerType = isPathMaker(mediaType);
  const overlayID = !isPathMakerType ? currentDisplayEngagement?.creativeCard?.id : null;
  const isShared = !isPathMakerType ? !!currentDisplayEngagement?.isSharedCard : false;
  const surveyID = !isPathMakerType ? surveyHelpers.getSurveyDetails(currentDisplayEngagement?.creativeCard).surveyID : null;
  const engagementTemplate = !isPathMakerType ? ENGAGEMENT_TEMPLATE_MAP[currentDisplayEngagement?.creativeCard?.creativeCardType] : null;
  const impressionEventDone = useRef(false);
  const fireJourneyTriggerWrapper = useCallback((event, elementTriggerId, suppressJourneyTrigger=false, pollingEnabled=false) => {
    const eventSplit = event.split("_"); // for card hotspots
    const eventName = eventSplit[0];
    if (!suppressJourneyTrigger) {
      fireJourneyTrigger(IN_SESSION_JOURNEY_EVENT_MAP[eventName] ? IN_SESSION_JOURNEY_EVENT_MAP[eventName] : eventName);
    }

    if (!environment.isODCEngagementPreviewEnabled()) {
      if (elementTriggerId) {
        pollingEnabled ?
        setTimeout(() => {
          setTriggerState((prevState) => {
            return getNewTriggerState(prevState, elementTriggerId, event)
          });
        }, MC_POLLING_TIMEOUT) : setTriggerState((prevState) => { 
          return getNewTriggerState(prevState, elementTriggerId, event)
        });
       
        // Multiple JT events can fire simultaenously e.g interactiveHotspotInteraction and interactiveHotspotClickThrough so filter out non-in-session triggerable events to prevent overwrite
        // in session events should be 1 event per user interaction
        if (IN_SESSION_JOURNEY_EVENT_MAP[event] || IN_SESSION_JOURNEY_EVENT_MAP[eventName]) {
          const triggerIds = getNextTriggerElementIdsInPath(allInSessionTriggerDetails, triggerState, elementTriggerId, event);
          if (triggerIds && triggerIds.length !== 0) {
            pollingEnabled ?
            setTimeout(() => {
            setTriggerIdsInPath(triggerIds);
            }, MC_POLLING_TIMEOUT) :  setTriggerIdsInPath(triggerIds);
          }
        }
      } else {
        console.log("fireJourneyTrigger called without elementTriggerId on event: ", event);
      }
    }
  }, [fireJourneyTrigger, setTriggerState, setTriggerIdsInPath, allInSessionTriggerDetails, triggerState, environment]);


  const isLiveRampEnabled = settings?.liveRampEnabled;
  const rampIdImpressionIdApiGatewayPixel = settings?.rampIdImpressionIdApiGatewayPixel;
  const trackImpression = useCallback(() => {

    const fireLoadedEventToParentWindow = () => {
      if (window.parent && `${environment.getAdUnitId()}`) {
        window.parent.postMessage({code: 'Completed', id: `${environment.getAdUnitId()}`}, '*');
      }
    }

    if (!impressionEventDone.current) {
      impressionEventDone.current = true;
      let payload = {action: 'Impression', overlayID, isShared, engagementTemplate,  shouldCardExposureEventsBeSend: surveyID || overlayID? 'true' : null}
      if(surveyID) {
        payload = {...payload, surveyID}
      }
      capture(payload);

      capturePlayerLoadTime();
      fireJourneyTrigger(JourneyTriggerInteractiveEvent.impression); // Does not need fireJourneyTriggerWrapper as this is not used for InstaJourney
      fireCallback(THIRD_PARTY_EVENTS.IMPRESSION);
      fireLoadedEventToParentWindow();
      executeLiveRampIdPullFlow(isLiveRampEnabled, rampIdImpressionIdApiGatewayPixel, localConfig.isRealLiveRampIdFetchEnabled, localConfig.liveRampIdFetchUrl, capture, environment.isEditMode() || environment.isInAdminTool());
    }

    // This hook is only supposed to fire once from the renderer, these dependencies should never change once loaded or this hook will fire unexpectedly
  }, [isShared, fireCallback, fireJourneyTrigger, overlayID, engagementTemplate, surveyID, isLiveRampEnabled, rampIdImpressionIdApiGatewayPixel, environment, capture, capturePlayerLoadTime, localConfig]);

  const fireDSPClickTrackingURL = () => {
    const clickTrackingURL = environment.getClickTrackingURL();
    if (clickTrackingURL) {
      doGet(clickTrackingURL);
    }
  }

  const onTrackSurveyClickThrough = () => {
    const journeyTriggerClickEvent = getJourneyTriggerDisplayEvent(settings.mediaType);
    fireJourneyTrigger(journeyTriggerClickEvent); // Does not need fireJourneyTriggerWrapper as this is not used for InstaJourney
    fireJourneyTrigger(JourneyTriggerInteractiveEvent.clickThrough); // Does not need fireJourneyTriggerWrapper as this is not used for InstaJourney
    fireDSPClickTrackingURL();
    fireCallback(THIRD_PARTY_EVENTS.CLICK_THROUGH);
  }

  const trackClickThrough = (payload, triggerElementId, hasTrackingUrl) => {
    fireJourneyTriggerWrapper(JourneyTriggerInteractiveEvent.clickThrough, triggerElementId, !hasTrackingUrl);
    // TODO: isolate isEditMode event prevention across all of these functions
    if (environment.isEditMode() || !hasTrackingUrl) {
      return;
    } else {
      if (payload) {
        capture(payload);
      }
      fireDSPClickTrackingURL();
      fireCallback(THIRD_PARTY_EVENTS.CLICK_THROUGH);
    }
  }

  const {width, height, adChoicesEnabled = true} = settings;

  const aspectRatio = useMemo(() => width / height, [width, height]);

  return (
    <div className={style.phoenixDisplay}>
      { settings.creativeBorderEnabled &&
      <CreativeBorder
          borderColor={settings.creativeBorderColor}
          borderWidth={settings.creativeBorderWidth}
      /> }
      <TopBar isCloseButtonShowing={false}>
        <GroupRow>
          <PositionLeft>
            <BrandLogoManager
              settings={settings}
              fireJourneyTrigger={fireJourneyTriggerWrapper}
              showing={!!settings?.topLogo?.visible}
              position={POSITION_OPTIONS.TOP.toLowerCase()}
              overlayID={overlayID}
              currentSlideId = {currentSlideId}
              creativeDimensions={creativeDimensions}
            />
          </PositionLeft>
        </GroupRow>
      </TopBar>
      <div className={style.fillSpace}>
        <DisplayCreativeRenderer
          displayEngagement={currentDisplayEngagement}
          settings={settings}
          trackImpression={trackImpression}
          fireJourneyTrigger={fireJourneyTriggerWrapper}
          submitSurvey={submitSurvey}
          isSurveySubmitted={isSurveySubmitted}
          trackSurveyClickThrough={onTrackSurveyClickThrough}
          trackClickThrough={trackClickThrough}
          fireCallback={fireCallback}
          aspectRatio={aspectRatio}
          overlayID={overlayID}
          creativeDimensions={creativeDimensions}
          isContentFlowCreative={isContentFlowCreative}
          capture={capture}
          setCurrentSlideId={setCurrentSlideId}
          hotspotProps={hotspotProps}
        />
      </div>
      <BottomBar>
        <GroupRow groupRowStyle={{alignItems: 'flex-end'}}>
          <PositionLeft/>
          <PositionRight>
            <BrandLogoManager
              settings={settings}
              fireJourneyTrigger={fireJourneyTriggerWrapper}
              showing={!!settings?.bottomLogo?.visible}
              position={POSITION_OPTIONS.BOTTOM.toLowerCase()}
              overlayID={overlayID}
              currentSlideId={currentSlideId}
              creativeDimensions={creativeDimensions}
            />
          </PositionRight>
        </GroupRow>
        {adChoicesEnabled && <AdChoices creativeDimensions={creativeDimensions} settingsDimensions={settingsDimensions} isDisplayCreative={true}/>}
      </BottomBar>
    </div>
  );
}

export default DisplayCreative;
