/* global FB */
import getCachedScript from './resource-loader';

const singleton = Symbol();
const singletonEnforcer = Symbol();

const FB_SDK_VERSION = 'v5.0';

class FBLoader {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw 'Cannot construct singleton';
    }

    this._state = 0;// 0 - unloaded, 1 - in progress, 2 - loaded
    this._listeners = [];
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new FBLoader(singletonEnforcer);
    }
    return this[singleton];
  }

  initAndAddListener(fbApiKey, fn = () => {}) {
    if (this._state === 0) {
      this._initSdk(fbApiKey);
      this._state = 1;
      this._listeners.push(fn);
    } else if (this._state === 1) {
      this._listeners.push(fn);
    }
  }

  addListener(fn) {
    if (this._state === 1) {
      this._listeners.push(fn);
    } else {
      fn();
    }
  }

  _initSdk(fbApiKey) {
    getCachedScript(`//connect.facebook.net/en_US/sdk.js#xfbml=1&version=${FB_SDK_VERSION}&appId=${fbApiKey}`).then(() => {
      if (window.FB == null) {
        return;
      }

      window.FB.init({
        appId: fbApiKey,
        version: FB_SDK_VERSION
      });

      window.FB.getLoginStatus(() => {
        this._state = 2;
        this._callFns();
      });
    }).catch(e => console.log('Unable to load FB lib'));
  }

  _callFns() {
    this._listeners.forEach( listenerFunc => listenerFunc.call(this) );
  }
}

export default FBLoader;
