import React from "react";
import {ENGAGEMENT_DISPLAY_MODE} from "utils/constants";
import FlexContainer, {Flex, JUSTIFY} from "elements/containers";
import {Button, Title} from "elements/typography";
import SocialIconsGroup from "compounds/social-icons-group";
import {AutoSize} from "elements/autosize";
import style from './style.module.scss';

function SocialConnectorRenderer({title, titleFontSettings, buttonText, buttonColor, buttonFontSettings, icons, boxStyle, iconStyle, mode = ENGAGEMENT_DISPLAY_MODE.FULL_MODE, onCtaClick, onShareIconClick, shareUrlConfig, inVideoEngagementPosition, creativeDimensions, isCreativeStudioPreviewMode, ...rest}) {
  return (mode !== ENGAGEMENT_DISPLAY_MODE.HORIZONTAL) ? (
      <Flex.Column fullWidth fullHeight spacing={150} justify={JUSTIFY.CENTER}>
        <Title fontSettings={titleFontSettings} centerAlign autoResize>{title}</Title>
        {icons && icons.length > 0 && <AutoSize isMulti hideElementsDuringResize><SocialIconsGroup icons={icons} boxStyle={boxStyle} iconStyle={iconStyle} onClick={onShareIconClick} shareUrlConfig={shareUrlConfig} inVideoEngagementPosition={inVideoEngagementPosition} mode={mode} creativeDimensions={creativeDimensions}/></AutoSize>}
        <div className={style.maxWidth}><Button autoResize padding='.5em 2em' fontSettings={{...buttonFontSettings, size: '.85em'}} buttonColor={buttonColor} onClick={onCtaClick} noPointer={isCreativeStudioPreviewMode} centerAlign>{buttonText}</Button></div>
      </Flex.Column>
    ) :
    (
      <FlexContainer style={{padding:'.5em', height: '100%'}}>
        <Flex.Column flex={7} spacing={50} fullHeight justify={JUSTIFY.CENTER}>
          <Title fontSettings={titleFontSettings} centerAlign autoResize containerStyle={{height: '60%'}}>{title}</Title>
          {icons && icons.length > 0 && <AutoSize isMulti hideElementsDuringResize className={style.height40}><SocialIconsGroup icons={icons} boxStyle={boxStyle} iconStyle={iconStyle} onClick={onShareIconClick} shareUrlConfig={shareUrlConfig} inVideoEngagementPosition={inVideoEngagementPosition} mode={mode} creativeDimensions={creativeDimensions}/></AutoSize>}
        </Flex.Column>
        <Flex.Column fullWidth fullHeight flex={3} justify={JUSTIFY.CENTER}>
          <Button padding='.5em 2em' fontSettings={{...buttonFontSettings, size: '.85em'}} buttonColor={buttonColor} onClick={onCtaClick} noPointer={isCreativeStudioPreviewMode} autoResize centerAlign>{buttonText}</Button>
        </Flex.Column>
      </FlexContainer>
    )
}
export default SocialConnectorRenderer;
