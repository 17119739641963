/* global ga, IntersectionObserver */
import { v4 as uuidv4 } from 'uuid';
import {eventUtil, CustomError, constants, urlHelper} from "@phoenix/core";
import config from '../../local-config';
import env from './environment';
import RuntimeProperties from 'utils/runtimeProperties';
import {captureException} from '@sentry/browser';
import LoggerHelper from './loggerHelper';

const {ENGAGEMENT_TYPES, LOG_DETAILS_TYPE, VAST_EVENTS, ErrorType} = constants;
const singleton = Symbol();
const singletonEnforcer = Symbol();

const VALID_EVENT_TYPES = ['ClickEvent', 'ShareEvent', 'SurveyEvent', 'PlayerEvent'];
const PLAYER_LOAD_THRESHOLD_TIME = 7; // Normally publisher kills us when player takes around 8s to load. Choosing 7s to cater all event passing time.
const logger = LoggerHelper.instance;


// TODO: remove singleton pattern in favor of 
export default class EventHelper {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw 'Cannot construct singleton';
    }
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new EventHelper(singletonEnforcer);
    }
    return this[singleton];
  }

  static destroyInstance() {
    delete this[singleton];
  }

  init(settings) {
    if (settings) {
      this.isDisplayCreative = settings.creativeType === "DISPLAY";
      this.isEmbed = settings.embed || env.isVGEmbed();
      this._initBasePayLoad(settings);
    }
  }

  captureSentryError(ev) {
    if (!ev) {
      return;
    }
    const errorType = this.findErrorType(ev);
    const payload = {
      type: 'error',
      errorType
    };
    if (errorType === ErrorType.PLAYER_TOOK_MORE_TIME_TO_LOAD) {
        payload.playertotalloadtime = ev.extra?.playerLoadTime;
    }
    if (!this.params) {
      this._initBasePayLoad({}); // In case we get error before settings getting fetched, initialize it with default settings.
    }
    this.capture(payload);
    if (errorType === ErrorType.NO_SUPPORTED_SOURCE_ERROR) {
      this.capture({ type : 'startfail' } );
    }
  }

  shouldSkipEventToSentry(ev, ignoredErrorsInSentry) {
    return ev && ev.exception && ev.exception.values && ev.exception.values[0] && ev.exception.values[0].value && ignoredErrorsInSentry.some(str => ev.exception.values[0].value.includes(str))
  }

  findErrorType(ev) {
    let errorType = '';
    // Check ODC-3376, we need to capture this error in druid and also register it as a start fail
    if (ev.message !== undefined && ev.message.toLowerCase().includes('notsupportederror: failed to load because no supported source was found')) {
      errorType = ErrorType.NO_SUPPORTED_SOURCE_ERROR;
    } else {
      errorType = ev.extra && ev.extra.errorType ? ev.extra.errorType : ErrorType.GENERIC_ERROR;
    }
    return errorType;
  }
  /**
   * Submit events to druid via pixel technique. If GoogleAnalytics is enabled, it will also submit an event to ga.
   * Avoids sending any data to either service when in edit mode.
   * @param {Object} payload the payload information.
   * @param {boolean} isPromise, if true, then return promise for pixel
   * @returns {promise} promise to indicate that image pixel was generated.
   * @see _initBasePayLoad
   */
  capture(payload) {
    // Uncomment below to debug event capture when network capture is bypassed due to misisng ed param etc
    // console.log("capture called - action: " + payload?.action + ", type: " + payload?.type)
    // console.dir(payload)

    // As per ODC-3790: To reduce counts discrepancy between odyssey and 3rd party vendors -
    // the common standard events ['Impression','Q1','Q2','Q3','Pause','Done','Mute','Unmute'] are now captured in the druid via pixels added in vast xml which are fired by the publisher
    if (this.shouldPhoenixSkipFiringStandardVastPixel(payload.action)) {
      return Promise.resolve(false);
    }
    if (!this.isEdParamAvailable()) {
      return Promise.resolve(false);
    }
    const {action} = payload;
    if (action) {
      payload.type = action.toLowerCase();
    }
    // Going forward, we will be using vg_type.
    // TODO: For backward compatibility, lets keep both type and vg_type. Will remove type in next release.
    payload.vg_type = payload.type.toLowerCase();
    payload.phoenix = true;
    payload.viewabilityratio = this.viewabilityRatio ?? -1;
    payload.autoplayMode = RuntimeProperties.getAutoplayMode();
    payload.browserAutoplayDisabled = RuntimeProperties.isBrowserAutoplayDisabled();
    payload.clientRequestId = uuidv4();
    if (!this.isDisplayCreative && payload.errorType === ErrorType.PLAYER_TOOK_MORE_TIME_TO_LOAD) {
      this.enrichWithLogDetails(payload);
    }
    const fullURL = this.genURL(payload);
    return eventUtil.doGetPromise(fullURL);
  }

  isEdParamAvailable() {
    return this.params && !!this.params.ed;
  }

  genParams(payload) {
    const type = payload.type;

    const param = Object.assign({}, this.params);

    if (VALID_EVENT_TYPES.includes(type)) {
      param.tzOffset = this.tzOffsetValue;
    }

    return Object.assign(param, payload);
  }

  genURL(payload) {
    const param = this.genParams(payload);
    return `${config.vgaServerUrl}?${urlHelper.toQueryString(param)}`;
  }

  genHeavyAdInteractionCaptureURL(payload, captureURL) {
    if (!this.params) { // In case we get heavy ad error before settings is fetched, need to initialize params.
      this._initBasePayLoad(payload);
    }
    const param = this.genParams(payload);
    return `${captureURL}?${urlHelper.toQueryString(param)}`;
  }

  _initBasePayLoad(settings) {
    const {creativeId = env.getAdUnitId(), ed = env.getEd(), decryptedEd, clientHints} = settings
    const decodedEd = ed ? decodeURIComponent(ed) :null
    this.params = {
      creativeId,
      ed,
      edLength: decodedEd?.length,
      srcURL: encodeURIComponent(env.getSrcUrl()),
      srcParentUrl: urlHelper.urlParam('srcParentUrl'),
      referer: urlHelper.urlParam('referer'),
      decryptedEd: decryptedEd ? JSON.stringify(decryptedEd) : null,
      cdnEnabled: false,
      vgChUa: clientHints ? JSON.stringify(clientHints) : null,
    };
    if (RuntimeProperties.isVideoExploreCampaign()) {
      this.params.parentCreativeId = RuntimeProperties.getVideoExploreParentCampaignId();
      this.params.engagementTemplate = ENGAGEMENT_TYPES.Explorer;
      this.params.optionPosition = RuntimeProperties.getVideoExploreOptionPosition();
      const explorerParentCreativeDetails = RuntimeProperties.getExplorerParentCreativeDetails();
      if (explorerParentCreativeDetails) {
        const {engagementExperienceSource, hotspotId, overlayID, hotspotType, invideoposition, timetriggerinterval, videotime} = explorerParentCreativeDetails
        this.params.engagementExperienceSource = engagementExperienceSource;
        this.params.hotspotId = hotspotId;
        this.params.overlayID = overlayID;
        this.params.hotspotType = hotspotType;
        this.params.invideoposition = invideoposition;
        this.params.timetriggerinterval = timetriggerinterval;
        this.params.videotime = videotime;
      }
    }
  }

  getPlayerBaseConfiguration() {
    return this.params;
  }

  shouldPhoenixSkipFiringStandardVastPixel(event) {
    return Object.values(VAST_EVENTS).includes(event) && env.isVPAIDEnabled() && !env.isShareLandingPage() && !RuntimeProperties.isVideoExploreCampaign() && !this.isEmbed;
  }

  capturePlayerLoadTime()  {
    const ibtMillis = RuntimeProperties.getIbtMillis();
    const playerLoadTime = Date.now() - ibtMillis;
    const playerLoadTimeDim = Math.ceil(playerLoadTime / 100) * 100; // rounded to top 100 milisecond.
    // to make sure we do not go beyond 10 seconds and capture everything
    
    const payload = {
      type: 'PlayerEvent',
      action: 'PlayerLoadTime',
      playerLoadTimeMillis: `${playerLoadTime}`,
      loadTimeMillisDim: `${playerLoadTimeDim}`,
      elapsedSeconds: 0,
      cacheBehaviour: null
    };
    if (!this.isDisplayCreative) {
      this.enrichWithLogDetails(payload);
    }
    this.capture(payload);
    if (env.isVPAIDEnabled()) {
      const playerLoadTimeInSeconds = playerLoadTime ? playerLoadTime / 1000 : null;
      console.log(`Player took ${playerLoadTimeInSeconds}s to load`);
      if (playerLoadTimeInSeconds && playerLoadTimeInSeconds >= PLAYER_LOAD_THRESHOLD_TIME) {
        logger.printAllLogs();
        captureException(new CustomError('Player took more time to load'), {extra: {errorType: ErrorType.PLAYER_TOOK_MORE_TIME_TO_LOAD, playerLoadTime}})
      }
    }
  }

  /**
   * This will add load timing for multiple assets into payload.
   * These will go into raw data and then snowflake (This wont go into druid)
   * @param payload
   */
  enrichWithLogDetails(payload) {
    try {
        Object.values(LOG_DETAILS_TYPE).forEach((logDetailsType) => {
          const logDetails = logger.assetLoadingTime ? logger.assetLoadingTime[logDetailsType] : null;
          if (!logDetails) {
            return;
          }
          const {startTime, endTime} = logDetails;
          if (startTime && endTime) {
            const logDetailsTypeFormatted = `${logDetailsType.toLowerCase().replace(/ /g,'')}time`
            payload[logDetailsTypeFormatted] = endTime - startTime
          }
        })
      if (window.performance) {
        const perfEntries = window.performance.getEntriesByType("resource");
        if (perfEntries) {
          const entry = perfEntries.find(entry => entry.name.includes(`${config.vgpServerUrl}/player/settings?cid`))
          if (entry) {
            payload["contentdownloadtime"] = (entry.responseEnd - entry.responseStart).toFixed(2);
            payload["postconnectionfirstbytetime"] = (entry.responseStart - entry.requestStart).toFixed(2);
            payload["connectiontime"] = (entry.connectEnd - entry.connectStart).toFixed(2);
            payload["dnslookuptime"] = (entry.domainLookupEnd - entry.domainLookupStart).toFixed(2);
            payload["totalfetchduration"] = entry.duration.toFixed(2);
          }
        }
      }
    } catch (ex) {
      console.error('Error in setting up log details in payload', ex)
    }
  }
}
