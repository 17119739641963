import 'core-js/es6/map';
import 'core-js/es6/set';
import 'proto-polyfill';
import React from 'react';
import ReactDOM, {unmountComponentAtNode} from 'react-dom';
import initSentryFn from 'utils/sentry';
import {PhoenixCore, constants, urlHelper} from "@phoenix/core";

import './main.css';
import AppContainer from "./AppContainer";
import RuntimeProperties from "utils/runtimeProperties";
import LoggerHelper from './utils/loggerHelper';
import Environment from "utils/environment";

const {LOG_DETAILS_TYPE} = constants;

const deps = []; // dependencies to pre-load

// Conditional polyfills - mainly for ie11
if (!window.fetch) {
  deps.push(import(/* webpackChunkName: "polyfill-fetch" */ 'whatwg-fetch'));
}


let ibtMillis = urlHelper.urlParam('ibtMillis');

if (ibtMillis) {
  ibtMillis = parseInt(ibtMillis, 10);
} else {
  ibtMillis = new Date().getTime();
}

const rootElement = document.getElementById('app');
const buildVersion = rootElement.getAttribute('data-build-version');

const removeAd = () => {
  unmountComponentAtNode(rootElement);
}

if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function (strToReplace, strToReplaceWith)  {
        if (Object.prototype.toString.call(strToReplace).toLowerCase() === '[object regexp]') {
            return this.replace(strToReplace, strToReplaceWith);
        }
        const escapedString = strToReplace.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // escape regex reserved keywords
        return this.replace(new RegExp(escapedString, "g"), strToReplaceWith);
    }
}

RuntimeProperties.setIbtMillis(ibtMillis);
const logger = LoggerHelper.instance;
const load = _ => {
  const creativeId = RuntimeProperties.isVideoExploreCampaign() ? RuntimeProperties.getVideoExploreCampaignId() : Environment.getAdUnitId();
  initSentryFn(buildVersion, creativeId);
  logger.captureAssetLoadingTime(LOG_DETAILS_TYPE.POLYFILL_LOAD, true);
  logger.captureAssetLoadingTime(LOG_DETAILS_TYPE.APP_COMPONENT_MOUNT, false);
  unmountComponentAtNode(rootElement);
  ReactDOM.render(
    (
      <React.StrictMode>
        <AppContainer reload={load} removeAd={removeAd} key={new Date().getTime()}/>
      </React.StrictMode>
    ), rootElement);
}

Promise.all(deps)
  .then(load)
  .catch((error) => {
    console.error('Failed to load polyfills', error);
  })
