import React from 'react'
import {Flex, FlexContainer} from "elements/containers";

import FontAndColorHelper from "utils/fontAndColorHelper";

function Engagement({children, fontSettings, onClose, noPadding, ...rest}) {
  // TODO: dummy color passed in to get the function to work, should refactor or add function that just translates
  //      backend font to real font-family
  const fontAndColor = FontAndColorHelper.getFontAndColor(fontSettings, true);
  const fontWeightAndSize = FontAndColorHelper.getFontWeightAndSize(fontSettings);

  const containerStyle = {
    color: fontAndColor.color,
    fontFamily: fontAndColor.font,
    ...fontWeightAndSize && fontWeightAndSize.fontSize && {fontSize: fontWeightAndSize.fontSize},
    ...fontWeightAndSize && fontWeightAndSize.fontWeight && {fontWeight: fontWeightAndSize.fontWeight},
    padding: noPadding ? 0 : '.25em'
  };

  return (
    <FlexContainer
      style={containerStyle}
      {...rest}>
      <Flex.Column fullHeight fullWidth>
        {children}
      </Flex.Column>
    </FlexContainer>
  )
}

export default Engagement;
