import React from 'react';
import classnames from "classnames";

import {ENGAGEMENT_DISPLAY_MODE} from "../../utils/constants"

import style from "../../common/styles/engagement-likert-survey.module.scss";

const getInstructionLabelClass = (mode, isDisplayCreative) => {
  if (isDisplayCreative) {
    return style.instructionLabel;
  }
  if (mode === ENGAGEMENT_DISPLAY_MODE.VERTICAL) {
    return style.instructionLabelVertical;
  }
  if (mode === ENGAGEMENT_DISPLAY_MODE.HORIZONTAL) {
    return style.instructionLabelHorizontal;
  }
  return style.instructionLabelFull;
}

const getInstructionLabelMarginClass = (mode) => {
  if (mode === ENGAGEMENT_DISPLAY_MODE.VERTICAL) {
    return style.instructionLabelVerticalMargin;
  }
  if (mode === ENGAGEMENT_DISPLAY_MODE.FULL_MODE) {
    return style.instructionLabelFullMargin;
  }
}

export function SliderInstructions(props) {
  const {
    mode,
    isDisplayCreative,
  } = props;

  return (
    <div
      className={classnames(getInstructionLabelClass(mode, isDisplayCreative), getInstructionLabelMarginClass(mode))}>
      <div>Slide or click to submit your response</div>
    </div>
  )
}