import {captureException} from '@sentry/browser';
import {ErrorType} from 'utils/constants';
import CustomError from 'utils/customError';
import { v4 as uuidv4 } from 'uuid';
const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 * Add listener to this class which is singleton to lister from player.
 * Events would be Load, Playing, Paused, Stopped, CountsAsView
 * Vpaid.js, event handlers for firing third party events uses this
 */
class MessageHelper {

  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw 'Cannot construct singleton';
    }

    this.listeners = [];
  }

  playerStatus(event, time, duration, volume) {
    this.listeners.forEach(({listener}) => {
      try {
        listener.call(this, {vgPlayerStatus: event, time, duration, volume});
      } catch (err) {
        console.error('Error in executing callbacks for player status', err);
        captureException(new CustomError(ErrorType.ERROR_EXECUTING_PLAYER_STATUS_CALLBACKS), {extra: {errorType: ErrorType.ERROR_EXECUTING_PLAYER_STATUS_CALLBACKS}});
      }
    });
  }

  /**
   *   alias with better name for {@link playerStatus} method
   */

  notifyJourneyTrigger(event) {
    this.playerStatus(event);
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new MessageHelper(singletonEnforcer);
    }
    return this[singleton];
  }

  static destroyInstance() {
    delete this[singleton];
  }

  get push() {
    return {
      playerStatus: this.playerStatus.bind(this)
    };
  }

  removeListener(id) {
    this.listeners = this.listeners.filter(it => it.id !== id);
  }

  addListener(fn) {
    const id = uuidv4();
    this.listeners.push({listener:fn, id});
    return id;
  }

  sendToAll(data) {
    window.parent.postMessage(JSON.stringify(data), '*');

    this.listeners.forEach(({listener}) => {
      try {
        listener.call(this, data);
      } catch (e) {
        console.error(`Exception during message delivery:${e}`);
      }
    });
  }

  sendToVg(data) {
    try {
      window.parent.postMessage(JSON.stringify(data), '*');
    } catch (ex) {
      console.error(`Failed to send message to VG. message:${data} error:${ex}`, ex);
    }
  }
}

export default MessageHelper;
