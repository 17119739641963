import React from 'react';
import {CheckmarkSVG} from "./checkmarkSVG";
import style from './style.module.scss';

export function SubmitCheckmark(props) {
  const {
    checkmarkColor,
    checkmarkBackgroundColor
  } = props;

  return <div className={style.checkmarkContainer}>
    <div className={style.checkmarkBackground}>
      <div className={style.checkmarkColor} style={{background: checkmarkColor}}/>
    </div>
    <CheckmarkSVG
      fill={checkmarkBackgroundColor}
    />
  </div>
}
