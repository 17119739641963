import {useEffect} from "react";
import {constants, environmentHelper} from "@phoenix/core";
import EventHelper from 'utils/eventHelper';
import config from "../../local-config";
import Environment from 'utils/environment';

const {ErrorType} = constants;

export default function useHeavyAdReporting() {

  useEffect(() => {
    let observer = null
    let heavyAdInterventionErrorSent = false
    /**
     * Whenever our ad is detached due to HeavyAdIntervention in chrome,
     * this piece of code captures that event so that we could know how many users got impacted.
     * See: https://developers.google.com/web/updates/2020/05/heavy-ad-interventions
     */
    const registerHeavyAdInterventionObserver = () => {
      try {
        observer = new window.ReportingObserver(
          (reports) => {
            sendReports(reports);
          },
          {buffered: true}
        );

        observer.observe();
        window.addEventListener('pagehide', unloadCallback);
      } catch (er) {
        console.error('Error in registerHeavyAdInterventionObserver', er);
      }

    }

    const unloadCallback = () => {
      sendReports(observer.takeRecords())
    }

    const sendReports = (reports) => {
      for (const report of reports) {
        if (report && report.type === 'intervention' && !heavyAdInterventionErrorSent) {
          heavyAdInterventionErrorSent = true
          console.error('Chrome removed ad due to HeavyAdIntervention', report);
          const ed = Environment.getEd();
          const payload = {
            type: 'error',
            errorType: ErrorType.CHROME_HEAVY_AD_INTERVENTION,
            creativeId: Environment.getAdUnitId(),
            ...ed && {ed}
          };
          const url = EventHelper.instance.genHeavyAdInteractionCaptureURL(payload, `${config.vgpServerUrl}/player/captureHeavyAdInteraction`);
          navigator.sendBeacon(url, null);
        }
      }
    }
    if (environmentHelper.isChrome()) {
      registerHeavyAdInterventionObserver();
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
      window.removeEventListener('pagehide', unloadCallback);
    }
  }, []);

}
