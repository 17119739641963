// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes pulse--22F9V{from{transform:scale3d(1, 1, 1)}50%{transform:scale3d(1.05, 1.05, 1.05)}to{transform:scale3d(1, 1, 1)}}.pulse--22F9V:hover{animation-name:pulse--22F9V;animation-duration:1s;animation-fill-mode:both}", ""]);
// Exports
exports.locals = {
	"pulse": "pulse--22F9V"
};
module.exports = exports;
