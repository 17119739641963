import {useEffect, useRef} from 'react';
import {captureException} from '@sentry/browser';

import messageHelper from 'utils/messageHelper';

import {
  AUTOPLAY_MODE,
  PLAYER_STATE,
  USER_INTERACTION_ON_SPLASH_SCREEN_STOP, VAST_EVENTS,
  VPAID_TO_VAST_EVENTS,
  EventType,
  JourneyTriggerInteractiveEvent, ErrorType
} from 'utils/constants';
import CustomError from 'utils/customError';
import {useSpecialSettings} from "hooks/useSpecialSettings";
import {isNumber, isString} from "../utils/helpers";
const message = messageHelper.instance;
/* Events
 AdLoaded
 AdStarted
 AdStopped
 AdSkipped
 AdSkippableStateChange
 AdLinearChange
 AdDurationChange
 AdExpandedChange
 AdRemainingTimeChange (Deprecated in 2.0)
 AdVolumeChange
 AdImpression
 AdVideoStart
 AdVideoFirstQuartile
 AdVideoMidpoint
 AdVideoThirdQuartile,
 AdVideoComplete
 AdClickThru
 AdInteraction
 AdUserAcceptInvitation
 AdUserMinimize
 AdUserClose
 AdPaused
 AdPlaying
 AdLog
 AdError
 */

/**
 * This will act as an interface class on top of video views beneath it
 * Will be responsible for sending events to VPAID Ad object that partner will
 * load while doing VPAID like AdLoaded, AdStarted, AdImpression, AdClickThru etc.
 * and also to take VPAID method calls from it like startAd, pauseAd, resumeAd, resizeAd etc
 */
export class VPAIDIfc  {

  _init(settings, getSpecialSettingPlatformValue, shouldPhoenixSkipFiringStandardVastPixel, isInAdminTool, isVPAIDEnabled, playerWidth, playerHeight, isDebug) {
    this.destroy();
    this.shouldPhoenixSkipFiringStandardVastPixel = shouldPhoenixSkipFiringStandardVastPixel;
    this.isInAdminTool = isInAdminTool;
    this.isVPAIDEnabled = isVPAIDEnabled;
    this.playerWidth = playerWidth;
    this.playerHeight = playerHeight;
    this.isDebug = isDebug;

    this.allCompleteEventsSend = false;
    this.getSpecialSettingPlatformValue = getSpecialSettingPlatformValue;
    this._enabled = false;
    this._adVolume = null;
    this._vpsMode = false;
    this._sentAdLoaded = false;
    this._sentAdInteractionForStart = false;
    this._stopped = false;
    this._adVideoComplete = false;
    this._clientWindow = null;
    this._adPaused = false;
    this.playing = false;
    this.listenerIdx = -1;
    this.queuedMessages = [];
    this.init(settings);
    const noop = () => {}
    this.video =  {tracking : {started : false}}
    this.video.play = noop;
    this.video.pause = noop;
    this.video.setVolume = noop
    this.video.getDuration = noop
    this.video.getCurrentTime = () => 0
    this.video.getVolume = noop;
    this._vastTrackingPixels = null;
  }

  _sendAdInteractionForStart() {
    if (!this._sentAdInteractionForStart) {
      this._fireEvent(EventType.AdInteraction);
      this._sentAdInteractionForStart = true;
    }
  }

  init(settings) {
    this.settings = settings;
    this.playing = false;
    this.started = false;
    this.quartile = 0;

    this.checkAdType();
    if(this._enabled) {
      this.addPlayerEventListener();
    }
  }

  log(...args) {
    if(this.isDebug) {
      console.log(...args);
    }
  }

  /**
   * @name addPlayerEventListener
   * @description This method listens for messages sent from a player (such as video-view.js) for events like adloaded,
   * started, completed, paused etc to send them to vpaid player on top of this
   * @returns {void}
   */
  addPlayerEventListener() {
    this.listenerIdx = message.addListener((ev) => {
      const playerStatus = ev.vgPlayerStatus;
      if (playerStatus) {
        this.fireVPAIDEvents(playerStatus);
      }

      this._updateProps();
      if (ev.action === USER_INTERACTION_ON_SPLASH_SCREEN_STOP) {
        this.removeAd();
      }
    });
  }

  removePlayerEventListener() {
    if (this.listenerIdx === -1) {
      return;
    }

    message.removeListener(this.listenerIdx);
  }

  shouldVideoAutoPlay() {
    const {autoPlay} = this.settings;
    return !this.isInAdminTool && autoPlay;
  }

  /**
   * @description This method takes the player status as in adStarted, paused, completed and then converts into VPAID event type to send across the  iframe where VPAID Ad is loaded likely vpaid.js in vgp
   * @param {String} playerStatus enumerated status value. Examples: "playing", "adInteractionForStart", "paused"
   * @returns {void}
   */
  fireVPAIDEvents(playerStatus) {
    switch (playerStatus) {
      case 'playing':
        if (!this.started) {
          this.started = true;
          this._fireEvent(EventType.AdVideoStart);
          if (!this.shouldVideoAutoPlay()) {
            this._sendAdInteractionForStart();
          }
          this._started = true;
        }
        if (!this.playing) {
          this.playing = true;
          this._fireEvent(EventType.AdPlaying);
          this._adPaused = false;
          this._adAutoPaused = false;
        }
        break;
      case 'paused':
        if (this.playing) {
          this.playing = false;
          this._fireEvent(EventType.AdPaused);
        }
        this._adPaused = true;
        break;
      case 'completed':
        this.playing = false;
        if(!this._adVideoComplete && this.allCompleteEventsSend) {
          this._fireEvent(EventType.AdVideoComplete);
          this._adVideoComplete = true;
        }
        if(!this.finaleEnabled && this.allCompleteEventsSend && this._adVideoComplete && !this.isPixelFiringInProgress) {
            this.removeAd();
        }

        break;
      case 'adStopped':
        this.video.pause();
        this.removeAd();
        break;
      case 'adSkipped':
        this._fireEvent(EventType.AdSkipped);
        break;
      case 'adSkippableStateChange':
        this._fireEvent(EventType.AdSkippableStateChange);
        break;
      case EventType.AdClickThru:
        this._fireEvent(playerStatus);
        break;
      default: {
        const isJourneyTriggerEvent = !!JourneyTriggerInteractiveEvent[playerStatus];
        if (isJourneyTriggerEvent) {
          this._fireEvent(playerStatus, true);
        }
        break;
      }
    }
  }

  /**
   * @description This will listen for message from vpaid.js file in vgp which act as a VPAIDAd individually if HTML or bundled in legacy js for Flash
   * @returns {void}
   */
  addMessageListener() {
    window.addEventListener('message', (ev) => {
      const jsonStr = ev.data;

      if (isString(jsonStr) && jsonStr.indexOf('_vgvp_') > -1) {
        if (this._clientWindow == null) {
          this._clientWindow = ev.source;
          if (this._clientWindow == null) {
            captureException(new CustomError(ErrorType.CLIENT_WINDOW_NOT_EXIST), {extra: {errorType: ErrorType.CLIENT_WINDOW_NOT_EXIST}});
          }
          // send a nice to meet you message.
          this._sendMessage({command: 'hi'});
        }

        const obj = JSON.parse(jsonStr);
        const method = obj.method;
        if (method && method.indexOf('_') !== 0) {
          this.log('Getting request from vpaid.js for ', method, obj);
          this[method](obj);
        } else {
          this.log('Invalid method requested:', method);
        }
      }
    });
  }

  checkAdType() {
    // check for vps enabled on url.
    this._vpsMode = this.isVPAIDEnabled;

    this.log('Init. vpsMode:', this._vpsMode);
    if (this._vpsMode) {
      this._enabled = true;
      this.addMessageListener();
    }
  }

  removeAd() {
    if(this._stopped) {
      return;
    }
    this._stopped = true;
    this._fireEvent(EventType.AdStopped);
  }

  _sendMessage(ev, force) {
    if (!this._enabled && !force) {
      return;
    }

    this.queuedMessages.push(ev);
    const targetWindow = this._clientWindow || window.parent;

    if (targetWindow == null) {
      if (this.queuedMessages.length > 1000) {
        this._enabled = false;
        this.log('can not find window:', targetWindow, 'after', this.queuedMessages.length, 'events');
        throw Error('too many queued messages without finding the client window');
      }
      if (!this.handshakingExceptionCaptured) {
        captureException(new CustomError(ErrorType.ERROR_IN_VPAID_HANDSHAKING), {extra: {errorType: ErrorType.ERROR_IN_VPAID_HANDSHAKING}});
        this.handshakingExceptionCaptured = true;
      }

      this.log('VPS:Delaying send message since no target window. message:', ev);
      return;
    }

    if (ev && ev.type === EventType.AdImpression) {
      this.notifiedAdImpression = true;
    }
    this._sendAllQueuedMessages();
  }

  _sendAllQueuedMessages() {
    const targetWindow = this._clientWindow || window.parent;
    if (!targetWindow) {
      return;
    }
    while (this.queuedMessages.length > 0) {
      const queuedMessage = this.queuedMessages.shift();
      queuedMessage._vgvp_ = 'true';// mark it as vgvp event.
      const jsonStr = JSON.stringify(queuedMessage);
      targetWindow.postMessage(jsonStr, '*');
    }
  }

  // NOTE: for unit testing purposes
  _fireEventInner(evType, interactiveJourney, isVPAIDEnabled, shouldPhoenixSkipFiringStandardVastPixel) {
    this.log('ev:', evType);
    const objToSend = {command: 'event', type: evType, interactiveJourney};
    const thirdPartyPixel = this._getVastEventPixelsFromVPAID(evType, isVPAIDEnabled, shouldPhoenixSkipFiringStandardVastPixel);
    if (thirdPartyPixel) {
      objToSend['thirdPartyPixel'] = thirdPartyPixel;
    }
    this._sendMessage(objToSend);
  }

  _fireEvent(evType, interactiveJourney) {
    this._fireEventInner(evType, interactiveJourney, this.isVPAIDEnabled, this.shouldPhoenixSkipFiringStandardVastPixel);
  }

  // NOTE: isVPAIDEnabled and shouldPhoenixSkipFiringStandardVastPixel added as args for unit testing purposes
  _getVastEventPixelsFromVPAID(vpaidEventName, isVPAIDEnabled, shouldPhoenixSkipFiringStandardVastPixel) {
    let vastEventName = VPAID_TO_VAST_EVENTS[vpaidEventName]
    if (!isVPAIDEnabled || !shouldPhoenixSkipFiringStandardVastPixel(vastEventName)) {
      return null;
    }
    if (vpaidEventName === EventType.AdVolumeChange) {
      vastEventName = this.video.getVolume() === 0 ? VAST_EVENTS.MUTE : VAST_EVENTS.UNMUTE;
    }
    if (!vastEventName) {
      return null;
    }
    return this._vastTrackingPixels && vastEventName ? this._vastTrackingPixels[vastEventName] : null;
  }

  updateVolume() {
    const orgVolume = this.video ? this.video.getVolume() : undefined;
    if (!isNumber(orgVolume) || orgVolume < 0) {
      return false;
    }

    // if orgVolume is between 0 and 1, leave the value alone.  If the volume is greater than 1, divide by 100.
    const vgVolume = (orgVolume >= 0 && orgVolume <= 1) ? orgVolume : orgVolume / 100;

    if (this._adVolume == null || this._adVolume !== vgVolume) {
      this.log('current _adVolume:', this._adVolume, 'read from vpaid player vgVolume:', vgVolume, 'rawVpaidVolume:', orgVolume);
      this._adVolume = vgVolume;
      return true;
    }

    return false;
  }

  _updateProps() {
    if (!this._sentAdLoaded || !this.video) {
      return;
    }

    // let skippable = false;
    // if (this.video && this.video.views && this.video.views.skipButton) {
    //   skippable = this.video.views.skipButton.rendered;
    // }

    const previousVolume = this._adVolume;
    const volumeChanged = this.updateVolume();
    const adDuration = this.video.getDuration();
    const AdRemainingTime = this.video.getDuration() - this.video.getCurrentTime();

    this._sendMessage({
      command: 'setPropValues',
      AdLinear: true, // Pause anything in container while this runs.
      AdWidth: this.playerWidth,
      AdHeight: this.playerHeight,
      AdExpanded: true,
      AdSkippableState: false,
      AdRemainingTime,
      AdDuration: adDuration,
      AdVolume: this._adVolume,
      AdCompanions: null,
      AdIcons: null
    });
    // Initially volume is null. No need to set ad volume change when it is first set.
    if (previousVolume !== null && this.started && volumeChanged) { // Do not fire AdVolumeChange until video is started or, previous volume was null.
      this._fireEvent(EventType.AdVolumeChange);
    }
  }


  resizeAd(ev) {
    this.log('resizeAd');
    this._fireEvent(EventType.AdSizeChange);
  }

  // Noop
  startAd(ev) {
    if (this._stopped) {
      // if stopped, do not allow start to be called again
      return;
    }
    this.log('startAd');
    this._updateProps();
    this._fireEvent(EventType.AdStarted);
  }

  hello(ev) {

  }

  stopAd(ev) {
    if (this._stopped || !this.video) {
      // if stopped, do not allow stop to be called again
      return;
    }
    this._stopped = true;
    this.log('stopAd');
    this._fireEvent(EventType.AdStopped);
  }

  pauseAd() {
    if (this._stopped || !this.video) {
      // if stopped, do not allow pause
      return;
    }
    const started = this.video.tracking.started;
    if (started) {
      this._adPaused = true;
      this.video.pause();
      this._fireEvent(EventType.AdPaused);
    } else {
      this.log('Ad cannot be paused if user did not start it yet.');
    }
  }

  resumeAd() {
    if (this._stopped || !this.video) {
      // don't allow resume for ad stopped cases.  Pause is not the same as stop
      return;
    }

    this.log('resumeAd:');

    if (this._adPaused) {
      const started = this.video.tracking.started;
      if (started) {
        this._adPaused = false;
        this.video.play();
        this._fireEvent(EventType.AdPlaying);
      } else {
        this.log('Cant resume add if user did not start it.');
      }
    }
  }

  // NoOp
  expandAd() {
    this.log('expandAd');
  }

  // NoOp
  collapseAd() {
    this.log('collapseAd');
  }

  skipAd() {
    if (this._stopped || !this.video) {
      // don't allow skip for cases when video is stopped
      return;
    }

    this.log('skipAd');

    if (this.video.skipAd) {
      this.video.skipAd();
    }
  }

  disableAPISetVolume = () => {
    return this.getSpecialSettingPlatformValue('disableAPISetVolume');
  }

  setAdVolume(ev) {
    if (this._stopped || !this.video || !this._sentAdLoaded) {
      // don't set volume for cases when video is stopped or ad is not loaded
      return;
    }

    // check if this is disabled.
    if (this.disableAPISetVolume()) {
      this.log('setAdVolume API disabled by settings');
      return;
    }

    // parse both possibilities, ev.volume and ev.target.volume
    // https://stackoverflow.com/questions/42396737/html5-audio-player-error-the-provided-double-value-is-non-finite
    const evVolume = parseFloat(ev.volume);
    const evTargetVolume = parseFloat(ev.target ? ev.target.volume : undefined);

    if (isNaN(evVolume) && isNaN(evTargetVolume)) {
      this.log('setAdVolume not called. Ad volume is not a number.');
      return;
    }

    const volume = isNaN(evVolume) ? evTargetVolume : evVolume;

    if (this._adVolume === volume) {
      this.log('setAdVolume not called. Ad volume is equal to old value.');
      return;
    }

    if (volume < 0) {
      this.log('setAdVolume not called. Volume parameter is less than 0.');
      return;
    }

    if (volume > 0) {
      this.ensureUnmutingAllowed(volume);
    } else {
      this.changeVolume(volume);
    }
  }

  /**
   In case we get a request to unmute from publisher player, then below logic would be applied:
   Case 1. If Canary ran at video view in the beginning says the video cannot be played with sound (this.video.muteAdOnStart would be true in that case),
   also user did not unmute by clicking wasabi 's mute/unmute button (this.video.unmutedOnce would be false).
   In that case we will try 1 more canary test and see if muted is allowed as we got user interaction from publisher player.
   We will reject request if unmuting is not allowed as per canary. For any subsequent unmuting request, we would use the previous canary promise result. We won;t be trying canary every for every unmuting request.
   Case 2. If User has already unmuted video by clicking our mute/unmute button. (this.video.unmutedOnce would be true).
   Here we won't run any canary again and will send unmute request to our player.
   Case 3. If initial canary test that ran before video load, says video can be unmuted (this.video.muteAdOnStart would be false).
   Here we won't run any canary again and will send unmute request to our player.
   **/

  ensureUnmutingAllowed(volume) {
    if (this.video.canUnmute) {
      this.changeVolume(volume);
    }
  }




  changeVolume(volume) {
    this.log('setAdVolume volume:', volume);
    this.video.setVolume(volume);
    this._adVolume = volume;
    this._updateProps();
    this._fireEvent(EventType.AdVolumeChange);
  }

  update({play, pause, toggleMuteUnmute, playerDuration, currentTime, autoPlayMode, canUnmute, playerState, isMute, allCompleteEventsSend, finaleEnabled, isPixelFiringInProgress}) {
    this.video = {tracking : {started : Boolean(playerState) && playerState !== PLAYER_STATE.READY} }
    this.video.play = play;
    this.video.pause = pause;
    this.video.setVolume = (v) => toggleMuteUnmute(v > 0 ? false : true);
    this.video.getDuration = () => playerDuration;
    this.video.getCurrentTime = () => currentTime;
    this.video.muteAdOnStart = autoPlayMode === AUTOPLAY_MODE.AUTOPLAY_MUTED;
    this.video.canUnmute = canUnmute;
    this.video.getVolume = () => isMute ? 0 : 1;
    this.allCompleteEventsSend = allCompleteEventsSend;
    this.finaleEnabled = finaleEnabled;
    this.isPixelFiringInProgress = isPixelFiringInProgress;
  }

  destroy() {
    messageHelper.instance.removeListener(this.listenerIdx);
  }

}

const vpaid = new VPAIDIfc();

function useVPAIDInterface({play, pause, toggleMuteUnmute, playerDuration, currentTime, canUnmute, playerState, isMute, settings, 
  allCompleteEventsSend, finaleEnabled, vastTrackingPixels, impressionEvent, q1, q2, q3, isPixelFiringInProgress, 
  shouldPhoenixSkipFiringStandardVastPixel, platformType, isInAdminTool, isVPAIDEnabled, playerWidth, playerHeight, isDebug}) {

  const {getSpecialSettingPlatformValue} = useSpecialSettings(settings, platformType);
  const firstQEventDone = useRef(false);
  const midEventDone = useRef(false);
  const thirdQEventDone = useRef(false);
  const loadEventDone = useRef(false);
  const impressionEventDone = useRef(false);
  const vastTrackingPixelsSet = useRef(null);
  useEffect(() => {
    vpaid._init(settings, getSpecialSettingPlatformValue, shouldPhoenixSkipFiringStandardVastPixel, isInAdminTool, isVPAIDEnabled, playerWidth, playerHeight, isDebug)
  }, [settings, getSpecialSettingPlatformValue, shouldPhoenixSkipFiringStandardVastPixel, isInAdminTool, isVPAIDEnabled, playerWidth, playerHeight, isDebug]);

  useEffect(() => {
    const ready = playerDuration && playerState && vpaid;
    if(ready) {
      vpaid.update({play, pause, toggleMuteUnmute, playerDuration, currentTime, canUnmute, playerState, isMute, allCompleteEventsSend, finaleEnabled, isPixelFiringInProgress});
    }
  }, [play, pause, toggleMuteUnmute, playerDuration, currentTime, canUnmute, playerState, isMute, allCompleteEventsSend, finaleEnabled, isPixelFiringInProgress]);

  useEffect(() => {
    if (playerState && !loadEventDone.current) {
      loadEventDone.current = true;
      vpaid._fireEvent(EventType.AdLoaded);
      vpaid._sentAdLoaded = true;
    }

    if (impressionEvent && !impressionEventDone.current) {
      impressionEventDone.current = true;
      vpaid._fireEvent(EventType.AdImpression);
    }

    if (q1 && !firstQEventDone.current) {
      firstQEventDone.current = true;
      vpaid._fireEvent(EventType.AdVideoFirstQuartile);
    }
    if (q2 && !midEventDone.current) {
      midEventDone.current = true;
      vpaid._fireEvent(EventType.AdVideoMidpoint);
    }
    if (q3 && !thirdQEventDone.current) {
      thirdQEventDone.current = true;
      vpaid._fireEvent(EventType.AdVideoThirdQuartile);
    }

    if (!vastTrackingPixelsSet.current && vastTrackingPixels) {
      vastTrackingPixelsSet.current = true;
      vpaid._vastTrackingPixels = vastTrackingPixels;
    }

    message.playerStatus(playerState?.toLowerCase(),
      currentTime,
      playerDuration,
      isMute ? 0 : 1,
    );
  })

}

export default useVPAIDInterface;
