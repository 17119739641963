import {useCallback} from 'react';
import MessageHelper from 'utils/messageHelper';
import {USER_INTERACTION_ON_SPLASH_SCREEN_STOP} from 'utils/constants';

// Notifies vpaid interface that ad actually stopped
export function useAdStoppedEvent() {
  const fireAdStoppedEvent = useCallback(() => {
    MessageHelper.instance.sendToAll({
      action: USER_INTERACTION_ON_SPLASH_SCREEN_STOP
    });
  }, []);

  return {
    fireAdStoppedEvent
  };
}
