import config from "../../local-config";
import EventHelper from "utils/eventHelper";
import {configureScope, init} from "@sentry/browser";
import LoggerHelper from '../utils/loggerHelper';

const logger = LoggerHelper.instance
const sentErrorTypes = new Set();
// ignoreErrorsInSentry will skip the error in sentry but events will still go in druid.
const ignoredErrorsInSentry = [
        "ResizeObserver loop limit exceeded",
        "ResizeObserver loop completed with undelivered notifications",
        "vpaid ad error:: AD_ERROR",
        "MEDIA_FILE_NOT_SUPPORTED",
        "Player took more time to load",
        "Can't execute code from a freed script",
        "FETCH_LIVERAMP_TEXT_TO_JSON_PARSING_ERROR",
        "FETCH_LIVERAMP_RESPONSE_PARSING_ERROR",
        "Quartile fired without currentTime",
        "Ad stop event fired before ad complete",
        "Calling onAdVideoComplete too early",
        "CLIENT_WINDOW_NOT_EXIST",
        "ERROR_IN_VPAID_HANDSHAKING",
        "Missed Quartile Events",
        "ERROR_EXECUTING_PLAYER_STATUS_CALLBACKS"
    ]
export default function initSentry(buildVersion, creativeId) {
    if (window.location.href.indexOf('localhost') !== -1) {
        console.log('skipping sentry config setup due to localhost');
        return;
    }

    if (config.sentryUrl) {
        logger.log('Installing sentry config');
        init({
            dsn: config.sentryUrl,
            release: `${buildVersion}-phoenix`, // need to add -phoenix suffix so that a version like M100 doesn't conflict with M100 in another repo
            beforeSend: (ev) => {
                EventHelper.instance.captureSentryError(ev);
                if (EventHelper.instance.shouldSkipEventToSentry(ev, ignoredErrorsInSentry)) {
                    return null; // skip error in sentry
                }

                // Only allow one event per error type to be sent to sentry
                let returnEv = ev;
                if (ev && ev.exception && ev.exception.values && ev.exception.values[0] && ev.exception.values[0].type) {
                    if (sentErrorTypes.has(ev.exception.values[0].type)) {
                        returnEv = null;
                    } else {
                        sentErrorTypes.add(ev.exception.values[0].type);
                    }
                }

                return returnEv;
            }
        });

        configureScope((scope) => {
            scope.setExtras({
                customReferrer: document.referrer,
                customLocationHref: document.location.href,
                creativeId
            });
            scope.setUser({id: creativeId});
        });
    } else {
        console.log('skipping sentry config setup due to missing config for sentryUrl');
    }
}
