export const AdUnitEvent = {
  ClickEvent: 'ClickEvent',
  ShareEvent: 'ShareEvent',
  SurveyEvent: 'SurveyEvent',
  SurveyAttemptEvent: 'SurveyAttemptEvent',
  CardExposure: 'CardExposure',
  TotalCardExposures: 'TotalCardExposures',
  SlideExposures: 'SlideExposure',
  TotalSlideExposures: 'TotalSlideExposures',
  SurveyExposure5s: 'SurveyExposure5s',
  SurveyExposure10s: 'SurveyExposure10s',
  SurveyResponse: 'SurveyResponse',
  Call: 'Call',
  Directions: 'Directions',
  StoreInfo: 'StoreInfo',
  HotSpotInteraction: 'HotSpotInteraction',
  VPaidAdInteraction: 'VPaidAdInteraction',
  HotSpotDriveToSiteClick: 'HotSpotDriveToSiteClick'
}

export const MediaSubType = {
  JS_TAG: 'JS_TAG',
  JS_URL: 'JS_URL',
  HTML_URL: 'HTML_URL',
  HTML_TAG: 'HTML_TAG'
}

export const DisplayMediaType = {
  TAG_URL: 'TAG_URL',
  RAW_IMAGE: 'RAW_IMAGE',
  LIKERT_SURVEY: 'LIKERT_SURVEY',
  IMAGE_SURVEY: 'IMAGE_SURVEY',
  MULTIPLE_CHOICE_SURVEY: 'MULTIPLE_CHOICE_SURVEY',
  STORE_LOCATOR: 'STORE_LOCATOR',
  CUSTOM_SURVEY: 'CUSTOM_SURVEY',
  PRODUCT_EXPLORER: 'PRODUCT_EXPLORER'
}

export const ErrorType = {
  MEDIA_FILE_NOT_SUPPORTED: 'MEDIA_FILE_NOT_SUPPORTED',
  ERROR_EXECUTING_PLAYER_STATUS_CALLBACKS: 'ERROR_EXECUTING_PLAYER_STATUS_CALLBACKS',
  AUTOPLAY_START_FAIL : 'AUTOPLAY_START_FAIL',
  ERROR_IN_VPAID_HANDSHAKING: 'ERROR_IN_VPAID_HANDSHAKING',
  CLIENT_WINDOW_NOT_EXIST: 'CLIENT_WINDOW_NOT_EXIST',
  AD_ERROR: 'AD_ERROR',
  GENERIC_ERROR: 'GENERIC_ERROR',
  NO_SUPPORTED_SOURCE_ERROR: 'NO_SUPPORTED_SOURCE_ERROR',
  FETCH_SETTINGS_ERROR: 'FETCH_SETTINGS_ERROR',
  CHROME_HEAVY_AD_INTERVENTION: 'CHROME_HEAVY_AD_INTERVENTION',
  MISSED_QUARTILE: 'MISSED_QUARTILE',
  INVALID_DURATION: 'INVALID_DURATION',
  QUARTILE_WITHOUT_CURRENT_TIME: 'QUARTILE_WITHOUT_CURRENT_TIME',
  AD_STOPPED_BEFORE_COMPLETE: 'AD_STOPPED_BEFORE_COMPLETE',
  PLAYER_TOOK_MORE_TIME_TO_LOAD: 'PLAYER_TOOK_MORE_TIME_TO_LOAD',
  FETCH_SETTINGS_RETRY_ERROR: 'FETCH_SETTINGS_RETRY_ERROR',
  FETCH_SETTINGS_RETRY_SUCCESSFUL: 'FETCH_SETTINGS_RETRY_SUCCESSFUL',
  FETCH_LIVERAMP_RESPONSE_PARSING_ERROR: 'FETCH_LIVERAMP_RESPONSE_PARSING_ERROR',
  FETCH_LIVERAMP_TEXT_TO_JSON_PARSING_ERROR: 'FETCH_LIVERAMP_TEXT_TO_JSON_PARSING_ERROR',
  REPLACE_ALL_NOT_DEFINED:'REPLACE_ALL_NOT_DEFINED'
}

export const HotSpotType = {
  ENGAGEMENT_EXPERIENCE: 'Card',
  DRIVE_TO_SITE: 'Drive to Site'
}

export const JourneyTriggerInteractiveEvent = {
  interactiveSocialConnectorClickThrough: 'interactiveSocialConnectorClickThrough',
  interactiveSocialConnectorExposure:'interactiveSocialConnectorExposure',
  interactiveTopLogoClickThrough: 'interactiveTopLogoClickThrough',
  interactiveLikertClickThrough: 'interactiveLikertClickThrough',
  interactiveBottomLogoClickThrough: 'interactiveBottomLogoClickThrough',
  interactiveExplorerClickThrough: 'interactiveExplorerClickThrough',
  interactiveExplorerExposure: 'interactiveExplorerExposure',
  interactive1StarResponse: 'interactive1StarResponse',
  interactive2StarResponse: 'interactive2StarResponse',
  interactive3StarResponse: 'interactive3StarResponse',
  interactive4StarResponse: 'interactive4StarResponse',
  interactive5StarResponse: 'interactive5StarResponse',
  interactiveImageSurveyClickThrough: 'interactiveImageSurveyClickThrough',
  interactiveCustomView: 'interactiveCustomView',
  interactiveImageSurveySubmission: 'interactiveImageSurveySubmission',
  interactiveImageSurveyExposure: 'interactiveImageSurveyExposure',
  interactiveImageSurveyOption1Response: 'interactiveImageSurveyOption1Response',
  interactiveImageSurveyOption2Response: 'interactiveImageSurveyOption2Response',
  interactiveImageSurveyOption3Response: 'interactiveImageSurveyOption3Response',
  interactiveExplorerEarnedView: 'interactiveExplorerEarnedView',
  interactiveLikertExposure: 'interactiveLikertExposure',
  interactiveShare: 'interactiveShare',
  interactiveEngagementExperienceClickThrough: 'interactiveEngagementExperienceClickThrough',
  interactiveCtaCopyClickThrough: 'interactiveCtaCopyClickThrough',
  interactiveCustomImageExposure: 'interactiveCustomImageExposure',
  interactiveCustomImageClickThrough: 'interactiveCustomImageClickThrough',
  interactiveRawImageExposure:'interactiveRawImageExposure',
  interactiveLikertSubmission: 'interactiveLikertSubmission',
  clickThrough: 'clickThrough',
  impression: 'impression',
  start: 'start',
  firstQuartile: 'firstQuartile',
  midpoint: 'midpoint',
  thirdQuartile: 'thirdQuartile',
  complete: 'complete',
  interactiveMapStoreDirections: 'interactiveMapStoreDirections',
  interactiveMapStoreCall: 'interactiveMapStoreCall',
  interactiveMapStoreInfo: 'interactiveMapStoreInfo',
  interactiveMapExposure:'interactiveMapExposure',
  interactiveHotspotInteraction: 'interactiveHotspotInteraction',
  interactiveHotspotClickThrough: 'interactiveHotspotClickThrough',
  interactiveInVideoClickThrough: 'interactiveInVideoClickThrough',
  interactiveMultipleChoiceSurveyOption1Response: 'interactiveMultipleChoiceSurveyOption1Response',
  interactiveMultipleChoiceSurveyOption2Response: 'interactiveMultipleChoiceSurveyOption2Response',
  interactiveMultipleChoiceSurveyOption3Response: 'interactiveMultipleChoiceSurveyOption3Response',
  interactiveMultipleChoiceSurveyOption4Response: 'interactiveMultipleChoiceSurveyOption4Response',
  interactiveMultipleChoiceSurveyOption5Response: 'interactiveMultipleChoiceSurveyOption5Response',
  interactiveMultipleChoiceSurveyOption6Response: 'interactiveMultipleChoiceSurveyOption6Response',
  interactiveMultipleChoiceSurveySubmission: 'interactiveMultipleChoiceSurveySubmission',
  interactiveMultipleChoiceSurveyClickThrough: 'interactiveMultipleChoiceSurveyClickThrough',
  interactiveMultipleChoiceSurveyExposure: 'interactiveMultipleChoiceSurveyExposure',
  // Custom Survey Response
  interactiveCustomSurveyOption1Response: 'interactiveCustomSurveyOption1Response',
  interactiveCustomSurveyOption2Response: 'interactiveCustomSurveyOption2Response',
  interactiveCustomSurveyOption3Response: 'interactiveCustomSurveyOption3Response',
  interactiveCustomSurveyOption4Response: 'interactiveCustomSurveyOption4Response',
  interactiveCustomSurveyOption5Response: 'interactiveCustomSurveyOption5Response',
  interactiveCustomSurveyOption6Response: 'interactiveCustomSurveyOption6Response',
  interactiveCustomSurveyOption7Response: 'interactiveCustomSurveyOption7Response',
  interactiveCustomSurveyOption8Response: 'interactiveCustomSurveyOption8Response',
  interactiveCustomSurveyOption9Response: 'interactiveCustomSurveyOption9Response',
  interactiveCustomSurveyOption10Response: 'interactiveCustomSurveyOption10Response',
  interactiveCustomSurveyOption11Response: 'interactiveCustomSurveyOption11Response',
  interactiveCustomSurveyOption12Response: 'interactiveCustomSurveyOption12Response',
  interactiveCustomSurveyOption13Response: 'interactiveCustomSurveyOption13Response',
  interactiveCustomSurveyOption14Response: 'interactiveCustomSurveyOption14Response',
  interactiveCustomSurveyOption15Response: 'interactiveCustomSurveyOption15Response',
  interactiveCustomSurveySubmission: 'interactiveCustomSurveySubmission',
  interactiveCustomSurveyClickThrough: 'interactiveCustomSurveyClickThrough',
  interactiveCustomSurveyExposure: 'interactiveCustomSurveyExposure',

  // Product Explorer JT events
  interactiveProductExplorerClickThrough: 'interactiveProductExplorerClickThrough',
  interactiveProductExplorerExposure: 'interactiveProductExplorerExposure',

  /*
    NOTE: these are "helper" events for in-session-journeys and are mapped to
    interactiveLikertSubmission, interactiveImageSurveySubmission, and interactiveMultipleChoiceSurveySubmission before being sent to event ingestion/druid
  */
  // TODO: additional mapping for product element clicks for explorer?  map to interactiveExplorerClickThrough
  interactive1StarSubmission: 'interactive1StarSubmission',
  interactive2StarSubmission: 'interactive2StarSubmission',
  interactive3StarSubmission: 'interactive3StarSubmission',
  interactive4StarSubmission: 'interactive4StarSubmission',
  interactive5StarSubmission: 'interactive5StarSubmission',
  interactiveMultipleChoiceSurveyOption1Submission: 'interactiveMultipleChoiceSurveyOption1Submission',
  interactiveMultipleChoiceSurveyOption2Submission: 'interactiveMultipleChoiceSurveyOption2Submission',
  interactiveMultipleChoiceSurveyOption3Submission: 'interactiveMultipleChoiceSurveyOption3Submission',
  interactiveMultipleChoiceSurveyOption4Submission: 'interactiveMultipleChoiceSurveyOption4Submission',
  interactiveMultipleChoiceSurveyOption5Submission: 'interactiveMultipleChoiceSurveyOption5Submission',
  interactiveMultipleChoiceSurveyOption6Submission: 'interactiveMultipleChoiceSurveyOption6Submission',
  interactiveImageSurveyOption1Submission: 'interactiveImageSurveyOption1Submission',
  interactiveImageSurveyOption2Submission: 'interactiveImageSurveyOption2Submission',
  interactiveImageSurveyOption3Submission: 'interactiveImageSurveyOption3Submission',
  interactiveExplorerOption1ClickThrough: 'interactiveExplorerOption1ClickThrough',
  interactiveExplorerOption2ClickThrough: 'interactiveExplorerOption2ClickThrough',
  interactiveExplorerOption3ClickThrough: 'interactiveExplorerOption3ClickThrough',
  interactiveExplorerOption4ClickThrough: 'interactiveExplorerOption4ClickThrough',
  interactiveExplorerOption5ClickThrough: 'interactiveExplorerOption5ClickThrough',
  interactiveExplorerOption6ClickThrough: 'interactiveExplorerOption6ClickThrough',
  // Custom Survey Submission
  interactiveCustomSurveyOption1Submission: 'interactiveCustomSurveyOption1Submission',
  interactiveCustomSurveyOption2Submission: 'interactiveCustomSurveyOption2Submission',
  interactiveCustomSurveyOption3Submission: 'interactiveCustomSurveyOption3Submission',
  interactiveCustomSurveyOption4Submission: 'interactiveCustomSurveyOption4Submission',
  interactiveCustomSurveyOption5Submission: 'interactiveCustomSurveyOption5Submission',
  interactiveCustomSurveyOption6Submission: 'interactiveCustomSurveyOption6Submission',
  interactiveCustomSurveyOption7Submission: 'interactiveCustomSurveyOption7Submission',
  interactiveCustomSurveyOption8Submission: 'interactiveCustomSurveyOption8Submission',
  interactiveCustomSurveyOption9Submission: 'interactiveCustomSurveyOption9Submission',
  interactiveCustomSurveyOption10Submission: 'interactiveCustomSurveyOption10Submission',
  interactiveCustomSurveyOption11Submission: 'interactiveCustomSurveyOption11Submission',
  interactiveCustomSurveyOption12Submission: 'interactiveCustomSurveyOption12Submission',
  interactiveCustomSurveyOption13Submission: 'interactiveCustomSurveyOption13Submission',
  interactiveCustomSurveyOption14Submission: 'interactiveCustomSurveyOption14Submission',
  interactiveCustomSurveyOption15Submission: 'interactiveCustomSurveyOption15Submission',
}

export const EventType = {
  AdLoaded:'AdLoaded',
  AdSizeChange:'AdSizeChange',
  AdStarted: 'AdStarted',
  AdStopped:'AdStopped',
  AdSkipped:'AdSkipped',
  AdSkippableStateChange:'AdSkippableStateChange',
  AdLinearChange:'AdLinearChange',
  AdDurationChange:'AdDurationChange',
  AdExpandedChange:'AdExpandedChange',
  AdRemainingTimeChange:'AdRemainingTimeChange',
  AdVolumeChange:'AdVolumeChange',
  AdImpression:'AdImpression',
  AdVideoStart:'AdVideoStart',
  AdVideoFirstQuartile:'AdVideoFirstQuartile',
  AdVideoMidpoint:'AdVideoMidpoint',
  AdVideoThirdQuartile:'AdVideoThirdQuartile',
  AdVideoComplete:'AdVideoComplete',
  AdClickThru:'AdClickThru',
  AdInteraction:'AdInteraction',
  AdUserAcceptInvitation:'AdUserAcceptInvitation',
  AdUserMinimize:'AdUserMinimize',
  AdUserClose:'AdUserClose',
  AdPaused:'AdPaused',
  AdPlaying:'AdPlaying',
  AdLog:'AdLog',
  AdError:'AdError'
}
export const ENGAGEMENT_STATUS = {
  NOT_SHOWING: 'NOT_SHOWING',
  SHOWING_IN_VIDEO: 'SHOWING_IN_VIDEO',
  SHOWING_ON_PAUSE: 'SHOWING_ON_PAUSE',
  SHOWING_ON_COMPLETE: 'SHOWING_ON_COMPLETE',
  SHOWING_ON_HOTSPOT: 'SHOWING_ON_HOTSPOT'
}

export const CREATIVE_MODE = {
  VIDEO: 'VIDEO',
  DISPLAY: 'DISPLAY'
}


export const USER_INTERACTION_ON_SPLASH_SCREEN_STOP = 'USER_INTERACTION_ON_SPLASH_SCREEN_STOP'
export const POSITION_OPTIONS = {
  TOP: 'Top',
  BOTTOM: 'Bottom',
  LEFT: 'Left',
  RIGHT: 'Right'
}

export const CTA_POSITIONS = {
  'TOP': 'TOP',
  'BOTTOM': 'BOTTOM'
}

export const SHOW_CONDITION ={
  ON_HOVER: 'ON_HOVER', 
  ALWAYS: 'ALWAYS'
}

export const PLAYER_STATE = {
  READY: 'READY',
  PLAYING: 'PLAYING',
  PAUSED: 'PAUSED',
  COMPLETED: 'COMPLETED',
  CLOSE: 'CLOSE',
  STOPPED: 'STOPPED',
}

export const ENGAGEMENT_DISPLAY_MODE = {
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL',
  FULL_MODE: 'FULL_MODE'
}

export const SCRUB_ICONS = {
  PLAY: 'play',
  PAUSE: 'pause',
  EXPAND: 'expand',
  COMPRESS: 'compress',
  MUTE: 'mute',
  UNMUTE: 'unmute',
  REPLAY: 'replay'
}

export const PLAYER_EVENT = {
  LOAD: "LOAD",
  IMPRESSION: "IMPRESSION",
  START: "START",
  FIRST_QUARTILE: "Q1",
  MIDPOINT: "Q2",
  THIRD_QUARTILE: "Q3",
  COMPLETE: "DONE",
  MUTE: 'MUTE',
  UNMUTE: "UNMUTE",
  CLOSE: "CLOSE",
  PAUSE: "PAUSE",
  DELIVERED_VIEW: "COUNTSASVIEW",
  CUSTOM_ONE: "CUSTOM1",
  VIDEO_RETENTION: "VIDEORETENTION",
}

export const THIRD_PARTY_EVENTS = {
  IMPRESSION: "Impression",
  FIRST_QUARTILE: "Q1",
  MIDPOINT: "Q2",
  THIRD_QUARTILE: "Q3",
  COMPLETE: "Finish",
  VG_VIEW: "VGView",
  STORE_INFO: "StoreInfo",
  CLICK_THROUGH: "ClickThrough",
  CALL: "Call",
  DIRECTIONS: "Directions"
}

export const CLICK_EVENT = 'ClickEvent'

export const LIVE_RAMP_EVENTS = {
  RAMP_ID_FETCH_ATTEMPT: "RampIdAttempt",
  RAMP_ID_FETCHED: "RampIdReturned"
}

export const CLICK_SRC_WIDGET = {
  "CUSTOM_IMAGE_SPLASH_SCREEN_CLICK_FOR_MORE": "CustomImageSplashScreenClickForMore",
  "SPLASH_SCREEN_CLICK_FOR_MORE": "SplashScreenClickForMore",
  "VIDEO_EXPLORER_ENGAGE_CARD": "VideoExplorerEngageCard",
  "VIDEO_SCREEN_HYPER_LINK": "VideoScreenHyperLink",
  "DISPLAY_SCREEN_HYPER_LINK": "DisplayScreenHyperLink",
  "VIDEO_SCREEN_IMAGE_BOTTOM_LINK": "VideoScreenImageBottomLink",
  "VIDEO_SCREEN_IMAGE_TOP_LINK": "VideoScreenImageTopLink",
  "DISPLAY_SCREEN_IMAGE_BOTTOM_LINK": "DisplayScreenImageBottomLink",
  "DISPLAY_SCREEN_IMAGE_TOP_LINK": "DisplayScreenImageTopLink",
  "VPAID_CLICK_THRU": "VPaidClickThru",
  "VAST_CLICK_THROUGH": "VastClickThrough",
  "LIKERT_SURVEY_SCREEN_SUBMISSION_CLICK": "LikertSurveyScreenSubmissionClick",
  "IMAGE_SURVEY_SCREEN_SUBMISSION_CLICK": "ImageSurveyScreenSubmissionClick",
  "MULTIPLE_CHOICE_SURVEY_SCREEN_SUBMISSION_CLICK": "MultipleChoiceSurveyScreenSubmissionClick",
  "CUSTOM_SURVEY_SCREEN_SUBMISSION_CLICK": "CustomSurveyScreenSubmissionClick",
  "DISPLAY_IMAGE_CLICK_THROUGH": "DisplayImageClickThrough",
  "DISPLAY_TAG_CLICK_THROUGH": "DisplayTagClickThrough",
  "HOT_SPOT_DRIVE_TO_SITE_CLICK": "HotSpotDriveToSiteClick",
  "AD_CHOICES_CLICK": "adChoices",
  "PRODUCT_EXPLORER_CLICK_THROUGH": "ProductExplorerClickThrough",
}

export const ENGAGEMENT_EXPERIENCE_SOURCE = {
  FINALE: 'Finale',
  HOTSPOT: 'HotSpot',
  IN_VIDEO: 'In-Video',
  DISPLAY: 'Display',
};

export const CUSTOM_ENGAGEMENT_TYPE = {
  IMAGE: 'IMAGE',
  EMBED: 'EMBED'
};

// Create CONSTANTS for all EngagementTypes - was not standardized in wasabi.
export const ENGAGEMENT_TYPES = {
  ThankYou: 'Default',
  Custom: 'Custom',
  Explorer: 'VideoExplore',
  ImageSurvey: 'ImageSurvey',
  MultipleChoiceSurvey: 'MultipleChoiceSurvey',
  StarSurvey: 'Survey',
  Map: 'EngagementLocator',
  CustomSurvey: 'CustomSurvey',
  // @TODO: Add more as we move forward
};

export const RAW_IMAGE_LABEL = 'RawImage'

// For aligning display and video types to send to druid
export const ENGAGEMENT_TEMPLATE_MAP = {
  ...(
    Object.values(ENGAGEMENT_TYPES)
      .reduce((res, value) => {
        res[value] = value;
        return res;
      }, {})
  ),
  // TAG_URL: 'TAG_URL', //No mapping for TAG_URL 
  RAW_IMAGE: RAW_IMAGE_LABEL,
  LIKERT_SURVEY: ENGAGEMENT_TYPES.StarSurvey,
  IMAGE_SURVEY: ENGAGEMENT_TYPES.ImageSurvey,
  MULTIPLE_CHOICE_SURVEY: ENGAGEMENT_TYPES.MultipleChoiceSurvey,
  STORE_LOCATOR: ENGAGEMENT_TYPES.Map,
  CUSTOM_SURVEY: ENGAGEMENT_TYPES.CustomSurvey,
  SOCIAL_CONNECTOR: ENGAGEMENT_TYPES.ThankYou,
  CUSTOM: ENGAGEMENT_TYPES.Custom,
  EXPLORER: ENGAGEMENT_TYPES.Explorer,
  PRODUCT_EXPLORER: 'ProductExplorer'
}

export const DESCRIPTION_LAYOUT_TYPE = {
  BUTTON: 'BUTTON',
  OVERLAY: 'OVERLAY',
  NONE: 'NONE',
}

export const CAROUSEL_ITEM_TYPE = {
  VIDEO: 'Video',
  PRODUCT: 'Product',
}

export const NO_OPTION_SELECTED_ALERT = 'Oops, looks like you missed one, please select one option.';

export const DISPLAY_MEDIA_TYPES = {
  TAG_URL: 'TAG_URL',
  RAW_IMAGE: 'RAW_IMAGE',
  LIKERT_SURVEY: 'LIKERT_SURVEY',
  IMAGE_SURVEY: 'IMAGE_SURVEY',
  MULTIPLE_CHOICE_SURVEY: 'MULTIPLE_CHOICE_SURVEY',
  CUSTOM_SURVEY: 'CUSTOM_SURVEY',
  STORE_LOCATOR: 'STORE_LOCATOR',
  INSTAJOURNEY: 'INSTAJOURNEY',
  PATHMAKER: 'PATHMAKER',
  PRODUCT_EXPLORER: 'PRODUCT_EXPLORER',
}

export const SHARE_ICON_POSITION = {
  TOP_RIGHT: 'TOP_RIGHT',
  TOP_LEFT: 'TOP_LEFT',
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT'
}

export const PLAYER_HOSTS = {
  JW: 'JW',
  VAST: 'VAST'
};

export const ASPECT_RATIO = {
  SIXTEEN_BY_NINE: 'SIXTEEN_BY_NINE',
  NINE_BY_SIXTEEN: 'NINE_BY_SIXTEEN',
  FOUR_BY_THREE: 'FOUR_BY_THREE',
}

export const ASPECT_RATIO_VALUES = {
  [ASPECT_RATIO.SIXTEEN_BY_NINE]: 16 / 9,
  [ASPECT_RATIO.NINE_BY_SIXTEEN]: 9 / 16,
  [ASPECT_RATIO.FOUR_BY_THREE]: 4 / 3
};

export const DEFAULT_ASPECT_RATIO = ASPECT_RATIO.SIXTEEN_BY_NINE;

export const DEVICE_TYPE = {
  IOS_MOBILE_DEVICE: 'IOS_MOBILE_DEVICE',
  NON_IOS_MOBILE_DEVICE: 'NON_IOS_MOBILE_DEVICE'
}

export const AUTOPLAY_MODE = {
  NO_AUTOPLAY: "NO_AUTOPLAY",
  AUTOPLAY_MUTED: "AUTOPLAY_MUTED",
  AUTOPLAY_UNMUTED: "AUTOPLAY_UNMUTED"
}

export const BRAND_LOGO_SIZE = {
  SMALL: "27px",
  MEDIUM: "31px",
  LARGE: "38px"
}

export const OVERLAY_INDEX = "overlayIndex";
export const DISPLAY_ENGAGEMENT_INDEX = "displayEngagementIndex";

export const IN_SESSION_JOURNEY_EVENT_MAP = {
  [JourneyTriggerInteractiveEvent.interactiveSocialConnectorClickThrough]: JourneyTriggerInteractiveEvent.interactiveSocialConnectorClickThrough,
  [JourneyTriggerInteractiveEvent.interactiveTopLogoClickThrough]: JourneyTriggerInteractiveEvent.interactiveTopLogoClickThrough,
  [JourneyTriggerInteractiveEvent.interactiveBottomLogoClickThrough]: JourneyTriggerInteractiveEvent.interactiveBottomLogoClickThrough,
  [JourneyTriggerInteractiveEvent.interactiveShare]: JourneyTriggerInteractiveEvent.interactiveShare,
  [JourneyTriggerInteractiveEvent.interactiveCtaCopyClickThrough]: JourneyTriggerInteractiveEvent.interactiveCtaCopyClickThrough,
  [JourneyTriggerInteractiveEvent.interactiveCustomImageClickThrough]: JourneyTriggerInteractiveEvent.interactiveCustomImageClickThrough,
  [JourneyTriggerInteractiveEvent.interactiveMapStoreDirections]: JourneyTriggerInteractiveEvent.interactiveMapStoreDirections,
  [JourneyTriggerInteractiveEvent.interactiveMapStoreCall]: JourneyTriggerInteractiveEvent.interactiveMapStoreCall,
  [JourneyTriggerInteractiveEvent.interactiveHotspotClickThrough]: JourneyTriggerInteractiveEvent.interactiveHotspotClickThrough,

  // option specfic submission events are in-session only and are remapped for JT event sending
  [JourneyTriggerInteractiveEvent.interactive1StarSubmission]: JourneyTriggerInteractiveEvent.interactiveLikertSubmission,
  [JourneyTriggerInteractiveEvent.interactive2StarSubmission]: JourneyTriggerInteractiveEvent.interactiveLikertSubmission,
  [JourneyTriggerInteractiveEvent.interactive3StarSubmission]: JourneyTriggerInteractiveEvent.interactiveLikertSubmission,
  [JourneyTriggerInteractiveEvent.interactive4StarSubmission]: JourneyTriggerInteractiveEvent.interactiveLikertSubmission,
  [JourneyTriggerInteractiveEvent.interactive5StarSubmission]: JourneyTriggerInteractiveEvent.interactiveLikertSubmission,

  [JourneyTriggerInteractiveEvent.interactiveMultipleChoiceSurveyOption1Submission]: JourneyTriggerInteractiveEvent.interactiveMultipleChoiceSurveySubmission,
  [JourneyTriggerInteractiveEvent.interactiveMultipleChoiceSurveyOption2Submission]: JourneyTriggerInteractiveEvent.interactiveMultipleChoiceSurveySubmission,
  [JourneyTriggerInteractiveEvent.interactiveMultipleChoiceSurveyOption3Submission]: JourneyTriggerInteractiveEvent.interactiveMultipleChoiceSurveySubmission,
  [JourneyTriggerInteractiveEvent.interactiveMultipleChoiceSurveyOption4Submission]: JourneyTriggerInteractiveEvent.interactiveMultipleChoiceSurveySubmission,
  [JourneyTriggerInteractiveEvent.interactiveMultipleChoiceSurveyOption5Submission]: JourneyTriggerInteractiveEvent.interactiveMultipleChoiceSurveySubmission,
  [JourneyTriggerInteractiveEvent.interactiveMultipleChoiceSurveyOption6Submission]: JourneyTriggerInteractiveEvent.interactiveMultipleChoiceSurveySubmission,

  [JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption1Submission]: JourneyTriggerInteractiveEvent.interactiveCustomSurveySubmission,
  [JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption2Submission]: JourneyTriggerInteractiveEvent.interactiveCustomSurveySubmission,
  [JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption3Submission]: JourneyTriggerInteractiveEvent.interactiveCustomSurveySubmission,
  [JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption4Submission]: JourneyTriggerInteractiveEvent.interactiveCustomSurveySubmission,
  [JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption5Submission]: JourneyTriggerInteractiveEvent.interactiveCustomSurveySubmission,
  [JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption6Submission]: JourneyTriggerInteractiveEvent.interactiveCustomSurveySubmission,
  [JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption7Submission]: JourneyTriggerInteractiveEvent.interactiveCustomSurveySubmission,
  [JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption8Submission]: JourneyTriggerInteractiveEvent.interactiveCustomSurveySubmission,
  [JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption9Submission]: JourneyTriggerInteractiveEvent.interactiveCustomSurveySubmission,
  [JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption10Submission]: JourneyTriggerInteractiveEvent.interactiveCustomSurveySubmission,
  [JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption11Submission]: JourneyTriggerInteractiveEvent.interactiveCustomSurveySubmission,
  [JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption12Submission]: JourneyTriggerInteractiveEvent.interactiveCustomSurveySubmission,
  [JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption13Submission]: JourneyTriggerInteractiveEvent.interactiveCustomSurveySubmission,
  [JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption14Submission]: JourneyTriggerInteractiveEvent.interactiveCustomSurveySubmission,
  [JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption15Submission]: JourneyTriggerInteractiveEvent.interactiveCustomSurveySubmission,

  [JourneyTriggerInteractiveEvent.interactiveImageSurveyOption1Submission]: JourneyTriggerInteractiveEvent.interactiveImageSurveySubmission,
  [JourneyTriggerInteractiveEvent.interactiveImageSurveyOption2Submission]: JourneyTriggerInteractiveEvent.interactiveImageSurveySubmission,
  [JourneyTriggerInteractiveEvent.interactiveImageSurveyOption3Submission]: JourneyTriggerInteractiveEvent.interactiveImageSurveySubmission,

  [JourneyTriggerInteractiveEvent.interactiveExplorerOption1ClickThrough]: JourneyTriggerInteractiveEvent.interactiveExplorerClickThrough,
  [JourneyTriggerInteractiveEvent.interactiveExplorerOption2ClickThrough]: JourneyTriggerInteractiveEvent.interactiveExplorerClickThrough,
  [JourneyTriggerInteractiveEvent.interactiveExplorerOption3ClickThrough]: JourneyTriggerInteractiveEvent.interactiveExplorerClickThrough,
  [JourneyTriggerInteractiveEvent.interactiveExplorerOption4ClickThrough]: JourneyTriggerInteractiveEvent.interactiveExplorerClickThrough,
  [JourneyTriggerInteractiveEvent.interactiveExplorerOption5ClickThrough]: JourneyTriggerInteractiveEvent.interactiveExplorerClickThrough,
  [JourneyTriggerInteractiveEvent.interactiveExplorerOption6ClickThrough]: JourneyTriggerInteractiveEvent.interactiveExplorerClickThrough,

  [JourneyTriggerInteractiveEvent.clickThrough]: JourneyTriggerInteractiveEvent.clickThrough,
}

export const CREATIVE_CARD_TYPE = {
  TAG_URL: 'TAG_URL',
  RAW_IMAGE: 'RAW_IMAGE',
  LIKERT_SURVEY: 'LIKERT_SURVEY',
  IMAGE_SURVEY: 'IMAGE_SURVEY',
  MULTIPLE_CHOICE_SURVEY: 'MULTIPLE_CHOICE_SURVEY',
  STORE_LOCATOR: 'STORE_LOCATOR',
  PATHMAKER: 'PATHMAKER',
  CUSTOM_SURVEY: 'CUSTOM_SURVEY',
  PRODUCT_EXPLORER: 'PRODUCT_EXPLORER',
  EXPLORER: 'EXPLORER',
  CUSTOM: 'CUSTOM',
  THANK_YOU: 'THANK_YOU'
}


export const IN_SESSION_TRIGGERS_PER_ENGAGEMENT = {
  [CREATIVE_CARD_TYPE.THANK_YOU]: [
    JourneyTriggerInteractiveEvent.interactiveSocialConnectorClickThrough,
    JourneyTriggerInteractiveEvent.interactiveShare,
  ],
  [CREATIVE_CARD_TYPE.CUSTOM]: [
    JourneyTriggerInteractiveEvent.interactiveCustomImageClickThrough,
  ],
  [CREATIVE_CARD_TYPE.EXPLORER]: [
    JourneyTriggerInteractiveEvent.interactiveExplorerOption1ClickThrough,
    JourneyTriggerInteractiveEvent.interactiveExplorerOption2ClickThrough,
    JourneyTriggerInteractiveEvent.interactiveExplorerOption3ClickThrough,
    JourneyTriggerInteractiveEvent.interactiveExplorerOption4ClickThrough,
    JourneyTriggerInteractiveEvent.interactiveExplorerOption5ClickThrough,
    JourneyTriggerInteractiveEvent.interactiveExplorerOption6ClickThrough,
  ],
  [CREATIVE_CARD_TYPE.IMAGE_SURVEY]: [
    JourneyTriggerInteractiveEvent.interactiveImageSurveyOption1Submission,
    JourneyTriggerInteractiveEvent.interactiveImageSurveyOption2Submission,
    JourneyTriggerInteractiveEvent.interactiveImageSurveyOption3Submission,
  ],
  [CREATIVE_CARD_TYPE.MULTIPLE_CHOICE_SURVEY]: [
    JourneyTriggerInteractiveEvent.interactiveMultipleChoiceSurveyOption1Submission,
    JourneyTriggerInteractiveEvent.interactiveMultipleChoiceSurveyOption2Submission,
    JourneyTriggerInteractiveEvent.interactiveMultipleChoiceSurveyOption3Submission,
    JourneyTriggerInteractiveEvent.interactiveMultipleChoiceSurveyOption4Submission,
    JourneyTriggerInteractiveEvent.interactiveMultipleChoiceSurveyOption5Submission,
    JourneyTriggerInteractiveEvent.interactiveMultipleChoiceSurveyOption6Submission,
  ],
  [CREATIVE_CARD_TYPE.CUSTOM_SURVEY]: [
    JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption1Submission,
    JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption2Submission,
    JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption3Submission,
    JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption4Submission,
    JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption5Submission,
    JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption6Submission,
    JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption7Submission,
    JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption8Submission,
    JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption9Submission,
    JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption10Submission,
    JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption11Submission,
    JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption12Submission,
    JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption13Submission,
    JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption14Submission,
    JourneyTriggerInteractiveEvent.interactiveCustomSurveyOption15Submission,
  ],
  [CREATIVE_CARD_TYPE.LIKERT_SURVEY]: [
    JourneyTriggerInteractiveEvent.interactive1StarSubmission,
    JourneyTriggerInteractiveEvent.interactive2StarSubmission,
    JourneyTriggerInteractiveEvent.interactive3StarSubmission,
    JourneyTriggerInteractiveEvent.interactive4StarSubmission,
    JourneyTriggerInteractiveEvent.interactive5StarSubmission,
  ],
  [CREATIVE_CARD_TYPE.STORE_LOCATOR]: [
    JourneyTriggerInteractiveEvent.interactiveMapStoreDirections,
    JourneyTriggerInteractiveEvent.interactiveMapStoreCall,
  ],
}

export const IN_SESSION_TRIGGERS_PER_DISPLAY_ENGAGEMENT = {
  [CREATIVE_CARD_TYPE.LIKERT_SURVEY]: IN_SESSION_TRIGGERS_PER_ENGAGEMENT[CREATIVE_CARD_TYPE.LIKERT_SURVEY],
  [CREATIVE_CARD_TYPE.IMAGE_SURVEY]: IN_SESSION_TRIGGERS_PER_ENGAGEMENT[CREATIVE_CARD_TYPE.IMAGE_SURVEY],
  [CREATIVE_CARD_TYPE.MULTIPLE_CHOICE_SURVEY]: IN_SESSION_TRIGGERS_PER_ENGAGEMENT[CREATIVE_CARD_TYPE.MULTIPLE_CHOICE_SURVEY],
  [CREATIVE_CARD_TYPE.STORE_LOCATOR]: IN_SESSION_TRIGGERS_PER_ENGAGEMENT[CREATIVE_CARD_TYPE.STORE_LOCATOR],
  [CREATIVE_CARD_TYPE.CUSTOM_SURVEY]: IN_SESSION_TRIGGERS_PER_ENGAGEMENT[CREATIVE_CARD_TYPE.CUSTOM_SURVEY],
}

// Pseudo-event representing lack of interaction at all in a creative
export const IN_SESSION_NO_INTERACTION_EVENT = "noInteraction";

// Pseudo-event representing lack of interaction for an element/node
export const IN_SESSION_NO_CLICK_EVENT = "noClick";

export const STYLE_MATCH_DISPLAY_NONE_WITH_DELAY = {transitionDelay: '.6s', transitionProperty: 'height, width', height: '0px', width: '0px'}

export const INTERACTIVE_ELEMENTS = {
  CTA: 'callToAction',
  TOP_BRAND_LOGO: 'topLogo',
  BOTTOM_BRAND_LOGO: 'bottomLogo',
  SOCIAL_SHARE: 'socialShare',
  HOTSPOT: 'hotspot',
  OVERLAY: 'overlay'
}

export const INTERACTIVE_ELEMENTS_SETTINGS_MAP = {
  [INTERACTIVE_ELEMENTS.CTA]: 'callToAction',
  [INTERACTIVE_ELEMENTS.TOP_BRAND_LOGO]: 'playerTop',
  [INTERACTIVE_ELEMENTS.BOTTOM_BRAND_LOGO]: 'playerBottom',
  [INTERACTIVE_ELEMENTS.SOCIAL_SHARE]: 'share'
}

export const DEFAULT_VIDEO_CLEANUP_TIMEOUT_SECONDS = 10800;

// Sync with Campaign UI for Validation.
export const HOTSPOT_ANIMATION_DURATION = 250; // In milliseconds


export const LOG_DETAILS_TYPE = {
  APP_COMPONENT_MOUNT: 'App Component Mount',
  POLYFILL_LOAD: 'Polyfill Load',
  SETTINGS_FETCH: 'Settings Fetch',
  FONT_LOAD: 'Font Load',
  RESIZE: 'Resize',
  VIDEO_CREATIVE_COMPONENT_MOUNT: 'Video Creative Component Mount',
  PLAYER_MOUNT: 'Player Mount',
  CANARY: 'Canary',
  HEAVY_AD: 'Heavy Ad',
  PLAYER_INITIALIZATION: 'Player Initialization',
  PLAYER_JS_LOAD: 'Player Js Load',
  VPAID_ENDPOINT_RESPONSE: 'Vpaid Endpoint Response',
  VPAID_AD_LOAD: 'Vpaid ad load',
  JW_JS_LOAD: 'Jw Js Load',
  JW_VIDEO_LOAD: 'Jw Video Load',
  SETTINGS_API_RESPONSE: 'Settings API Response'
}

export const VAST_EVENTS = {
  IMPRESSION: 'impression',
  Q1: 'q1',
  FIRST_QUARTILE: 'firstQuartile',
  Q2: 'q2',
  MIDPOINT: 'midpoint',
  THIRD_QUARTILE: 'thirdQuartile',
  Q3: 'q3',
  COMPLETE: 'complete',
  DONE: 'done',
  PAUSE: 'pause',
  MUTE: 'mute',
  UNMUTE: 'unmute'
}

export const VPAID_TO_VAST_EVENTS = {
  [EventType.AdImpression]: VAST_EVENTS.IMPRESSION,
  [EventType.AdVideoFirstQuartile]: VAST_EVENTS.FIRST_QUARTILE,
  [EventType.AdVideoMidpoint]: VAST_EVENTS.MIDPOINT,
  [EventType.AdVideoThirdQuartile]: VAST_EVENTS.THIRD_QUARTILE,
  [EventType.AdVideoComplete]: VAST_EVENTS.COMPLETE,
  [EventType.AdPaused]: VAST_EVENTS.PAUSE
}

// Sync with Campaign UI
export const BACKGROUND_STYLES = {
  SOLID: 'SOLID',
  IMAGE: 'IMAGE',
  GRADIENT: 'GRADIENT'
}

// Sync with Campaign UI
export const BACKGROUND_IMAGE_TYPE = {
  IN_VIDEO: 'inVideo',
  CARD: 'card'
}

// Sync with Campaign UI
export const GRADIENT_ANGLES = {
  TOP_TO_BOTTOM: 'TOP_TO_BOTTOM',
  BOTTOM_TO_TOP: 'BOTTOM_TO_TOP',
  CENTRE: 'CENTRE',
  LEFT_TO_RIGHT: 'LEFT_TO_RIGHT',
  RIGHT_TO_LEFT: 'RIGHT_TO_LEFT',
}

export const GRADIENT_TYPES = {
  LINEAR: 'linear-gradient',
  RADIAL: 'radial-gradient'
}

export const isPathMaker= (mediaType) => {
  return [DISPLAY_MEDIA_TYPES.PATHMAKER , DISPLAY_MEDIA_TYPES.INSTAJOURNEY].indexOf(mediaType) !== -1
}

export const GEOGRAPHIC_RESTRICTION_ERROR_MESSAGE = "Content is not available in your country";

export const journeyTriggerExposureEvents = {
  [CREATIVE_CARD_TYPE.LIKERT_SURVEY] : JourneyTriggerInteractiveEvent.interactiveLikertExposure,
  [CREATIVE_CARD_TYPE.IMAGE_SURVEY] : JourneyTriggerInteractiveEvent.interactiveImageSurveyExposure,
  [CREATIVE_CARD_TYPE.MULTIPLE_CHOICE_SURVEY] : JourneyTriggerInteractiveEvent.interactiveMultipleChoiceSurveyExposure,
  [CREATIVE_CARD_TYPE.CUSTOM_SURVEY] : JourneyTriggerInteractiveEvent.interactiveCustomSurveyExposure,
  [CREATIVE_CARD_TYPE.PRODUCT_EXPLORER] : JourneyTriggerInteractiveEvent.interactiveProductExplorerExposure,
  [CREATIVE_CARD_TYPE.RAW_IMAGE] : JourneyTriggerInteractiveEvent.interactiveRawImageExposure,
  [CREATIVE_CARD_TYPE.STORE_LOCATOR] : JourneyTriggerInteractiveEvent.interactiveMapExposure,
  [CREATIVE_CARD_TYPE.THANK_YOU] : JourneyTriggerInteractiveEvent.interactiveSocialConnectorExposure,
}

export const LIKERT_OPTION_STYLE = {
  ICON: 'ICON',
  SLIDER: 'SLIDER'
}

export const LIKERT_SUBMIT_BUTTON_TYPE = {
  BUTTON: 'BUTTON',
  CHECKMARK: 'CHECKMARK'
}

export const MARKER = {
  CUSTOM: 'CUSTOM',
  DEFAULT: 'DEFAULT',
  CIRCLE: 'CIRCLE',
  PLUS_CIRCLE: 'PLUS_CIRCLE',
  DOT_CIRCLE: 'DOT_CIRCLE',
  ASTERISK: 'ASTERISK',
  INFO_CIRCLE: 'INFO_CIRCLE',
  STAR: 'STAR',
  HEART: 'HEART',
  MAP: 'MAP',
  PLUS: 'PLUS',
  SHOPPING: 'SHOPPING',
  EMBED: 'EMBED',
  ICON: 'ICON'
};

export const MARKER_CONFIG = {
  [MARKER.CUSTOM]: {
    name: 'Custom',
    isCustom: true
  },
  [MARKER.DEFAULT]: {
    name: 'Default',
    hasIcon: false,
    isDefault: true
  },
  [MARKER.CIRCLE]: {
    name: 'Circle',
    hasIcon: true,
    icon: {
      defaultStyle: 'far',
      icon: 'fa-circle'
    }
  },
  [MARKER.PLUS_CIRCLE]: {
    name: 'Plus Circle',
    hasIcon: true,
    icon: {
      defaultStyle: 'far',
      icon: 'fa-plus-circle'
    }
  },
  [MARKER.DOT_CIRCLE]: {
    name: 'Dot Circle',
    hasIcon: true,
    icon: {
      defaultStyle: 'far',
      icon: 'fa-dot-circle'
    }
  },
  [MARKER.ASTERISK]: {
    name: 'Asterisk',
    hasIcon: true,
    icon: {
      defaultStyle: 'far',
      icon: 'fa-asterisk'
    }
  },
  [MARKER.INFO_CIRCLE]: {
    name: 'Info Circle',
    hasIcon: true,
    icon: {
      defaultStyle: 'fal',
      icon: 'fa-info-circle'
    }
  },
  [MARKER.STAR]: {
    name: 'Star',
    hasIcon: true,
    icon: {
      defaultStyle: 'far',
      icon: 'fa-star'
    }
  },
  [MARKER.HEART]: {
    name: 'Heart',
    hasIcon: true,
    icon: {
      defaultStyle: 'far',
      icon: 'fa-heart'
    }
  },
  [MARKER.MAP]: {
    name: 'Map Marker',
    hasIcon: true,
    icon: {
      defaultStyle: 'far',
      icon: 'fa-map-marker'
    }
  },
  [MARKER.PLUS]: {
    name: 'Plus',
    hasIcon: true,
    icon: {
      defaultStyle: 'far',
      icon: 'fa-plus'
    }
  },
  [MARKER.SHOPPING]: {
    name: 'Shopping Bag',
    hasIcon: true,
    icon: {
      defaultStyle: 'far',
      icon: 'fa-shopping-bag'
    }
  },
};

export const DEFAULT_FONT_COLOR = "#ffffff";
export const DEFAULT_FONT = "GothamBook";
export const DEFAULT_VIDEO_DURATION = 30;

export const MEDIA_TYPE = {
  RAW_VIDEO: "RAW_VIDEO",
  RAW_VIDEO_VPAID: "RAW_VIDEO_VPAID",
  RAW_VIDEO_VAST: "RAW_VIDEO_VAST",
  VPAID_URL: "VPAID_URL",
  // More in backend, only ported needed ones for phoenix
}

export const SHARE_BUTTON_THEME = {
  DEFAULT: 'DEFAULT',
  SQUARE: 'SQUARE',
  CIRCLE: 'CIRCLE',
}

export const SHARE_ITEM_THEME_COLOR = {
  DEFAULT: 'DEFAULT',
  BLACK: 'BLACK',
  WHITE: 'WHITE',
}

export const LOCATOR_PIN_TYPE = {
  DEFAULT: 'DEFAULT',
  CUSTOM: 'CUSTOM'
}

export const TRIGGER_LOGIC_KEY = {
  OR: 'or',
  AND: 'and',
  NOT: '!',
  VAR: 'var'
}

// OV-211 - prepend vg-fx- to EFFECT_TYPE (lowercased) as a classname to use effects ex. vg-fx-grow
export const EFFECT_TYPE = {
  GROW: 'GROW',
  FILL: 'FILL',
  OVERLAY: 'OVERLAY',
  BORDER: 'BORDER',
  FANCY: 'FANCY', // based on the 'One div hover' example 
  OUTLINE: 'OUTLINE',
  SWEEP: 'SWEEP',
  SWEEP_WITH_ACCENT: 'SWEEP_WITH_ACCENT',
  NONE: 'NONE', // nofx
}

// label order determines order for effect select dropdown
export const EFFECT_TYPE_LABEL = {
  [EFFECT_TYPE.NONE]: 'None',
  [EFFECT_TYPE.GROW]: 'Grow',
  [EFFECT_TYPE.FILL]: 'Fill',
  [EFFECT_TYPE.OVERLAY]: 'Color Overlay',
  [EFFECT_TYPE.BORDER]: 'Border',
  [EFFECT_TYPE.FANCY]: 'Animated Border',
  [EFFECT_TYPE.OUTLINE]: 'Outline',
  [EFFECT_TYPE.SWEEP]: 'Sweep',
  [EFFECT_TYPE.SWEEP_WITH_ACCENT]: 'Sweep with Accent Bar',
}

// array of effect types that support primary color
export const EFFECT_PRIMARY_COLOR_TYPES = [
  EFFECT_TYPE.BORDER,
  EFFECT_TYPE.FANCY,
  EFFECT_TYPE.OUTLINE,
  EFFECT_TYPE.OVERLAY,
  EFFECT_TYPE.SWEEP,
  EFFECT_TYPE.SWEEP_WITH_ACCENT,
]
// effect types that support secondary color
export const EFFECT_SECONDARY_COLOR_TYPES = [
  EFFECT_TYPE.FANCY,
]
// effect types that support border style
export const EFFECT_BORDER_STYLE_TYPES = [
  EFFECT_TYPE.BORDER,
  EFFECT_TYPE.OUTLINE,
]
// array of effect types that support effect width
export const EFFECT_WIDTH_TYPES = [
  EFFECT_TYPE.SWEEP_WITH_ACCENT,
]

export const EFFECT_PREFIX = {
  BASE: 'vg-fx-',
  HOVER: 'vg-fx-hvr-',
  PERM: 'vg-fx-perm-'
}

// OV-211 default effectType to GROW
export const DEFAULT_EFFECT_VALUES = {
  effectType: EFFECT_TYPE.GROW,
  primaryColor: 'rgba(255,255,255,1)',
  secondaryColor: 'rgba(0,0,0,1)',
  effectWidth: 8,
}

export const MC_POLLING_TIMEOUT = 5000

export const CH_UA_IFRAME_ALLOW = 'ch-ua; ch-ua-mobile; ch-ua-full-version-list; ch-ua-platform; ch-ua-arch; ch-ua-model; ch-ua-bitness'

// NOTE: this is just a subset of values for variationTypeAndDisplaySizeMappingField
export const CREATIVE_CARD_VARIATION_TYPE = {
  IN_VIDEO_LEFT: 'IN_VIDEO_LEFT',
  IN_VIDEO_RIGHT: 'IN_VIDEO_RIGHT',
  IN_VIDEO_BOTTOM: 'IN_VIDEO_BOTTOM',
  IN_VIDEO_TOP: 'IN_VIDEO_TOP',
  FINALE: 'FINALE',
  DIMENSION: 'DIMENSION'
}

export const IN_VIDEO_CREATIVE_CARD_VARIATION_TYPE_MAP = {
  [POSITION_OPTIONS.LEFT]: CREATIVE_CARD_VARIATION_TYPE.IN_VIDEO_LEFT,
  [POSITION_OPTIONS.RIGHT]: CREATIVE_CARD_VARIATION_TYPE.IN_VIDEO_RIGHT,
  [POSITION_OPTIONS.BOTTOM]: CREATIVE_CARD_VARIATION_TYPE.IN_VIDEO_BOTTOM,
  [POSITION_OPTIONS.TOP]: CREATIVE_CARD_VARIATION_TYPE.IN_VIDEO_TOP
}
