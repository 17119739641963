import {useEffect, useState} from "react";
import ResizeObserver from "resize-observer-polyfill";

/*
  Please make sure to sync any changes to this file to Campaign UI.
*/
export const useResizedElementSize = (elementRef) => {
  const [elementSize, setElementSize] = useState({});

  useEffect(() => {
    const elementSizeChangeHandler = () => {
      if (elementRef) {
        setElementSize({
          height: elementRef.clientHeight,
          width: elementRef.clientWidth,
          offsetHeight: elementRef.offsetHeight,
          offsetWidth: elementRef.offsetWidth
        });
      } else {
        setElementSize({height: 0, width: 0, offsetHeight: 0, offsetWidth: 0});
      }
    }

    const ro = new ResizeObserver(elementSizeChangeHandler);

    if (elementRef) {
      ro.observe(elementRef);
    }

    return () => {
      if (elementRef) {
        ro.unobserve(elementRef);
      }
      ro.disconnect();
    }
  }, [elementRef]);

  return elementSize;
}
