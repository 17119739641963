import React from 'react';
import Cta from 'compounds/cta';

import {CLICK_EVENT, JourneyTriggerInteractiveEvent} from 'utils/constants';
import {useEventCapture}  from "utils/EventCaptureProvider";

function CTARenderer({settings: {callToAction}, fireJourneyTrigger, onClickThrough, showing}) {
  const {capture} = useEventCapture();

  return (
    <Cta
      callToAction = {callToAction}
      isShowing={showing}
      onClick = {(srcWidget) => handleClick(srcWidget, fireJourneyTrigger, onClickThrough, callToAction?.inSessionTriggerDetails?.triggerElementId, !!callToAction.displayURL, capture)}
    />
  )
}

function handleClick(srcWidget, fireJourneyTrigger, onClickThrough, triggerElementId, hasUrl, capture) {
  if (hasUrl) {
    const payload = {type: CLICK_EVENT.toLowerCase(), srcWidget}
    capture(payload);
  }
  fireJourneyTrigger(JourneyTriggerInteractiveEvent.interactiveCtaCopyClickThrough, triggerElementId, !hasUrl);
  if (hasUrl) {
    onClickThrough();
  }
}


export default CTARenderer;
