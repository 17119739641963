const Properties = {};
const errorMessage = 'error';

export default class RuntimeProperties {

  static setIbtMillis(val) {
    if (Properties.ibtMillis) {
      throw errorMessage;
    }

    Properties.ibtMillis = val;
  }

  static getIbtMillis() {
    return Properties.ibtMillis;
  }

  static setVideoExploreParentCampaignId(cid) {
    Properties.videoExploreParentCampaignId = cid;
  }

  static getVideoExploreParentCampaignId() {
    return Properties.videoExploreParentCampaignId;
  }

  static getVideoExploreCampaignId() {
    return Properties.videoExploreCampaignId;
  }

  static setVideoExploreCampaignId(cid) {
    Properties.videoExploreCampaignId = cid;
  }

  static isVideoExploreCampaign() {
    return Properties.videoExploreCampaign;
  }

  static setVideoExploreCampaign(videoExploreCampaign) {
    Properties.videoExploreCampaign = videoExploreCampaign;
  }

  static setVideoExploreOptionPosition(optionPosition) {
    Properties.optionPosition = optionPosition;
  }

  static getVideoExploreOptionPosition() {
    return Properties.optionPosition;
  }
  static setExplorerParentCreativeDetails(explorerParentCreativeDetails) {
    Properties.explorerParentCreativeDetails = explorerParentCreativeDetails;
  }

  static getExplorerParentCreativeDetails() {
    return Properties.explorerParentCreativeDetails;
  }

  static setAutoplayMode(autoplayMode) {
    Properties.autoplayMode = autoplayMode;
  }

  static setBrowserAutoplayDisabled(browserAutoplayDisabled) {
    Properties.browserAutoplayDisabled = browserAutoplayDisabled;
  }

  static isBrowserAutoplayDisabled() {
    return !!Properties.browserAutoplayDisabled;
  }

  static getAutoplayMode() {
    return Properties.autoplayMode;
  }
 
  static setSubmittedSurveys(surveys) {
    Properties.submittedSurveys = surveys;
  }

  static getSubmittedSurveys() {
    return Properties.submittedSurveys;
  }
}
