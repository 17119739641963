import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {ENGAGEMENT_DISPLAY_MODE} from 'utils/constants';
import surveyHelpers from 'utils/survey-helper';
import {AUTO_RESIZE_GROUPS} from "utils/AutoResizeProvider";
import {AutoSize} from 'elements/autosize';
import classnames from 'classnames';
import style from './style.module.scss';

import FontAndColorHelper from 'utils/fontAndColorHelper';
import {isMobileDevice} from "utils/environmentHelper";

const OPTION_CONTAINER_CLASS = `option-container`

function MultipleChoiceSurveyOption({option, optionFont, mode, selected, onClick, disabled, noPointer,optionCount,submissionsCount,showScreen,selectedOptionForSubmit}) {
 
  // We need to fill the background color because the optionDecorator can have gaps with the container due to subpixel rendering rounding in various browsers
  const optionStyle = {...(selected && !showScreen) && {borderColor: option?.title?.bgColor, backgroundColor: option?.title?.bgColor}};
  const optionFontSetting = {color: option?.title?.color, font: optionFont};
  const fontAndColor = FontAndColorHelper.getFontAndColor(optionFontSetting);
  const fontWeightAndSize = FontAndColorHelper.getFontWeightAndSize(optionFontSetting);
  const optionId = option?.id;
  const optionTextStyle = {
    color: fontAndColor.color,
    fontFamily: fontAndColor.font,
    ...fontWeightAndSize && fontWeightAndSize.fontSize && {fontSize: fontWeightAndSize.fontSize},
    ...fontWeightAndSize && fontWeightAndSize.fontWeight && {fontWeight: fontWeightAndSize.fontWeight}
  };
  
  const  percentageCount = (optionId) => {
    const displayCount = selectedOptionForSubmit?.id === optionId ?  optionCount + 1  : optionCount;
    const submissionCount = showScreen ? submissionsCount + 1 : submissionsCount;
    return Math.round((100 * displayCount) / submissionCount);
  }
  const optionDecoratorStyle = {
    backgroundColor: option?.title?.bgColor,
    // OV-812 set left 0 to fill option when 100%
    ...(showScreen && {left: 0, width: `${percentageCount(optionId)}%`, opacity: "80%"})
  }

  // OV-510 set style on option when text container is the event target.
  const handleTouchStart = (evt) => {
    clearTouchSelection()
    const option = evt.target?.closest(`.${OPTION_CONTAINER_CLASS}`)
    if (option) {
      option.classList.add(style.optionTouch)
    }
  }
  const handleTouchEnd = (evt) => {
    const option = evt.target?.closest(`.${OPTION_CONTAINER_CLASS}`)
    if (option) {
      option.classList.remove(style.optionTouch)
    }
  }

  // this handles clearing touch selection for desktops with touchscreens when switching from touch to mouse events
  const handleMouseOver = () => {
    if (!isMobileDevice()) {
      clearTouchSelection()
    }
  }

  // OV-510 remove other touch selected effect
  const clearTouchSelection = () => {
    const elems = document.querySelectorAll(`.${style.optionTouch}`)
    elems.forEach((e) => {
      e.classList.remove(style.optionTouch)
    })
  }

  return(
    <div
      className={classnames(mode !== ENGAGEMENT_DISPLAY_MODE.HORIZONTAL ? style.option : style.optionHorizontal,showScreen ? style.noPointer:null, disabled ? style.optionDisabled : null, {[style.noPointer]: noPointer}, OPTION_CONTAINER_CLASS)}
      style={optionStyle}
      onClick={!showScreen ? onClick : null}
      data-testid={`multiple-choice-option-${option.id}`}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchEnd}
      onTouchCancel={handleTouchEnd}
      onMouseOver={handleMouseOver}
    >
      <div className={style.optionDecorator} style={optionDecoratorStyle} />
      <div className={classnames(style.optionText)} style={optionTextStyle}>
        {/* TODO: investigate why the non iterative sizing doesn't work in max char cases */}
        <AutoSize resizeGroup={AUTO_RESIZE_GROUPS.MULTIPLE_CHOICE_OPTIONS}>{option?.title?.text}</AutoSize>
      </div>
      {showScreen ? (<div className={classnames(style.optionText,style.optionPercentage)} style={optionTextStyle}>
        {/* TODO: investigate why the non iterative sizing doesn't work in max char cases */}
        <AutoSize resizeGroup={AUTO_RESIZE_GROUPS.MULTIPLE_CHOICE_OPTIONS}>{percentageCount(optionId)}%</AutoSize>
      </div>) : <div></div>}
    </div>
  )
}

function MultipleChoiceOptionRenderer(
  {
    option,
    index,
    onClick,
    submitEnabled,
    setSelectedOptionForSubmit,
    onSubmit,
    questionAndOptionsFontSettings,
    selectedOptionForSubmit,
    mode,
    surveySubmittingOption,
    setSurveySubmittingOption,
    isCreativeStudioPreviewMode,
    submissionsCount,
    showScreen,
  }
) {
  const onClickWrapper = useCallback(() => {
    if (isCreativeStudioPreviewMode) {
      return;
    }
    if (submitEnabled) {
      onClick(option.id, index + 1, option.optionIndex);
      setSelectedOptionForSubmit({id: option.id, position: index + 1, optionIndex: option.optionIndex});
    } else {
      if (isMobileDevice()) {
        // animationInProgress is only set when there is animation in progress (ie: when submit button is disabled)
        if (surveySubmittingOption) {
          return;
        }
        // Wait to complete the animation
        setSurveySubmittingOption(option.id);
        setSelectedOptionForSubmit({id: option.id, position: index + 1, optionIndex: option.optionIndex});
        setTimeout(() => {
          onClick(option.id, index + 1, option.optionIndex);
          onSubmit(option.id, index + 1, option.optionIndex);
          setSurveySubmittingOption(null);
        }, 2 * 250); // Sync with transition delay in button.
      } else {
        setSelectedOptionForSubmit({id: option.id, position: index + 1, optionIndex: option.optionIndex});
        onClick(option.id, index + 1, option.optionIndex);
        onSubmit(option.id, index + 1, option.optionIndex);
      }
    }
  }, [submitEnabled, onClick, option, index, setSelectedOptionForSubmit, surveySubmittingOption, setSurveySubmittingOption, onSubmit, isCreativeStudioPreviewMode]);
  const optionCount = option?.responseChoiceCount || 0;
  const selected = useMemo(() => selectedOptionForSubmit && selectedOptionForSubmit.id === option.id, [option, selectedOptionForSubmit]);
  const disabled = useMemo(() => surveySubmittingOption && (surveySubmittingOption !== option.id), [option, surveySubmittingOption]);
  return <MultipleChoiceSurveyOption
    key={option.id}
    option={option}
    optionFont={questionAndOptionsFontSettings.font}
    mode={mode}
    selected={selected}
    onClick={onClickWrapper}
    disabled={disabled}
    noPointer={isCreativeStudioPreviewMode}
    optionCount={optionCount}
    submissionsCount={submissionsCount}
    showScreen={showScreen}
    selectedOptionForSubmit={selectedOptionForSubmit}
  />
}

function MultipleChoiceSurveyRenderer({title, options, questionAndOptionsFontSettings, submitEnabled, submitButtonText, submitButtonFontSettings, submitButtonBackgroundColor, mode = ENGAGEMENT_DISPLAY_MODE.VERTICAL, onClick, onSubmit, isNarrow, selectedPosition, isCreativeStudioPreviewMode, isDisplayCreative, pollingEnabled, submissionsCount, showPollingScreen, surveySubmitted, scrubBarShowing,submissionDetails, surveyID, ...rest}) {
  const [selectedOptionForSubmit, setSelectedOptionForSubmit] = useState(null);
  // TODO: test and reconcile various css rules for display, (not) mobile, FourByThree, in-video only
  //      goal is to make one css responsive instead of all these nested conditionals

  useEffect(() => {
    if (selectedPosition !== null && selectedPosition !== undefined) {
      const selectedOption = options[selectedPosition-1]
      setSelectedOptionForSubmit({id: selectedOption.id, position: selectedPosition, optionIndex: selectedOption.optionIndex})
    }
    if(!selectedOptionForSubmit && submissionDetails && submissionDetails[surveyID] && pollingEnabled && !isDisplayCreative) {
      const surveyObj = submissionDetails[surveyID]
      setSelectedOptionForSubmit({id: surveyObj.selectedQuestionOptionID, position: surveyObj.position, optionIndex: surveyObj.optionIndex})
    }
  },[selectedPosition, options, isDisplayCreative , pollingEnabled , submissionDetails, surveyID]);
  
  const titleFontAndColor = FontAndColorHelper.getFontAndColor(questionAndOptionsFontSettings);
  const titleFontWeightAndSize = FontAndColorHelper.getFontWeightAndSize(questionAndOptionsFontSettings);
  const submitFontAndColor = FontAndColorHelper.getFontAndColor(submitButtonFontSettings);
  const submitFontWeightAndSize = FontAndColorHelper.getFontWeightAndSize(submitButtonFontSettings);

  const [surveySubmittingOption, setSurveySubmittingOption] = useState(null);
  const getVotesStylesClass = (isDisplayCreative) => {
    if (isDisplayCreative || (!scrubBarShowing && mode !== ENGAGEMENT_DISPLAY_MODE.VERTICAL)) {
      return style.votes;
    } else {
      return style.inVideoVotes;
    }
  };

  const submissionTime = submissionDetails && submissionDetails[surveyID]
  const showScreen = isDisplayCreative && surveySubmitted || (!isDisplayCreative && submissionTime) || showPollingScreen;
  const submissionCountDisplay = showScreen ? submissionsCount + 1 : submissionsCount;
  const submissionCountDisplayText = submissionCountDisplay === 1 ? `${submissionCountDisplay} Vote` : `${submissionCountDisplay} Votes`

  const titleStyle = {
    color: titleFontAndColor.color,
    fontFamily: titleFontAndColor.font,
    ...titleFontWeightAndSize && titleFontWeightAndSize.fontSize && {fontSize: titleFontWeightAndSize.fontSize},
    ...titleFontWeightAndSize && titleFontWeightAndSize.fontWeight && {fontWeight: titleFontWeightAndSize.fontWeight}
  };

  const submitButtonStyle = {
    backgroundColor: submitButtonBackgroundColor,
    color: submitFontAndColor.color,
    fontFamily: submitFontAndColor.font,
    ...submitFontWeightAndSize && submitFontWeightAndSize.fontSize && {fontSize: submitFontWeightAndSize.fontSize},
    ...submitFontWeightAndSize && submitFontWeightAndSize.fontWeight && {fontWeight: submitFontWeightAndSize.fontWeight}
  }

  const getOptionsContainerClass = () => {
    if(mode === ENGAGEMENT_DISPLAY_MODE.VERTICAL || isNarrow) {
      return style.optionsContainerVertical;
    }
    if(mode === ENGAGEMENT_DISPLAY_MODE.FULL_MODE) {
      return style.optionsContainer;
    }
    if(mode === ENGAGEMENT_DISPLAY_MODE.HORIZONTAL) {
      return style.optionsContainerHorizontal;
    }
    return style.optionsContainer;
  }

  const memoizedOptions = useMemo(() => options.map((option, index) =>
    <MultipleChoiceOptionRenderer
      key={option.id}
      option={option}
      index={index}
      onClick={onClick}
      submitEnabled={submitEnabled}
      setSelectedOptionForSubmit={setSelectedOptionForSubmit}
      onSubmit={onSubmit}
      questionAndOptionsFontSettings={questionAndOptionsFontSettings}
      selectedOptionForSubmit={selectedOptionForSubmit}
      mode={mode}
      setSurveySubmittingOption={setSurveySubmittingOption}
      surveySubmittingOption={surveySubmittingOption}
      isCreativeStudioPreviewMode={isCreativeStudioPreviewMode}
      submissionsCount={submissionsCount}
      showScreen={showScreen}
    />
  ), [mode, onClick, onSubmit, options, questionAndOptionsFontSettings, selectedOptionForSubmit, submitEnabled, surveySubmittingOption, isCreativeStudioPreviewMode,submissionsCount,showScreen]);

  return (
    <div className={mode !== ENGAGEMENT_DISPLAY_MODE.HORIZONTAL ? style.multipleChoiceContainer : style.multipleChoiceHorizontalContainer}>
      <div className={classnames(mode !== ENGAGEMENT_DISPLAY_MODE.HORIZONTAL ? style.title : style.titleHorizontal)} style={titleStyle}>
        <AutoSize isMulti={true}>{title}</AutoSize>
      </div>
      <div className={getOptionsContainerClass()}>
        {memoizedOptions}
      </div>
      {submitEnabled && mode !== ENGAGEMENT_DISPLAY_MODE.HORIZONTAL && !showScreen &&
        <div
          className={classnames(style.submitButton, {[style.noPointer]: isCreativeStudioPreviewMode})}
          style={submitButtonStyle}
          onClick={() => {
            if (isCreativeStudioPreviewMode) {
              return 
            }

            if (!selectedOptionForSubmit) {
              surveyHelpers.showNoOptionSelectedAlert();
            } else {
              onSubmit(selectedOptionForSubmit.id, selectedOptionForSubmit.position, selectedOptionForSubmit.optionIndex);
            }
          }}
          data-testid={'multiple-choice-submit'}
        >
          <AutoSize isMulti={true} resizeGroup={AUTO_RESIZE_GROUPS.MULTIPLE_CHOICE_OPTIONS}>{submitButtonText}</AutoSize>
        </div>
      }
        {pollingEnabled && (
        <div className={getVotesStylesClass(isDisplayCreative)} style={titleStyle}>
          {/* replace the vote count from backend api */}
          <AutoSize isMulti={true} resizeGroup={AUTO_RESIZE_GROUPS.MULTIPLE_CHOICE_OPTIONS}>{submissionCountDisplayText}</AutoSize>
        </div>
      )}
    </div>
  );
}

export default MultipleChoiceSurveyRenderer;
