// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".hotspotContainer--1UX0J{position:absolute;height:100%;width:100%}.fullContainer--3VnZt{left:0;top:0;pointer-events:none}.pointerEventsAll--3J_-2{pointer-events:all;z-index:1;-webkit-user-select:none;-ms-user-select:none;user-select:none}.moveable-control{--bgColor: white;--borderColor: $vgBlue;position:absolute !important;box-sizing:border-box !important;margin-top:-4px !important;margin-left:-4px !important;z-index:10 !important;height:8px !important;width:8px !important;border-radius:100% !important;border-width:.8px !important;border-style:solid !important;border-color:var(--borderColor) !important;background:var(--bgColor) !important;pointer-events:all !important}.moveable-origin{visibility:hidden !important}.moveable-line{position:absolute;height:2px;width:2px;background:#23adf0 !important}.moveable-area{pointer-events:all;cursor:pointer}", ""]);
// Exports
exports.locals = {
	"hotspotContainer": "hotspotContainer--1UX0J",
	"fullContainer": "fullContainer--3VnZt",
	"pointerEventsAll": "pointerEventsAll--3J_-2",
	"moveable-control": "moveable-control",
	"moveable-origin": "moveable-origin",
	"moveable-line": "moveable-line",
	"moveable-area": "moveable-area"
};
module.exports = exports;
