import React, {useMemo, useState} from 'react';
import style from './style.module.scss';
import {useResizedElementSize} from "hooks/useResizedElementSize";
import {HOTSPOT_POSITIONS} from "utils/hotspot-helper";

/*
  Please make sure to sync any changes to this file to Campaign UI.
*/
export const HotspotTooltipPopper = (
  {
    children,
    open,
    anchorEl,
    popperPosition,
  }
) => {
  const [tooltipRef, setTooltipRef] = useState(null);

  const elementSize = useResizedElementSize(tooltipRef);
  const anchorElSize = useResizedElementSize(anchorEl);

  const popperTranslate = useMemo(() => {
    const {height = 0, width = 0} = elementSize;
    const {height: anchorElHeight = 0, width: anchorElWidth = 0} = anchorElSize;
    const translatedValue = {
      x: 0,
      y: 0
    }

    // Process X Position
    switch (popperPosition) {
      case HOTSPOT_POSITIONS.TOP:
      case HOTSPOT_POSITIONS.BOTTOM: {
        translatedValue.x = (anchorElWidth / 2) - (width / 2);
        break;
      }
      case HOTSPOT_POSITIONS.TOP_RIGHT:
      case HOTSPOT_POSITIONS.RIGHT:
      case HOTSPOT_POSITIONS.BOTTOM_RIGHT: {
        translatedValue.x = anchorElWidth;
        break;
      }
      case HOTSPOT_POSITIONS.BOTTOM_LEFT:
      case HOTSPOT_POSITIONS.LEFT:
      case HOTSPOT_POSITIONS.TOP_LEFT: {
        translatedValue.x = -1 * width;
        break;
      }
      default: {
        console.error('Invalid Hotspot Position')
      }
    }

    // Process Y Position
    switch (popperPosition) {
      case HOTSPOT_POSITIONS.TOP:
      case HOTSPOT_POSITIONS.TOP_RIGHT:
      case HOTSPOT_POSITIONS.TOP_LEFT: {
        translatedValue.y = -1 * height;
        break;
      }
      case HOTSPOT_POSITIONS.BOTTOM:
      case HOTSPOT_POSITIONS.BOTTOM_LEFT:
      case HOTSPOT_POSITIONS.BOTTOM_RIGHT: {
        translatedValue.y = anchorElHeight;
        break;
      }
      case HOTSPOT_POSITIONS.RIGHT:
      case HOTSPOT_POSITIONS.LEFT: {
        translatedValue.y = (anchorElHeight / 2) - (height / 2);
        break;
      }
      default: {
        console.error('Invalid Hotspot Position')
      }
    }

    return translatedValue;
  }, [elementSize, anchorElSize, popperPosition]);

  const containerStyle = useMemo(() => {
    const {x, y, z = 0} = popperTranslate;
    return {transform: `translate3d(${x}px, ${y}px, ${z}px)`};
  }, [popperTranslate]);

  if (!open || !anchorEl) {
    return <React.Fragment/>;
  }

  return <div ref={setTooltipRef} className={style.container} style={containerStyle}>
    {children}
  </div>
}
