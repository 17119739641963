const resourcesCache = new Map();
const getCachedScript = (url) => {
  url = ensureUrlScheme(url);
  const id = btoa(url);
  if(resourcesCache.has(id)) {
    if(resourcesCache.get(id).state !== null) {
      return new Promise((resolve, reject) => {
        if(resourcesCache.get(id).state) {
          resolve();
        } else {
          reject();
        }
      });
    }
    const promise = new Promise((resolve, reject) => {
      resourcesCache.get(id).all.push({resolve, reject});
    });
    return promise;
  } else {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      const onSuccess = () => {
        resourcesCache.get(id).state = true;
        resourcesCache.get(id).all.forEach(({resolve}) => resolve());
      }
      const onError = () => {
        resourcesCache.get(id).state = false;
        resourcesCache.get(id).all.forEach(({reject}) => reject(new Error(`Error Loading Script: ${url}`)))
      }
      script.onload = onSuccess;
      script.onerror = onError;
      script.async = true;
      script.src = url;
      resourcesCache.set(id, {state: null, all : [{resolve, reject}]});
      document.body.appendChild(script);
    });
  }
};

export default getCachedScript;


export const fetchCSS = (url) => {
  return new Promise((resolve, reject) => {
    url = ensureUrlScheme(url);
    const id = btoa(url);
    if (!document.getElementById(id)) {
      const head = document.getElementsByTagName('head')[0];
      const link = document.createElement('link');
      link.id = id;
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.onload = function () {
        resolve();
      };
      link.onerror = function () {
        reject(new Error(`Error Loading Script: ${url}`));
      };
      link.href = url;
      link.media = 'all';
      head.appendChild(link);
    } else {
      resolve();
    }
  })
}

export const ensureUrlScheme = (url) => {
  if(url.indexOf("http") === 0) {
    return url;
  }

  const cUrl = window.location.href;
  if(cUrl.indexOf("https") === 0) {
    return `https:${url}`;
  } else {
    return `http:${url}`;
  }
}
