import {CREATIVE_MODE} from 'utils/constants';

// it modifies the settings based on which iframe it is for studio preview
export default function useEditedSettings(
  {
    settings,
    isInScreenShooterEmulation,
    engagementItemId,
    isCreativeStudioPreviewMode,
    isODCEngagementPreviewEnabled,
    forHotspot,
    selectedHotspot
  }
) {
  if (!settings) {
    return;
  }

  if (isInScreenShooterEmulation && engagementItemId) {
    const overlay = (settings.creativeCardSettings || []).find(creativeCardSetting => creativeCardSetting.creativeCard.id === engagementItemId || creativeCardSetting.id === engagementItemId); // TODO: merge_de_and_eeo remove the check post deployment.
    const creativeType = settings.creativeType
    let isFullMode = true;
    if (creativeType === CREATIVE_MODE.VIDEO) {
      isFullMode = !!overlay.finaleEnabled
    }
    return {
      ...settings,
      showPlayer: !isFullMode,
      selectedOverlay: overlay?.overlayIndex,
      creativeCardSettings: overlay ? [{
        ...overlay,
        inVideoEngagementEnabled: !isFullMode,
        finaleEnabled: isFullMode
      }] : []
    }
  }

  if (!isCreativeStudioPreviewMode) {
    return settings
  }

  if (!isODCEngagementPreviewEnabled) {
    return {
      ...settings,
      hotspots: (settings.hotspots || []),
      creativeCardSettings: (settings.creativeCardSettings || []).map(it => ({
        ...it,
        enableEngagementExperienceOnPause: false,
      }))
    }
  }

  if (isCreativeStudioPreviewMode && !forHotspot) {
    return {
      ...settings,
      showPlayer: false,
      hotspots: [],
      showLogo: false,
      callToAction: {visible: false},
      creativeCardSettings: (settings.creativeCardSettings || [])
    }
  }

  if (isCreativeStudioPreviewMode && forHotspot) {
    let creativeCardSettings
    if (settings.creativeType === CREATIVE_MODE.DISPLAY) {
      creativeCardSettings = (settings.creativeCardSettings || []).map(it => ({
        ...it,
        creativeCard: {
          ...it.creativeCard,
          hotspots: []
        }
      }))
    } else {
      creativeCardSettings =  settings.hotspots && settings.hotspots.length > 0 ?
          [{
            creativeCard: {
               ...(settings.hotspots[selectedHotspot] ? settings.hotspots[selectedHotspot] : {})
            },
            finaleEnabled: true
          }] : []
    }
    return {
      ...settings,
      showPlayer: false,
      hotspots: [],
      showLogo: false,
      callToAction: {visible: false},
      creativeCardSettings
    }
  }


  return settings;
}
