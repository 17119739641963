import React from 'react';
import classnames from 'classnames';

import ScrubIcon from 'elements/icons/scrub-icon';
import {PLAYER_STATE, SCRUB_ICONS} from 'utils/constants';

import stylePlayPause from './style.module.css';
import style from '../style.module.scss';
import {useConfig} from "utils/ConfigProvider";

function PlayPause({playerState, handleOnClick, handleReplay, isContentFlowCreative}) {
  const {environment} = useConfig();

  const isPauseVisible = playerState === PLAYER_STATE.PLAYING
  const isCompleted = playerState === PLAYER_STATE.COMPLETED
  const isPlayVisible = !isPauseVisible
  const contentVideoCompleted = isCompleted && isContentFlowCreative;
  return (
    <div className={classnames(style.scrubElementsContainer)}>
      <div className={classnames(style.iconContainer, stylePlayPause.playPauseContainer)}>
        <div className={classnames(stylePlayPause.play, isPlayVisible ? stylePlayPause.show :  stylePlayPause.hide)}>
          {!contentVideoCompleted && <ScrubIcon variant={SCRUB_ICONS.PLAY}   onClick={ () => isCompleted && !environment.isCreativeStudioPreviewMode() ? '' : handleOnClick()}/>}
          {contentVideoCompleted && <ScrubIcon variant={SCRUB_ICONS.REPLAY}  onClick={handleReplay}/>}
        </div>
        <div className={classnames(stylePlayPause.pause, isPauseVisible ? stylePlayPause.show :  stylePlayPause.hide)}>
            <ScrubIcon variant={SCRUB_ICONS.PAUSE}   onClick={ () => handleOnClick()}/>
        </div>
      </div>
    </div>
  )
}

export default PlayPause;
