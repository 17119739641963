// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".popper--2c6Ag{padding:calc(var(--rootFontSize) * 0.5);margin:4.5px;border-radius:calc(var(--rootFontSize) * 0.25);display:flex;justify-content:center;align-items:center;position:relative;-webkit-user-select:none;-ms-user-select:none;user-select:none}.popperCorner--26JX7{margin:.75px}.image--2R9Lg{height:100%;width:100%;object-fit:contain;object-position:center;-webkit-user-select:none;-ms-user-select:none;user-select:none}.noImage--3zOqf{display:flex;justify-content:center;align-items:center;overflow:hidden;text-overflow:ellipsis;-webkit-user-select:none;-ms-user-select:none;user-select:none}.iconContainer--2oGVd{height:100%;width:100%;display:flex;justify-content:center;align-items:center}.icon--3nvsN{transition:font-size 50ms linear}.resizable--Q9DQr{text-align:center}.arrowBase--32BcS{opacity:0}.arrow--1aH3f{opacity:1;position:absolute;width:3px;height:3px;border:3px solid rgba(0,0,0,0)}.arrowRight--2NLNQ{left:-6px}.arrowLeft--215J1{transform:rotate(180deg);right:-6px}.arrowTop--1K7eB{transform:rotate(270deg);bottom:-6px}.arrowBottom--3lAAP{transform:rotate(90deg);top:-6px}", ""]);
// Exports
exports.locals = {
	"popper": "popper--2c6Ag",
	"popperCorner": "popperCorner--26JX7",
	"image": "image--2R9Lg",
	"noImage": "noImage--3zOqf image--2R9Lg",
	"iconContainer": "iconContainer--2oGVd",
	"icon": "icon--3nvsN",
	"resizable": "resizable--Q9DQr",
	"arrowBase": "arrowBase--32BcS",
	"arrow": "arrow--1aH3f",
	"arrowRight": "arrowRight--2NLNQ arrow--1aH3f",
	"arrowLeft": "arrowLeft--215J1 arrow--1aH3f",
	"arrowTop": "arrowTop--1K7eB arrow--1aH3f",
	"arrowBottom": "arrowBottom--3lAAP arrow--1aH3f"
};
module.exports = exports;
