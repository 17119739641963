// (mean) radius of Earth (meters)
const R = 6378137;
const PI_360 = Math.PI / 360;
const MILES_IN_METER = 0.00062137;

export default class MathHelper {

  static getDistanceInMilesBetweenTwoPoints(point1, point2) {
    const metersFromUserLocation = MathHelper.distance(point1, point2);

    // mi = m * 0.00062137
    return metersFromUserLocation * MILES_IN_METER;
  }

  static distance(a, b) {

    const cLat = Math.cos((a.lat + b.lat) * PI_360);
    const dLat = (b.lat - a.lat) * PI_360;
    const dLon = (b.lng - a.lng) * PI_360;

    const f = dLat * dLat + cLat * cLat * dLon * dLon;
    const c = 2 * Math.atan2(Math.sqrt(f), Math.sqrt(Math.abs(1 - f)));

    return R * c;
  }
}
