// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".videoSlot--1Ojao{height:100%;width:100%;position:absolute;z-index:0}", ""]);
// Exports
exports.locals = {
	"videoSlot": "videoSlot--1Ojao"
};
module.exports = exports;
