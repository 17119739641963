import React, {useMemo} from 'react';
import {AutoSize} from "../autosize";
import {LIKERT_SUBMIT_BUTTON_TYPE} from "../../utils/constants";
import style from "common/styles/engagement-likert-survey.module.scss";
import FontAndColorHelper from "../../utils/fontAndColorHelper";
import classnames from "classnames";
import {SubmitCheckmark} from "../submitCheckmark";

export function SubmitButton(props) {
  const {
    onClick,
    submitEnabled,
    submitButtonText,
    submitFontSettings,
    submitButtonColor,
    submitButtonType,
    iconColor,
    submitClassName,
    noPointer
  } = props;

  const {
    submitButtonStyle,
    checkmarkColor,
    checkmarkBackgroundColor
  } = useMemo(() => {
    const submitFontWeightAndSize = FontAndColorHelper.getFontWeightAndSize(submitFontSettings);
    const fontStyle = FontAndColorHelper.getFontAndColor(submitFontSettings);
    return {
      submitButtonStyle: {
        backgroundColor: submitButtonColor,
        fontFamily: fontStyle.font,
        color: fontStyle.color,
        ...submitFontWeightAndSize && submitFontWeightAndSize.fontWeight && {fontWeight: submitFontWeightAndSize.fontWeight},
        ...noPointer && {cursor: 'default'}
      },
      checkmarkColor: iconColor,
      checkmarkBackgroundColor: submitButtonColor,
    };
  }, [iconColor, submitButtonColor, submitFontSettings, noPointer]);

  if (!submitEnabled) {
    return <React.Fragment/>;
  }

  switch (submitButtonType) {
    case LIKERT_SUBMIT_BUTTON_TYPE.BUTTON: {
      return <div
        className={submitClassName}
        onClick={onClick}
        style={submitButtonStyle}
        data-testid={'likert-submit'}
      >
        <AutoSize isMulti={true}>{submitButtonText}</AutoSize>
      </div>;
    }
    case LIKERT_SUBMIT_BUTTON_TYPE.CHECKMARK: {
      return <div
        className={classnames(submitClassName, style.checkboxSubmit)}
        onClick={onClick}
        data-testid={'likert-submit'}
      >
        <SubmitCheckmark
          checkmarkColor={checkmarkColor}
          checkmarkBackgroundColor={checkmarkBackgroundColor}
        />
      </div>;
    }
    default: {
      console.error('Invalid Submit Button Type');
      return <React.Fragment/>;
    }
  }
}
