import {useEffect, useState} from "react";
import {captureException} from '@sentry/browser';
import {constants, helpers, urlHelper, CustomError} from "@phoenix/core";
import config from "../../local-config";
import Environment from 'utils/environment';
import LoggerHelper from '../utils/loggerHelper';
import ErrorHelper from '../utils/errorHelper';
import RuntimeProperties from '../utils/runtimeProperties';
import MessageHelper from "utils/messageHelper";

const {LOG_DETAILS_TYPE, ErrorType, CREATIVE_MODE, GEOGRAPHIC_RESTRICTION_ERROR_MESSAGE} = constants;

const logger = LoggerHelper.instance

export default function useSettings({isExplorer, creativeId, isDisplay, ed, isShareLandingPage}) {
  const [settings, set] = useState(undefined);
  const [errorState, setErrorState] = useState(undefined);

  useEffect(() => {
    if (!settings) {
      logger.captureAssetLoadingTime(LOG_DETAILS_TYPE.APP_COMPONENT_MOUNT, true)
      logger.captureAssetLoadingTime(LOG_DETAILS_TYPE.SETTINGS_FETCH, false)
      fetchSettings({creativeId, ed, isExplorer, isShareLandingPage, set, setErrorState, isDisplay})
    }
  }, [isExplorer, creativeId, ed, isShareLandingPage, settings, isDisplay]);

  return [settings, set, errorState];
}
const fetchSettings = ({creativeId, ed, isExplorer, isShareLandingPage, set, setErrorState, isDisplay}) => {
  const url = getUrl({isExplorer, creativeId, ed, isShareLandingPage});
  logger.captureAssetLoadingTime(LOG_DETAILS_TYPE.SETTINGS_API_RESPONSE, false);
  fetch(url)
    .then(response => {
      logger.captureAssetLoadingTime(LOG_DETAILS_TYPE.SETTINGS_API_RESPONSE, true);
      if (!response.ok) {
        throw response
      }
      return response.json();
    })
    .then(settings => {
      // OC-598: player settings for cid = DRAFT always returns a shell video creative, this can cause excessive registering/unregsistering of the post messasge listener so overriding here
      set({
        ...settings, 
        ...(isDisplay && Environment.isDraft() && {creativeType: CREATIVE_MODE.DISPLAY})
      });
      logger.captureAssetLoadingTime(LOG_DETAILS_TYPE.SETTINGS_FETCH, true)
    })
    .catch(e => {
      logger.error('Error in fetching settings for the creative ::', creativeId, e.status, e.statusText, e);
      const errorType = ErrorHelper.getSettingsErrorCode(e);
      if(Environment.inHeavyAdsEmulation()) {
        MessageHelper.instance.sendToVg({code:'onFetchSettingsError'});
      }
      const wafBlocked = helpers.getResponseHeader(e.headers, 'vg-waf-blocked');
      if (wafBlocked) {
        setErrorState(GEOGRAPHIC_RESTRICTION_ERROR_MESSAGE);
      }
      captureException(new CustomError(errorType), {extra: {errorType}})
    });
}

// get url params
const getUrl = ({creativeId, ed, isExplorer = false, isShareLandingPage = false}) => {
  let url = `${config.vgpServerUrl}/player/creativeSettings?cid=${creativeId}`;

  if (ed) {
    url += `&ed=${urlHelper.fixPlusEncoding(ed)}`;
  } else {
    const urlComponents = urlHelper.getUrlComponents(window.location.href);
    const queryStrings = urlComponents.params;
    if (queryStrings) {
      url = appendDSPMacros(url, queryStrings);
    }
  }
  if (isExplorer === true) {
    url += '&isVideoExplorerCreative=true';
  }
  if (Environment.inHeavyAdsEmulation()) {
    url += '&inODCHeavyAdEmulation=true';
  }
  if (isShareLandingPage === true) {
    url += '&isShareLandingPage=true';
  }

  return url;
}

const appendDSPMacros = (url, queryStrings) => {
  Object.keys(queryStrings).forEach((queryParam) => {
    if (queryParam !== 'campaignId') {
      url += `&${queryParam}=${queryStrings[queryParam]}`;
    }
  });
  return url;
}
