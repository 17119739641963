export const MAX_PERCENTAGE = 100
export const PERCENTAGES_TO_IGNORE = [25, 50, 75] // These percentages would be fired from quartile events.
export const generateVideoTimeToPercentages = (duration) => {
  const videoTimeToPercentageMapping = new Map();
  let lastPercentage = 0;
  if (duration < MAX_PERCENTAGE) {
    for (let time = 1; time <= duration; time++) {
      const percentage = Math.round(time / duration * MAX_PERCENTAGE)
      videoTimeToPercentageMapping.set(time, getRange(lastPercentage, percentage));
      lastPercentage = percentage;
    }
  } else {
    for (let perc = 1; perc <= MAX_PERCENTAGE; perc++) {
      if (PERCENTAGES_TO_IGNORE.includes(perc)) {
        continue;
      }
      const time = Math.round(perc / MAX_PERCENTAGE * duration)
      videoTimeToPercentageMapping.set(time, perc.toString());
    }
  }
  return videoTimeToPercentageMapping;
}

export const getRange = (low, end) => {
  const arr = [];
  while(low < end) {
    const no = low + 1
    if (!PERCENTAGES_TO_IGNORE.includes(no)) {
      arr.push(no);
    }
    low++;
  }
  return arr.join(",");
}