// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".customBackgroundImage--18kqp{background-repeat:no-repeat;background-position:center;background-size:contain;width:100%;height:100%;display:block}.customImage--1jaze{height:100%;width:auto;object-fit:contain;display:block}.customImage--1jaze:hover{transform:scale(1.1);transform-style:preserve-3d;-webkit-backface-visibility:hidden;backface-visibility:hidden}", ""]);
// Exports
exports.locals = {
	"customBackgroundImage": "customBackgroundImage--18kqp",
	"customImage": "customImage--1jaze"
};
module.exports = exports;
