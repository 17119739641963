import {useCallback, useRef, useState} from 'react';
import {ensureMarkerRatio, hotspotFieldDecimalScale, isNotEmpty, removeGrowEffect} from "utils/hotspotInteractionsHelper";
import {roundToDecimalPlace} from "utils/helpers";
import {useConfig} from "utils/ConfigProvider";

const MIN_SIZE = 15;

const MOVEABLE_DEFAULT_OPTIONS = {
  draggable: true,
  bounds: {'left': 0, 'top': 0, 'right': 0, 'bottom': 0, 'position': 'css'},
  edgeDraggable: false,
  snappable: true,
  resizable: true,
  snapDirections: {'top': true, 'left': true, 'bottom': true, 'right': true, 'center': true, 'middle': true},
  elementSnapDirections: {'top': true, 'left': true, 'bottom': true, 'right': true, 'center': true, 'middle': true},
  isDisplaySnapDigit: false,
  isDisplayInnerSnapDigit: false,
  throttleDrag: 0,
  throttleResize: 0,
  snapThreshold: 5
}

const attachDragHandler = (
  {
    moveable,
    onDrag,
    onDragStart,
    onDragEnd
  }
) => {
  if (!moveable || !onDrag) {
    return;
  }

  const onTargetDrag = ({target, left, top}) => {
    target.style.left = `${left}px`;
    target.style.top = `${top}px`;
  }

  const onTargetDragEnd = ({target, lastEvent}) => {
    if (onDragEnd) {
      onDragEnd();
    }

    if (!lastEvent) {
      return;
    }

    const {left, top} = lastEvent;

    onDrag({top, left, target});
  }

  moveable
    // Single Node Events
    .on('dragStart', onDragStart)
    .on('drag', onTargetDrag)
    .on('dragEnd', onTargetDragEnd)
    // Group Node Events
    .on('dragGroupStart', onDragStart)
    .on('dragGroup', ({events}) => {
      for (const event of events) {
        onTargetDrag(event);
      }
    })
    .on('dragGroupEnd', ({events}) => {
      if (events) {
        for (const event of events) {
          onTargetDragEnd(event);
        }
      }
    });
}

const attachResizeHandler = (
  {
    moveable,
    onResize,
    onResizeStart,
    onResizeEnd,
    markerRatio,
    videoComponentSize,
    modifyPosition = true
  }
) => {
  if (!moveable || !onResize) {
    return;
  }

  const onTargetResize = ({target, width, height, drag, direction, dist}) => {
    target.style.width = `${Math.max(width, MIN_SIZE)}px`;
    target.style.height = `${Math.max(height, MIN_SIZE)}px`;
    if (modifyPosition) {
      target.style.left = `${drag.left + (direction[0] * dist[0])}px`;
      target.style.top = `${drag.top + (direction[1] * dist[1])}px`;
    }
  }

  const onTargetResizeEnd = ({lastEvent, target}) => {
    if (onResizeEnd) {
      onResizeEnd();
    }
    if (!lastEvent) {
      return;
    }

    const computedStyles = window.getComputedStyle(target);

    const currentValues = {
      top: parseFloat(computedStyles.getPropertyValue('top')),
      left: parseFloat(computedStyles.getPropertyValue('left')),
      height: parseFloat(computedStyles.getPropertyValue('height')),
      width: parseFloat(computedStyles.getPropertyValue('width')),
    }

    const {
      width,
      height,
      offsetX,
      offsetY
    } = ensureMarkerRatio(
      currentValues.width,
      currentValues.height,
      markerRatio,
      videoComponentSize.width,
      videoComponentSize.height,
      currentValues.left,
      currentValues.top
    );

    onResize({
      top: offsetY,
      left: offsetX,
      height,
      width,
      target
    });
  }

  moveable
    // Single Node Events
    .on('resizeStart', onResizeStart)
    .on("resize", onTargetResize)
    .on('resizeEnd', onTargetResizeEnd)
    // Group Node Events
    .on('resizeGroupStart', onResizeStart)
    .on('resizeGroup', ({events}) => {
      for (const event of events) {
        onTargetResize(event);
      }
    })
    .on('resizeGroupEnd', ({events}) => {
      if (events) {
        for (const event of events) {
          onTargetResizeEnd(event);
        }
      }
    });
}

export const useHotspotInteractionHandler = (
  {
    cssSelector,
    hotspotPopperCSSSelector,
    snappingHotspotCSSSelector,
    updateHotspot,
    clearSelectedHotspots
  } = {}
) => {
  const moveableRef = useRef(null);
  const moveablePopperRef = useRef(null);
  const [hotspotContainer, setHotspotContainer] = useState(null);
  const [moveableIterator, setMoveableIterator] = useState(0);

  const {appRef} = useConfig();

  const incrementMoveableIterator = useCallback(() => {
    setMoveableIterator(moveableIterator + 1)
  }, [moveableIterator]);

  const updateHotspotConfig = useCallback((
    {
      positionX,
      positionY,
      width,
      height,
      hotspotTextWidth,
      hotspotTextHeight,
      target
    }
  ) => {
    updateHotspot({
      updatedValues: {
        ...isNotEmpty(positionX) ? {positionX: roundToDecimalPlace(positionX, hotspotFieldDecimalScale)} : {},
        ...isNotEmpty(positionY) ? {positionY: roundToDecimalPlace(positionY, hotspotFieldDecimalScale)} : {},
        ...isNotEmpty(width) ? {width: roundToDecimalPlace(width, hotspotFieldDecimalScale)} : {},
        ...isNotEmpty(height) ? {height: roundToDecimalPlace(height, hotspotFieldDecimalScale)} : {},
        ...isNotEmpty(hotspotTextWidth) ? {hotspotTextWidth: roundToDecimalPlace(hotspotTextWidth, hotspotFieldDecimalScale)} : {},
        ...isNotEmpty(hotspotTextHeight) ? {hotspotTextHeight: roundToDecimalPlace(hotspotTextHeight, hotspotFieldDecimalScale)} : {},
      },
      config: {...target.dataset}
    })
  }, [updateHotspot]);

  const initInteractions = useCallback((
    {
      disposeHotspotMoveable,
      disposePopperMoveable,
      moduleRef,
      attachClearHandler
    }
  ) => {
    if (!hotspotContainer) {
      return;
    }

    const videoComponentSize = hotspotContainer.getBoundingClientRect();
    const moveableNodes = [...document.querySelectorAll(cssSelector)]
      .map((node) => (removeGrowEffect(node))) // moveable wraps around grown hotspot if it has grow effect, remove it from the node

    if (moveableNodes.length > 1) {
      attachClearHandler();
    }

    // Can only use Ratio when 1 Node is selected
    const markerRatio = moveableNodes.length === 1 ? Number(moveableNodes[0].getAttribute('data-ratio')) : null;

    moveableRef.current = new moduleRef.current(hotspotContainer, {
      ...MOVEABLE_DEFAULT_OPTIONS,
      target: moveableNodes,
      keepRatio: Boolean(markerRatio),
      elementGuidelines: [...document.querySelectorAll(snappingHotspotCSSSelector)],
      verticalGuidelines: [0, videoComponentSize.width / 2, videoComponentSize.width],
      horizontalGuidelines: [0, videoComponentSize.height / 2, videoComponentSize.height],
    });

    attachDragHandler({
      moveable: moveableRef.current,
      onDrag: ({top, left, target}) => {
        updateHotspotConfig({
          positionX: left / videoComponentSize.width * 100,
          positionY: top / videoComponentSize.height * 100,
          target
        });
      },
      onDragStart: ({target}) => {
        removeGrowEffect(target) // target has original grow classes even though we removed them before setting the target on moveableRef. remove them again.
        disposePopperMoveable()
      },
      onDragEnd: incrementMoveableIterator,
      clearSelectedHotspots
    });

    attachResizeHandler({
      moveable: moveableRef.current,
      onResize: ({top, left, height, width, target}) => {
        updateHotspotConfig({
          positionX: left / videoComponentSize.width * 100,
          positionY: top / videoComponentSize.height * 100,
          width: width / videoComponentSize.width * 100,
          height: height / videoComponentSize.height * 100,
          target
        });
      },
      markerRatio,
      videoComponentSize,
      onResizeStart: ({target}) => {
        removeGrowEffect(target) // target has original grow classes even though we removed them before setting the target on moveableRef. remove them again.
        disposePopperMoveable()
      },
      onResizeEnd: incrementMoveableIterator,
      clearSelectedHotspots
    });

    // Only added Handler to Popper when 1 node is selected.
    if (moveableNodes.length === 1) {
      const moveablePopperNode = document.querySelector(`${cssSelector} ${hotspotPopperCSSSelector}`);
      if (moveablePopperNode) {
        moveablePopperRef.current = new moduleRef.current(hotspotContainer, {
          ...MOVEABLE_DEFAULT_OPTIONS,
          target: moveablePopperNode,
          elementGuidelines: [...document.querySelectorAll(snappingHotspotCSSSelector)],
          verticalGuidelines: [0, videoComponentSize.width / 2, videoComponentSize.width],
          horizontalGuidelines: [0, videoComponentSize.height / 2, videoComponentSize.height]
        });

        attachResizeHandler({
          moveable: moveablePopperRef.current,
          onResize: ({height, width}) => {
            updateHotspotConfig({
              hotspotTextWidth: width / videoComponentSize.width * 100,
              hotspotTextHeight: height / videoComponentSize.height * 100,
              target: moveableNodes[0]
            });
          },
          videoComponentSize,
          modifyPosition: false,
          onResizeStart: disposeHotspotMoveable,
          onResizeEnd: incrementMoveableIterator,
          clearSelectedHotspots
        });
      }
    }
  }, [clearSelectedHotspots, cssSelector, hotspotContainer, hotspotPopperCSSSelector, incrementMoveableIterator, snappingHotspotCSSSelector, updateHotspotConfig]);

  const interactWithElement = useCallback(({moduleRef}) => {
    const disposeHotspotMoveable = () => {
      if (moveableRef.current) {
        moveableRef.current.destroy();
        moveableRef.current = null;
      }
    }

    const disposePopperMoveable = () => {
      if (moveablePopperRef.current) {
        moveablePopperRef.current.destroy();
        moveablePopperRef.current = null;
      }
    }

    const escapeHandler = (e) => {
      if (e.key === 'Escape') {
        clearSelectedHotspots();
      }
    }

    const handleClickOutside = (e) => {
      if (
        (appRef && hotspotContainer) &&
        // If the click is inside Phoenix, but not outside
        appRef.contains(e.target) &&
        !hotspotContainer.contains(e.target)
      ) {
        clearSelectedHotspots();
      }
    }

    const detachClearHandler = () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('keydown', escapeHandler);
    }

    const attachClearHandler = () => {
      detachClearHandler();
      document.addEventListener('click', handleClickOutside, true);
      document.addEventListener('keydown', escapeHandler, {once: true});
    }

    const disposeMoveable = () => {
      disposeHotspotMoveable();
      disposePopperMoveable();
      detachClearHandler();
    }

    disposeMoveable();

    initInteractions({disposeHotspotMoveable, disposePopperMoveable, moduleRef, attachClearHandler});

    return disposeMoveable;
  }, [appRef, clearSelectedHotspots, hotspotContainer, initInteractions]);

  return {
    interactWithElement,
    setHotspotContainer,
  }
}
