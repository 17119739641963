import React from 'react';
import classnames from 'classnames';

import style from './style.module.scss';

function ScrubIcon({variant, color = 'white', children, scaleOnHover = true, onClick, ...rest}) {
  return (
    <>
      <div style={{color}} className={classnames(style.icon, scaleOnHover ? style.scale: '', style[variant])}  {...rest} onClick={(e) => onClick(e, variant)}>{children}</div>
    </>
  )
}

export default ScrubIcon;
