import Environment from './environment';
import {constants, urlHelper} from "@phoenix/core";

const {LOG_DETAILS_TYPE} = constants;

const singleton = Symbol();
const singletonEnforcer = Symbol();

/**
 * Sentry error breadcrumb would show up the logs that happened after sentry got initialized.
 * This logger stores the logs in local array and that array can be printed any time using method printAllLogs.
 * This has been introduced to debug all assets loading time.
 * Use Logger.log or logger.error method only for those logs which you feel might be logged before sentry initialization.
 */
class LoggerHelper {

  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw 'Cannot construct singleton';
    }

    this.logs = [];
    this.assetLoadingTime = {}
    this.vpaidEnabled = Environment.isVPAIDEnabled();
    if (this.vpaidEnabled) {
      const ibtMillis = urlHelper.urlParam('ibtMillis');
      if (ibtMillis) {
        this.log(`Time when phoenix started loading ${new Date(parseInt(ibtMillis, 10))}`)
      }
      const loadTime = this.getElapsedMilliSeconds(ibtMillis)
      this.captureAssetLoadingTime(LOG_DETAILS_TYPE.PLAYER_JS_LOAD, true, ibtMillis);
      this.captureAssetLoadingTime(LOG_DETAILS_TYPE.POLYFILL_LOAD, false);
      this.log(`Time to load JS :: ${loadTime}ms`)
    }
  }

  captureAssetLoadingTime(type, isFinished, overriddenStartTime) {
    try {
      if (this.assetLoadingTime[type]) {
        if (!isFinished) {
          return; // Prevent sending duplicate start log.
        }
        if (this.assetLoadingTime[type].endTime && isFinished) {
          return; // Prevent sending duplicate end log
        }
      }
      this.log(`${type} ${isFinished ? ' ended' : ' started'}`)
      this.assetLoadingTime[type] = this.assetLoadingTime[type] || {[type]: {}}
      this.assetLoadingTime[type].startTime = this.assetLoadingTime[type].startTime || overriddenStartTime || Date.now();
      this.assetLoadingTime[type].endTime = this.assetLoadingTime[type].endTime || (isFinished ? Date.now() : null);
    } catch (e) {
      console.error('Exception in captureAssetLoadingTime', e);
    }
  }

  logMessage(isError, ...args) {
    const timestamp = new Date();
    if (isError) {
      console.error(...args);
    } else {
      console.log(...args);
    }

    try {
      this.logs.push({
        timestamp,
        msg: args
      });
    } catch (e) {
      console.log('Error adding Log');
    }
  }
  error(...args) {
    this.logMessage(true, ...args)
  }

  log(...args) {
    this.logMessage(false, ...args)
  }

  printAllLogs() {
    if (this.vpaidEnabled) {
      console.log(JSON.stringify(this.logs));
    }
  }

  getElapsedMilliSeconds(initialTime) {
    if (!initialTime) {
      return 0;
    }
    return Date.now() - initialTime;
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new LoggerHelper(singletonEnforcer);
    }
    return this[singleton];
  }

  static destroyInstance() {
    delete this[singleton];
  }


}

export default LoggerHelper;
