/* global twttr, FB */
import {useEffect} from 'react';

import {openUrl} from "utils/url-helper";
import {DEVICE_TYPE} from "utils/constants";
import FBLoader from "utils/fbSdkLoader";
import getCachedScript from "utils/resource-loader";
import Functions from "utils/functions";

const encodedEmailTextMessage = encodeURIComponent('\n\n Watch the video here: ');
const SHARE_HANDLERS = {
  'facebook': (img, title, text) => 'https://www.facebook.com/sharer/sharer.php?u=',
  'googleplus': (img, title, text) => 'https://plus.google.com/share?url=',
  'twitter': (img, title, text) => `http://twitter.com/intent/tweet?source=ViralGains&text=${text}%20`,
  'tumblr': (img, title, text) => `http://www.tumblr.com/share/link?description=${text}&name=${title}&url=`,
  'linkedin': (img, title, text) => 'https://www.linkedin.com/shareArticle?source=Viral%20Gains%20Player&url=',
  'pinterest': (img, title, text) => `http://pinterest.com/pin/create/button/?description=${text}&media=${img}&url=`,
  'stumbleupon': (img, title, text) => 'http://www.stumbleupon.com/submit?url=',
  'reddit': (img, title, text) => 'http://www.reddit.com/submit?url=',
  'email': (img, title, text) => `mailto:?subject=&body='${text}${encodedEmailTextMessage}`,
  'sms': (img, title, text, deviceType) => {
    if (deviceType === DEVICE_TYPE.NON_IOS_MOBILE_DEVICE) {
      return `sms:?body=${text}`;
    } else if (deviceType === DEVICE_TYPE.IOS_MOBILE_DEVICE) {
      return `sms:&body=${text}`;
    } else {
      return '';
    }
  }
};

export default function useSocialClickHandler(
  {
    shortUrl,
    header,
    description,
    sharePostImgUrl,
    videoTitle,
  },
  fbApiKey,
  isMobileDevice,
  isDraft,
  deviceType,
  appRootId,
) {
  const initTwitter = () => {
    getCachedScript('//platform.twitter.com/widgets.js').then((e) => {
      if (window.twttr) {
        window.twttr.ready((twttr) => {
          const handlers = twttr.events._handlers || {};
          handlers.tweet = [];

          twttr.events.bind('tweet', () => {
            this.captureShareCallback();
          });
        });
      } else {
        console.error("twitter widget load error - window.twtter undefined");
      }
    }).catch(e => console.log('Unable to load twitter lib'));
  }

  const doFb = (url) => {
    if (window.FB == null) {
      return;
    }

    FB.ui({
      method: 'share',
      href: url
    }, (response) => {
      if (response && response.error_code) {
        console.error('error in sharing on fb', response);
      }
    });
  }

  const doCopy = (e, shortUrl) => {
    Functions.copyTextToClipboard(shortUrl, appRootId);
  }


  const url = (variant, shortUrl, img, title, text) => {

    const shareBaseObj = SHARE_HANDLERS[variant];

    const shareBase = shareBaseObj ? shareBaseObj(img, title, text, deviceType) : '';

    let finalShareURL;

    if (variant === 'sms') {
      finalShareURL = shareBase + shortUrl;
    } else {
      finalShareURL = shareBase + encodeURIComponent(shortUrl);
    }

    if (variant === 'email' && !isMobileDevice) {
      finalShareURL = '#';
    }

    if (variant === 'copyandpasteshareurl') {
      finalShareURL = '#';
    }


    return finalShareURL;
  }

  const getText = (variant, header = '', description = '') => {
    let text = header;

    if (variant !== 'twitter' && description) {
      text += ' - ';
      text += description;
    }
    return encodeURIComponent(text);
  }

  const handleSocialClick = (e, variant) => {
    if (isDraft) {
      return e.preventDefault();
    }

    const urlToNavigateTo = url(variant, shortUrl, sharePostImgUrl, videoTitle, getText(variant, header, description))

    if (variant === 'facebook') {
      return doFb(shortUrl || sharePostImgUrl);
    }

    if (variant === 'copyandpasteshareurl') {
      return doCopy(e, shortUrl);
    }

    if (urlToNavigateTo && urlToNavigateTo !== '#') {
      openUrl(urlToNavigateTo, e.currentTarget.getAttribute('target'));
    }
  }

  const getEmailShareText = () => {
    let displayText = header;
    if (description) {
      displayText += `\n${description}`;
    }
    return encodeURIComponent(displayText);
  }


  useEffect(() => {
    FBLoader.instance.initAndAddListener(fbApiKey);
    initTwitter();
  }, [description, header, sharePostImgUrl, shortUrl, videoTitle, fbApiKey]);

  return [handleSocialClick, getEmailShareText()];
}




