import React, {useCallback, useEffect, useMemo, useRef} from "react";

import connectAutoStartModeChange from "../connectAutoStartModeChange";

import {PLAYER_STATE} from "utils/constants";
import {useConfig} from "utils/ConfigProvider";
import {ensureHttps} from "utils/settingsHelper";
import Functions from '../../../../utils/functions';


function HTMLPlayer({state, autoPlayMode, settings, isMute, onPlayerTimeChange, onPlayerStateChange, setPlayerControlWrapper, onMuteStateChange, onDurationFetch, scrubToHandler, setAutoplayUnMutedError, setImpressionEvent, getThumbnail, setQuartile}) {
  const { environment } = useConfig();

  const {video} = settings;
  const {videoMediaURL, status, videoMediaEncodings} = video;

  const ref = useRef();

  const player = useCallback(() => ref?.current, [ref]);

  useEffect(() => {
    const api = player();
    if(!api) {
      return;
    }

    api.onloadedmetadata = _ => {
      api.duration && onDurationFetch(Math.floor(api.duration));
      onPlayerStateChange(PLAYER_STATE.READY);
    }

    scrubToHandler(() => {
      return (time) => {
        const api = player();
        if(api) {
          api.currentTime = time;
        }
      }
    })

    api.addEventListener('play', _ => {
      setImpressionEvent(true);
      onPlayerStateChange(PLAYER_STATE.PLAYING)
    }, false);
    api.addEventListener('pause', _ => onPlayerStateChange(PLAYER_STATE.PAUSED), false);
    api.addEventListener('timeupdate', _ => {
      onPlayerTimeChange(api.currentTime)
      setQuartile(Functions.getQuarter(api.currentTime, api.duration));
    }, false);
    api.addEventListener('ended', _ => onPlayerStateChange(PLAYER_STATE.COMPLETED), false);
    api.addEventListener('volumechange', event => onMuteStateChange(event.target.muted));
  }, [player, onDurationFetch, onPlayerStateChange, onPlayerTimeChange, onMuteStateChange, scrubToHandler, setImpressionEvent, setQuartile]);


  const wrapper = useMemo(() => ({
    play: _ => {
      if(environment.isCreativeStudioPreviewMode() && state === PLAYER_STATE.COMPLETED) {
        const api = player();
        if(api) {
          api.currentTime = 0;
          api.play();
        }
      } else {
        if (player()) {
          player().play();
        }
      }
    },
    pause: _ => {
      if (player()) {
        player().pause()
      }

      // To mimic jw/vpaid, on pause we need to move out of READY state, but pausing a paused html5 video will not generate a pause event
      if (state === PLAYER_STATE.READY) {
        onPlayerStateChange(PLAYER_STATE.PAUSED);
      }
    },
    mute: m => player() ? player().muted = m : null,
    setVolume: v => player() ? player().volume = v / 100: null
  }), [player, state, onPlayerStateChange, environment]);

  useEffect(() => {
    setPlayerControlWrapper(wrapper)
  }, [setPlayerControlWrapper, wrapper])

  connectAutoStartModeChange(autoPlayMode, state, setAutoplayUnMutedError, wrapper);

  let url = videoMediaURL;
  if (status === 'READY_TO_USE_WITH_ENCODINGS') {
    const sortedEncodingsByDescSize = videoMediaEncodings?.sort((a, b) => (a.size < b.size) ? 1 : -1 );
    if (sortedEncodingsByDescSize && sortedEncodingsByDescSize.length > 0) {
      url = ensureHttps(sortedEncodingsByDescSize[0].url);
    }
  }

  return <React.Fragment>
    <video id="vg-video-slot" playsInline ref={ref} data-testid='html' style={{width: '100%', height: '100%'}} preload="metadata">
      <source src={url} type="video/mp4"/>
    </video>
    {getThumbnail && getThumbnail()}
  </React.Fragment>

}

export default HTMLPlayer;
