import React from 'react';
import {ImageTag} from 'elements/backgroundImage';


function BrandLogoImage({imageSrc, ctaUrlImage, onClick}) {
  return (
        <ImageTag src={imageSrc} onClick={onClick} ctaUrlImage={ctaUrlImage} forceOnClick={true}/>
  )
}

export default BrandLogoImage;
