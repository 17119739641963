import {urlHelper} from "@phoenix/core";

const CLICK_URL_MACROS = ['[%%CLICK_URL_ESC%%]', '[ENCODED_CLICK_REDIRECT]'];

// TODO: we should rename this something like url params to make it clear what this is for.  Ideally we can refactor this to be props we can pass into
//  a context without the awkward class+method structure.
export default class Environment {

  // NOTE: these url param fetches are static/read once, if we need to change this we should bring in react-router

  static isShareLandingPage() {
    return urlHelper.urlParam('autoPlay') === 'true';
  }

  static isEditMode() {
    return urlHelper.urlParam('edit') != null;
  }

  static forHotspot() {
    return urlHelper.urlParam('forHotspot') === 'true';
  }

  static isCreativeStudioPreviewMode() {
    return urlHelper.urlParam('isPreview') === 'true';
  }

  static isTestTimeoutMode() {
    return urlHelper.urlParam('isTestTimeout') === 'true';
  }

  static isDisplayCreative() {
    return urlHelper.urlParam('isDisplay') === 'true';
  }

  static isInCreativeStudioPreviewOrPreviewPage() {
    return Environment.isCreativeStudioPreviewMode() || !Environment.getEd();
  }

  static getClickTrackingURL() {
    const clickURLEnc = urlHelper.urlParam('clickURLEnc');
    return Environment.isValidURLEnc(clickURLEnc) ? decodeURIComponent(clickURLEnc) : null;
  }

  static isValidURLEnc(clickURLEnc) {
    return clickURLEnc && !CLICK_URL_MACROS.includes(clickURLEnc);
  }

  static isVPAIDEnabled() {
    return urlHelper.urlParam('vps') != null;
  }

  static isSurveyEditMode() {
    return !!urlHelper.urlParam('surveyEdit');
  }

  static isShowingOnDashboard() {
    return urlHelper.urlParam('showingOnDashboard') === 'true';
  }

  static isODCEngagementPreviewEnabled() {
    return urlHelper.urlParam('odcEngagementPreview') === 'true';
  }

  static isCreativeStudioEngagementPreviewEnabled() {
    return Environment.isCreativeStudioPreviewMode() && Environment.isODCEngagementPreviewEnabled();
  }

  static isInAdminTool() {
    return Environment.isEditMode() || Environment.isShowingOnDashboard() || Environment.isSurveyEditMode();
  }

  static getAdUnitId() {
    return urlHelper.urlParam('creativeId') || urlHelper.urlParam('campaignId');
  }

  static getEd() {
    return urlHelper.urlParam('ed');
  }

  static getParentUrl() {
    const isInIframe = (parent !== window);
    let parentUrl = null;
    if (isInIframe) {
      parentUrl = document.referrer;
    }
    return parentUrl;
  }

  static getSrcUrl() {
    const url = Environment.getParentUrl();
    return (url == null) ? window.location.href : url;
  }

  static getSrcUrlWithoutQueryParam() {
    const url = Environment.getSrcUrl();
    if (url) {
      return url.split('?')[0];
    }
    return url;
  }

  static getSelectedHotspot() {
    return parseInt(urlHelper.urlParam('selectedHotspot'), 10);
  }

  static getStandaloneSelectedHotspot() {
    // Only used when in Creative Studio
    return -1;
  }

  static inHeavyAdsEmulation() {
    return urlHelper.urlParam('inEmulation') === 'true';
  }

  static isInScreenShooterEmulation() {
    return urlHelper.urlParam('isInScreenShooterEmulation') === 'true';
  }

  static isThirdPartyVendor() {
    return !!urlHelper.urlParam('vendorId');
  }

  static isVGEmbed() {
    return urlHelper.urlParam('vg_embed') === 'true';
  }

  static getEngagementItemId() {
    return urlHelper.urlParam('odcItemId');
  }

  static getCreativeVersion() {
    return urlHelper.urlParam('creativeVersion');
  }

  static isDraft() {
    return this.getAdUnitId() === 'DRAFT';
  }

  static isDebug() {
    return urlHelper.urlParam('debug') === "true";
  }

  static ignoreLogicAndPath() {
    return Environment.isCreativeStudioPreviewMode() && !Environment.isInScreenShooterEmulation() && !Environment.inHeavyAdsEmulation();
  }
}
