import React, {useCallback, useEffect, useRef, useState} from "react";
import style from './style.module.scss';
import classnames from "classnames";
import {PLAYER_STATE} from "utils/constants";
import {debounce} from "../../utils/helpers";

const videoScrubHandler = (event, videoBarRef, duration, playerState, scrubHandle, onScrub) => {
    const x = event.pageX;
    const progress = videoBarRef;
    const position = x - progress.current.getBoundingClientRect().left;
    let percentage = 100 * position / progress.current.offsetWidth;
    if (percentage > 100) {
        percentage = 100;
    }
    if (percentage < 0) {
        percentage = 0;
    }
    const scrubToTime = (percentage / 100) * duration;
    scrubHandle(scrubToTime, playerState)
    onScrub(scrubToTime)
}

function ScrubEnabledVideoPositionBar({videoElapsedPercentage, fullScrubShowing, duration, currentTime, scrubHandle, playerState, onScrub, ...rest}) {

    const [isSeeking, setSeeking] = useState(false);
    const [touchId, setTouchId] = useState(null);
    const [isHover, setHover] = useState(false);
    const videoBarRef = useRef(null);

    useEffect( () => {
        const handleMouseMove = debounce ((event) => {
            if (isSeeking) {
                videoScrubHandler(event, videoBarRef, duration, playerState, scrubHandle, onScrub);
            }
        }, 10)

        const handleTouchMove = debounce ((event) => {
            const touch = Array.from(event.changedTouches).find(t => t.identifier === touchId);
            if (touch && isSeeking) {
                event.preventDefault();
                videoScrubHandler(touch, videoBarRef, duration, playerState, scrubHandle, onScrub);
            }
        }, 10)

        const handleTouchEnd = (event) => {
            const touch = Array.from(event.changedTouches).find(t => t.identifier === touchId);
            if(touch && isSeeking) {
                setSeeking(false);
                setTouchId(null);
                setHover(false);
            }
        }

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('touchmove', handleTouchMove);
        window.addEventListener('touchend', handleTouchEnd);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('touchmove', handleTouchMove);
            window.removeEventListener('touchend', handleTouchEnd);
        }

    }, [duration, isSeeking, playerState, onScrub, scrubHandle, touchId])



    const handleVideoBarClick = useCallback( (event) => {
        if (playerState === PLAYER_STATE.COMPLETED) {
            return;
        }

        videoScrubHandler(event, videoBarRef, duration, playerState, scrubHandle, onScrub);
    }, [duration, playerState, onScrub, scrubHandle])

    const handleSeekStart = useCallback(() => {
        if (playerState === PLAYER_STATE.COMPLETED) {
            return;
        }
        setSeeking(true);
    }, [playerState]);

    const handleTouchStart = useCallback((event) => {
        if (playerState === PLAYER_STATE.COMPLETED) {
            return;
        }

        const touch = event.changedTouches[0];
        setSeeking(true);
        setTouchId(touch.identifier);
        setHover(true);
    }, [playerState])

    const handleSeekEnd = useCallback(() => {
        setSeeking(false);
    }, [])

    const isCompleted = playerState === PLAYER_STATE.COMPLETED;
    return (
        <>
            <div className={classnames(fullScrubShowing ? style.videoPositionBar : style.videoPositionBarBottom, isHover ? style.mobileHover : null, isCompleted ? null : style.scrubEnabledBar)}
                 {...rest}
                 ref={videoBarRef}
                 onMouseDown={handleSeekStart}
                 onMouseUp={handleSeekEnd}
                 onMouseLeave={handleSeekEnd}
                 onTouchStart={handleTouchStart}
                 onClick={handleVideoBarClick}
            >
                <span className={style.videoElapsed} style={{width: `${videoElapsedPercentage}%`}}/>
                {!isCompleted && <div className={style.barThumb} style={{'left': `${videoElapsedPercentage}%`}}/>}
            </div>
        </>
    )
}

export default ScrubEnabledVideoPositionBar;
