import {MediaSubType, CH_UA_IFRAME_ALLOW} from 'utils/constants';

export function appendIframeForCustomHtml(ref, bodyContent, scriptSrc, scriptContent, mediaSubType, iframeSrc, enableScroll) {
  if (!ref || !ref.current) {
    return;
  }

  const iframe = document.createElement('iframe');
  iframe.setAttribute('scrolling', enableScroll ? 'auto' : 'no');
  iframe.setAttribute('margin', '0');
  iframe.setAttribute('padding', '0');
  iframe.setAttribute('width', '100%');
  iframe.setAttribute('height', '100%');
  iframe.setAttribute('frameBorder', '0');
  iframe.setAttribute('display', 'block');
  iframe.setAttribute('allow', CH_UA_IFRAME_ALLOW);

  // wait for iframe to load otherwise contentWindow can be undefined
  iframe.onload = function () {
    iframe.onload = null; // ie 11 seems to loop the onload call if we don't remove the reference after the first call
    if (mediaSubType === MediaSubType.HTML_URL) {
      iframe.src = iframeSrc;
    } else if (mediaSubType !== MediaSubType.HTML_URL && iframe.contentWindow && iframe.contentWindow.document) {
      const doc = iframe.contentWindow.document;
      doc.open();
      doc.write(`
        <!DOCTYPE html>
        <html>
        <head>
          <style>
            html, body {
            height: 100%;
            position: relative;
            margin: 0;
            padding: 0;
            width: 100%;
            scrollbar-arrow-color: transparent;
            scrollbar-track-color: transparent;
            scrollbar-3dlight-color: transparent;
            scrollbar-face-color: #cdc9c9;
            scrollbar-base-color: transparent;
            scrollbar-darkshadow-color: transparent;
            scrollbar-highlight-color: transparent;
            scrollbar-shadow-color: transparent;
            scrollbar-width : thin;
          }
          ::-webkit-scrollbar {
            width: 7px;
            background-color: transparent;
          }
          ::-webkit-scrollbar-thumb {
            background: #cdc9c9;
            border-radius: 6px;
          }
          </style>
          <script>
            ${scriptContent}
          </script>
          ${scriptSrc ? `<script src="${scriptSrc}"></script>` : ""}
        </head>
        <body>
        ${bodyContent}
        </body>
        </html>
        `)
      doc.close();
    }
  }
  ref.current.appendChild(iframe);
}
