import React from "react";
import EmailIcon, {ICONS} from "elements/icons/email";
import {Flex, JUSTIFY} from "elements/containers";

function EmailIcons({onClick, icons = ICONS, shareText, shareUrl}) {
  return (
    <Flex.Column justify={JUSTIFY.LEFT} spacing={100}>
      {icons.map(icon => <EmailIcon key={icon} data-testid={icon} onClick={(e) => onClick(e, icon)} variant={icon} shareText={shareText} shareUrl={shareUrl}/>)}
    </Flex.Column>
  )
}
export default EmailIcons;
