// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".phoenix--m5nKq{width:100%;height:100%;display:flex;justify-content:center;align-items:center;flex-direction:column;z-index:2;background:#000}.phoenixDisplay--pim95{background:rgba(0,0,0,0)}.phoenixEngagementPreview--45zy_{background:rgba(0,0,0,0)}.fillSpace--2_LUw{width:100%;height:100%;display:flex;flex:1 1 auto;overflow:hidden}.ctaTop--1niNP{padding:5px 0 0 5px}.ctaShow--nvGia{opacity:1;transition:.6s ease-in-out}.ctaHideFromTop--3EsyK{transform:translateY(-100%)}.ctaHideFromBottom--1Do_f{transform:translateY(100%)}.ctaHide--16e4G{opacity:0;height:0}", ""]);
// Exports
exports.locals = {
	"phoenix": "phoenix--m5nKq",
	"phoenixDisplay": "phoenixDisplay--pim95 phoenix--m5nKq",
	"phoenixEngagementPreview": "phoenixEngagementPreview--45zy_ phoenix--m5nKq",
	"fillSpace": "fillSpace--2_LUw",
	"ctaTop": "ctaTop--1niNP",
	"ctaShow": "ctaShow--nvGia",
	"ctaHideFromTop": "ctaHideFromTop--3EsyK",
	"ctaHideFromBottom": "ctaHideFromBottom--1Do_f",
	"ctaHide": "ctaHide--16e4G"
};
module.exports = exports;
