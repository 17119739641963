// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".absolute--1ChQK{position:absolute}.flex--3_2hG{display:flex;height:100%;width:100%}.center--3JNr6{align-items:center;justify-content:center}.fullHeight--_113B{height:100%}.fullWidth--3UnZS{width:100%}.spacing50--1Bxqs>*{padding:.25em}.spacing75--2ynKe>*{padding:.375em}.spacing100--2RzLL>*{padding:.5em}.spacing150--15nRk>*{padding:.75em}.spacing200--1vKcP>*{padding:1em}.row--1jFpW{display:flex;flex-direction:row}.rowReverse--3BdiY{flex-direction:row-reverse}.column--1Xe8B{display:flex;flex-direction:column}.columnReverse--2cgWC{flex-direction:column-reverse}.justify-left--3UfOX{justify-content:left}.justify-center--2PVfE{justify-content:center;align-items:center}.justify-around--3zgut{justify-content:space-around}", ""]);
// Exports
exports.locals = {
	"absolute": "absolute--1ChQK",
	"flex": "flex--3_2hG",
	"center": "center--3JNr6",
	"fullHeight": "fullHeight--_113B",
	"fullWidth": "fullWidth--3UnZS",
	"spacing50": "spacing50--1Bxqs",
	"spacing75": "spacing75--2ynKe",
	"spacing100": "spacing100--2RzLL",
	"spacing150": "spacing150--15nRk",
	"spacing200": "spacing200--1vKcP",
	"row": "row--1jFpW",
	"rowReverse": "rowReverse--3BdiY",
	"column": "column--1Xe8B",
	"columnReverse": "columnReverse--2cgWC",
	"justify-left": "justify-left--3UfOX",
	"justify-center": "justify-center--2PVfE",
	"justify-around": "justify-around--3zgut"
};
module.exports = exports;
