// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".animation--25Niz{animation-delay:2s;animation-duration:3s;animation-fill-mode:none;transition:opacity 1.5s,height .5s}.ctaContainer--10rT6{height:100%;flex:1}.cta--2pqtK{display:flex;height:100%;padding:0 0 5px 5px}.noClick--9jvXk{cursor:default}", ""]);
// Exports
exports.locals = {
	"animation": "animation--25Niz",
	"ctaContainer": "ctaContainer--10rT6 animation--25Niz",
	"cta": "cta--2pqtK animation--25Niz",
	"noClick": "noClick--9jvXk"
};
module.exports = exports;
