import React from "react";

import {StoreListItem} from "elements/store-list-item";

import style from "./style.module.scss";

export function StoreList(
  {
    stores,
    iconFile,
    iconColor,
    isFullScreen,
    onPhoneClick,
    onDirectionClick
  }
) {

  return <div className={style.listContainer}>
    <div className={style.list}>
      {stores.map((store, index) => <StoreListItem
          key={index}
          store={store}
          index={index}
          iconFile={iconFile}
          iconColor={iconColor}
          isFullScreen={isFullScreen}
          onPhoneClick={onPhoneClick}
          onDirectionClick={onDirectionClick}
        />
      )}
    </div>
  </div>;
}
