import {useEffect, useMemo, useState} from 'react';
import MathHelper from 'utils/mathHelper';
import {MAX_ITEM_TO_RENDER} from "utils/store-locator-helper";

class LocationFetch {
  urlFetched = {};

  async fetch(url) {
    const key = window.btoa(url);
    if (!this.urlFetched[key]) {
      this.urlFetched[key] = await (await fetch(url)).json();
    }
    return this.urlFetched[key];
  }
}

export function getDistanceInMilesFromCurrentUserLocation(location, userCurrentLocation, isMobileDevice) {
  return MathHelper.getDistanceInMilesBetweenTwoPoints(userCurrentLocation, location);
}

export function processStores(stores, userCurrentLocation, isMobileDevice) {
  return stores.map((currentStore) => {
    currentStore.phoneNumber = currentStore.phoneNumber || '';
    currentStore.isMobile = isMobileDevice;
    currentStore.phoneLink = `tel:+1${currentStore.phoneNumber.replace(/\D/g, '')}`;
    currentStore.storeAddress = `${currentStore.streetAddress}, ${currentStore.city}, ${currentStore.state} ${currentStore.zipCode}`;
    currentStore.googleMapsDirectionsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(currentStore.storeAddress)}`;
    currentStore.latLng = {
      lat: currentStore.latitude,
      lng: currentStore.longitude
    };
    currentStore.distanceFromUser = getDistanceInMilesFromCurrentUserLocation(currentStore.latLng, userCurrentLocation);
    currentStore.iconFile = currentStore?.mapLocatorImage?.url;
    return currentStore;
  }).sort((a, b) => a.distanceFromUser - b.distanceFromUser);
}

export function useFetchLocations(url, userCurrentLocation, isMobileDevice) {
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);

  const locationFetch = useMemo(() => new LocationFetch(), []);

  useEffect(() => {
    let isMounted = true;

    const init = async () => {
      setLoading(true);
      try {
        const res = await locationFetch.fetch(url);
        if (isMounted) {
          setStores(processStores(res?.locationJson || [], userCurrentLocation, isMobileDevice).slice(0, MAX_ITEM_TO_RENDER));
        }
      } catch (e) {
        if (isMounted) {
          setStores([]);
        }
      } finally {
        setLoading(false);
      }
    };

    init()
      .then(() => console.log('Store List Loaded'))
      .catch((e) => console.log('Error Loading Store List', e));

    return () => {
      isMounted = false;
    };
  }, [locationFetch, url, userCurrentLocation, isMobileDevice]);

  return {stores, loading};

}
