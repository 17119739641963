import {useCallback, useMemo} from 'react';
import {openInfoWindow} from 'utils/store-locator-helper';
import {openUrl} from "utils/url-helper";

import {THIRD_PARTY_EVENTS, AdUnitEvent, JourneyTriggerInteractiveEvent} from '../../utils/constants';

class InfoWindowStore {
  infoWindow = null;

  setInfoWindow(infoWindow) {
    this.infoWindow = infoWindow;
  }

  getInfoWindow() {
    return this.infoWindow;
  }

  closeExistingInfoWindow() {
    if (this.infoWindow) {
      this.infoWindow.close();
    }
    this.setInfoWindow(null);
  }
}


export function useStoreLocatorEvents(fireJourneyTrigger, inVideo, onEvent, fireCallback, triggerElementId) {
  const infoWindowStore = useMemo(() => new InfoWindowStore(), []);

  const onPhoneClick = useCallback(() => {
    fireCallback(THIRD_PARTY_EVENTS.CALL);
    onEvent({
      action: AdUnitEvent.ClickEvent,
      srcWidget: AdUnitEvent.Call,
    });
    fireJourneyTrigger(JourneyTriggerInteractiveEvent.interactiveMapStoreCall, triggerElementId);
  }, [onEvent, fireCallback, fireJourneyTrigger, triggerElementId]);

  const onDirectionClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    fireCallback(THIRD_PARTY_EVENTS.DIRECTIONS);
    onEvent({
      action: AdUnitEvent.ClickEvent,
      srcWidget: AdUnitEvent.Directions,
    });

    openUrl(e.currentTarget.getAttribute('href'), '_blank');
    fireJourneyTrigger(JourneyTriggerInteractiveEvent.interactiveMapStoreDirections, triggerElementId);
    fireJourneyTrigger(JourneyTriggerInteractiveEvent.interactiveEngagementExperienceClickThrough, triggerElementId);
    if (inVideo) {
      fireJourneyTrigger(JourneyTriggerInteractiveEvent.interactiveInVideoClickThrough, triggerElementId);
    }
  }, [fireJourneyTrigger, inVideo, onEvent, fireCallback, triggerElementId]);

  const onMarkerClick = useCallback((map, marker, markerPin, fireEvents = true, setLastMarker) => {
    if (fireEvents) {
      fireCallback(THIRD_PARTY_EVENTS.STORE_INFO);
      onEvent({
        action: AdUnitEvent.ClickEvent,
        srcWidget: AdUnitEvent.StoreInfo,
      });
      fireJourneyTrigger(JourneyTriggerInteractiveEvent.interactiveMapStoreInfo, triggerElementId);
    }
    infoWindowStore.closeExistingInfoWindow();
    openInfoWindow(map, marker, markerPin, (infoWindow) => infoWindowStore.setInfoWindow(infoWindow), setLastMarker, onPhoneClick, onDirectionClick);
  }, [infoWindowStore, fireCallback, onEvent, fireJourneyTrigger, triggerElementId, onPhoneClick, onDirectionClick]);

  return {
    onMarkerClick,
    onPhoneClick,
    onDirectionClick
  };
}
