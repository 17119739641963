import screenfull from 'screenfull';
import {isEmpty} from "./helpers";

import {
  ENGAGEMENT_TYPES,
  ENGAGEMENT_TEMPLATE_MAP,
  DisplayMediaType,
  NO_OPTION_SELECTED_ALERT,
  CLICK_SRC_WIDGET,
  AdUnitEvent,
  JourneyTriggerInteractiveEvent, CREATIVE_CARD_TYPE
} from './constants';

import {doGetViaScriptTag, isValidUrl} from 'utils/eventUtil';
import {openUrl} from 'utils/url-helper';

const Fn = Function;

const getSurveyResponsePayload = (surveyID, rating, questionId, selectedQuestionOptionID, engagementTemplate, suppressPositionField) => {
  return {
    type: AdUnitEvent.SurveyResponse,
    surveyQuestionID: questionId,
    surveyID,
    ...(!suppressPositionField && {optionPosition: rating}),
    surveyQuestionOptionID: selectedQuestionOptionID,
    engagementTemplate,
    numStars: rating,
    shouldSendSurveySubmissionEvent: 'true' // TODO: Added query param shouldSendSurveySubmissionEvent so that lambda start firing extra survey submission event along with surveyresponse. Will remove it after next release
  }
}

const getSelectedQuestionOptionRule = (surveyRules, selectedQuestionOptionID) => {
  return !surveyRules ? surveyRules : surveyRules.find((surveyRule) => surveyRule.optId === selectedQuestionOptionID);
}

const getSelectedQuestionOptionRuleForLikert = (surveyRules, position) => {
  return !surveyRules ? surveyRules : surveyRules.find(({rating}) => rating === position);
}

const getJourneyTriggerEvent = (engagementType) => {
  switch (engagementType) {
    case ENGAGEMENT_TYPES.ImageSurvey:
      return JourneyTriggerInteractiveEvent.interactiveImageSurveyClickThrough;
    case DisplayMediaType.IMAGE_SURVEY:
      return JourneyTriggerInteractiveEvent.interactiveImageSurveyClickThrough;
    case ENGAGEMENT_TYPES.StarSurvey:
      return JourneyTriggerInteractiveEvent.interactiveLikertClickThrough;
    case DisplayMediaType.LIKERT_SURVEY:
      return JourneyTriggerInteractiveEvent.interactiveLikertClickThrough;
    case ENGAGEMENT_TYPES.MultipleChoiceSurvey:
      return JourneyTriggerInteractiveEvent.interactiveMultipleChoiceSurveyClickThrough;
    case DisplayMediaType.MULTIPLE_CHOICE_SURVEY:
      return JourneyTriggerInteractiveEvent.interactiveMultipleChoiceSurveyClickThrough;
    case ENGAGEMENT_TYPES.CustomSurvey:
      return JourneyTriggerInteractiveEvent.interactiveCustomSurveyClickThrough;
    case DisplayMediaType.CUSTOM_SURVEY:
      return JourneyTriggerInteractiveEvent.interactiveCustomSurveyClickThrough;
    default:
      return null;
  }
}

const getClickSrcWidget = (engagementType) => {
  switch (engagementType) {
    case ENGAGEMENT_TYPES.ImageSurvey:
      return CLICK_SRC_WIDGET.IMAGE_SURVEY_SCREEN_SUBMISSION_CLICK;
    case DisplayMediaType.IMAGE_SURVEY:
      return CLICK_SRC_WIDGET.IMAGE_SURVEY_SCREEN_SUBMISSION_CLICK;
    case ENGAGEMENT_TYPES.StarSurvey:
      return CLICK_SRC_WIDGET.LIKERT_SURVEY_SCREEN_SUBMISSION_CLICK;
    case DisplayMediaType.LIKERT_SURVEY:
      return CLICK_SRC_WIDGET.LIKERT_SURVEY_SCREEN_SUBMISSION_CLICK;
    case ENGAGEMENT_TYPES.MultipleChoiceSurvey:
      return CLICK_SRC_WIDGET.MULTIPLE_CHOICE_SURVEY_SCREEN_SUBMISSION_CLICK;
    case DisplayMediaType.MULTIPLE_CHOICE_SURVEY:
      return CLICK_SRC_WIDGET.MULTIPLE_CHOICE_SURVEY_SCREEN_SUBMISSION_CLICK;
    case ENGAGEMENT_TYPES.CustomSurvey:
      return CLICK_SRC_WIDGET.CUSTOM_SURVEY_SCREEN_SUBMISSION_CLICK;
    case DisplayMediaType.CUSTOM_SURVEY:
      return CLICK_SRC_WIDGET.CUSTOM_SURVEY_SCREEN_SUBMISSION_CLICK;
    default:
      return null;
  }
}
const openSurveyRuleUrl = ({url, surveyID, engagementTemplate, onEvent, isInVideo, fireJourneyTrigger, trackSurveyClickThrough, onClickThrough, triggerElementId}) => {
  const payload = {
    type: AdUnitEvent.ClickEvent,
    surveyID,
    engagementTemplate,
    srcWidget: getClickSrcWidget(engagementTemplate)
  };

  onEvent(payload);
  openUrl(url);
  const journeyTriggerEvent = exportedFunctions.getJourneyTriggerEvent(engagementTemplate);
  fireJourneyTrigger(journeyTriggerEvent, triggerElementId);
  fireJourneyTrigger(JourneyTriggerInteractiveEvent.interactiveEngagementExperienceClickThrough, triggerElementId);
  if (isInVideo) {
    fireJourneyTrigger(JourneyTriggerInteractiveEvent.interactiveInVideoClickThrough, triggerElementId);
  }
  if (trackSurveyClickThrough) {
    trackSurveyClickThrough();
  }
  if (onClickThrough) {
    onClickThrough();
  }
}

const executeSurveyRules = ({surveyRule, surveyID, engagementTemplate, onEvent, isInVideo, fireJourneyTrigger, trackSurveyClickThrough, onClickThrough, triggerElementId}) => {
  if (!surveyRule) {
    return;
  }
  const {webUrl: url, jsCodes} = surveyRule;
  if (url) {
    exportedFunctions.openSurveyRuleUrl({url, surveyID, engagementTemplate, onEvent, isInVideo, fireJourneyTrigger, trackSurveyClickThrough, onClickThrough, triggerElementId}); // if web url, open specified url using correct method
  }
  if (jsCodes && Array.isArray(jsCodes) && jsCodes.length > 0) {
    jsCodes.filter((jsCode) => !!jsCode).forEach((jsCode) => {
      try {
        // check if js code is a url that returns some js
        const isUrl = isValidUrl(jsCode);
        if (isUrl) {
          doGetViaScriptTag(jsCode);
        } else {
          // This means not a url, so lets try executing the js code
          const func = new Fn(jsCode);
          func();
        }
      } catch (e) {
        console.log('Error firing js code for survey tracking. Please talk to the support team.', e);
      }
    });
  }
}

const showNoOptionSelectedAlert = () => {
  /* eslint-disable no-alert */
  if (screenfull.isFullscreen) {
    screenfull.exit().then(() => {
      // need to put time out if full screen so that the player gets enough time to exit out of full screen before the alert is shown and the survey resizes.
      setTimeout(() => {
        window.alert(NO_OPTION_SELECTED_ALERT);
      }, 700);
    });
  } else {
    window.alert(NO_OPTION_SELECTED_ALERT);
  }
}


const getSurveyDetails = (creativeCard) => {
  if (!creativeCard || isEmpty(creativeCard)) {
    return {};
  }

  const creativeCardType = creativeCard?.creativeCardType;

  let engagement;
  let isSurvey = false;

  // TODO: this seems oddly inconsistent, can we fix the actual data model?
  switch (creativeCardType) {
    case CREATIVE_CARD_TYPE.THANK_YOU:
      engagement = creativeCard.thankYouEngagement;
      break;
    case CREATIVE_CARD_TYPE.EXPLORER:
      engagement = creativeCard.explorerEngagement;
      break;
    case CREATIVE_CARD_TYPE.IMAGE_SURVEY:
      engagement = creativeCard.imageSurveyEngagement;
      isSurvey = true;
      break;
    case CREATIVE_CARD_TYPE.CUSTOM:
      engagement = creativeCard.customHTMLEngagement;
      break;
    case CREATIVE_CARD_TYPE.LIKERT_SURVEY:
      engagement = creativeCard.starSurveyEngagement;
      isSurvey = true;
      break
    case CREATIVE_CARD_TYPE.STORE_LOCATOR:
      engagement = creativeCard.mapEngagement;
      break;
    case CREATIVE_CARD_TYPE.MULTIPLE_CHOICE_SURVEY:
      engagement = creativeCard.multipleChoiceEngagement;
      isSurvey = true;
      break;
    case CREATIVE_CARD_TYPE.CUSTOM_SURVEY:
      engagement = creativeCard.customSurveyEngagement;
      isSurvey = true;
      break;
    case CREATIVE_CARD_TYPE.PRODUCT_EXPLORER:
      engagement = creativeCard.productExplorerEngagement;
      break;
    default:
      // no-op
  }

  return {
    ...(isSurvey && {surveyID: engagement?.id}),
    engagementTemplate: ENGAGEMENT_TEMPLATE_MAP[creativeCardType] // For eventing
  }
}

const exportedFunctions = {
  showNoOptionSelectedAlert,
  executeSurveyRules,
  openSurveyRuleUrl,
  getJourneyTriggerEvent,
  getSelectedQuestionOptionRule,
  getSurveyResponsePayload,
  getSelectedQuestionOptionRuleForLikert,
  getSurveyDetails
}

export default exportedFunctions;
