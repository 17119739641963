import React from 'react';
import style from './style.module.scss';

export function InfoWindow({store}) {
  const {
    storeName,
    streetAddress,
    city,
    state,
    zipCode,
    notes,
    phoneNumber,
    isMobile,
    distanceFromUser,
    phoneLink,
    latitude,
    longitude,
    googleMapsDirectionsUrl
  } = store;

  return <div className={style.infoWindowContainer}>
    <div className={style.addressContainer}>
      <div className={style.addressTitle}>{storeName}</div>
      <div>{streetAddress}</div>
      <div>{city}, {state} {zipCode}</div>
      {
        notes ?
          <div>{notes}</div> :
          <React.Fragment/>
      }
      {
        (Boolean(phoneNumber) && !isMobile) ?
          <div>{phoneNumber}</div> :
          <React.Fragment/>
      }
      <div>{(distanceFromUser || 0).toFixed(2)} mi away</div>
    </div>
    <div className={style.contactMethods}>
      {
        phoneNumber ?
          <a
            data-phone-number={phoneNumber}
            href={phoneLink}
            target={'_parent'}
            className={style.contactMethodItems}
            id="vgInfoWindowPhoneNumberLink"
          >
            Call
          </a> :
          <React.Fragment/>
      }
      <a
        className={style.contactMethodItems}
        data-lat-lon={`${latitude},${longitude}`}
        href={googleMapsDirectionsUrl}
        target={'_blank'}
        rel={'noreferrer'}
        id="vgInfoWindowDirectionLink"
      >
        Directions
      </a>
    </div>
  </div>
}
