import React, {useCallback, useMemo, useState} from 'react';
import style from './style.module.scss';
import classnames from "classnames";
import {
  getHotspotStyle,
  HOTSPOT_POSITIONS,
  STYLE_TO_FONT_STYLE_MAPPING,
  TEXT_ALIGN
} from "utils/hotspot-helper";
import {HotspotTooltipPopper} from "../hotspotTooltipPopper";
import {EmbedHTML} from "elements/embedHTML";
import {getOpacityStyle} from "utils/engagement-helper";
import {MARKER, MARKER_CONFIG} from "utils/constants";
import ResizeObserver from "resize-observer-polyfill";
import {SVGTextContainer} from "elements/typography/svgTextContainer"

const arrowStyleToClassMapping = {
  [HOTSPOT_POSITIONS.TOP]: style.arrowTop,
  [HOTSPOT_POSITIONS.RIGHT]: style.arrowRight,
  [HOTSPOT_POSITIONS.BOTTOM]: style.arrowBottom,
  [HOTSPOT_POSITIONS.LEFT]: style.arrowLeft,
}

function getPopperDimensions(width, height, containerWidth, containerHeight) {
  const dimensions = {}
  if (width) {
    dimensions.width = containerWidth * (width / 100);
  } else {
    // When no width is selected, we will add minWidth to be 10% of containerWidth - Refer VGO-2446
    dimensions.minWidth = containerWidth * (10 / 100);
  }
  if (height) {
    dimensions.height = containerHeight * (height / 100);
  }
  return dimensions;
}

function HotspotIcon(props) {
  const {
    styleToBeUsed,
    setRef,
    icon,
    baseColor,
    attachResizeHandler = false
  } = props;
  const [iconFontSize, setIconFontSize] = useState(0);

  const iconContainerRefHandler = useCallback((ref) => {
    if (typeof setRef === 'function') {
      setRef(ref);
    }

    if (!ref) {
      return setIconFontSize(0);
    }

    const setIconSize = () => {
      const {width, height} = ref.getBoundingClientRect();
      setIconFontSize(Math.min(width, height));
    }

    let resizeObserver = null;
    if (attachResizeHandler) {
      resizeObserver = new ResizeObserver(setIconSize);
      resizeObserver.observe(ref);
    } else {
      setIconSize();
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    }
  }, [attachResizeHandler, setRef]);

  return <div className={style.iconContainer} ref={iconContainerRefHandler}>
    <i className={classnames(styleToBeUsed, icon)} style={{fontSize: iconFontSize, color: baseColor}}/>
  </div>
}

function HotspotImage(props) {
  const {imageSrc, setRef, opacity} = props;
  const imgStyle = useMemo(() => getOpacityStyle(opacity), [opacity]);

  return imageSrc ?
    <img
      ref={setRef}
      src={imageSrc}
      className={style.image}
      alt={'Custom Marker'}
      style={imgStyle}
    /> :
    <div
      ref={setRef}
      className={style.noImage}
    >
      Upload a Custom marker
    </div>
}

function HotspotIconContainer(props) {
  const [ref, setRef] = useState(null);
  const {
    hotspot,
    playerWidth,
    playerHeight,
    icon,
    styleToBeUsed,
    baseColor,
    onClick,
    isImage,
    textAlignStyle,
    isEmbed,
    isCreativeStudioPreviewMode,
    hotspotPopperSelector
  } = props;

  const {
    hotspotText: {
      text
    },
    hotspotBgColor,
    customMarker = {},
    hotspotTextPosition,
    hotspotTextWidth,
    hotspotTextHeight,
    hotspotEmbedUrl,
    hotspotEmbedType
  } = hotspot;

  const {fontFamily, fontWeight, color, fontSize} = getHotspotStyle(hotspot, playerHeight);

  const {linkURL: imageSrc, opacity} = customMarker || {};

  const popperDimensions = useMemo(() => {
    return getPopperDimensions(hotspotTextWidth, hotspotTextHeight, playerWidth, playerHeight)
  }, [hotspotTextWidth, hotspotTextHeight, playerHeight, playerWidth]);

  const popperPosition = useMemo(() => hotspotTextPosition || HOTSPOT_POSITIONS.RIGHT, [hotspotTextPosition]);
  const isPopperOnCorner = useMemo(() => ![HOTSPOT_POSITIONS.RIGHT, HOTSPOT_POSITIONS.LEFT, HOTSPOT_POSITIONS.TOP, HOTSPOT_POSITIONS.BOTTOM].includes(popperPosition), [popperPosition]);

  const getHotspotIcon = () => {
    if (isImage) {
      return <HotspotImage
        imageSrc={imageSrc}
        setRef={setRef}
        opacity={opacity}
      />

    }
    if (isEmbed) {
      return <EmbedHTML
        content={hotspotEmbedUrl}
        mediaSubType={hotspotEmbedType}
        setRef={setRef}
        preventClick={isCreativeStudioPreviewMode}
      />

    }
    return <HotspotIcon
      styleToBeUsed={styleToBeUsed}
      setRef={setRef}
      icon={icon}
      baseColor={baseColor}
      attachResizeHandler={isCreativeStudioPreviewMode}
    />
  }

  return <>
    {getHotspotIcon()}
    <HotspotTooltipPopper
      open={Boolean(text && ref)}
      anchorEl={ref}
      popperPosition={popperPosition}
    >
      <div
        className={classnames(style.popper, isPopperOnCorner ? style.popperCorner : null, isCreativeStudioPreviewMode ? hotspotPopperSelector : null)}
        style={{
          backgroundColor: hotspotBgColor,
          color,
          fontSize,
          fontFamily,
          fontWeight,
          ...popperDimensions,
          ...textAlignStyle
        }}
        onClick={onClick}
      >
        <div
          className={classnames(style.arrowBase, arrowStyleToClassMapping[popperPosition])}
          style={{
            // By Default making left pointing arrow and rotating to point in different directions.
            borderRightColor: hotspotBgColor,
          }}
        />
        {text}
      </div>
    </HotspotTooltipPopper>
  </>
}

export const getHotspotTextAlignStyles = (rawHotspot) => {
  const {hotspotTextAlign = TEXT_ALIGN.CENTER} = rawHotspot;
  switch (hotspotTextAlign) {
    case TEXT_ALIGN.LEFT: {
      return {
        justifyContent: 'flex-start',
        textAlign: 'left'
      }
    }
    case TEXT_ALIGN.CENTER: {
      return {
        justifyContent: 'center',
        textAlign: 'center'
      }
    }
    case TEXT_ALIGN.RIGHT: {
      return {
        justifyContent: 'flex-end',
        textAlign: 'right'
      }
    }
    case TEXT_ALIGN.JUSTIFY: {
      return {
        justifyContent: 'unset',
        textAlign: 'justify'
      }
    }
    default: {
      return {}
    }
  }
}

export const getHotspotMarkerConfig = (rawHotspot, textAlignStyle, hotspotPopperSelector, isSelected, isCreativeStudioPreviewMode) => {
  const hotspot = {...rawHotspot};
  let content = null;
  const marker = hotspot.marker || MARKER.DEFAULT;
  if (marker === MARKER.DEFAULT) {
    content = (playerWidth, playerHeight, styles) => {
      return <SVGTextContainer
        text={hotspot.hotspotText.text}
        {...styles}
        textAlignStyle={textAlignStyle}
        renderAsSVG={!isCreativeStudioPreviewMode}
      />
    };
  } else {
    hotspot.borderType = 'solid';
    hotspot.borderWidth = 1;
    hotspot.borderColor = 'transparent'
    hotspot.bgColor = null;

    if (marker === MARKER.CUSTOM) {
      content = (playerWidth, playerHeight) => {
        return <HotspotIconContainer
          isImage={true}
          hotspot={rawHotspot}
          playerHeight={playerHeight}
          playerWidth={playerWidth}
          textAlignStyle={textAlignStyle}
          isCreativeStudioPreviewMode={isCreativeStudioPreviewMode}
          hotspotPopperSelector={hotspotPopperSelector}
        />
      };
    } else if (marker === MARKER.EMBED) {
      content = (playerWidth, playerHeight) => {
        return <HotspotIconContainer
          isEmbed={true}
          hotspot={rawHotspot}
          playerHeight={playerHeight}
          playerWidth={playerWidth}
          textAlignStyle={textAlignStyle}
          isCreativeStudioPreviewMode={isCreativeStudioPreviewMode}
          hotspotPopperSelector={hotspotPopperSelector}
        />
      };
    } else {
      const markerConfig = MARKER_CONFIG[marker];
      if (markerConfig) {
        const {icon: {defaultStyle, icon} = {}} = markerConfig;

        const styleToBeUsed = STYLE_TO_FONT_STYLE_MAPPING[hotspot.hotspotMarkerStyle] || defaultStyle;

        content = (playerWidth, playerHeight) => {
          return <HotspotIconContainer
            hotspot={rawHotspot}
            playerHeight={playerHeight}
            playerWidth={playerWidth}
            icon={icon}
            styleToBeUsed={styleToBeUsed}
            baseColor={hotspot.hotspotMarkerColor}
            textAlignStyle={textAlignStyle}
            isCreativeStudioPreviewMode={isCreativeStudioPreviewMode}
            hotspotPopperSelector={hotspotPopperSelector}
          />;
        };
      }
    }
  }
  return {content, hotspot};
}
