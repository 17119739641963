import {useCallback, useMemo} from 'react';

const DEFAULT_SPECIAL_SETTINGS = {
  enableVgOverlay: true,
  dismissVgOverlay: true,
  disableAPISetVolume: false
}

export const useSpecialSettings = (settings, platformType) => {
  const specialSettings = useMemo(() => {
    let specialSettingsParsed = {};
    if(!settings?.creativeConfig?.profileString) {
      return specialSettingsParsed;
    }
    try {
      specialSettingsParsed = JSON.parse(settings?.creativeConfig?.profileString);
    } catch (e) {
      console.error('Error Parsing Special Settings', e);
    }

    return specialSettingsParsed
  }, [settings]);

  const getSpecialSettingPlatformValue = useCallback((propertyName) => {
    const specialSettingsPropertyValue = specialSettings[propertyName];

    if (!specialSettingsPropertyValue) {
      return DEFAULT_SPECIAL_SETTINGS[propertyName];
    }

    const settingsValue = typeof specialSettingsPropertyValue === "object" ?
      specialSettingsPropertyValue[platformType] : specialSettingsPropertyValue;

    return settingsValue === undefined ? DEFAULT_SPECIAL_SETTINGS[propertyName] : Boolean(settingsValue);
  }, [specialSettings, platformType]);


  return {
    getSpecialSettingPlatformValue
  }
};
