import React, {useCallback, useMemo, useState} from "react";
import classnames from 'classnames';
import {getHotspotHorizontalPadding, getHotspotStyle} from "utils/hotspot-helper";

import style from './style.module.scss';
import effectStyle from 'common/styles/effects.module.scss';
import {getHotspotMarkerConfig, getHotspotTextAlignStyles} from "../hotspotIcon";
import {HOTSPOT_ANIMATION_DURATION, HotSpotType, EFFECT_PREFIX, EFFECT_TYPE} from "utils/constants";
import {getMarkerRatio} from "utils/engagement-helper";

const hotspotAnimationDuration = `${HOTSPOT_ANIMATION_DURATION}ms`;

export function Hotspot(
  {
    hotspot: rawHotspot,
    onClick,
    containerDimensions,
    showing,
    isCardHotspot,
    useHotspotAnimation,
    noPointer,
    isSurveyOption,
    index,
    isSelected,
    isCreativeStudioPreviewMode,
    selectedHotspotSelector,
    hotspotPopperSelector,
    snappingHotspotSelector
  }
) {
  const textAlignStyle = useMemo(() => getHotspotTextAlignStyles(rawHotspot), [rawHotspot]);
  const {
    content,
    hotspot
  } = useMemo(() => {
    return getHotspotMarkerConfig(
      rawHotspot,
      textAlignStyle,
      hotspotPopperSelector,
      isSelected,
      isCreativeStudioPreviewMode
    )
  }, [isCreativeStudioPreviewMode, hotspotPopperSelector, isSelected, rawHotspot, textAlignStyle]);

  const hotspotStyle = useMemo(() => getHotspotStyle(hotspot, containerDimensions.height), [containerDimensions.height, hotspot]);
  const {useAnimation, linkToUrl, hotspotType, hasChildren} = rawHotspot;

  const markerRatio = useMemo(() => getMarkerRatio(rawHotspot?.marker, rawHotspot?.customMarker), [rawHotspot]);

  const customHotspotClasses = useMemo(() => {
    if (selectedHotspotSelector && snappingHotspotSelector) {
      return classnames({
        [selectedHotspotSelector]: isSelected,
        [snappingHotspotSelector]: !isSelected
      })
    }
    return null
  }, [isSelected, selectedHotspotSelector, snappingHotspotSelector])

  const showPointer = useMemo(() => {
    if (isSurveyOption) {
      return true
    }

    if (noPointer) {
      return false;
    }

    if (hasChildren) {
      return true;
    }

    return Boolean(linkToUrl) || HotSpotType[hotspotType] === HotSpotType.ENGAGEMENT_EXPERIENCE;
  }, [isSurveyOption, hasChildren, linkToUrl, noPointer, hotspotType]);

  const hasEffect = useMemo(() => {
    return (hotspot.effect && hotspot.effect.effectType !== EFFECT_PREFIX.NONE) ? true : false
  }, [hotspot])

  const effectName = useMemo(() => hasEffect ? hotspot.effect.effectType?.toLowerCase() : EFFECT_TYPE.NONE.toLowerCase(), [hasEffect, hotspot])
  
  const classes = useMemo(() => {
    return classnames(
      style.hotspot,
      showPointer ? null : style.noPointer,
      (useAnimation && useHotspotAnimation) ? style.useHotspotAnimation : null,
      isCardHotspot ? style.cardHotspot : style.videoHotspot,
      showing ? null : style.hidden,
      customHotspotClasses,
      hasEffect ? `${effectStyle[`${EFFECT_PREFIX.HOVER}${effectName}`]} ${effectStyle[`${EFFECT_PREFIX.PERM}${effectName}`]} ${effectStyle.vgFxNoTouchHighlight}` : null,
    );
  }, [customHotspotClasses, isCardHotspot, showPointer, showing, useAnimation, useHotspotAnimation, hasEffect, effectName]);

  const horizontalPadding = useMemo(() => getHotspotHorizontalPadding(rawHotspot, containerDimensions.height), [containerDimensions.height, rawHotspot]);
  const padding = useMemo(() => `0px ${horizontalPadding}px`, [horizontalPadding]);

  const styles = useMemo(() => {
    return {
      ...hotspotStyle, 
      '--hotspotAnimationDuration': hotspotAnimationDuration, 
      ...textAlignStyle,
      padding
    }
  }, [hotspotStyle, padding, textAlignStyle]);

  const contentStyles = useMemo(() => {
    // get size from %
    // slice off the % sign, divide by 100 to get as decimal, multiply by the player size.
    // subtract border width from container width and height.
    const borderWidth = parseFloat((styles.borderWidth || '0px').slice(0, -2));
    const width = ((styles.width.slice(0, -1) / 100) * containerDimensions.width) - (borderWidth * 2);
    const height = ((styles.height.slice(0, -1) / 100) * containerDimensions.height) - (borderWidth * 2);
    return {
      fontSize: styles.fontSize,
      width: width - (2 * horizontalPadding),
      height
    };
  }, [containerDimensions.height, containerDimensions.width, horizontalPadding, styles.borderWidth, styles.fontSize, styles.height, styles.width]);

  const [touched, setTouched] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const onHotspotClick = useCallback((e) => {
    onClick(hotspot, index, isSelected, e.shiftKey)
  }, [hotspot, index, isSelected, onClick]);

  const handleHotspotClick = (evt) => {
    if (touched) {
      // OV-405 prevent multiple submissions
      if (!isAnimating) {
        setIsAnimating(true)
        const event = {...evt} // copy event because it gets released before timeout function runs
        setTimeout(() => {
          handleTouchEnd(event)
          onHotspotClick(event)
          setIsAnimating(false)
        }, 600); // 0.6 second delay.
      }
    } else {
      onHotspotClick(evt);
    }
  }

  const handleTouchStart = (evt) => {
    if (evt.currentTarget && hasEffect) {
      setTouched(true)
      // remove hover class if touch start detected
      evt.currentTarget.classList.remove(effectStyle[`${EFFECT_PREFIX.HOVER}${effectName}`])
      evt.currentTarget.classList.add(effectStyle[`${EFFECT_PREFIX.BASE}${effectName}`])
    }
  }

  const handleTouchEnd = (evt) => {
    if (evt.currentTarget && hasEffect) {
      setTouched(false) // reset touched state
      evt.currentTarget.classList.remove(effectStyle[`${EFFECT_PREFIX.BASE}${effectName}`])
    }
  }

  /**
   * add hover class back on mouse over if it has been removed.
   * this supports devices with touch and mouse capabilities
   */
  const handleMouseOver = (evt) => {
    if (!touched && evt.currentTarget && hasEffect) {
      const style = effectStyle[`${EFFECT_PREFIX.HOVER}${effectName}`]
      // only add style if needed
      if (!evt.currentTarget.classList.contains(style)) {
        evt.currentTarget.classList.add(style)
      }
    }
  }

  return <>
    <div
      className={classes}
      style={styles}
      onClick={handleHotspotClick}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchEnd}
      onTouchCancel={handleTouchEnd}
      onMouseOver={handleMouseOver}
      data-ratio={markerRatio}
      data-id={rawHotspot.id}
      data-temp-id={rawHotspot.tempId}
      data-change-key={rawHotspot.changeKey}
    >
      {content(containerDimensions.width, containerDimensions.height, contentStyles)}
    </div>
  </>
}
