import React, {createContext, useContext, useMemo} from 'react';

const noOp = (() => {
});

const EventCapture = createContext(noOp);

export const useEventCapture = () => {
  return useContext(EventCapture);
};

export function EventCaptureProvider({children, capture, capturePlayerLoadTime, captureSentryError, shouldPhoenixSkipFiringStandardVastPixel, captureAssetLoadingTime}) {

  const captureFunctions = useMemo(() => ({
    capture, capturePlayerLoadTime, captureSentryError, shouldPhoenixSkipFiringStandardVastPixel, captureAssetLoadingTime   
  }), [capture, capturePlayerLoadTime, captureSentryError, shouldPhoenixSkipFiringStandardVastPixel, captureAssetLoadingTime]);

  return <EventCapture.Provider value={captureFunctions}>
    {children}
  </EventCapture.Provider>
}
