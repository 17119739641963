import React, {useMemo} from "react";

import VideoCreative from "modules/creative-video";
import DisplayCreative from "modules/creative-display";
import {AutoResizeProvider} from "utils/AutoResizeProvider";
import {ConfigProvider} from "utils/ConfigProvider";
import {EventCaptureProvider} from "utils/EventCaptureProvider";
import {CREATIVE_MODE, OVERLAY_INDEX, DISPLAY_ENGAGEMENT_INDEX} from 'utils/constants';
import {getVideoHost} from 'utils/settingsHelper';

import {FulLScreenProvider} from "utils/FullScreenProvider";

import {use3rdPartyEventTracking} from "hooks/use3rdPartyEventTracking";

// TODO: refactor - useEditedSettings and useExpandedTriggerLogicSettings are not really hooks so renaming them here otherwise they trigger the rule of hooks linting check below
import generateEditedSettings from "hooks/useEditedSettings";
import generateExpandedTriggerLogicSettings from "hooks/useExpandedTriggerLogicSettings";
import {SettingsProvider} from "utils/SettingsProvider";


// TODO: there are global styles here that may affect how things will look in creative studio if we don't load it...
// import style from './main.css' // find in phoenix-app

function PhoenixCore(
  {
    appRootId,
    appRef,
    settings,
    creativeDimensions,
    reloadAd,
    removeAd,
    onSetPlayerControlWrapper,
    onPlaybackStateChange,
    onPlayerTimeChange,
    onSetScrubHandle,
    localConfig,
    environment,
    runtimeProperties,
    captureAssetLoadingTime,
    capture,
    capturePlayerLoadTime,
    captureSentryError,
    shouldPhoenixSkipFiringStandardVastPixel,
    hotspotProps
  }
) {

  const {creativeType, mediaType, flowType, video = {}, vastTagUrl} = settings || {};

  const host = getVideoHost(mediaType);
  // Ported from PlayerService.PlayerSettingUtil to support studio preview
  const vastTagUrlActual = !vastTagUrl && mediaType && video?.content ? video.content : vastTagUrl;

  const {track3rdPartyEvents} = use3rdPartyEventTracking(settings);

  const isContentFlowCreative = useMemo(() => flowType === 'CONTENT', [flowType]);

  const memoSettings = useMemo(() => (settings && {
    ...settings,
    creativeCardSettings: (settings.creativeCardSettings || [])
      .map((creativeCardSetting, index) => ({
        ...creativeCardSetting, 
        [OVERLAY_INDEX]: index, 
        [DISPLAY_ENGAGEMENT_INDEX]: index,
      })),
  }), [settings]);

  const expandedTriggerLogicSettings = useMemo(() => generateExpandedTriggerLogicSettings(memoSettings), [memoSettings]);
  const editedSettings = useMemo(() =>
    generateEditedSettings({
      settings: expandedTriggerLogicSettings,
      isInScreenShooterEmulation: environment.isInScreenShooterEmulation(),
      engagementItemId: environment.getEngagementItemId(),
      isCreativeStudioPreviewMode: environment.isCreativeStudioPreviewMode(),
      isODCEngagementPreviewEnabled: environment.isODCEngagementPreviewEnabled(),
      forHotspot: environment.forHotspot(),
      selectedHotspot: environment.getSelectedHotspot()
    }),
    [expandedTriggerLogicSettings, environment]
  );

  return (
    <AutoResizeProvider>
      <FulLScreenProvider>
        <ConfigProvider
          localConfig={localConfig}
          environment={environment}
          runtimeProperties={runtimeProperties}
          appRootId={appRootId}
          appRef={appRef}
        >
          <EventCaptureProvider
            capture={capture}
            capturePlayerLoadTime={capturePlayerLoadTime}
            captureSentryError={captureSentryError}
            shouldPhoenixSkipFiringStandardVastPixel={shouldPhoenixSkipFiringStandardVastPixel}
            captureAssetLoadingTime={captureAssetLoadingTime}
          >
            <SettingsProvider
              /*
              * Using the original settings here, because for HotspotEngagement,
              * we splice the `engagementOverlayExperience` to just have EE for the selected Hotspot,
              * which will mess up with getSettings from Change Key
              * */
              settings={settings}
            >
              {creativeType === CREATIVE_MODE.VIDEO &&
                <VideoCreative
                  key={`${host}_${vastTagUrlActual}`}
                  settings={editedSettings}
                  track3rdPartyEvents={track3rdPartyEvents}
                  reloadAd={reloadAd}
                  removeAd={removeAd}
                  onSetPlayerControlWrapper={onSetPlayerControlWrapper}
                  onPlaybackStateChange={onPlaybackStateChange}
                  onPlayerTimeChange={onPlayerTimeChange}
                  onSetScrubHandle={onSetScrubHandle}
                  creativeDimensions={creativeDimensions}
                  isContentFlowCreative={isContentFlowCreative}
                  hotspotProps={hotspotProps}
                />
              }
              {creativeType === CREATIVE_MODE.DISPLAY &&
                <DisplayCreative
                  settings={editedSettings}
                  track3rdPartyEvents={track3rdPartyEvents}
                  creativeDimensions={creativeDimensions}
                  isContentFlowCreative={isContentFlowCreative}
                  hotspotProps={hotspotProps}
                />
              }
            </SettingsProvider>
          </EventCaptureProvider>
        </ConfigProvider>
      </FulLScreenProvider>
    </AutoResizeProvider>
  )
}

export default PhoenixCore;
