// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".popover--17639{display:flex;z-index:9999999999 !important;background-color:#fff !important;box-shadow:0 0 3px rgba(0,0,0,.5);padding:4px !important;margin:calc(var(--rootFontSize) * 0.5) 0 !important;border-radius:4px !important;pointer-events:all !important}.content--3adQo{background:#fff;display:inline-flex;font-size:.75em;pointer-events:unset;font-family:\"Roboto\",\"Helvetica\",\"Arial\",sans-serif}.rightPopper--1deC5,.leftPopper--1y6s4{margin:0 .625em !important}.topPopper--2a-Hc{top:-5px !important}.bottomPopper--36uHJ{top:5px !important}.arrowBase--EsdKZ{opacity:0}.arrow--1MMMt{opacity:1;position:absolute;width:.45em;height:.45em;border:.45em solid rgba(0,0,0,0);border-right-color:#fff}.arrowRight--9hz8n{left:calc(-2 * 0.45em + 1px);top:calc(50% - 0.45em)}.arrowLeft--23rq2{transform:rotate(180deg);right:calc(-2 * 0.45em + 1px);top:calc(50% - 0.45em)}.arrowTop--3FIJc{transform:rotate(270deg);bottom:calc(-2 * 0.45em + 1px);left:calc(50% - 0.45em)}.arrowBottom--1lSBX{transform:rotate(90deg);top:calc(-2 * 0.45em + 1px);left:calc(50% - 0.45em)}", ""]);
// Exports
exports.locals = {
	"popover": "popover--17639",
	"content": "content--3adQo",
	"rightPopper": "rightPopper--1deC5",
	"leftPopper": "leftPopper--1y6s4",
	"topPopper": "topPopper--2a-Hc",
	"bottomPopper": "bottomPopper--36uHJ",
	"arrowBase": "arrowBase--EsdKZ",
	"arrow": "arrow--1MMMt",
	"arrowRight": "arrowRight--9hz8n arrow--1MMMt",
	"arrowLeft": "arrowLeft--23rq2 arrow--1MMMt",
	"arrowTop": "arrowTop--3FIJc arrow--1MMMt",
	"arrowBottom": "arrowBottom--1lSBX arrow--1MMMt"
};
module.exports = exports;
