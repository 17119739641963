import React from 'react';
import classnames from "classnames";
import style from "../../common/styles/engagement-likert-survey.module.scss";
import {LikertIcon} from "../../elements/likertIcon";
import {ENGAGEMENT_DISPLAY_MODE, LIKERT_OPTION_STYLE} from "../../utils/constants";
import {LikertSlider} from "../../elements/likertSlider";
import {SliderInstructions} from "./sliderInstructions";

const getOptionsStyleClass = (mode) => {
  if (mode === ENGAGEMENT_DISPLAY_MODE.VERTICAL) {
    return style.options;
  }
  if (mode === ENGAGEMENT_DISPLAY_MODE.HORIZONTAL) {
    return style.optionsHorizontal;
  }
  return style.optionsFinale;
}

export function LikertOptions(props) {
  const {
    mode,
    showing,
    selectedPosition,
    options,
    selectedOptionForSubmit,
    setStarHover,
    starColor,
    iconType,
    iconSize,
    singleIcon,
    customIcon,
    optionStyle,
    sliderColors,
    optionClickHandler,
    starHover,
    isDisplayCreative,
    height,
    width,
    noPointer
  } = props;

  switch (optionStyle) {
    case LIKERT_OPTION_STYLE.ICON: {
      return <div
        className={classnames(getOptionsStyleClass(mode), showing && !selectedPosition ? style.animate : null)}>
        {
          options.map((option, index) => {
            const isOptionChecked = selectedOptionForSubmit !== null && index <= selectedOptionForSubmit.position - 1;
            const isCurrentOptionChecked = selectedOptionForSubmit !== null && index === selectedOptionForSubmit.position - 1;
            return <LikertIcon
              key={option.id}
              onClick={() => optionClickHandler(index, option)}
              option={option}
              setStarHover={setStarHover}
              isOptionChecked={isOptionChecked}
              isCurrentOptionChecked={isCurrentOptionChecked}
              starColor={starColor}
              mode={mode}
              iconType={iconType}
              iconSize={iconSize}
              singleIcon={singleIcon}
              customIcon={customIcon}
              noPointer={noPointer}
            />
          })
        }
      </div>
    }
    case LIKERT_OPTION_STYLE.SLIDER: {
      return <>
        <div className={classnames(getOptionsStyleClass(mode), style.sliderOptionContainer)}>
          <LikertSlider
            sliderColors={sliderColors}
            optionClickHandler={(index) => {
              optionClickHandler(index, options[index]);
            }}
            value={(selectedOptionForSubmit?.position || 1) - 1}
            totalOptions={options.length}
            options={options}
            setStarHover={setStarHover}
            starHover={starHover}
            mode={mode}
            isDisplayCreative={isDisplayCreative}
            height={height}
            width={width}
          />
        </div>
        {
          mode === ENGAGEMENT_DISPLAY_MODE.HORIZONTAL ?
            <React.Fragment/> :
            <SliderInstructions mode={mode} isDisplayCreative={isDisplayCreative}/>
        }
      </>
    }
    default: {
      console.error('Invalid Option Style');
      return <React.Fragment/>
    }
  }
}
