import {useEffect, useState} from "react";
import {
  // ENGAGEMENT_DISPLAY_MODE,
  ENGAGEMENT_STATUS,
  PLAYER_STATE,
  POSITION_OPTIONS,
  PLAYER_HOSTS,
  INTERACTIVE_ELEMENTS,
  SHOW_CONDITION,
  SHARE_ICON_POSITION,
  CTA_POSITIONS
} from "utils/constants";
import {canShowInSessionTriggerDetails} from 'utils/inSessionTriggerHelper';

const isSocialIconsColliding = (socialShare, playerTime, overlay, overlayState, inVideoCollapsed) => {
  if ([ENGAGEMENT_STATUS.SHOWING_ON_PAUSE, ENGAGEMENT_STATUS.SHOWING_ON_COMPLETE, ENGAGEMENT_STATUS.SHOWING_ON_HOTSPOT].includes(overlayState)) {
    return true
  }
  if (overlayState === ENGAGEMENT_STATUS.NOT_SHOWING) {
    return false;
  }
  if (inVideoCollapsed) {
    return false;
  }

  const pos = socialShare.shareIconPosition.toLowerCase();
  const {inVideoEngagementPosition = ''} = overlay;
  return inVideoEngagementPosition && pos.indexOf(inVideoEngagementPosition.toLowerCase()) !== -1;
}

/**
 *
 * @param isMouseHover
 * @param playerState
 * @param overlayState
 * @param engagementMode
 * @param overlay
 * @param getSpecialSettingPlatformValue
 * @param videoHost
 * @param inVideoCollapsed
 * @param settings
 * @param playerTime
 * @param triggerState
 * @param triggerIdsInPath
 * @returns {{showBottomBrandLogo: (*|boolean|boolean), isCloseButtonShowing: boolean, isGradientShowing: (boolean|*), showTopBrandLogo: (*|boolean|boolean), showElements: (boolean|*), showScrub: (*|boolean), playButtonShowing: (boolean|boolean)}}
 */
function useShowElements(
  isMouseHover, playerState, overlayState, engagementMode, overlay,
  getSpecialSettingPlatformValue,
  videoHost, inVideoCollapsed, 
  settings, playerTime, triggerState, triggerIdsInPath,
  isCreativeStudioPreviewMode, isInScreenShooterEmulation, ignoreLogicAndPath
) {
  const {
    callToAction = {},
    socialShare,
    topLogo = {},
    bottomLogo = {},
    video,
    playButtonEnabled = true,
    scrubBarEnabled = true,
    activeInteractiveElement = '',
    showSubElements = SHOW_CONDITION.HOVER,
  } = settings;

  const [initialScrubShowDone, setInitialScrubShowDone] = useState(false);
  useEffect(() => {
    if (initialScrubShowDone) {
      return
    }

    if (playerState === PLAYER_STATE.PLAYING) {
      setTimeout(() => {
        setInitialScrubShowDone(true);
      }, 3000);
    }
  }, [initialScrubShowDone, playerState, isMouseHover]);

  // Generic Constants - This can be used to derive different states
  const showingOnPause = overlayState === ENGAGEMENT_STATUS.SHOWING_ON_PAUSE;
  const showingOnComplete = overlayState === ENGAGEMENT_STATUS.SHOWING_ON_COMPLETE;
  const showingInVideo = overlayState === ENGAGEMENT_STATUS.SHOWING_IN_VIDEO;
  const showingOnHotspot = overlayState === ENGAGEMENT_STATUS.SHOWING_ON_HOTSPOT;

  const showingFinale = showingOnPause || showingOnComplete;

  const inVideoPosition = overlay?.inVideoEngagementPosition;

  const isInVideoShowingOnLeft = showingInVideo && inVideoPosition === POSITION_OPTIONS.LEFT;
  const isInVideoShowingOnRight = showingInVideo && inVideoPosition === POSITION_OPTIONS.RIGHT;
  const isInVideoShowingOnBottom = showingInVideo && inVideoPosition === POSITION_OPTIONS.BOTTOM;
  const isInVideoShowingOnTop = showingInVideo && inVideoPosition === POSITION_OPTIONS.TOP;

  const logoEnabledOnFinale = (showingOnPause || showingOnComplete) ? overlay.enabledBrandLogoInFinale : true;

  // const isInVideoHorizontal = engagementMode === ENGAGEMENT_DISPLAY_MODE.HORIZONTAL;
  // const isInVideoVertical = engagementMode === ENGAGEMENT_DISPLAY_MODE.VERTICAL;

  // const isFullMode = engagementMode === ENGAGEMENT_DISPLAY_MODE.FULL_MODE;

  const isVPAIDPlayer = videoHost === PLAYER_HOSTS.VAST;
  const isPlayerReady = playerState === PLAYER_STATE.READY;
  const isPlayerPlaying = playerState === PLAYER_STATE.PLAYING;
  const isPlayerPaused = playerState === PLAYER_STATE.PAUSED;
  const isPlayerCompleted = playerState === PLAYER_STATE.COMPLETED;

  // Special Settings
  const enableVgOverlay = isVPAIDPlayer ? getSpecialSettingPlatformValue('enableVgOverlay') : true;
  const showVgPlayButton = isVPAIDPlayer ? (enableVgOverlay && playButtonEnabled) || (isPlayerReady && !!(video?.thumbnail?.linkURL)) : playButtonEnabled;
  const dismissVgOverlay = isVPAIDPlayer ? getSpecialSettingPlatformValue('dismissVgOverlay') : false;

  const showCtaAllTime = showSubElements === SHOW_CONDITION.ALWAYS;
  const {verticalLocation} = callToAction;

  const enableCTABottom = isCreativeStudioPreviewMode && activeInteractiveElement === INTERACTIVE_ELEMENTS.CTA ? true : !(isInVideoShowingOnBottom || isInVideoShowingOnLeft) || inVideoCollapsed;
  const enableCTATop = isCreativeStudioPreviewMode && activeInteractiveElement === INTERACTIVE_ELEMENTS.CTA ? true : !(isInVideoShowingOnTop || isInVideoShowingOnLeft) || inVideoCollapsed;
  const enableCTA = verticalLocation === 'TOP' ? enableCTATop : enableCTABottom;

  const showSpecialCases = (isPlayerPaused || isMouseHover || showCtaAllTime || (isPlayerPlaying && !initialScrubShowDone));

  const canShowCTABasedOnTriggers = canShowInSessionTriggerDetails(callToAction.inSessionTriggerDetails, playerTime, triggerState, triggerIdsInPath, false, showingFinale, ignoreLogicAndPath);

  const showCTA = enableCTA &&
    callToAction.visible && 
    !showingOnHotspot &&
    !showingFinale &&
    showSpecialCases &&
    canShowCTABasedOnTriggers &&
    (isCreativeStudioPreviewMode ? activeInteractiveElement === INTERACTIVE_ELEMENTS.CTA : true);

  const socialIconsColliding = socialShare && socialShare.visible && isSocialIconsColliding(socialShare, playerTime, overlay, overlayState, inVideoCollapsed);
  const canShowShareIconsBasedOnTriggers = socialShare && socialShare.visible && canShowInSessionTriggerDetails(socialShare.inSessionTriggerDetails, playerTime, triggerState, triggerIdsInPath, false, showingFinale, ignoreLogicAndPath);
  const showShareIcons = socialShare && socialShare.visible && !showingOnHotspot &&
      !showingFinale &&
      showSpecialCases &&
      (!socialIconsColliding || (isCreativeStudioPreviewMode && activeInteractiveElement === INTERACTIVE_ELEMENTS.SOCIAL_SHARE)) &&
      canShowShareIconsBasedOnTriggers &&
      (isCreativeStudioPreviewMode ? activeInteractiveElement === INTERACTIVE_ELEMENTS.SOCIAL_SHARE : true);

  const showScrub = scrubBarEnabled &&
    !showingOnHotspot &&
    !showingOnComplete &&
    ((isMouseHover && !isPlayerReady) || (isPlayerPlaying && !initialScrubShowDone) || (isPlayerCompleted || isPlayerPaused));

  const showLogoInSpecialCases = (showingOnPause || showingOnComplete) ? logoEnabledOnFinale : showSpecialCases;

  const isPlayerTopEnabled = topLogo?.visible && topLogo?.mediaId;
  const isPlayerBottomEnabled = bottomLogo?.visible && bottomLogo?.mediaId;
  
  const canShowTopLogoBasedOnTriggers = canShowInSessionTriggerDetails(topLogo?.inSessionTriggerDetails, playerTime, triggerState, triggerIdsInPath, logoEnabledOnFinale, showingFinale, ignoreLogicAndPath);
  const canShowBottomLogoBasedOnTriggers = canShowInSessionTriggerDetails(bottomLogo?.inSessionTriggerDetails, playerTime, triggerState, triggerIdsInPath, logoEnabledOnFinale, showingFinale, ignoreLogicAndPath);

  const showTopBrandLogo = isPlayerTopEnabled &&
                           !showingOnHotspot &&
                           showLogoInSpecialCases &&
                           canShowTopLogoBasedOnTriggers &&
                           (!showingInVideo || isInVideoShowingOnRight || isInVideoShowingOnBottom || inVideoCollapsed || isCreativeStudioPreviewMode) &&
                           canShowTopLogoBasedOnTriggers &&
                           (isCreativeStudioPreviewMode ? activeInteractiveElement === INTERACTIVE_ELEMENTS.TOP_BRAND_LOGO : true);
  
  const showBottomBrandLogo = isPlayerBottomEnabled &&
                              !showingOnHotspot &&
                              showLogoInSpecialCases &&
                              canShowBottomLogoBasedOnTriggers &&
                              (!showingInVideo || isInVideoShowingOnLeft || isInVideoShowingOnTop || inVideoCollapsed || isCreativeStudioPreviewMode) &&
                              canShowBottomLogoBasedOnTriggers &&
                              (isCreativeStudioPreviewMode ? activeInteractiveElement === INTERACTIVE_ELEMENTS.BOTTOM_BRAND_LOGO : true);

  const playButtonShowing = showVgPlayButton &&
    !showingOnHotspot &&
    !showingOnPause &&
    (isPlayerReady || isPlayerPaused) && !isInScreenShooterEmulation;

  const isGradientShowing = !dismissVgOverlay &&
    (isPlayerPaused || showScrub)

  const isCloseButtonShowing = showingInVideo || showingOnHotspot || showingOnPause
  const isAdStoppedButtonShowing = showingOnComplete


  // Legal positions of elements, with advanced pathmaker these can now be configured to collide in some cases, default to a single element in any given corner
  //   Brand logo top = topLeft
  //   Brand logo bottom = bottomRight
  //   Social share = topLeft, topRight, bottomLeft, bottomRight
  //   CTA top/bottom = topLeft, bottomLeft
  let showTopBrandLogoOverride = showTopBrandLogo;
  let showShareIconsOverride = showShareIcons;
  
  const socialSharePosition = socialShare?.shareIconPosition;
  const callToActionPosition = callToAction?.verticalLocation === CTA_POSITIONS.TOP ? SHARE_ICON_POSITION.TOP_LEFT : SHARE_ICON_POSITION.BOTTOM_LEFT;
  const topLogoPosition = SHARE_ICON_POSITION.TOP_LEFT;
  const bottomLogoPosition = SHARE_ICON_POSITION.BOTTOM_RIGHT;

  if (showShareIconsOverride && showCTA && socialSharePosition === callToActionPosition) {
    console.log("socialShare position collides with CTA, hiding socialShare");
    showShareIconsOverride = false;
  }

  if (showShareIconsOverride && showTopBrandLogoOverride && socialSharePosition === topLogoPosition) {
    console.log("socialShare position collides with top logo, hiding socialShare");
    showShareIconsOverride = false;
  }

  if (showShareIconsOverride && showBottomBrandLogo && socialSharePosition === bottomLogoPosition) {
    console.log("socialShare position collides with bottom logo, hiding socialShare");
    showShareIconsOverride = false; 
  }

  if (showCTA && showTopBrandLogoOverride && callToActionPosition === topLogoPosition) {
    console.log("CTA position collides with top logo, hiding top logo");
    showTopBrandLogoOverride = false; 
  }



  return {
    showCTA,
    showScrub,
    showTopBrandLogo: showTopBrandLogoOverride,
    showBottomBrandLogo,
    playButtonShowing,
    isGradientShowing,
    isCloseButtonShowing,
    isAdStoppedButtonShowing,
    showShareIcons: !!showShareIconsOverride // OV-373 return false instead of undefined because SocialIcons component defaults show to true
  };
}

export default useShowElements;
