import React, {useMemo} from 'react';
import {getOverlay} from "utils/engagement-helper";

export const useOverlayComponent = (overlay, settings, onEngagementEvent, inVideo, mode, fireJourneyTrigger, fireCallback, submitSurvey, isInVideoShowing, onClickThrough, getSelectedPosition, setSelectedOption, renderHotspots, creativeDimensions, hotspotProps, scrubBarShowing, isSurveySubmitted, submissionDetails, setsubmissionDetailsTime, overlayState) => {
  const creativeCard = overlay.creativeCard || overlay
  const {OverlayComponent, extraProps} = useMemo(() => getOverlay(creativeCard, settings), [creativeCard, settings]);

  const Engagement = useMemo(() => {
    return <OverlayComponent
      overlay={{creativeCard, isSharedCard: overlay.isSharedCard, inSessionTriggerDetails : overlay.inSessionTriggerDetails, inVideoEngagementPosition: overlay.inVideoEngagementPosition}}
      onEvent={onEngagementEvent}
      inVideo={inVideo}
      mode={mode}
      fireJourneyTrigger={fireJourneyTrigger}
      fireCallback={fireCallback}
      onClickThrough={onClickThrough}
      submitSurvey={submitSurvey}
      showing={inVideo ? isInVideoShowing : true}
      getSelectedPosition={getSelectedPosition}
      setSelectedOption={setSelectedOption}
      renderHotspots={renderHotspots}
      creativeDimensions={creativeDimensions}
      hotspotProps={hotspotProps}
      scrubBarShowing={scrubBarShowing}
      isSurveySubmitted={isSurveySubmitted}
      submissionDetails={submissionDetails}
      setsubmissionDetailsTime={setsubmissionDetailsTime}
      overlayState={overlayState}
      {...extraProps}
    />
  }, [overlay, creativeCard, onEngagementEvent, inVideo, mode, fireJourneyTrigger, fireCallback, onClickThrough, submitSurvey, isInVideoShowing, getSelectedPosition, setSelectedOption, renderHotspots, creativeDimensions, hotspotProps, extraProps, scrubBarShowing, isSurveySubmitted, submissionDetails, setsubmissionDetailsTime, overlayState]);

  return Engagement;
}
