import React, {useEffect, useMemo, useCallback, useState} from "react";

import {PhoenixCore, constants,} from "@phoenix/core";
import useSettings from "hooks/useSettings";
import useResizeScreen from "hooks/useResizeScreen";
import useFonts from "hooks/useFonts";
import RuntimeProperties from "utils/runtimeProperties";
import Environment from "utils/environment";

import useHeavyAdReporting from 'hooks/useHeavyAdReporting';
import useFireDspUserIdFetchPixels from "hooks/useFireDspUserIdFetchPixels";
import EventHelper from 'utils/eventHelper';
import LoggerHelper from 'utils/loggerHelper';
import localConfig from "../local-config";

import style from './main.css'
import useHighEntropyClientHints from "hooks/useHighEntropyClientHints";

const {CREATIVE_MODE, OVERLAY_INDEX, DISPLAY_ENGAGEMENT_INDEX, LOG_DETAILS_TYPE, PLAYER_STATE, DEFAULT_ASPECT_RATIO} = constants;


const captureAssetLoadingTimeInstance = LoggerHelper.instance.captureAssetLoadingTime.bind(LoggerHelper.instance);
const captureInstance = EventHelper.instance.capture.bind(EventHelper.instance);
const capturePlayerLoadTimeInstance = EventHelper.instance.capturePlayerLoadTime.bind(EventHelper.instance);
const captureSentryErrorInstance = EventHelper.instance.captureSentryError.bind(EventHelper.instance);
const shouldPhoenixSkipFiringStandardVastPixelInstance= EventHelper.instance.shouldPhoenixSkipFiringStandardVastPixel.bind(EventHelper.instance);

function AppContainer({reload, removeAd}) {

  const creativeId = RuntimeProperties.isVideoExploreCampaign() ? RuntimeProperties.getVideoExploreCampaignId() : Environment.getAdUnitId();
  // TODO: Using variables outside of react prop/state as inputs to useEffect hooks inside useSettings (and to components in general) may cause subtle race and rerendering timing issues...  
  const [settings, overrideSettings, errorState] = useSettings({creativeId, isDisplay: Environment.isDisplayCreative(), isExplorer: RuntimeProperties.isVideoExploreCampaign(), ed: Environment.getEd(), isShareLandingPage: Environment.isShareLandingPage()});
  const {creativeType, flowType, video, mediaType, width, height} = settings || {};
  const aspectRatio = video?.videoAspectRatio || DEFAULT_ASPECT_RATIO;
  const clientHints = useHighEntropyClientHints();
  
  EventHelper.instance.init({...settings, clientHints})

  const {fontsLoaded} = useFonts(settings);
  useFireDspUserIdFetchPixels(settings);
  useHeavyAdReporting()
  const {isResized, creativeDimensions} = useResizeScreen({creativeType, aspectRatio, mediaType, width, height});

  const ready = useMemo(() => Boolean(creativeType && isResized && fontsLoaded), [creativeType, isResized, fontsLoaded]);

  if(errorState) {
    return  <div className = {style.geoRestrictionErrorMessage}><img src={require('./images/circle-exclamation-light_white.svg').default} alt="Exclamation Mark"/>{errorState}</div>
  }

  if (ready) {
    return (
      <PhoenixCore
        settings={settings}
        creativeDimensions={creativeDimensions}
        reloadAd={reload}
        removeAd={removeAd}
        
        localConfig={localConfig}
        environment={Environment}
        runtimeProperties={RuntimeProperties}
        appRootId={"app"}

        captureAssetLoadingTime={captureAssetLoadingTimeInstance}
        capture={captureInstance}
        capturePlayerLoadTime={capturePlayerLoadTimeInstance}
        captureSentryError={captureSentryErrorInstance}
        shouldPhoenixSkipFiringStandardVastPixel={shouldPhoenixSkipFiringStandardVastPixelInstance}
      />
    );
  } else {
    return <React.Fragment/>
  }
}

export default AppContainer;
