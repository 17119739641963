import React, {useEffect, useState} from "react";
import classnames from "classnames";
import {Carousel as ReactCarousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import CarouselItem from 'compounds/carousel-item';
import {ENGAGEMENT_DISPLAY_MODE} from 'utils/constants';
import {isHybridCarousel} from 'utils/carousel-helper';

import style from './style.module.scss'


const NO_OF_ELEMENTS_PER_SLIDE = 3

function Carousel({items, creativeAspectRatio, mode = ENGAGEMENT_DISPLAY_MODE.FULL_MODE, onClick, refElement, maxPercentageHeight}) {
  const noOfItemsPerSlide = mode === ENGAGEMENT_DISPLAY_MODE.VERTICAL ? 1 : NO_OF_ELEMENTS_PER_SLIDE
  const carouselNeeded = isCarouselNeeded(items, noOfItemsPerSlide);
  const isAnyHybridCarouselSlide = carouselNeeded && isHybridCarousel(items)
  const carouselArrowClasses = getCarouselArrowClasses(mode, isAnyHybridCarouselSlide);

  const [containerHeight, setContainerHeight] = useState(null);

  useEffect(() => {
    if (refElement?.current) {
      const heightPercentage = maxPercentageHeight || 1;
      const element = refElement.current;
      const height = element.clientHeight * heightPercentage;

      setContainerHeight(height);
    }
  }, [maxPercentageHeight, refElement]);

  const renderContent = () => {
    return getItemsPerSlide(noOfItemsPerSlide, items).map((itemsPerSlide, parentIndex) => {
      const isHybrid = isHybridCarousel(itemsPerSlide)
      return (
        <div className={carouselNeeded ? style.carouselSlideContainer : style.carouselContainer} key={parentIndex}>
          {
            itemsPerSlide.map((item, index) => {
              const position = parentIndex * noOfItemsPerSlide + index + 1;
              return (<div className={classnames(getClasses(items.length, mode, creativeAspectRatio))}
                           key={`${parentIndex}_${index}`}>
                <CarouselItem
                  position={position} mode={mode}
                  key={`${parentIndex}_${items.length}_${index}`}
                  {...getProps(item)}
                  isHybrid={isHybrid}
                  onClick={onClick}
                  containerHeight={containerHeight}
                />
              </div>)
            })
          }
        </div>
      )
    });
  }

  return carouselNeeded ? <ReactCarousel
    width={'100%'}
    showArrows={true}
    infiniteLoop={true}
    swipeable={true}
    emulateTouch={true}
    showStatus={false}
    showThumbs={false}
    showIndicators={false}
    transitionTime={500}
    renderArrowPrev={(onClickHandler, hasPrev, label) =>
      hasPrev && (
        <button
          className={classnames(carouselArrowClasses)}
          type="button" onClick={onClickHandler} title={label} style={{left: '1.5%'}}>
          <i className={style.carouselControlLeft} aria-hidden="true"/>
        </button>
      )
    }
    renderArrowNext={(onClickHandler, hasNext, label) =>
      hasNext && (
        <button
          className={classnames(carouselArrowClasses)}
          type="button" onClick={onClickHandler} title={label} style={{right: '1.5%'}}>
          <i className={style.carouselControlRight} aria-hidden="true"/>
        </button>
      )
    }
  >
    {renderContent()}
  </ReactCarousel> : renderContent();
}

const getClasses = (itemsCount, mode, creativeAspectRatio) => {
  const classes = [style.carouselItemWrapper];
  switch (creativeAspectRatio) {
    case 'FourByThree':
      classes.push(style.fourByThree);
      break;
    case 'SixteenByNine':
      classes.push(style.sixteenByNine);
      break;
    default:
      break;
  }
  classes.push(style[`count${itemsCount}`]);
  mode === ENGAGEMENT_DISPLAY_MODE.HORIZONTAL && classes.push(style.horizontalDisplay)
  mode === ENGAGEMENT_DISPLAY_MODE.VERTICAL && classes.push(style.verticalDisplay)
  return classes;
}

function isCarouselNeeded(items, noOfItemsPerSlide) {
  return noOfItemsPerSlide > 0 && items.length > noOfItemsPerSlide
}

function getProps(item) {
  if (!item) {
    return {}
  }
  const {
    imageSrcUrl,
    itemId,
    adUnitId,
    imageLandingSrcUrl,
    titleFontSettings,
    layoutType,
    buttonColor,
    title,
    itemType,
    optionIndex,
  } = item
  return {
    imageSrcUrl,
    itemId,
    adUnitId,
    imageLandingSrcUrl,
    titleFontSettings,
    layoutType,
    buttonColor,
    title,
    itemType,
    optionIndex,
  }
}

function getItemsPerSlide(noOfItemsPerSlide, items) {
  const temp = [].concat.apply([],
    items.map((elem, i) => {
      return i % noOfItemsPerSlide ? [] : [items.slice(i, i + noOfItemsPerSlide)];
    })
  );
  if (items.length <= noOfItemsPerSlide) {
    return temp;
  }
  // Fill remaining elements with null
  const lastArray = temp[temp.length - 1]
  for (let index = lastArray.length; index < noOfItemsPerSlide; index++) {
    lastArray[index] = null
  }
  return temp;
}

function getCarouselArrowClasses(mode, isAnyHybridCarouselSlide) {
  return {
    [style.carouselControlButton]: true,
    [style.horizontalDisplay]: mode === ENGAGEMENT_DISPLAY_MODE.HORIZONTAL,
    [style.verticalDisplay]: mode === ENGAGEMENT_DISPLAY_MODE.VERTICAL,
    [style.hybrid]: isAnyHybridCarouselSlide
  }
}

export default Carousel;
