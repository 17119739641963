import {useEffect} from "react";
import {captureException} from "@sentry/browser";
import {AUTOPLAY_MODE, PLAYER_STATE, ErrorType} from "utils/constants";
import CustomError from "utils/customError";

function useConnectAutoStartModeChange(autoPlayMode, state, setAutoplayUnMutedError, {play, mute}) {
  useEffect(() => {
    if(!state || state !== PLAYER_STATE.READY) {
      return
    }

    const playAndSetError = _ => {
      try {
        console.log(`Play request, playmode: ${autoPlayMode}`);
        play();
      } catch (e) {
        setAutoplayUnMutedError(true);
        captureException(new CustomError(ErrorType.AUTOPLAY_START_FAIL), {extra: {errorType: ErrorType.AUTOPLAY_START_FAIL}});
      }
    }

    if(autoPlayMode === AUTOPLAY_MODE.AUTOPLAY_UNMUTED) {
      playAndSetError();
    } else if(autoPlayMode === AUTOPLAY_MODE.AUTOPLAY_MUTED) {
      mute(true);
      playAndSetError();
    }
  }, [autoPlayMode, state, mute, play, setAutoplayUnMutedError])
}

export default useConnectAutoStartModeChange;
