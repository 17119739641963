import {PLAYER_STATE} from "./constants";

export const getVideoElapsedPercentage = (playerDuration, playerTime, playerState) => {
  if (playerState === PLAYER_STATE.COMPLETED) {
    return 100;
  }
  if (playerDuration && playerTime) {
    return Math.floor(100 * (playerTime / playerDuration));
  } else {
    return 0;
  }
}
