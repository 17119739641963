// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".listContainer--3Rd5K{flex:1;font-size:calc(var(--rootFontSize) * 1);line-height:1.5;color:#303030;font-weight:500;text-align:center;display:flex;margin:calc(var(--rootFontSize) * 2.2) calc(var(--rootFontSize) * 0.5) calc(var(--rootFontSize) * 0.5);overflow:hidden}.list--1cqoV{flex:1;height:100%;text-align:center;box-sizing:border-box;overflow:auto}.list--1cqoV::-webkit-scrollbar{display:none}", ""]);
// Exports
exports.locals = {
	"listContainer": "listContainer--3Rd5K",
	"list": "list--1cqoV"
};
module.exports = exports;
