// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container--oULXB{position:absolute;height:100%;width:100%;top:0;left:0}.pointerEventsNone--2rahQ{pointer-events:none}", ""]);
// Exports
exports.locals = {
	"container": "container--oULXB",
	"pointerEventsNone": "pointerEventsNone--2rahQ"
};
module.exports = exports;
