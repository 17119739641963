/* global google */
import React, {useEffect, useRef, useState} from "react";
import ResizeObserver from "resize-observer-polyfill";

import {
  createMapIcon,
  MAX_NUMBER_OF_STORES_TO_ADD_NUMBER_TO,
  recenterMap,
  resetBounds
} from "utils/store-locator-helper";

import style from './style.module.scss';
import {debounce} from "../../utils/helpers";

const debouncedResize = debounce(recenterMap, 100);

export function Map(
  {
    centerLat,
    centerLng,
    markerColor,
    markerFontColor,
    useDefaultIcon,
    iconImage,
    onMarkerClick,
    markers = []
  }
) {
  const [lastMarker, setLastMarker] = useState(null);
  const mapRef = useRef();
  const onMarkerClickRef = useRef(onMarkerClick);

  useEffect(() => {
    onMarkerClickRef.current = onMarkerClick
  }, [onMarkerClick]);

  useEffect(() => {
    let resizeObserver = null;
    // Added check for ResizeObserver - as its not available in Internet Explorer
    if (mapRef && lastMarker && ResizeObserver) {
      const {marker, markerPin, map} = lastMarker;
      const el = mapRef.current;

      resizeObserver = new ResizeObserver(() => {
        debouncedResize(map, markerPin, marker, centerLat, centerLng, () => {
          onMarkerClickRef.current(map, marker, markerPin, false, setLastMarker);
        });
      });

      resizeObserver.observe(el);
    }
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    }
  }, [mapRef, lastMarker, centerLat, centerLng])

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    const el = mapRef.current;

    const map = new google.maps.Map(el, {
      zoom: 14,
      center: {lat: centerLat, lng: centerLng},
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false
    });

    const addMarker = (marker, index) => {
      const icon = useDefaultIcon ?
        createMapIcon(markerColor, markerFontColor, index < MAX_NUMBER_OF_STORES_TO_ADD_NUMBER_TO ? index + 1 : '') :
        iconImage;

      const markerPin = new google.maps.Marker({
        position: {lat: marker.latitude, lng: marker.longitude},
        map,
        title: marker.name,
        icon
      });

      markerPin.addListener('click', () => {
        setLastMarker({
          marker,
          markerPin,
          map
        });
        onMarkerClickRef.current(map, marker, markerPin, true, setLastMarker);
      });
      return markerPin;
    };

    const markerPins = markers.map(addMarker);
    resetBounds(map, markerPins, centerLat, centerLng);
  }, [centerLat, centerLng, mapRef, iconImage, markers, markerColor, markerFontColor, useDefaultIcon]);

  return <div ref={mapRef} className={style.mapContainer}>

  </div>;
}
