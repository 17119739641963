// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".customHTMLContainer--yezEu{width:100%;height:100%;display:flex;justify-content:stretch;align-items:stretch;position:relative}.customHTML--1h6pb{width:100%;height:100%;border:0;display:block}.clickOverlay--3KoL3{position:absolute;top:0;left:0;height:100%;width:100%;z-index:1}", ""]);
// Exports
exports.locals = {
	"customHTMLContainer": "customHTMLContainer--yezEu",
	"customHTML": "customHTML--1h6pb",
	"clickOverlay": "clickOverlay--3KoL3"
};
module.exports = exports;
