import React from 'react';
import { CH_UA_IFRAME_ALLOW } from 'utils/constants';

export function CustomEmbedRenderer(props) {
  const {iframeIdentifierGroup, iframeSrc, scriptContent, scriptSrc, bodyContent, dataTestId, enableScroll, setRef} = props;
  return <iframe
    ref={setRef}
    className={iframeIdentifierGroup}
    scrolling={enableScroll ? "auto": "no"}
    src={iframeSrc}
    srcDoc={iframeSrc ? null : `
            <!DOCTYPE html>
            <html>
              <head>
                <style>
                    html, body {
                        height: 100%;
                        position: relative;
                        margin: 0;
                        padding: 0;
                        scrollbar-arrow-color: transparent;
                        scrollbar-track-color: transparent;
                        scrollbar-3dlight-color: transparent;
                        scrollbar-face-color: #cdc9c9;
                        scrollbar-base-color: transparent;
                        scrollbar-darkshadow-color: transparent;
                        scrollbar-highlight-color: transparent;
                        scrollbar-shadow-color: transparent;
                        scrollbar-width : thin;
                    }
                    ::-webkit-scrollbar {
                         width: 7px;
                         background-color: transparent;
                    }
                    ::-webkit-scrollbar-thumb {
                        background: #cdc9c9;
                        border-radius: 6px;
                    }
                </style>
                <script>
                  ${scriptContent}
                </script>
                ${scriptSrc ? `<script src="${scriptSrc}"></script>` : ""}
              </head>
              <body>
                ${bodyContent}
              </body>
            </html>
          `}
    data-testid = {dataTestId ? dataTestId : null}
    allow={CH_UA_IFRAME_ALLOW}
  />
}
