import React, {useMemo} from "react";
import style from "common/styles/engagement-likert-survey.module.scss";

export function StarAnimation(props) {
  const {starColor} = props;

  const favSparkleLineDetailStyle = useMemo(() => ({background: starColor}), [starColor]);
  const starColorStyle = useMemo(() => ({color: starColor}), [starColor]);

  return <>
    <div className={style.favBloom} style={starColorStyle}/>
    <div className={style.favSparkle}>
      {[...Array(5).keys()].map((i) => {
        return (
          <div className={style.favSparkleLine} key={i}>
            <div className={style.favSparkleLineDetail} style={favSparkleLineDetailStyle}/>
          </div>
        )
      })}
    </div>
  </>
}
