import React, {useCallback, useMemo} from 'react';
import useCustomSurveyEvents from './useCustomSurveyEvents';
import {useConfig} from 'utils/ConfigProvider';
import surveyHelpers from "../../utils/survey-helper";
import {CustomSurveyQuestionRenderer} from "./renderer";
import classnames from "classnames";
import style from "./style.module.scss";
import {useHotspotContainerDimension} from "hooks/useHotspotContainerDimension";
import {useHotspotInteractions} from "hooks/useHotspotInteractions";

export function CustomSurvey(props) {
  const {
    onEvent,
    fireJourneyTrigger,
    overlay,
    onClickThrough,
    submitSurvey,
    trackSurveyClickThrough,
    showing,
    inVideo = false,
    renderHotspots,
    hotspotProps
  } = props;
  const {creativeCard, isSharedCard} = overlay
  const {customSurveyEngagement} = creativeCard;
  const surveyID = customSurveyEngagement.id;
  const question = useMemo(() => customSurveyEngagement.questions?.[0], [customSurveyEngagement.questions]);
  const surveyRules = useMemo(() => {
    return customSurveyEngagement?.surveyRules
      .map((surveyRule) => ({
        ...surveyRule,
        optId: question?.options?.find((option) => option.optionIndex === surveyRule.rating)?.id
      }))
  }, [customSurveyEngagement?.surveyRules, question?.options]);

  const {environment} = useConfig();

  const {
    onClick: onClickEventHandler,
  } = useCustomSurveyEvents(onEvent, inVideo, fireJourneyTrigger, submitSurvey, trackSurveyClickThrough, onClickThrough, overlay?.inSessionTriggerDetails?.triggerElementId, environment.isVPAIDEnabled());

  const {containerDimensions, setHotspotContainer, hotspotContainer} = useHotspotContainerDimension();

  const isCreativeStudioPreviewMode = useMemo(() => {
    return environment.isCreativeStudioPreviewMode instanceof Function ? environment.isCreativeStudioPreviewMode() : false;
  }, [environment]);

  const {selectedHotspots, standaloneHotspots} = useMemo(() => {
    if (hotspotProps && hotspotProps?.isCustomSurveyQuestionEditable && hotspotProps?.canEdit && (!isSharedCard || hotspotProps?.allowSharedCardEdit)) {
      const {standaloneHotspots, selectedHotspots} = hotspotProps;
      /*
      * This is only used for Card that are triggered with a Hotspot,
      * So we need a list in which Standalone (Video) Hotspots are not there.
      *
      * For now its being used only by Custom Survey as it is the only survey that is triggered by Hotspot, and can have interaction.
      * (For all other Cards, we don't allow Hotspots on them when triggered by a Standalone Hotspot)
      * */
      const selectedHotspotsWithoutStandaloneHotspots = (selectedHotspots || []).filter((id) => !(standaloneHotspots.includes(id)));

      /*
      * If nothing is Selected, select the Question by Default
      * */
      if (!selectedHotspotsWithoutStandaloneHotspots?.length) {
        selectedHotspotsWithoutStandaloneHotspots.push(hotspotProps.getHotspotIdForSelection(question));
      }

      return {
        selectedHotspots: selectedHotspotsWithoutStandaloneHotspots,
        standaloneHotspots
      };
    }
    return {};
  }, [hotspotProps, question, isSharedCard]);

  const interactProps = useHotspotInteractions({
    canInteract: !!(isCreativeStudioPreviewMode && (!isSharedCard || hotspotProps?.allowSharedCardEdit)),
    hotspotProps,
    selectedHotspots,
    standaloneHotspots,
    onChangeDeltaOnly: isSharedCard,
  });

  const {
    canInteract,
    setHotspotInteractionContainer,
    openHotspot,
    isHotspotSelected,

    selectedHotspotSelector,
    hotspotPopperSelector,
    snappingHotspotSelector,
  } = useMemo(() => interactProps, [interactProps]);

  const setHotspotContainerRef = useCallback((ref) => {
    setHotspotContainer(ref);
    if (setHotspotInteractionContainer instanceof Function) {
      setHotspotInteractionContainer(ref);
    }

  }, [setHotspotContainer, setHotspotInteractionContainer]);

  const memoizedQuestions = useMemo(() => {
    const onClick = (selectedQuestionOptionID, position, optionIndex, questionId) => {
      if (hotspotProps?.openHotspot instanceof Function) {
        hotspotProps.openHotspot(optionIndex);
      } else {
        onClickEventHandler(surveyID, questionId, position, optionIndex, selectedQuestionOptionID, surveyHelpers.getSelectedQuestionOptionRule(surveyRules, selectedQuestionOptionID));
      }
    };

    return customSurveyEngagement.questions.map((question, index) => {
      return <CustomSurveyQuestionRenderer
        question={question}
        key={index}
        onClick={onClick}
        containerDimensions={containerDimensions}
        hotspotContainer={hotspotContainer}
        canInteract={canInteract}
        openHotspot={openHotspot}
        isHotspotSelected={isHotspotSelected}
        selectedHotspotSelector={selectedHotspotSelector}
        hotspotPopperSelector={hotspotPopperSelector}
        snappingHotspotSelector={snappingHotspotSelector}
        isCreativeStudioPreviewMode={isCreativeStudioPreviewMode}
      />
    })
  }, [customSurveyEngagement, containerDimensions, hotspotContainer, canInteract, openHotspot, surveyID, surveyRules, hotspotProps,
      isHotspotSelected, selectedHotspotSelector, hotspotPopperSelector, snappingHotspotSelector, isCreativeStudioPreviewMode, onClickEventHandler])

  if (!showing) {
    return <React.Fragment/>;
  }

  return <div
    className={classnames(style.container, isCreativeStudioPreviewMode ? style.pointerEventsNone : null)}
    ref={setHotspotContainerRef}
  >
    {
      renderHotspots ?
        renderHotspots({renderWithoutContainer: true, hotspotContainer, interactProps, containerDimensions}) :
        <React.Fragment/>
    }
    {memoizedQuestions}
  </div>
}
