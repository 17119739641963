// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".flexFull--1SFf9{display:flex;width:100%;height:100%}.engagementCarousel--2hnpc{flex-direction:column;justify-content:center}.titleContainerHorizontal--2VnT2{width:40%;display:flex;flex-direction:row;flex:4 1 0}.carouselContainerHorizontal--hF1Ja{justify-content:center;align-items:center;flex:6 1 0;width:60%;display:flex;flex-direction:row}.titleContainer--22b-c{padding:0 2%;min-height:0;max-height:calc(25% - calc(var(--rootFontSize) * 1));margin-bottom:calc(var(--rootFontSize) * 1);display:flex}.titleContainerFullMode--1EPCm{flex:1;width:100%;display:flex;justify-content:center;line-height:1.5;align-items:center;overflow-wrap:break-word;word-break:break-word}.carouselContainer--TSdW2{max-height:75%}.titleContainerVertical--3auRK{flex:2 1;max-height:100%;display:flex;justify-content:center;align-items:center;overflow-wrap:break-word;word-break:break-all}.verticalCarousel--2StCR{flex:auto;display:flex;justify-content:center;align-items:flex-start}", ""]);
// Exports
exports.locals = {
	"flexFull": "flexFull--1SFf9",
	"engagementCarousel": "engagementCarousel--2hnpc flexFull--1SFf9",
	"titleContainerHorizontal": "titleContainerHorizontal--2VnT2",
	"carouselContainerHorizontal": "carouselContainerHorizontal--hF1Ja",
	"titleContainer": "titleContainer--22b-c",
	"titleContainerFullMode": "titleContainerFullMode--1EPCm",
	"carouselContainer": "carouselContainer--TSdW2",
	"titleContainerVertical": "titleContainerVertical--3auRK titleContainer--22b-c",
	"verticalCarousel": "verticalCarousel--2StCR"
};
module.exports = exports;
