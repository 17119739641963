import React, {useEffect, useState} from 'react';
import classnames from 'classnames';

import {Button, Title} from 'elements/typography';
import {Flex} from 'elements/containers';
import {CLICK_SRC_WIDGET, DEFAULT_FONT, DEFAULT_FONT_COLOR} from 'utils/constants';
import {getTarget, openUrl} from 'utils/url-helper';
import {useConfig} from "utils/ConfigProvider";

import style from './style.module.scss';

function Cta({callToAction, isShowing, onClick}) {
  const {environment} = useConfig();
  const isCreativeStudioPreviewMode = environment.isCreativeStudioPreviewMode();
  const {heading, font, description1, description2, displayURL} = callToAction;
  const [dimensions, setDimensions] = useState({height: window.innerHeight, width: window.innerWidth});
  const SMALL_CTA_HEADER_FONTSIZE = '0.8em';
  const LARGE_CTA_HEADER_FONTSIZE = '1em';
  const SMALL_CTA_LINES_FONTSIZE = '0.7em';
  const LARGE_CTA_LINES_FONTSIZE = '.9em';
  const CTA_FONTSIZE_SCREEN_WIDTH_BREAKPOINT = 500;

  useEffect(() => {

    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize)
    }

  }, [])


  const headingFontSettings = {
    color: heading?.color || DEFAULT_FONT_COLOR,
    font: font || DEFAULT_FONT
  };

  // Description line colors are stored in description 1 only
  const descriptionFontSettings = {
    color: description1?.color || DEFAULT_FONT_COLOR,
    font: font || DEFAULT_FONT
  }

  const output =  (
    <div className={style.ctaContainer}>
        <div className={classnames(style.cta)}>
          <Flex.Column justify = {'around'}>
            <Title fontSettings={headingFontSettings} padding='0' fontSize={dimensions.width > CTA_FONTSIZE_SCREEN_WIDTH_BREAKPOINT ? SMALL_CTA_HEADER_FONTSIZE : LARGE_CTA_HEADER_FONTSIZE}>{heading?.text}</Title>
            {description1?.text && <Title fontSettings={descriptionFontSettings} padding='0' fontSize={dimensions.width > CTA_FONTSIZE_SCREEN_WIDTH_BREAKPOINT ? SMALL_CTA_LINES_FONTSIZE : LARGE_CTA_LINES_FONTSIZE}>{description1?.text}</Title>}
            {description2?.text && <Title fontSettings={descriptionFontSettings} padding='0' fontSize={dimensions.width > CTA_FONTSIZE_SCREEN_WIDTH_BREAKPOINT ? SMALL_CTA_LINES_FONTSIZE : LARGE_CTA_LINES_FONTSIZE}>{description2?.text}</Title>}
          </Flex.Column>
        </div>
    </div>
  )

  return isShowing ? (
    <Button onClick={() => !isCreativeStudioPreviewMode ? handleCTAClick(displayURL, onClick) : null} noPointer={isCreativeStudioPreviewMode}>
      {output}
    </Button>
  ) : <>{output}</>
}

function handleCTAClick(displayURL, onClick) {
  if (displayURL) {
    const target = getTarget(displayURL);
    openUrl(displayURL, target);
  }
  onClick(CLICK_SRC_WIDGET.VIDEO_SCREEN_HYPER_LINK)
}


export default Cta;
