import React, {useCallback, useMemo} from 'react';

import OverlayFinale from "compounds/overlay-finale";
import {getOverlay, getProps} from "utils/engagement-helper";
import {ENGAGEMENT_DISPLAY_MODE, CREATIVE_CARD_TYPE, DisplayMediaType, journeyTriggerExposureEvents} from 'utils/constants';

import style from "./style.module.scss";

import useDisplayCardExposureEvents from "hooks/useDisplayCardExposureEvents";

const supportedDisplayEngagementTypes = [
    CREATIVE_CARD_TYPE.LIKERT_SURVEY,
    CREATIVE_CARD_TYPE.IMAGE_SURVEY,
    CREATIVE_CARD_TYPE.MULTIPLE_CHOICE_SURVEY,
    CREATIVE_CARD_TYPE.STORE_LOCATOR,
    CREATIVE_CARD_TYPE.CUSTOM_SURVEY,
    CREATIVE_CARD_TYPE.PRODUCT_EXPLORER
];

function DisplayEngagement(
  {
    settings,
    displayEngagement,
    fireJourneyTrigger,
    submitSurvey,
    trackSurveyClickThrough,
    fireCallback,
    onEvent,
    isSurveySubmitted,
    cardHotspotFireJourneyTriggerWrapper,
    onHotspotClickThrough,
    renderHotspots,
    creativeDimensions,
    isContentFlowCreative,
    setCurrentSlideId,
    hotspotProps
  }
) {

  const {mediaType, isHeavyAd} = settings;
  const {creativeCard} = displayEngagement
  const {creativeCardType, multipleChoiceEngagement} = creativeCard;
  const pollingEnabled = multipleChoiceEngagement?.pollingEnabled;

  const {OverlayComponent, extraProps} = useMemo(() => getOverlay(creativeCard, settings), [creativeCard, settings]);
  const props = useMemo(() => getProps(displayEngagement), [displayEngagement]);
  const surveySubmitted = useMemo(() => isSurveySubmitted(props.surveyID), [isSurveySubmitted, props.surveyID]);
  const displayEngagementWithUpdatedMediaType = useMemo(() => ({...displayEngagement, mediaType}),[displayEngagement, mediaType]);
  const fireJourneyExposureTrigger = useCallback(() => {
    if (journeyTriggerExposureEvents[creativeCardType]) {
      fireJourneyTrigger(journeyTriggerExposureEvents[creativeCardType]);
    }
  }, [creativeCardType, fireJourneyTrigger]);
  useDisplayCardExposureEvents(displayEngagementWithUpdatedMediaType, !surveySubmitted, onEvent, fireJourneyExposureTrigger);

  // For initial studio creation, displayEngagement is not set
  if (!creativeCardType) {
    return null;
  }

  if (!supportedDisplayEngagementTypes.includes(creativeCardType)) {
    // TODO: should we throw an error and stop the creative instead of continuing?
    console.log(`Unsupported displayEngagementType: ${creativeCardType}`);
  }

  return (
    <OverlayFinale
      {...props}
      scrubShowing={false}
      closeShowing={false}
      isFull={creativeCardType === CREATIVE_CARD_TYPE.PRODUCT_EXPLORER}
    >
      {!surveySubmitted || (surveySubmitted && pollingEnabled)? (
          <OverlayComponent
            overlay={displayEngagement}
            onEvent={onEvent}
            inVideo={false}
            mode={ENGAGEMENT_DISPLAY_MODE.FULL_MODE}
            fireJourneyTrigger={fireJourneyTrigger}
            submitSurvey={(...args) => {
              submitSurvey(...args);
            }}
            trackSurveyClickThrough={trackSurveyClickThrough}
            showing={true}
            isDisplayCreative={true}
            fireCallback={fireCallback}
            cardHotspotFireJourneyTriggerWrapper={cardHotspotFireJourneyTriggerWrapper}
            onHotspotClickThrough={onHotspotClickThrough}
            renderHotspots={renderHotspots}
            creativeDimensions={creativeDimensions}
            isContentFlowCreative={isContentFlowCreative}
            isHeavyAd={isHeavyAd}
            setCurrentSlideId = {setCurrentSlideId}
            hotspotProps={hotspotProps}
            surveySubmitted={surveySubmitted}
            {...extraProps}
          />
        ) : (
          <div className={style.thankYouContainer}>
            <div className={style.thankYouText} data-testid="thank-you-text">Thank you!</div>
          </div>
        )
      }
    </OverlayFinale>
  );
}

export default DisplayEngagement;
