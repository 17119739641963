import React, {useEffect, useMemo, useState} from 'react';
import classnames from "classnames";
import {Carousel as ReactCarousel} from 'react-responsive-carousel';

import HotspotManager from "modules/hotspot-manager";
import {getOpacityStyle} from "utils/engagement-helper";
import {useConfig} from 'utils/ConfigProvider';
import {isMobileDevice, isChrome} from 'utils/environmentHelper';

import {useProductExplorerEngagementEvents} from './useProductExplorerEngagementEvents';

import style from './style.module.scss';

export const MAX_INDICATORS = 5;

const noOp = () => {};

function ProductExplorerSlide(
  {
    slide,
    overlayID,
    onSlideImageLinkClick,
    hotspotJourneyTriggerHandler,
    onHotspotEvent,
    onHotspotClickThrough,
    width,
    height,
    loadSlide,
    noClick,
    hotspotProps
  }
) {
  const imgStyle = useMemo(() => {
    return {
      ...getOpacityStyle(slide?.rawImage?.opacity),
    }
  }, [slide.rawImage]);

  return (
    <div>
      <div className={style.imageContainer}>
        <a
          className={slide.trackingURL && !noClick ? style.image : style.imageNoPointer}
          onClick={!noClick ? onSlideImageLinkClick : null}
          data-testid="slide-image-link"
          rel={'noreferrer'}
          style={{width, height}}
        >
          <img style={imgStyle} src={loadSlide ? slide?.rawImage?.linkURL : null}/>
        </a>
      </div>
      {slide.hotspots && loadSlide &&
        <HotspotManager
          hotspots={slide.hotspots}
          onEvent={onHotspotEvent}
          fireJourneyTrigger={hotspotJourneyTriggerHandler}
          showing={true} // always show when slide is showing
          setHotspotOverlay={noOp} //  noop
          onClickThrough={onHotspotClickThrough}
          isCardHotspot={true}
          overlayID={overlayID}
          hotspotProps={hotspotProps}
        />
      }
    </div>
  );
}


function CustomIndicators(
  {
    currentSlideIndex,
    onSlideIndicatorClick,
    currentIndicatorMax,
    currentIndicatorMin,
    totalSlides,
    isDisabled
  }
) {
  if (currentIndicatorMax < 0) {
    return null;
  }

  const slideIndices = [...Array(totalSlides).keys()];

  return (
    <div className={style.customIndicators}>
      <div className={style.customIndicatorList}>
        {
          slideIndices.map((index) => (
            <div
              key={index}
              className={
                classnames(
                  index === currentSlideIndex ? style.customIndicatorListItemSelected : style.customIndicatorListItem,
                  {[style.visibleIndicatorListItem]: index <= currentIndicatorMax && index >= currentIndicatorMin}
                )
              }
              onClick={() => {
                !isDisabled && onSlideIndicatorClick(index)
              }}
            />
          ))
        }
      </div>
    </div>
  );
}


export function calcInitialMaxIndicatorIndex(totalSlides, currentSlideIndex, maxIndicators) {
  return currentSlideIndex < (maxIndicators - 1) ? Math.min(maxIndicators - 1, totalSlides - 1) : Math.min(currentSlideIndex + 1, totalSlides - 1);
}

export function calcTransitionMaxIndicatorIndex(oldIndex, newIndex, totalSlides, currentIndicatorMax, currentIndicatorMin, maxIndicators) {
  if (newIndex > totalSlides - 1 || newIndex < 0) {
    throw Error(`Invalid index (${newIndex}) for product explorer slide transition with slide count ${totalSlides}`);
  }

  if (newIndex === 0 || newIndex === totalSlides - 1) {
    // loop to begin and loop to end handling
    return calcInitialMaxIndicatorIndex(totalSlides, newIndex, maxIndicators);
  } else if (newIndex > oldIndex) {
    // slide/jump forward check against max
    return newIndex >= currentIndicatorMax ? newIndex + 1 : currentIndicatorMax;
  } else {
    // slide/jump back check against min
    return newIndex <= currentIndicatorMin ? newIndex - 1 + maxIndicators - 1 : currentIndicatorMax;
  }
}

export function ProductExplorer(
  {
    onEvent,
    overlay,
    fireJourneyTrigger,
    onClickThrough,
    selectedProductExplorerSlideIndex,
    cardHotspotFireJourneyTriggerWrapper,
    onHotspotClickThrough,
    creativeDimensions,
    isHeavyAd,
    isContentFlowCreative,
    setCurrentSlideId,
    hotspotProps
  }
) {

  const {environment} = useConfig();
  const [hasInteracted, setHasInteracted] = useState(false);
  const {
    onClick,
    onSlideLoad
  } = useProductExplorerEngagementEvents(onEvent, fireJourneyTrigger, onClickThrough, overlay?.inSessionTriggerDetails?.triggerElementId);

  const {creativeCard} = overlay
  const productExplorerEngagement = useMemo(() => (creativeCard?.productExplorerEngagement || {}), [creativeCard]);
  const slides = useMemo(() => productExplorerEngagement.slides || [], [productExplorerEngagement]);
  const totalSlides = useMemo(() => slides.length || 0, [slides]);
  const isCarouselNeeded = useMemo(() => totalSlides > 1, [totalSlides]);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [currentIndicatorMax, setCurrentIndicatorMax] = useState(calcInitialMaxIndicatorIndex(totalSlides, currentSlideIndex, MAX_INDICATORS));
  const currentIndicatorMin = Math.max(currentIndicatorMax - (MAX_INDICATORS - 1), 0);

  const updateCurrentSlideIndex = (index) => {
    if (currentSlideIndex !== index) {
      setCurrentIndicatorMax(calcTransitionMaxIndicatorIndex(currentSlideIndex, index, totalSlides, currentIndicatorMax, currentIndicatorMin, MAX_INDICATORS));
      setCurrentSlideIndex(index);
    }
  };

  useEffect(() => {
    const currentSlideId = productExplorerEngagement.slides?.[currentSlideIndex]?.id;
    if (currentSlideId) {
      setCurrentSlideId(currentSlideId);
      onSlideLoad(productExplorerEngagement.slides[currentSlideIndex].id)
    }
  }, [currentSlideIndex, setCurrentSlideId, productExplorerEngagement, onSlideLoad]);

  const isPreview = environment.isCreativeStudioPreviewMode();
  const {width, height} = (creativeDimensions || {});

  // load all slides if the ad is...
  const loadAllSlides =
    !isHeavyAd ||  // not heavy
    !isChrome() ||  // we're not in chrome
    environment.inHeavyAdsEmulation() ||  // we're running heavyAd emulation
    selectedProductExplorerSlideIndex >= 0 || // we're in preview
    isContentFlowCreative || // is a content creative
    hasInteracted; // the ad has been interacted with

  const autoHideControlArrowButton = isMobileDevice();

  const slideIndexToBeUsed = useMemo(() => {
    if (environment.isCreativeStudioPreviewMode()) {
      if (selectedProductExplorerSlideIndex > -1) {
        return selectedProductExplorerSlideIndex;
      }
      return 0;
    }

    return currentSlideIndex;
  }, [currentSlideIndex, environment, selectedProductExplorerSlideIndex])

  return (
    <div
      className={style.productExplorerContainer}
      onClick={() => {
        setHasInteracted(true);
      }}
      onTouchStart={() => {
        setHasInteracted(true);
      }}
    >
      <ReactCarousel
        width={width}
        showArrows={true}
        infiniteLoop={!environment.isCreativeStudioPreviewMode() && isCarouselNeeded}
        swipeable={!isPreview}
        emulateTouch={!isPreview}
        showStatus={productExplorerEngagement?.showSlideNumbers}
        showThumbs={false}
        showIndicators={false}
        transitionTime={500}
        selectedItem={slideIndexToBeUsed}
        onChange={updateCurrentSlideIndex}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button
              className={autoHideControlArrowButton ? style.autoHideControlArrowButton : style.controlArrowButton}
              disabled={isPreview}
              type="button"
              onClick={onClickHandler}
              title={label}
              style={{left: '1.5%'}}
            >
              <i className={style.controlArrowLeft} aria-hidden="true"/>
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button
              className={autoHideControlArrowButton ? style.autoHideControlArrowButton : style.controlArrowButton}
              disabled={isPreview}
              type="button"
              onClick={onClickHandler}
              title={label}
              style={{right: '1.5%'}}
            >
              <i className={style.controlArrowRight} aria-hidden="true"/>
            </button>
          )
        }
        statusFormatter={(current, total) => (
          <div className={style.counter}>
            {current}/{total}
          </div>
        )}
      >
        {
          (productExplorerEngagement?.slides || [])
            .map(
              (slide, index) => {
                const isSlideSelectedForEdit = environment.isCreativeStudioPreviewMode() ? (slideIndexToBeUsed === index) : false;
                return <ProductExplorerSlide
                  key={index}
                  slide={slide}
                  overlayID={creativeCard?.id}
                  width={width}
                  height={height}
                  hotspotJourneyTriggerHandler={cardHotspotFireJourneyTriggerWrapper}
                  loadSlide={index === 0 || loadAllSlides} // always load the first slide
                  onHotspotClickThrough={onHotspotClickThrough}
                  noClick={environment.isCreativeStudioPreviewMode()}
                  onSlideImageLinkClick={() => {
                    onClick(slide.id, slide.trackingURL, index + 1);
                  }}
                  onHotspotEvent={(evt) => (
                    onEvent({
                      ...evt,
                      slideId: slide.id,
                      optionPosition: index + 1
                    })
                  )}
                  hotspotProps={isSlideSelectedForEdit ? hotspotProps : null}
                />
              }
            )
        }
      </ReactCarousel>
      <CustomIndicators
        currentSlideIndex={currentSlideIndex}
        onSlideIndicatorClick={updateCurrentSlideIndex}
        currentIndicatorMax={currentIndicatorMax}
        currentIndicatorMin={currentIndicatorMin}
        totalSlides={totalSlides}
        isDisabled={isPreview}
      />
    </div>
  );
}
