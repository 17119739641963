import {doGet} from "utils/eventUtil";
import {LIVE_RAMP_EVENTS} from "utils/constants";
import {captureException} from "@sentry/browser";
import {isEmpty} from "../utils/helpers";
import {ErrorType} from "./constants";
import CustomError from "./customError";

export const executeLiveRampIdPullFlow = async (isLiveRampEnabled= false, rampIdImpressionIdApiGatewayPixel, isRealLiveRampIdFetchEnabled, liveRampIdFetchUrl, capture, isPreview) => {

    if(!isRealLiveRampIdFetchEnabled) {
        console.debug("Live Ramp Id fetch not allowed for this env as isRealLiveRampIdFetchEnabled config is false")
        return;
    }

    if(!isLiveRampEnabled) {
        return;
    }
    if (isPreview) {
      return;
    }
    if (isEmpty(rampIdImpressionIdApiGatewayPixel)) {
        return;
    }

    capture({action: LIVE_RAMP_EVENTS.RAMP_ID_FETCH_ATTEMPT.toLowerCase()});
    let liveRampIdFetch;
    
    try {
        
        liveRampIdFetch = await fetch(liveRampIdFetchUrl, {
            method: 'GET',
            credentials: 'include'
        })
    } catch (e) {
        console.error('exception fetching the LiveRampId', e);
        // captureException(e)  // OV-739 - no longer capturing network errors for this to sentry as there are too many "expected" errors for CORS.
    }

    if (!liveRampIdFetch) { 
        return
    }

    if (liveRampIdFetch.status === 204) {
        console.debug("RampId not Collected: User has opted out from RampId Collection. No action needed as LiveRamp takes care of it.")
        return
        // If the user is opted out, you will receive a 204 response with no content. No need to do anything at our end as LiveRamp ignores those RampIds at their end already.
    }
    // OV-739 - Note LR returns CORS error which is handled as an exception by the browser and so won't trigger this line of code.  Leaving it here just in case the handling changes in the future
    if (liveRampIdFetch.status === 401) {
        console.debug("RampId not Collected: Origin not allowed, RampID collection attempt from not allowed domain.")
        return
    }
    if (liveRampIdFetch.status === 451) {
        console.debug("RampId not Collected: Disallowed country or consent required and missing.")
        return
    }
    let liveRampResponseText = '';
    let liveRampResponseJson = '';
    try {
        liveRampResponseText = await liveRampIdFetch.text()
    } catch(e) {
        captureException(new CustomError(ErrorType.FETCH_LIVERAMP_RESPONSE_PARSING_ERROR), {extra: {errorType:ErrorType.FETCH_LIVERAMP_RESPONSE_PARSING_ERROR, contentType: liveRampIdFetch.headers.get("content-type"), e}})
        return
    }

    try {
        liveRampResponseJson = JSON.parse(liveRampResponseText)
    } catch(e) {
        console.error('Received non-json response from RampID fetch API', e)
        console.log(liveRampResponseText)
        captureException(new CustomError(ErrorType.FETCH_LIVERAMP_TEXT_TO_JSON_PARSING_ERROR), {extra: {errorType:ErrorType.FETCH_LIVERAMP_TEXT_TO_JSON_PARSING_ERROR, e}})
        return;
    }

    const liveRampId = liveRampResponseJson.idl
    if(liveRampId) {
        capture({action: LIVE_RAMP_EVENTS.RAMP_ID_FETCHED.toLowerCase(), rampid: liveRampId});
        try {
            const PIXEL_FIRED_QUERY_PARAM = '&pixelFiredTime=';
            const USER_ID_QUERY_PARAM = '&userId=';
            const epocTimeString = PIXEL_FIRED_QUERY_PARAM + Date.now();
            const userIdString = USER_ID_QUERY_PARAM + liveRampId;
            const pixelString = decodeURIComponent(rampIdImpressionIdApiGatewayPixel)
            const url = `${pixelString}${epocTimeString}${userIdString}`;
            doGet(url)
        } catch (e) {
            console.error('Exception in firing rampIdImpressionIdApiGatewayPixel', e);
            captureException(e);
        }
    }

}