// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".icon--1iCT8{width:2em;height:2em;background:#fff;text-align:center;display:flex;justify-content:center;align-items:center;color:#fff;transition:.1s ease-out transform}.icon--1iCT8:hover,.icon--1iCT8:focus{cursor:pointer}.icon--1iCT8:before{font-family:\"Font Awesome 5 Brands\";font-weight:500;font-size:.8em}.noPointer--DobpC,.noPointer--DobpC:hover,.noPointer--DobpC:focus{cursor:default !important}.facebook--1tWzO{background-color:#3b5998}.facebook--1tWzO:before{content:\"\"}.x-twitter--AK9XJ{background-color:#000}.x-twitter--AK9XJ:before{content:\"\"}.linkedin--2OoX-{background-color:#007bb5}.linkedin--2OoX-:before{content:\"\"}.pinterest--32qF8{background-color:#ca2028}.pinterest--32qF8:before{content:\"\"}.email--V4DUN{background-color:#6bc2ec}.email--V4DUN:before{content:\"\";font-family:\"Font Awesome 5 Pro\";font-weight:600}.sms--OPqoe{background:#0cb500}.sms--OPqoe:before{content:\"\";font-family:\"Font Awesome 5 Pro\";font-weight:600}.copyandpasteshareurl--2JFOF{background-color:#929495}.copyandpasteshareurl--2JFOF:before{content:\"\";font-family:\"Font Awesome 5 Pro\";font-weight:600}.boxStyle-circle--9NWfe{border-radius:50%}.boxStyle-square--Na1qX{border-radius:2px}.iconStyle-white--AyXyL{color:#000;background-color:#fff}.iconStyle-black--2YxO-{color:#fff;background-color:#000}.copyPasteNotification--3iliC{padding:.5em;color:#fff;position:absolute;background:#000;transform:translateX(-50%);border-radius:2px;margin-top:.25em;opacity:0;pointer-events:none;transition:opacity .5s linear;font-size:.8em;z-index:3}.copyPasteNotification--3iliC:after{content:\"Link copied to clipboard\";white-space:nowrap}.copyPasteNotification--3iliC:before{content:\"\";font-family:\"Font Awesome 5 Pro\";font-weight:normal}.showCopyPasteNotification--2WpOK{display:block;opacity:1}", ""]);
// Exports
exports.locals = {
	"icon": "icon--1iCT8",
	"noPointer": "noPointer--DobpC",
	"facebook": "facebook--1tWzO",
	"x-twitter": "x-twitter--AK9XJ",
	"linkedin": "linkedin--2OoX-",
	"pinterest": "pinterest--32qF8",
	"email": "email--V4DUN",
	"sms": "sms--OPqoe",
	"copyandpasteshareurl": "copyandpasteshareurl--2JFOF",
	"boxStyle-circle": "boxStyle-circle--9NWfe",
	"boxStyle-square": "boxStyle-square--Na1qX",
	"iconStyle-white": "iconStyle-white--AyXyL",
	"iconStyle-black": "iconStyle-black--2YxO-",
	"copyPasteNotification": "copyPasteNotification--3iliC",
	"showCopyPasteNotification": "showCopyPasteNotification--2WpOK"
};
module.exports = exports;
