import {useCallback} from 'react';
import {getTarget, openUrl} from 'utils/url-helper';

import {CLICK_SRC_WIDGET, AdUnitEvent, JourneyTriggerInteractiveEvent} from 'utils/constants';

function useCustomEngagementEvents(onEvent, fireJourneyTrigger, isInVideo, onClickThrough, triggerElementId) {
  const onClick = useCallback((e) => {
    e.preventDefault();

    const element = e.currentTarget;
    const destinationURL = element.getAttribute('href');
    const target = getTarget(destinationURL);
    openUrl(destinationURL, target);

    onEvent({
      type: AdUnitEvent.ClickEvent,
      srcWidget: CLICK_SRC_WIDGET.CUSTOM_IMAGE_SPLASH_SCREEN_CLICK_FOR_MORE
    });

    fireJourneyTrigger(JourneyTriggerInteractiveEvent.interactiveCustomImageClickThrough, triggerElementId);
    fireJourneyTrigger(JourneyTriggerInteractiveEvent.interactiveEngagementExperienceClickThrough, triggerElementId);

    if (isInVideo) {
      fireJourneyTrigger(JourneyTriggerInteractiveEvent.interactiveInVideoClickThrough, triggerElementId);
    }

    if (onClickThrough) {
      onClickThrough();
    }
  }, [onEvent, fireJourneyTrigger, isInVideo, onClickThrough, triggerElementId]);

  return {onClick};
}

export default useCustomEngagementEvents;
