// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MuiPopover-root, .MuiTooltip-popper {\n    z-index: 9999999999 !important;\n}\n\n*, *:before, *:after\n{\n    box-sizing: border-box;\n}\n\nhtml, body\n{\n    width: 100%;\n    height: 100%;\n    overflow: hidden;\n    margin: 0;\n    padding: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: transparent;\n    font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\";\n}\n\n#app {\n    position: relative;\n    overflow: hidden;\n    width: 100%;\n    height: 100%;\n}\n\n.geoRestrictionErrorMessage--36TFN{\n    text-align:center;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    align-content: center;\n    font-size: 12px;\n    color: #F7F7F7;\n    font-family: 'Open Sans', sans-serif;\n    top:45%;\n    height: 100%;\n    width: 100%;\n    background-color: black;\n}\n.geoRestrictionErrorMessage--36TFN > img {\n    height: 12px;\n    padding: 0 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"MuiPopover-root": "MuiPopover-root",
	"MuiTooltip-popper": "MuiTooltip-popper",
	"app": "app",
	"geoRestrictionErrorMessage": "geoRestrictionErrorMessage--36TFN"
};
module.exports = exports;
