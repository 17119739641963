import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import style from './style.module.scss';
import {MediaSubType} from 'utils/constants';
import {CustomEmbedRenderer} from 'compounds/engagement-custom/customEmbedRenderer';
import {appendIframeForCustomHtml} from 'utils/customEmbedHelper';

export function EmbedHTML(
  {
    content,
    mediaSubType,
    setRef,
    preventClick
  }
) {

  const decisiveMediaSubType = useMemo(() => mediaSubType ? mediaSubType : MediaSubType.HTML_URL, [mediaSubType]);

  const {
    scriptContent,
    scriptSrc,
    bodyContent,
    iframeSrc
  } = useMemo(() => {
    let scriptContent = "";
    let scriptSrc = "";
    let bodyContent = "";
    let iframeSrc = "";

    switch (decisiveMediaSubType) {
      case MediaSubType.JS_TAG:
        scriptContent = content;
        break;

      case MediaSubType.JS_URL:
        scriptSrc = content;
        break;

      case MediaSubType.HTML_URL:
        iframeSrc = content;
        break;

      case MediaSubType.HTML_TAG:
        bodyContent = content;
        break;

      default:
        break;
    }

    return {
      scriptContent,
      scriptSrc,
      bodyContent,
      iframeSrc
    }
  }, [content, decisiveMediaSubType]);

  const ref = useRef();

  useEffect(() => {
    return appendIframeForCustomHtml(ref, bodyContent, scriptSrc, scriptContent, decisiveMediaSubType, iframeSrc, true)
  }, [ref, bodyContent, iframeSrc, scriptSrc, scriptContent, decisiveMediaSubType])

  return <div className={style.customHTMLContainer}>
    <CustomEmbedRenderer
      iframeIdentifierGroup={style.customHTML}
      iframeSrc={iframeSrc}
      scriptContent={scriptContent}
      scriptSrc={scriptSrc}
      bodyContent={bodyContent}
      dataTestId='custom-embed-html'
      enableScroll={true}
      setRef={setRef}
    />
    {
      preventClick ?
        <div className={style.clickOverlay}/> :
        <React.Fragment/>
    }
  </div>
}
