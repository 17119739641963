// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".hotspot--1HDsd{display:flex;justify-content:center;align-items:center;position:absolute;text-align:center;cursor:pointer;opacity:1}.noPointer--2l0L7{cursor:default}.useHotspotAnimation--3DcpN{transition:all var(--hotspotAnimationDuration)}.cardHotspot--3KedJ{z-index:2;pointer-events:all}.videoHotspot--nCwJH{z-index:1}.hidden--21AFs{opacity:0;pointer-events:none}", ""]);
// Exports
exports.locals = {
	"hotspot": "hotspot--1HDsd",
	"noPointer": "noPointer--2l0L7",
	"useHotspotAnimation": "useHotspotAnimation--3DcpN",
	"cardHotspot": "cardHotspot--3KedJ",
	"videoHotspot": "videoHotspot--nCwJH",
	"hidden": "hidden--21AFs"
};
module.exports = exports;
