// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container--32cVH{display:flex;overflow:hidden;margin:4px;opacity:0;transition:opacity .5s cubic-bezier(0.25, 0.46, 0.45, 0.94);transition-delay:.6s}.showing--2A92v{opacity:1;transition:.6s ease-in-out;overflow:visible}.hideFromTop--rCX8i{transform:translateY(-100%)}.hideFromBottom--1lWhW{transform:translateY(100%)}.shareIcon--3LB3U{z-index:1;transition:all .6s linear;display:flex;justify-content:center;align-items:center}.top_right--KqINn{right:0;flex-direction:row-reverse}.bottom_right--1-I3N{bottom:calc(var(--rootFontSize) * 2.4)}.bottom_left--2Ox_P{bottom:calc(var(--rootFontSize) * 2.4)}.icons--gBjVG{font-size:calc(var(--rootFontSize) * 0.75);display:flex;justify-content:center;align-items:center;flex-wrap:nowrap;transition:-webkit-clip-path .4s linear;transition:clip-path .4s linear;transition:clip-path .4s linear,-webkit-clip-path .4s linear;z-index:0;-webkit-clip-path:polygon(0 0, 0 0, 0 100%, 0 100%);clip-path:polygon(0 0, 0 0, 0 100%, 0 100%);--socialIconFontSize:calc(var(--rootFontSize) * 0.75)}.top_right--KqINn .icons--gBjVG,.bottom_right--1-I3N .icons--gBjVG{-webkit-clip-path:polygon(100% 0, 100% 0, 100% 100%, 100% 100%);clip-path:polygon(100% 0, 100% 0, 100% 100%, 100% 100%)}.active--1OJbc .icons--gBjVG{-webkit-clip-path:polygon(0 0, 100% 0, 100% 100%, 0 100%);clip-path:polygon(0 0, 100% 0, 100% 100%, 0 100%)}.active--1OJbc .shareIcon--3LB3U{transform:rotate(-360deg)}.hidden--EWI3o{opacity:0;height:0;transition:.6s ease-in-out}", ""]);
// Exports
exports.locals = {
	"container": "container--32cVH",
	"showing": "showing--2A92v",
	"hideFromTop": "hideFromTop--rCX8i",
	"hideFromBottom": "hideFromBottom--1lWhW",
	"shareIcon": "shareIcon--3LB3U",
	"top_right": "top_right--KqINn",
	"bottom_right": "bottom_right--1-I3N top_right--KqINn",
	"bottom_left": "bottom_left--2Ox_P",
	"icons": "icons--gBjVG",
	"active": "active--1OJbc",
	"hidden": "hidden--EWI3o"
};
module.exports = exports;
