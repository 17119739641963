import {
  IN_SESSION_NO_CLICK_EVENT, 
  IN_SESSION_TRIGGERS_PER_ENGAGEMENT, 
  JourneyTriggerInteractiveEvent, 
  HotSpotType, 
  CUSTOM_ENGAGEMENT_TYPE,
  IN_SESSION_TRIGGERS_PER_DISPLAY_ENGAGEMENT,
  TRIGGER_LOGIC_KEY
} from 'utils/constants';
import {CREATIVE_CARD_TYPE, CREATIVE_MODE} from "../utils/constants";

const getInsessionTriggersForVideoEngagement = (engagement) => {
  const mappedCreativeCardType = CREATIVE_CARD_TYPE[engagement.creativeCardType];
  const isNonCustomEmbed = mappedCreativeCardType !== CREATIVE_CARD_TYPE.CUSTOM || engagement.type === CUSTOM_ENGAGEMENT_TYPE.IMAGE;
  return isNonCustomEmbed ? IN_SESSION_TRIGGERS_PER_ENGAGEMENT[mappedCreativeCardType] : null;
}

// expands "No Click" triggers to actual trigger equivalents
// FUTURE: "Any Click" trigger expansion
export default function useExpandedTriggerLogicSettings(settings) {
  if (!settings) {
    return;
  }

  const allTriggerLogics = [];
  const addTriggerLogic = (triggerLogic) => {
    if (triggerLogic) {
      allTriggerLogics.push(triggerLogic)
    }
  }

  const hotspotEventMap = settings.hotspots ? settings.hotspots
    .reduce((acc, hotspot) => {
      addTriggerLogic(hotspot.inSessionTriggerDetails.triggerLogic);
      if (hotspot?.inSessionTriggerDetails?.triggerElementId) {
        return {
          ...acc,
          [hotspot?.inSessionTriggerDetails?.triggerElementId]: HotSpotType[hotspot.hotspotType] === HotSpotType.DRIVE_TO_SITE ? [JourneyTriggerInteractiveEvent.interactiveHotspotClickThrough] : getInsessionTriggersForVideoEngagement(hotspot)
        };
      } else {
        return acc;
      }
    }, {}) : {};

  const creativeCardSettingsEventMap = settings.creativeCardSettings ? settings.creativeCardSettings
    .reduce((acc, creativeSetting) => {
      if (creativeSetting.inSessionTriggerDetails) {
        addTriggerLogic(creativeSetting.inSessionTriggerDetails.triggerLogic);
      }
      if (creativeSetting?.inSessionTriggerDetails?.triggerElementId && creativeSetting?.creativeCard?.creativeCardType) {
        return {
          ...acc,
          [creativeSetting?.inSessionTriggerDetails?.triggerElementId]:  settings.creativeType === CREATIVE_MODE.DISPLAY ?
              IN_SESSION_TRIGGERS_PER_DISPLAY_ENGAGEMENT[creativeSetting.creativeCard?.creativeCardType]: getInsessionTriggersForVideoEngagement(creativeSetting.creativeCard)
        };
      } else {
        return acc;
      }
    }, {}) : {};


  const allCards = [
    ...(settings.creativeCardSettings || [])
  ]

  for (const {hotspots = []} of allCards) {
    for (const hotspot of hotspots) {
      addTriggerLogic(hotspot?.inSessionTriggerDetails?.triggerLogic);
    }
  }

  // Adding Trigger Logics for Interactive Elements
  addTriggerLogic(settings.topLogo?.inSessionTriggerDetails?.triggerLogic);
  addTriggerLogic(settings.bottomLogo?.inSessionTriggerDetails?.triggerLogic);
  addTriggerLogic(settings.socialShare?.inSessionTriggerDetails?.triggerLogic);
  addTriggerLogic(settings.callToAction?.inSessionTriggerDetails?.triggerLogic);

  const triggerElementIdEventMap = {
    ...(settings.topLogo?.inSessionTriggerDetails?.triggerElementId ? {
      [settings.topLogo?.inSessionTriggerDetails?.triggerElementId]: [
        JourneyTriggerInteractiveEvent.interactiveTopLogoClickThrough,
      ]
    } : {}),
    ...(settings.bottomLogo?.inSessionTriggerDetails?.triggerElementId ? {
      [settings.bottomLogo?.inSessionTriggerDetails?.triggerElementId]:
        [
          JourneyTriggerInteractiveEvent.interactiveBottomLogoClickThrough,
        ]
    } : {}),
    ...(settings.socialShare?.inSessionTriggerDetails?.triggerElementId ? {
      [settings.socialShare?.inSessionTriggerDetails?.triggerElementId]:
        [
          JourneyTriggerInteractiveEvent.interactiveShare,
        ]
    } : {}),
    ...(settings.callToAction?.inSessionTriggerDetails?.triggerElementId ? {
      [settings.callToAction?.inSessionTriggerDetails?.triggerElementId]:
        [
          JourneyTriggerInteractiveEvent.interactiveCtaCopyClickThrough,
        ]
    } : {}),
    ...hotspotEventMap,
    ...creativeCardSettingsEventMap
  }

  const expandedSettings = {
    ...settings,
    ...(settings?.topLogo && {topLogo: convertNoClick(populateHasChildren(settings.topLogo, allTriggerLogics), triggerElementIdEventMap)}),
    ...(settings?.bottomLogo && {bottomLogo: convertNoClick(populateHasChildren(settings.bottomLogo, allTriggerLogics), triggerElementIdEventMap)}),
    ...(settings?.socialShare && {socialShare: convertNoClick(populateHasChildren(settings.socialShare, allTriggerLogics), triggerElementIdEventMap)}),
    ...(settings?.callToAction && {callToAction: convertNoClick(populateHasChildren(settings.callToAction, allTriggerLogics), triggerElementIdEventMap)}),
    ...(settings?.hotspots && {hotspots: settings.hotspots.map((hotspot) => convertNoClick(populateHasChildren(hotspot, allTriggerLogics), triggerElementIdEventMap))}),
    ...(settings?.creativeCardSettings && {
      creativeCardSettings: settings.creativeCardSettings.map((overlay) => {
        const updateOverlay = convertNoClick(populateHasChildren(overlay, allTriggerLogics), triggerElementIdEventMap);
        return {
          ...updateOverlay,
          creativeCard: {
            ...updateOverlay.creativeCard,
            hotspots: (updateOverlay.creativeCard.hotspots || []).map((hotspot) => populateHasChildren(hotspot, allTriggerLogics, true))
          }
        }
      })
    })
  }

  return expandedSettings;
}


// Sample triggerLogic:
// {
//  "or":[
//     {
//        "var":[
//           "4efa7e7a-0f49-48fa-961a-e82e9617a305.noClick",
//           false
//        ]
//     },
//     {
//        "var":[
//           "8f7bc0ba-8746-412e-8aea-a274819a8373.interactiveCtaCopyClickThrough",
//           false
//        ]
//     }
//   ]
// }
const getTriggerLogic = (ruleNode, triggerElementIdEventMap) => {
  // determine if we are at the end of the nesting
  // 'var' keys wont appear alongside 'and' or 'or' keys, just check the first key.
  const nodeKeys = ruleNode.map((clause) => Object.keys(clause)[0])
  if (nodeKeys.includes(TRIGGER_LOGIC_KEY.VAR) && (nodeKeys.includes(TRIGGER_LOGIC_KEY.OR) || nodeKeys.includes(TRIGGER_LOGIC_KEY.AND))) {
    // if we want to handle this combination the isLeaf logic will need to change.
    console.error(`Unexpected node key combination. 'var' not expected alongside 'and' or 'or' keys`)
    return null
  }
  const isLeaf = Object.keys(ruleNode[0])[0] === TRIGGER_LOGIC_KEY.VAR
  return ruleNode.map((clause) => {
    if (isLeaf) {
      // check for noClick triggers
      const [triggerId, triggerEvent] = clause.var[0].split('.')
      if (triggerEvent === IN_SESSION_NO_CLICK_EVENT) {
        if (!triggerElementIdEventMap[triggerId]) {
          return clause
        }
        return {
          [TRIGGER_LOGIC_KEY.NOT]: [{
            [TRIGGER_LOGIC_KEY.OR]: triggerElementIdEventMap[triggerId].map((event) => (
              {[TRIGGER_LOGIC_KEY.VAR]: [`${triggerId}.${event}`, false]}
            ))
          }]
        }
      } else {
        return clause
      }
    } else {
      // next level
      const [levelKey, nextLevel] = Object.entries(clause)[0]
      return {[levelKey]: getTriggerLogic(nextLevel, triggerElementIdEventMap)}
    }
  })
}
const convertNoClick = (elementSettingsOriginal, triggerElementIdEventMap) => {

  // Make a copy of triggerLogic for noClick path determination in inSessionTriggerHelper.getChildrenTriggerElementIds
  const elementSettings = {
    ...elementSettingsOriginal,
    ...(elementSettingsOriginal.inSessionTriggerDetails &&
      {
        inSessionTriggerDetails: {
          ...elementSettingsOriginal.inSessionTriggerDetails,
          originalTriggerLogic: elementSettingsOriginal.inSessionTriggerDetails.triggerLogic
        }
      }
    )
  };

  if (elementSettings?.inSessionTriggerDetails?.triggerLogic && elementSettings.inSessionTriggerDetails.triggerLogic.includes(IN_SESSION_NO_CLICK_EVENT)) {

    const originalTriggerLogic = JSON.parse(elementSettings.inSessionTriggerDetails.triggerLogic);
    // supports and / or keys + nesting
    const [ruleKey, ruleNode] = Object.entries(originalTriggerLogic)[0] // should only be one rule level key
    const expandedTriggerLogic = getTriggerLogic(ruleNode, triggerElementIdEventMap)

    return {
      ...elementSettings,
      inSessionTriggerDetails: {
        ...elementSettings.inSessionTriggerDetails,
        triggerLogic: JSON.stringify({[ruleKey]: expandedTriggerLogic})
      }
    };
  } else {
    return elementSettings;
  }
}

const uuidRegexString = `[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}`;
const journeyTriggerRegexString = `[a-zA-Z0-9]+`;

const checkIfChildTrigger = (triggerElementId, triggerLogic, inCard) => {
  const idRegex = inCard ?
    new RegExp(`"(${uuidRegexString}).(${journeyTriggerRegexString})_(${triggerElementId})"`, 'gi') : // Creates a Regex like `"{uuid}.{triggerName}_{triggerElementId}"`
    new RegExp(`"(${triggerElementId}).(${journeyTriggerRegexString})"`, 'gi'); // Creates a Regex like `"{uuid}.{triggerName}"`

  const match = idRegex.exec(triggerLogic);
  const isValid = Boolean(match)
  return {
    isValid,
    triggerName: isValid ? match[2] : null
  }
}

const hasChildren = (element, allTriggerLogics, inCard) => {
  const triggerElementId = element?.inSessionTriggerDetails?.triggerElementId;

  if (!triggerElementId) {
    return false;
  }


  const child = allTriggerLogics.find((triggerLogic) => {
    const {isValid, triggerName} = checkIfChildTrigger(triggerElementId, triggerLogic, inCard);
    return isValid && (triggerName !== IN_SESSION_NO_CLICK_EVENT);
  })

  return Boolean(child);
}

const populateHasChildren = (element, allTriggerLogics, inCard) => {

  return {
    ...element,
    hasChildren: hasChildren(element, allTriggerLogics, inCard)
  };
}
