import React from "react";

import icon from 'images/in-video-cross-button.svg';

function CloseIcon({onClose, ...rest}) {
  return <span onClick={onClose} style={{cursor: 'pointer'}} {...rest}><img src={icon}/> </span>
}

export default CloseIcon;

