import React, {useMemo} from 'react';

import SocialConnectorRenderer from "./renderer";
import eventHandler from "./eventHandler";

import {getShareIconsInfoFromSettings} from "utils/engagement-helper";
import {isMobileDevice} from 'utils/environmentHelper';
import {useConfig} from "utils/ConfigProvider";

function SocialConnector({overlay, mode, socialShare, onEvent, fireJourneyTrigger, onClickThrough, inVideo = false, sharePostImgUrl, videoTitle}) {
  const {environment} = useConfig();
  const {hyperlink, shareUrlConfig, ...rendererProps} = useMemo(() => getProps(overlay, socialShare, isMobileDevice()), [overlay, socialShare]);
  const [onCtaClick, onShareIconClick] = useMemo(() => eventHandler(onEvent, fireJourneyTrigger, inVideo, hyperlink, onClickThrough, overlay?.inSessionTriggerDetails?.triggerElementId),[onEvent, fireJourneyTrigger, inVideo, hyperlink, onClickThrough, overlay]);

  return (
    <SocialConnectorRenderer
      {...rendererProps}
      mode = {mode}
      onCtaClick={!environment.isCreativeStudioPreviewMode() ? onCtaClick : () => {}}
      onShareIconClick={onShareIconClick}
      shareUrlConfig={{...shareUrlConfig, sharePostImgUrl, videoTitle}}
      isCreativeStudioPreviewMode={environment.isCreativeStudioPreviewMode()}
    />
    )
}

const getProps = (overlay, socialShare, isMobileDevice) => {
  const {creativeCard, inVideoEngagementPosition} = overlay
  const {thankYouEngagement} = creativeCard;
  const {title} = thankYouEngagement;
  const fontSettings = {
    font: thankYouEngagement?.font,
    color: thankYouEngagement?.color
  }
  const titleFontSettings = {
    font: thankYouEngagement?.font,
    color: thankYouEngagement?.color || 'white'
  };
  const ctaFontSettings = thankYouEngagement.ctaText ? {
    font: thankYouEngagement?.font,
    color: thankYouEngagement?.ctaColor
  }: null;
  const buttonColor = thankYouEngagement.ctaText ? thankYouEngagement.ctaBgColor : null;
  const buttonText = thankYouEngagement.ctaText;
  const buttonFontSettings = (ctaFontSettings || fontSettings);
  const hyperlink = thankYouEngagement.ctaText ? thankYouEngagement.hyperlink : null;

  const {boxStyle, iconStyle, icons, shareUrlConfig} = socialShare && socialShare.visible ? getShareIconsInfoFromSettings(socialShare, isMobileDevice) : {};
  return {title, titleFontSettings, buttonColor, buttonFontSettings, buttonText, boxStyle, iconStyle, icons, hyperlink, shareUrlConfig, inVideoEngagementPosition};
}


export default SocialConnector;
