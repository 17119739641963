import {useCallback} from 'react';
import {doGet} from "utils/eventUtil";

import MessageHelper from "../utils/messageHelper";

// Fire the pixels for each journey trigger event present in settings and/or in vast wrapper in TrackingEvents xml tag
export function useJourneyTriggerEvents(triggers, isVPAIDEnabled) {
  const fireJourneyTrigger = useCallback((event) => {
    if (!event) {
      return;
    }
    if (triggers) {
      const pixelList = triggers[event];
      if (pixelList && Array.isArray(pixelList)) {
        pixelList.forEach((pixel) => {
          doGet(pixel);
        });
      }
    }

    // notify vast wrapper to trigger from xml <TrackingEvents/> tag
    if (isVPAIDEnabled) {
      MessageHelper.instance.notifyJourneyTrigger(event);
    }
  }, [triggers, isVPAIDEnabled]);

  return {
    fireJourneyTrigger
  };
}
