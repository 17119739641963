import React from "react";
import style from './style.module.scss';

function VideoPositionBar({videoElapsedPercentage, fullScrubShowing, ...rest}) {
    return (
        <>
          <div className={fullScrubShowing ? style.videoPositionBar : style.videoPositionBarBottom} {...rest}>
            <span className={style.videoElapsed} style={{width: `${videoElapsedPercentage}%`}}/>
          </div>
        </>
  )
}

export default VideoPositionBar;
