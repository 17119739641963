import {useEffect, useMemo} from "react";
import surveyHelpers from "utils/survey-helper";
import {AdUnitEvent, isPathMaker} from "utils/constants";
import {ENGAGEMENT_TEMPLATE_MAP} from "../utils/constants";

function useDisplayCardExposureEvents(displayEngagement, showing, onEvent, fireJourneyExposureTrigger) {
  const cardExposedIds = useMemo(() => new Set(), []);
  const journeyTriggerExposedCardIds = useMemo(() => new Set(), []);
  // on showing, send
  useEffect(() => {
    const isInstaJourney = isPathMaker(displayEngagement?.mediaType);
    const {creativeCard} = displayEngagement
    const creativeCardType = creativeCard?.creativeCardType
    const {surveyID} = surveyHelpers.getSurveyDetails(creativeCard);
    const overlayID = creativeCard?.id

    if ((Boolean(surveyID) || Boolean(overlayID)) && showing) {
      if (!journeyTriggerExposedCardIds.has(overlayID)) {
        fireJourneyExposureTrigger();
        journeyTriggerExposedCardIds.add(overlayID);
      }
      let payload = {engagementTemplate: ENGAGEMENT_TEMPLATE_MAP[creativeCardType]}

      if(surveyID) {
        payload = {...payload, surveyID}
      }
      if (!cardExposedIds.has(overlayID) && (isInstaJourney)) {
        onEvent({
          ...payload,
          action: AdUnitEvent.CardExposure,
        });

        onEvent({
          ...payload,
          action: AdUnitEvent.TotalCardExposures,
        });

        cardExposedIds.add(overlayID);
      }
    }

  }, [onEvent, displayEngagement, showing, cardExposedIds, fireJourneyExposureTrigger, journeyTriggerExposedCardIds]);

}

export default useDisplayCardExposureEvents;
