import React, {createContext, useCallback, useContext} from 'react';
import {get} from 'lodash-es';

const SettingsGetContext = createContext(() => {});

export const useSettings = () => {
  return {getSettings: useContext(SettingsGetContext)};
};

export function SettingsProvider({children, settings}) {
  const getObjectFormSettings = useCallback((key) => {
    return get(settings, key);
  }, [settings]);

  return <SettingsGetContext.Provider value={getObjectFormSettings}>
    {children}
  </SettingsGetContext.Provider>
}
