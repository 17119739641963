// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".thankYouContainer--h3fG6{display:flex;height:100%;width:85%;margin:auto;flex-direction:column;justify-content:space-around;justify-content:space-evenly}.thankYouText--3TYi7{font-size:calc(var(--rootFontSize) * 2);align-self:center;text-align:center}", ""]);
// Exports
exports.locals = {
	"thankYouContainer": "thankYouContainer--h3fG6",
	"thankYouText": "thankYouText--3TYi7"
};
module.exports = exports;
