// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".play--3yngy, .pause--6ZnVS {\n    animation: none;\n    animation-duration: .5s;\n    animation-fill-mode: forwards;\n    animation-timing-function: ease-in;\n    opacity: 0;\n    position: absolute;\n}\n\n.show--1KgEn {\n    animation-name: zoomInCenter--2ZZsB;\n}\n\n.hide--Sfe2P {\n    animation-name: zoomOutCenter--ud9F4;\n}\n\n@keyframes zoomInCenter--2ZZsB {\n    from {\n        opacity: 0;\n        transform: scale(0);\n    }\n    50% {\n        opacity: 1;\n        transform: scale(0.3);\n    }\n    100% {\n        opacity: 1;\n        transform: scale(1);\n    }\n}\n\n@keyframes zoomOutCenter--ud9F4 {\n    from {\n        opacity: 1;\n        transform: scale(1);\n    }\n    50% {\n        opacity: 0;\n        transform: scale(0.3);\n    }\n    to {\n        opacity: 0;\n        transform: scale(0);\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"play": "play--3yngy",
	"pause": "pause--6ZnVS",
	"show": "show--1KgEn",
	"zoomInCenter": "zoomInCenter--2ZZsB",
	"hide": "hide--Sfe2P",
	"zoomOutCenter": "zoomOutCenter--ud9F4"
};
module.exports = exports;
