
const isTelephonicLink = (url) => {
  return url && url.indexOf('tel:') === 0;
};

export const getTarget = (url) => {
  return isTelephonicLink(url) ? '_top' : '_blank';
};

export const convertUrlToHttps = (url) => {
  if (url.indexOf('http://') === 0) {
    return `https${url.substring(4)}`;
  }

  return url;
}

export const openUrl = (url, target) => {
  // if url is null, undefined, empty string etc., return false
  if (!url) {
    return false;
  }

  return window.open(url, target);
};

/**
 * Retrieves the parameter value for the parameter name requested
 * @param {String} name the parameter to retrieve
 * @returns {String} value
 */
export const urlParam = (name) => {
  // eslint-disable-next-line no-useless-escape
  const results = new RegExp(`[\?&]${name}=([^&#]*)`).exec(window.location.href);
  if (results == null) {
    return null;
  } else {
    return results[1] || 0;
  }
}

export const fixPlusEncoding = (url) => {
  return url ? url.replace(/\+/g, '%2B') : null;
}

/**
 * it creates a json of 2 attributes:
 * 1. url that contains the url part without query_string
 * 2. params that is json of key-value of query string
 * e.g for url: https://www.viralgains.com/?cmpID=123&destURL=testURL
 * result will be {'url': 'https://www.viralgains.com',
 *                 'params': {'cmpID':'123', 'destURL':'testURL'}
 *                }
 * @param {String} url fully qualified url
 * @returns {Object} return an object containing the url and query-string parameters
 */
export const getUrlComponents = (url) => {
  if (typeof url !== "string" || url.length === 0) {
    throw new TypeError('url must be a non-empty string');
  }

  if (url.indexOf('?') === -1) {
    return {
      url,
      params: {}
    };
  }

  const urlParts = url.split('?');
  const [urlDomain, query] = urlParts;

  const params = {};
  const urlComponents = {
    url: urlDomain,
    params
  };

  const values = query.split('&');

  values.forEach((val) => {
    const [key, value] = val.split('=');
    const currentKeyValue = params[key];

    if (currentKeyValue === undefined) {
      params[key] = value;
    } else if (typeof currentKeyValue === "string") {
      // convert the current value from a string to an array with the value as the first item and append the new value
      params[key] = [currentKeyValue, value];
    } else {
      // add to the existing array
      params[key].push(value);
    }
  });
  return urlComponents;
}

export const toQueryString = (obj) => {
  const str = [];
  for (const p of Object.keys(obj)) {
    if (obj[p] !== null && obj[p] !== undefined) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  }
  return str.join("&");
}
