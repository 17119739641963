import React from "react";

import icon from 'images/share.svg';
import style from './style.module.scss';
import {isMobileDevice} from "utils/environmentHelper";

function EmailIcon({onClick, ...rest}) {
  return <span onClick={onClick} {...rest}><img src={icon} className={isMobileDevice() ? style.mobileShareIcon : style.shareIcon}/> </span>
}

export default EmailIcon;

