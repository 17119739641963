import {useCallback, useMemo} from 'react';
import {ENGAGEMENT_STATUS} from 'utils/constants';

export function useOverlayEvents(playerControlWrapper, overlayState, setHotspotOverlay) {

  const isInVideoShowing = useMemo(() => overlayState === ENGAGEMENT_STATUS.SHOWING_IN_VIDEO, [overlayState]);
  const isShowingOnPause = useMemo(() => overlayState === ENGAGEMENT_STATUS.SHOWING_ON_PAUSE, [overlayState]);
  const isShowingOnHotspot = useMemo(() => overlayState === ENGAGEMENT_STATUS.SHOWING_ON_HOTSPOT, [overlayState]);
  const isShowingOnComplete = useMemo(() => overlayState === ENGAGEMENT_STATUS.SHOWING_ON_COMPLETE, [overlayState]);
  
  const onFinaleEEClose = useCallback(() => {
    if (isShowingOnPause || isShowingOnHotspot) {
      playerControlWrapper.play();
    }

    if (isShowingOnHotspot) {
      setHotspotOverlay(null);
    }

  }, [playerControlWrapper, isShowingOnPause, isShowingOnHotspot, setHotspotOverlay]);

  const isFinaleShowing = useMemo(() => isShowingOnPause || isShowingOnHotspot || isShowingOnComplete, [isShowingOnComplete, isShowingOnHotspot, isShowingOnPause]);

  return {
    onFinaleEEClose,
    isInVideoShowing,
    isShowingOnPause,
    isFinaleShowing,
    isShowingOnHotspot
  }
}
