import React, {createContext, useContext} from 'react';

const noOp = (() => {});

const LocalConfig = createContext(noOp);
const Environment = createContext(noOp);
const RuntimeProperties = createContext(noOp);
const AppRootId = createContext(noOp);
const AppRef = createContext(null);

export const useConfig = () => {
  const localConfig = useContext(LocalConfig);
  const environment = useContext(Environment);
  const runtimeProperties = useContext(RuntimeProperties);
  const appRootId = useContext(AppRootId);
  const appRef = useContext(AppRef);

  return {
    localConfig,
    environment,
    runtimeProperties,
    appRootId,
    appRef
  }
};

// TODO: refactor out runtimeRroperties entirely
export function ConfigProvider({children, localConfig, environment, runtimeProperties, appRootId, appRef}) {
  return (
    <LocalConfig.Provider value={localConfig}>
      <Environment.Provider value={environment}>
        <RuntimeProperties.Provider value={runtimeProperties}>
          <AppRootId.Provider value={appRootId}>
            <AppRef.Provider value={appRef}>
              {children}
            </AppRef.Provider>
          </AppRootId.Provider>
        </RuntimeProperties.Provider>
      </Environment.Provider>
    </LocalConfig.Provider>
  );
}
