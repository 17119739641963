import React, {useMemo} from "react";
import {Hotspot} from "elements/hotspot";

export function HotspotRenderer(
  {
    isCreativeStudioPreviewMode,
    index,
    hotspot,
    isCardAndInVideoAndFinale,
    onHotspotClick,
    containerDimensions,
    isCardHotspot,
    useHotspotAnimation,
    isInVideoOverlay,
    isFinaleOverlay,
    isShowing,
    isStandaloneHotspots,
    isHotspotSelected,
    selectedHotspotSelector,
    hotspotPopperSelector,
    snappingHotspotSelector,
    isStandaloneHotspotShowing
  }
) {

  const showing = useMemo(() => isShowing(hotspot), [hotspot, isShowing]);
  const isSelected = useMemo(() => isHotspotSelected ? isHotspotSelected(hotspot) : false, [hotspot, isHotspotSelected]);
  const standaloneHotspotShowing = useMemo(() => isStandaloneHotspotShowing(index), [index, isStandaloneHotspotShowing]);

  // Hide Hotspot, when in preview mode, and hotspot panel is not open. - Refer OC-700
  if (isCreativeStudioPreviewMode && isStandaloneHotspots && !standaloneHotspotShowing) {
    return <React.Fragment/>;
  }

  // OC-956 for cards inVideo and finale check hotspot visibility options (inVideo, finaleEnabled)
  if (isCardAndInVideoAndFinale) {
    if (!hotspot.inVideo && isInVideoOverlay) {
      return <React.Fragment key={index}/>
    }
    if (!hotspot.finaleEnabled && isFinaleOverlay) {
      return <React.Fragment key={index}/>
    }
  }

  return <Hotspot
    hotspot={hotspot}
    onClick={onHotspotClick}
    index={index}
    containerDimensions={containerDimensions}
    showing={showing}
    isCardHotspot={isCardHotspot}
    useHotspotAnimation={useHotspotAnimation}
    isSelected={isSelected}
    selectedHotspotSelector={showing ? selectedHotspotSelector : null}
    hotspotPopperSelector={showing ? hotspotPopperSelector : null}
    snappingHotspotSelector={showing ? snappingHotspotSelector : null}
    isCreativeStudioPreviewMode={isCreativeStudioPreviewMode}
  />
}
