// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".title--12q5S{border-radius:calc(var(--rootFontSize) * 0.2);font-family:\"Red Hat Display\";line-height:1.5;max-height:100%;max-width:100%;word-break:break-word}.button--1-l7V{white-space:nowrap;cursor:pointer;align-self:center;line-height:calc(var(--rootFontSize) * 0.9)}.noPointer--86Fbt{cursor:default !important}.overlay--N-AvT{text-align:center;white-space:nowrap;display:flex;align-items:center;justify-content:center;flex-wrap:wrap;position:absolute;width:100%;bottom:0;background-color:rgba(0,0,0,.5);line-height:1.5}.center--2g6e9{text-align:center}.topLeft--2MNmv{text-align:left;align-items:flex-start}.titleFullWidth--2m4zu{width:100%}.titleFullHeight--3W7fS{height:100%}.svgContainer--3NeG8{display:flex;align-items:center;-webkit-user-select:none;-ms-user-select:none;user-select:none}", ""]);
// Exports
exports.locals = {
	"title": "title--12q5S",
	"button": "button--1-l7V title--12q5S",
	"noPointer": "noPointer--86Fbt",
	"overlay": "overlay--N-AvT",
	"center": "center--2g6e9",
	"topLeft": "topLeft--2MNmv",
	"titleFullWidth": "titleFullWidth--2m4zu",
	"titleFullHeight": "titleFullHeight--3W7fS",
	"svgContainer": "svgContainer--3NeG8"
};
module.exports = exports;
