// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".videoPositionBar--W2WtC{width:100%;background-color:#ccc;height:.2em;position:relative;z-index:2;align-self:center}.videoPositionBarBottom--3WbK6{width:100%}.videoElapsed--3zg7q{display:inline-block;position:absolute;background-color:#23adf0;width:0;height:100%;max-width:100%}.barThumb--2mb5l{transform:translate(-50%, -50%);top:50%;position:absolute;width:0;height:0;border-radius:10px;background:#019afd;transition:height .2s linear,width .2s linear}.videoPositionBar--W2WtC:hover .barThumb--2mb5l,.mobileHover--o3nfu .barThumb--2mb5l{width:calc(var(--rootFontSize) * 0.75);height:calc(var(--rootFontSize) * 0.75)}.scrubEnabledBar--2mKCm{cursor:pointer}", ""]);
// Exports
exports.locals = {
	"videoPositionBar": "videoPositionBar--W2WtC",
	"videoPositionBarBottom": "videoPositionBarBottom--3WbK6 videoPositionBar--W2WtC",
	"videoElapsed": "videoElapsed--3zg7q",
	"barThumb": "barThumb--2mb5l",
	"mobileHover": "mobileHover--o3nfu",
	"scrubEnabledBar": "scrubEnabledBar--2mKCm"
};
module.exports = exports;
