import {useEffect, useState} from "react"
import config from "../../local-config"
/**
 * WARNING: This uses experimental technology. (getHighEntropyValues)
 * Currently only Chrome, Edge, and Opera support this experimental function. 
 * If it breaks randomly, check the docs for any changes to its behavior since September 2023
 * Use the switch "clientHintsGetHighEntropy" in local-config to disable 
 * 
 * assure any failure is handled gracefully - it should be fine because of the try/catch wrapping the entire thing.
 * 
 * NOTE: 
 * getHighEntropyValues returns a promise, browsers may request user's permission or do other checks.. 
 * TBD -- how might that effect ad delivery? will we need to reject the promise after a certain amount of time?
 * https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData/getHighEntropyValues
 * 
 * Set clientHints state to the resolved value from getHighEntropyValues
 * clientHints state will be null if any error occurs
 */
const useHighEntropyClientHints = () => {
    const [clientHints, setClientHints] = useState(null)

    useEffect(() => {
        // userAgentData can be undefined - firefox/safari etc..
        if (config.clientHintsGetHighEntropy && navigator.userAgentData) {
            try {
                navigator.userAgentData.getHighEntropyValues(["architecture", "model", "bitness", "platformVersion", "fullVersionList"])
                .then((values) => {
                    setClientHints(values)
                })
                .catch((ex) => {
                    console.warn(`Error getting high entropy values`, ex)
                })
            } catch (ex) {
                console.warn(`Error setting user agent client hints`, ex)
            }
        }
    }, [])
  
    return clientHints
}

export default useHighEntropyClientHints