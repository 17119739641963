import React, {useMemo} from 'react';
import useExplorerEngagementEvents from './useExplorerEngagementEvents';

import EngagementCarousel from 'compounds/engagement-carousel';
import {useConfig} from 'utils/ConfigProvider';
import {CAROUSEL_ITEM_TYPE} from 'utils/constants';

function Explorer({onEvent, overlay, creativeAspectRatio, mode, inVideo, fireJourneyTrigger, onClickThrough}) {
  const {environment, runtimeProperties, localConfig} = useConfig();
  const {onClick} = useExplorerEngagementEvents(onEvent, inVideo, fireJourneyTrigger, onClickThrough, overlay?.inSessionTriggerDetails?.triggerElementId, 
    environment.getAdUnitId(), runtimeProperties.setVideoExploreCampaign, runtimeProperties.setVideoExploreParentCampaignId, runtimeProperties.setVideoExploreCampaignId, runtimeProperties.setVideoExploreOptionPosition);

  const rendererProps = useMemo(() => getProps(overlay.creativeCard, localConfig.generateImageSrcFromCache), [overlay, localConfig.generateImageSrcFromCache]);

  return (
    <EngagementCarousel
      mode = {mode}
      creativeAspectRatio = {creativeAspectRatio}
      onClick = {!environment.isCreativeStudioPreviewMode() ? onClick : null}
      inVideo={inVideo}
      {...rendererProps}
    />
  )
}

const getProps = (creativeCard, generateImageSrcFromCache) => {
  const {explorerEngagement} = creativeCard;
  
  const title = explorerEngagement?.title?.text;
  const titleFontSettings = {
    ...explorerEngagement?.font,
    fontWeight: explorerEngagement?.font?.weight,
    fontSize: explorerEngagement?.font?.size,
    color: explorerEngagement?.title?.color || explorerEngagement?.font?.color || 'white'
  }

  const items = explorerEngagement.items.map((item) => {
    let imageSrcUrl;
    if (item.itemType === CAROUSEL_ITEM_TYPE.VIDEO) {
      imageSrcUrl = !item.thumbnailURL && item.creativeId && generateImageSrcFromCache ? generateImageSrcFromCache(item.creativeId, null, true) : item?.thumbnailURL;
    } else {
      imageSrcUrl = !item.imageSrcUrl && item.explorerImage?.mediaId && generateImageSrcFromCache ? generateImageSrcFromCache(null, item.explorerImage.mediaId) : item.imageSrcUrl;
    }

    return {
      ...item,
      title: item.title?.text,
      'itemId': item.id,
      imageSrcUrl,
      'layoutType': item.type,
      'itemType': item.itemType,
      'optionIndex': item.itemOrder + 1,
      titleFontSettings: {
        ...item.title,
        fontWeight: item.title.weight,
        fontSize: item.title.size
      },
      buttonColor: item.title.bgColor,
      imageLandingSrcUrl: item.itemType === CAROUSEL_ITEM_TYPE.PRODUCT ? item.linkURL : null,
      adUnitId: item.itemType === CAROUSEL_ITEM_TYPE.VIDEO ? item.creativeId : null,

    }
  })
  return {items, title, titleFontSettings}
}


export default Explorer;
