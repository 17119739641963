import {useEffect, useMemo} from "react";
import surveyHelpers from "utils/survey-helper";
import {AdUnitEvent} from '../utils/constants'

function useCardExposureEvents(creativeCard, showing, onEvent, fireJourneyExposureTrigger) {
  const cardExposedIds = useMemo(() => new Set(), []);
  const journeyTriggerExposedCardIds = useMemo(() => new Set(), []);
  // on showing, send
  useEffect(() => {
    const timeouts = [];
    const {surveyID, engagementTemplate} = surveyHelpers.getSurveyDetails(creativeCard);
    const overlayID =  creativeCard?.id

    if ((Boolean(surveyID) || Boolean(overlayID)) && showing) {
      if (!journeyTriggerExposedCardIds.has(creativeCard.id)) {
        fireJourneyExposureTrigger();
        journeyTriggerExposedCardIds.add(creativeCard.id);
      }
      let payload = {engagementTemplate}

      if(surveyID) {
        payload = {...payload, surveyID}
      }
      if (!cardExposedIds.has(creativeCard.id)) {
        onEvent({
          ...payload,
          action: AdUnitEvent.CardExposure,
        });

        cardExposedIds.add(creativeCard.id);
      }

      onEvent({
        ...payload,
        action: AdUnitEvent.TotalCardExposures,
      });
      if(surveyID) {
        timeouts.push(setTimeout(() => {
          timeouts.push(setTimeout(() => {
            onEvent({...payload, action: AdUnitEvent.SurveyExposure10s})
          }, 5000));

          onEvent({
            ...payload,
            action: AdUnitEvent.SurveyExposure5s,
          })
        }, 5000));
      }
    }

    return () => {
      timeouts.forEach(clearTimeout);
    }
  }, [onEvent, creativeCard, showing, cardExposedIds, fireJourneyExposureTrigger, journeyTriggerExposedCardIds]);

}

export default useCardExposureEvents
