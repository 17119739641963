import React, {useCallback, useMemo} from "react";

import {openUrl, getTarget} from "utils/url-helper";
import {getOpacityStyle} from "utils/engagement-helper";
import {CLICK_EVENT, CLICK_SRC_WIDGET, journeyTriggerExposureEvents} from "utils/constants";
import {useConfig} from "utils/ConfigProvider";

import useDisplayCardExposureEvents from "hooks/useDisplayCardExposureEvents";
import style from "./style.module.scss";
import classnames from "classnames";
import {RAW_IMAGE_LABEL} from '../../utils/constants';

function DisplayRawImage({settings, displayEngagement, fireJourneyTrigger, trackClickThrough, onEvent}) {
  const {environment} = useConfig();
  const isCreativeStudioPreviewMode = environment.isCreativeStudioPreviewMode();

  const {mediaType} = settings;
  const {creativeCard, hasChildren} = displayEngagement;
  const {trackingURL, rawImage, creativeCardType} = creativeCard;
  const displayEngagementWithUpdatedMediaType = useMemo(() => ({...displayEngagement, mediaType}),[displayEngagement, mediaType]);

  const imgStyle = useMemo(() => {
    return {
      ...getOpacityStyle(rawImage?.opacity),
      backgroundImage: `url(${rawImage?.linkURL})`,
      ...(isCreativeStudioPreviewMode && {cursor: 'default'})
    }
  }, [rawImage, isCreativeStudioPreviewMode]);

  const handleRawImageLinkClick = (e) => {
    e.preventDefault();
    if (trackingURL && !isCreativeStudioPreviewMode) {
      const target = getTarget(trackingURL);
      openUrl(trackingURL, target);
    }
    const payload = { type: CLICK_EVENT, srcWidget: CLICK_SRC_WIDGET.DISPLAY_IMAGE_CLICK_THROUGH, overlayID: creativeCard.id, engagementTemplate: RAW_IMAGE_LABEL};
    trackClickThrough(payload, displayEngagement.inSessionTriggerDetails.triggerElementId, !!trackingURL);
  }

  const fireJourneyExposureTrigger = useCallback(() => {
    if (journeyTriggerExposureEvents[creativeCardType]) {
      fireJourneyTrigger(journeyTriggerExposureEvents[creativeCardType]);
    }
  }, [creativeCardType, fireJourneyTrigger]);

  useDisplayCardExposureEvents(displayEngagementWithUpdatedMediaType, true, onEvent, fireJourneyExposureTrigger);

  return (
    <div className={classnames(style.rawImageContainer, hasChildren ? style.pointer : null)}>
      <a
        href={trackingURL}
        target="_blank"
        className={style.rawImage}
        style={imgStyle}
        onClick={handleRawImageLinkClick}
        data-testid="raw-image-link"
        rel={'noreferrer'}
      />
    </div>
  );
}

export default DisplayRawImage;
