// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".playButton--3oj4-{width:100%;height:100%;display:block}.playButtonContainer--3bCHG{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}", ""]);
// Exports
exports.locals = {
	"playButton": "playButton--3oj4-",
	"playButtonContainer": "playButtonContainer--3bCHG"
};
module.exports = exports;
