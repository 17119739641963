import React, {useCallback, useEffect, useMemo} from 'react';
import OverlayFinale from "compounds/overlay-finale";
import {getProps, renderHotspotOutsideCard} from "utils/engagement-helper";
import {useOverlayComponent} from "modules/overlay/useOverlayComponent";

import {ENGAGEMENT_DISPLAY_MODE,} from "utils/constants";
import HotspotManager from "modules/hotspot-manager";
import {useOverlayHotspotHandler} from "./useOverlayHotspotHandler";

export const FinaleOverlay = (
  {
    overlay,
    settings,
    onEngagementEvent,
    fireJourneyTrigger,
    fireCallback,
    submitSurvey,
    isCloseButtonShowing,
    isShowingOnHotspot,
    onFinaleEEClose,
    setEngagementMode,
    onClickThrough,
    showingOverride,
    getSelectedPosition,
    setSelectedOption,
    onHotspotClickAway,
    cardHotspotFireJourneyTrigger,
    setPixelFiringInProgress,
    submitSurveyByHotspotClickThrough,
    creativeDimensions,
    hotspotProps,
    scrubBarShowing,
    isSurveySubmitted,
    submissionDetails,
    setsubmissionDetailsTime,
    overlayState
  }
) => {
  const props = useMemo(() => getProps(overlay), [overlay]);

  const mode = useMemo(() => ENGAGEMENT_DISPLAY_MODE.FULL_MODE, []);

  useEffect(() => {
    return setEngagementMode(mode)
  }, [mode, setEngagementMode]);

  const {creativeCard, hotspotType, isSharedCard} = overlay
  const engagement = hotspotType === 'ENGAGEMENT_EXPERIENCE' ? overlay : creativeCard
  const creativeCardType = engagement?.creativeCardType
  const {fireCardHotspotJourneyTrigger} = useOverlayHotspotHandler(overlay, cardHotspotFireJourneyTrigger, submitSurveyByHotspotClickThrough);
  const renderHotspots = useCallback(({renderWithoutContainer = false, hotspotContainer, interactProps, containerDimensions} = {}) => {
      const {creativeCard} = overlay
      return creativeCard?.hotspots ?
      <HotspotManager
        hotspots={creativeCard.hotspots}
        onHotspotClickAway={onHotspotClickAway}
        fireJourneyTrigger={fireCardHotspotJourneyTrigger}
        onEvent={onEngagementEvent}
        showing={true} // always show when finale overlay is showing
        setHotspotOverlay={() => {}} //  noop
        onClickThrough={onClickThrough}
        isCardHotspot={true}
        overlayID={creativeCard.id}
        setPixelFiringInProgress={setPixelFiringInProgress}
        isCardAndInVideoAndFinale={overlay.inVideoEngagementEnabled && overlay.finaleEnabled}
        isFinaleOverlay={true}
        hotspotProps={hotspotProps}
        renderWithoutContainer={renderWithoutContainer}
        hotspotContainer={hotspotContainer}
        interactProps={interactProps}
        containerDimensions={containerDimensions}
        isSharedCard={isSharedCard}
      /> :
      <React.Fragment/>
  }, [isSharedCard, fireCardHotspotJourneyTrigger, hotspotProps, onClickThrough, onEngagementEvent, onHotspotClickAway, overlay, setPixelFiringInProgress]);

  const Engagement = useOverlayComponent(overlay, settings, onEngagementEvent, false, mode, fireJourneyTrigger, fireCallback, submitSurvey, false, onClickThrough, getSelectedPosition, setSelectedOption, renderHotspots, creativeDimensions, hotspotProps, scrubBarShowing, isSurveySubmitted, submissionDetails, setsubmissionDetailsTime, overlayState);

  const canRenderOutsideCard = useMemo(() => {
    return (creativeCardType && renderHotspotOutsideCard(creativeCardType));
  }, [creativeCardType]);

  return <OverlayFinale
    {...props}
    closeShowing={isCloseButtonShowing}
    showAbsoluteCloseIcon={!isShowingOnHotspot}
    onClose={onFinaleEEClose}
    renderHotspots={renderHotspots}
    canRenderOutsideCard={canRenderOutsideCard}
    scrubBarShowing={scrubBarShowing}
    removeZIndexFromContainer={!canRenderOutsideCard}
  >
    {showingOverride && Engagement}
  </OverlayFinale>
}
