import jsonLogic from 'json-logic-js';
import {IN_SESSION_NO_INTERACTION_EVENT, IN_SESSION_NO_CLICK_EVENT, IN_SESSION_JOURNEY_EVENT_MAP} from 'utils/constants';
import {isEmpty} from "./helpers";

/*
  element is only eligible to be shown when:
     * if the video is not showing a finale: current player time falls in one of the element's time trigger ranges (if no time ranges are configured, we assume true)
       * if the video is showing a finale: the element is finaleEnabled
     * element's triggerLogic evaluates to true with current triggerState (if no triggerlogic is configured, we assume true)
     * element is on the trigger path or is directly "connected" to video start (this is the same as no triggerLogic case above)
       * if triggerState is empty and we bypass this check since triggerLogic will filter all elements with triggerLogic.  This is to support IN_SESSION_NO_INTERACTION_EVENT.
       * simulatePathLogicForConnectedToStart can be used to ignore the "always show when connected to start" rule.  This is used to hide non-survey cards connected to start based on pathing rules.
     * element may have additional constraints to be shown that is applied in conjuction with this filter (e.g bottom logo is not shown if bottom or right in-video planel is showing)
*/
export const canShowInSessionTriggerDetails = (inSessionTriggerDetails, playerTime, triggerState, triggerIdsInPath, finaleEnabled, isFinaleShowing, ignoreLogicAndPath, simulatePathLogicForConnectedToStart=false, allInSessionTriggerDetails) => {
  // TODO: need to handle preview cases as well as screenshotting...
  //   active editing of overlay when there can be multiple overlays at the same time for both in-video and finale
  //   cta, brand logo, and social share don't really have an active edit mode to determine when to show and when to respect logic
  //   engagement preview/screenshotting assumes only one finale, we can have multiple and if first one is conditionalized we will only see a black screen
  if (!inSessionTriggerDetails) {
    return true;
  }

  let timeRangeMatch = false
  if (isFinaleShowing) {
    timeRangeMatch = finaleEnabled;
  } else {
    timeRangeMatch = (!inSessionTriggerDetails.elementTimeTriggers || inSessionTriggerDetails.elementTimeTriggers.length === 0 ||  !!inSessionTriggerDetails.elementTimeTriggers.find(it =>  it.startTime <= playerTime && it.endTime > playerTime));
  }

  const noInteraction = isEmpty(triggerState);
  const noInteractionModdedTriggerState = {...triggerState, [IN_SESSION_NO_INTERACTION_EVENT]: noInteraction};
  const triggerLogicMatch = (!inSessionTriggerDetails.triggerLogic || jsonLogic.apply(JSON.parse(inSessionTriggerDetails.triggerLogic), noInteractionModdedTriggerState));

  let connectedToStartCheckForPath = false;
  if (!ignoreLogicAndPath && simulatePathLogicForConnectedToStart && allInSessionTriggerDetails && !inSessionTriggerDetails.triggerLogic) {
    connectedToStartCheckForPath = !hasTriggeredChildren(inSessionTriggerDetails.triggerElementId, triggerState, allInSessionTriggerDetails);
  } else {
    connectedToStartCheckForPath = !inSessionTriggerDetails.triggerLogic;
  }

  const isInTriggerPath = (connectedToStartCheckForPath || (triggerIdsInPath && triggerIdsInPath.includes(inSessionTriggerDetails.triggerElementId)) || (noInteraction && inSessionTriggerDetails.triggerLogic.includes(IN_SESSION_NO_INTERACTION_EVENT)));

  return ignoreLogicAndPath ? timeRangeMatch : timeRangeMatch && triggerLogicMatch && isInTriggerPath;
}

const hasTriggeredChildren = (triggerElementId, triggerState, allInSessionTriggerDetails) => {
  const currentTriggersForElement = triggerState[triggerElementId];
  let hasTriggeredChildren = false;
  
  if (currentTriggersForElement) {
    const triggeredEvents = Object.keys(currentTriggersForElement);
    for (const event of triggeredEvents) {
      if (getChildrenTriggerElementIds(allInSessionTriggerDetails, triggerElementId, event, triggerState).length > 0) {
        hasTriggeredChildren = true;
        break;
      }
    }
  }

  return hasTriggeredChildren;
}

// find the next untriggered set of triggerElementIds
export const getNextTriggerElementIdsInPath = (inSessionTriggerDetailsList, prevTriggerState, parentTriggerElementId, event) => {
  // For display pathmaker, getChildrenTriggerElementIds should always only return 1 or 0 element as logos cannot be chained to each other
  // and each triggerId + event combination should only yield one display engagement otherwise there would be a collision

  const nextTriggerState = getNewTriggerState(prevTriggerState, parentTriggerElementId, event);
  const triggerIds = getChildrenTriggerElementIds(inSessionTriggerDetailsList, parentTriggerElementId, event, nextTriggerState);
  const prevTriggeredId = triggerIds.find((triggerId) => {
    return prevTriggerState[triggerId] && Object.keys(prevTriggerState[triggerId]).find((event) => !!IN_SESSION_JOURNEY_EVENT_MAP[event]);
  })

  return prevTriggeredId ? getNextTriggerElementIdsInPath(inSessionTriggerDetailsList, prevTriggerState, prevTriggeredId, Object.keys(prevTriggerState[prevTriggeredId]).find((event) => !!IN_SESSION_JOURNEY_EVENT_MAP[event])) : triggerIds;

}

// This assumes that our graphs are directed acyclical multigraphs.
// This will evaluate whether or not an element is considered a 'child' based on whether or not the target element is both connected to 
// the parent and will be showable (triggerLogic is satisfied by triggerState)
export const getChildrenTriggerElementIds = (inSessionTriggerDetailsList, parentTriggerElementId, event, nextTriggerState) => {
  if (!inSessionTriggerDetailsList || inSessionTriggerDetailsList.length === 0 || !parentTriggerElementId || !event) {
    return [];
  }

  // look for elements that are triggered directly via element id + event
  const directTriggerElements = inSessionTriggerDetailsList
    .filter(({triggerElementId, originalTriggerLogic}) => originalTriggerLogic && originalTriggerLogic.includes(`${parentTriggerElementId}.${event}`));

  // look for any noClick grandchildren off of direct children
  const noClickGrandChildrenElement = directTriggerElements.length === 0 ? [] : inSessionTriggerDetailsList
    .filter(({originalTriggerLogic}) =>  new RegExp(directTriggerElements.map(({triggerElementId}) => (`${triggerElementId}.${IN_SESSION_NO_CLICK_EVENT}`)).join("|")).test(originalTriggerLogic));

  // filter out elements that will not show based on trigger logic to account for AND logic
  return [...directTriggerElements, ...noClickGrandChildrenElement]
    .filter((inSessionTriggerDetails) => inSessionTriggerDetails.triggerLogic && jsonLogic.apply(JSON.parse(inSessionTriggerDetails.triggerLogic), nextTriggerState))
    .map(({triggerElementId}) => triggerElementId);
}

export const getAllInSessionTriggerDetails = ({creativeCardSettings=[], hotspots=[], socialShare={}, topLogo={}, bottomLogo={}, callToAction={}}) => {
  const allInSessionTriggerDetails = [
    ...(socialShare && socialShare.visible && socialShare.inSessionTriggerDetails ? [socialShare.inSessionTriggerDetails] : []),
    ...(topLogo?.inSessionTriggerDetails ? [topLogo.inSessionTriggerDetails] : []),
    ...(bottomLogo?.inSessionTriggerDetails ? [bottomLogo.inSessionTriggerDetails] : []),
    ...(callToAction?.inSessionTriggerDetails ? [callToAction.inSessionTriggerDetails] : []),
    ...((creativeCardSettings || []).filter(({inSessionTriggerDetails}) => inSessionTriggerDetails).map(({inSessionTriggerDetails}) => inSessionTriggerDetails)),
    ...((hotspots || []).filter(({inSessionTriggerDetails}) => inSessionTriggerDetails).map(({inSessionTriggerDetails}) => inSessionTriggerDetails))
  ];

  return allInSessionTriggerDetails;
}

export const getNewTriggerState = (prevTriggerState, elementTriggerId, event) => {
  return {...prevTriggerState, [elementTriggerId]: {...prevTriggerState[elementTriggerId], [event]: true}};  
}
