import React from "react";
import {CUSTOM_ENGAGEMENT_TYPE} from "utils/constants";
import {BackgroundImage} from "elements/backgroundImage";
import {EmbedHTML} from "elements/embedHTML";

function CustomEngagementRenderer({type, imageUrl, imageCTA, imageOpacity, embedUrl, onClick, mediaSubType}) {
  return type === CUSTOM_ENGAGEMENT_TYPE.IMAGE ?
    <BackgroundImage
      url={imageUrl}
      cta={imageCTA}
      opacity={imageOpacity}
      onClick={onClick}
    />:
    <EmbedHTML
      content={embedUrl}
      mediaSubType = {mediaSubType}
    />
}

export default CustomEngagementRenderer;
