import select from 'select';


const SECONDS_IN_HOUR = 3600;
const MINUTES_IN_HOUR = 60;

export default class Functions {

  static getFormattedAdRemainingTime(numberOfSeconds, adStarted) {
    if (numberOfSeconds == null || isNaN(numberOfSeconds) || numberOfSeconds < 0 || (!adStarted && numberOfSeconds === 0)) {
      return '-:-';
    }

    const hours = Math.floor(numberOfSeconds / SECONDS_IN_HOUR);
    const minutes = Math.floor((numberOfSeconds - (hours * SECONDS_IN_HOUR)) / MINUTES_IN_HOUR);
    const seconds = numberOfSeconds - (hours * SECONDS_IN_HOUR) - (minutes * MINUTES_IN_HOUR);

    const minOutput = String(minutes);
    const secOutput = seconds < 10 ? `0${seconds}` : String(seconds);
    return `${minOutput}:${secOutput}`;
  }



  static getParentUrl() {
    // eslint-disable-next-line no-restricted-globals
    const isInIframe = (parent !== window);
    let parentUrl = null;
    if (isInIframe) {
      parentUrl = document.referrer;
    }
    return parentUrl;
  }

  static getSrcUrl() {
    const url = Functions.getParentUrl();
    return (url == null) ? window.location.href : url;
  }

  static getQuarter(time, duration) {
    const quarterSize = duration / 4;
    return Math.floor(time / quarterSize);
  }

  static getAllQuarters(duration) {
    if (!duration) {
      return null;
    }
    const q1 = Math.min(Math.ceil(duration / 4), duration);
    const q2 = Math.min(Math.ceil(duration / 2), duration);
    const q3 = Math.min(Math.ceil(3 * duration / 4), duration);
    return [q1, q2, q3]
  }


  static copyTextToClipboard(text, appRootId) {
    const appRoot = document.getElementById(appRootId) || document;
    const inputEl = document.createElement('input');
    inputEl.style.position = 'fixed';
    inputEl.style.top = '-1000px';
    inputEl.style.left = '-1000px';
    inputEl.value = text;
    appRoot.appendChild(inputEl);
    select(inputEl);
    document.execCommand('Copy');
    appRoot.removeChild(inputEl);
  }


  static getFormattedTime(secs) {
    // Convert seconds to HH:MM:SS format.
    if (secs == null) {
      return '00:00';
    }
    const sec_num = parseInt(secs, 10);
    const hours = Math.floor(sec_num / 3600);
    const minutes = Math.floor(sec_num / 60) % 60;
    const seconds = sec_num % 60;

    return [hours, minutes, seconds]
      .map(v => v < 10 ? `0${v}` : v)
      .filter((v, i) => v !== '00' || i > 0)
      .join(':');
  }
}
