import React, {useRef} from "react";
import Carousel from '../carousel';
import {ENGAGEMENT_DISPLAY_MODE} from 'utils/constants';
import classnames from 'classnames';
import style from './style.module.scss';
import {Title} from 'elements/typography';

function EngagementCarousel(
  {
    items,
    title,
    titleFontSettings,
    mode,
    creativeAspectRatio,
    onClick,
    inVideo
  }
) {
  const titleStyleProps = {autoResize: true, fontSize: '.9em', fontSettings: titleFontSettings, centerAlign: true}
  const itemsStyleProps = {mode, creativeAspectRatio, items, onClick}

  const ref = useRef(null);

  const renderInHorizontalMode = (titleStyleProps, title, itemsStyleProps) => {
    return <div className={style.flexFull}>
      <div className={style.titleContainerHorizontal}>
        <Title {...titleStyleProps} containerStyle={{display: 'flex'}} padding={'1em 0 0 1em'}
               topLeft={true}>{title}</Title>
      </div>
      <div className={style.carouselContainerHorizontal} ref={ref}>
        <Carousel {...itemsStyleProps} refElement={ref} maxPercentageHeight={0.85}/>
      </div>
    </div>
  }

  const renderInFullMode = (titleStyleProps, title, itemsStyleProps) => {
    return <div
      className={classnames(style.engagementCarousel)}>
      <div className={style.titleContainer}>
        <div className={style.titleContainerFullMode}>
          <Title {...titleStyleProps} padding={'0'} fullHeight={true}>{title}</Title>
        </div>
      </div>
      <div className={style.carouselContainer} ref={ref}>
        <Carousel {...itemsStyleProps} refElement={ref} maxPercentageHeight={items.length > 2 ? 1 : 0.90}/>
      </div>
    </div>
  }

  const renderInVerticalMode = (titleStyleProps, title, itemsStyleProps) => {
    return <div
      className={classnames(style.engagementCarousel)}>
      <div className={style.titleContainerVertical}>
        <Title {...titleStyleProps}
               autoSizeContainerStyle={{display: 'flex', justifyContent: 'center', alignItems: 'flex-end', flexWrap: 'wrap'}}
               padding={'0'} fullHeight={true}>{title}</Title>
      </div>
      <div className={style.verticalCarousel} ref={ref}>
        <Carousel {...itemsStyleProps} refElement={ref}/>
      </div>
    </div>
  }


  if (mode === ENGAGEMENT_DISPLAY_MODE.HORIZONTAL) {
    return renderInHorizontalMode(titleStyleProps, title, itemsStyleProps, inVideo);
  }

  if (mode === ENGAGEMENT_DISPLAY_MODE.FULL_MODE) {
    return renderInFullMode(titleStyleProps, title, itemsStyleProps);
  }

  if (mode === ENGAGEMENT_DISPLAY_MODE.VERTICAL) {
    return renderInVerticalMode(titleStyleProps, title, itemsStyleProps);
  }

  // Default return horizontal mode - Used in Display Creative
  return renderInFullMode(titleStyleProps, title, itemsStyleProps);
}

export default EngagementCarousel;
