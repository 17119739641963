import {useCallback, useMemo} from 'react';

import {getTarget, openUrl} from 'utils/url-helper';
import {CLICK_SRC_WIDGET, JourneyTriggerInteractiveEvent, AdUnitEvent} from 'utils/constants';

export function useProductExplorerEngagementEvents(onEvent, fireJourneyTrigger, onClickThrough, triggerElementId) {
  const onClick = useCallback((slideId, destinationURL, optionPosition) => {
    const target = getTarget(destinationURL);
    openUrl(destinationURL, target);

    onEvent({
      type: AdUnitEvent.ClickEvent,
      srcWidget: CLICK_SRC_WIDGET.PRODUCT_EXPLORER_CLICK_THROUGH,
      slideId,
      optionPosition
    });

    fireJourneyTrigger(JourneyTriggerInteractiveEvent.interactiveProductExplorerClickThrough, triggerElementId);
    fireJourneyTrigger(JourneyTriggerInteractiveEvent.interactiveEngagementExperienceClickThrough, triggerElementId);

    if (onClickThrough) {
      onClickThrough()
    }

  }, [onEvent, fireJourneyTrigger, onClickThrough, triggerElementId]);

  const slideExposedIds = useMemo(() => new Set(), []);
  const onSlideLoad = useCallback((slideId) => {

    if (slideId) {
      const payload = {
        slideId
      }

      if (!slideExposedIds.has(slideId)) {
        onEvent({
          ...payload,
          action: AdUnitEvent.SlideExposures,
        });
        slideExposedIds.add(slideId);
      }
      onEvent({
        ...payload,
        action: AdUnitEvent.TotalSlideExposures,
      });
    }
  }, [onEvent, slideExposedIds]);

  return {
    onClick,
    onSlideLoad
  };
}
