import {useCallback} from 'react';
import MessageHelper from "../utils/messageHelper";
import {THIRD_PARTY_EVENTS, EventType} from '../utils/constants';

// Fire the pixels for each journey trigger event present in settings and/or in vast wrapper in TrackingEvents xml tag
export function useClickThroughEvents(track3rdPartyEvents) {
  const trackClickThroughEvents = useCallback(() => {
    track3rdPartyEvents(THIRD_PARTY_EVENTS.CLICK_THROUGH);
    MessageHelper.instance.playerStatus(EventType.AdClickThru);
  }, [track3rdPartyEvents]);

  return trackClickThroughEvents
}
