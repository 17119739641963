import React, {useRef, useEffect} from "react";
import {MediaSubType} from 'utils/constants'

import style from "./style.module.scss";

import {replaceCacheBustingMacro, replaceVgMacro} from "utils/macroHelper";
import {CustomEmbedRenderer} from 'compounds/engagement-custom/customEmbedRenderer';
import {appendIframeForCustomHtml} from 'utils/customEmbedHelper';
import {useConfig} from 'utils/ConfigProvider';

function DisplayTagUrl({settings}) {
  const ref = useRef();

  const {environment} = useConfig();

  const replaceMacro = (content) => {
    if (!content) {
      return '';
    }
    content = replaceCacheBustingMacro(content);
    content = replaceVgMacro(content, environment.getSrcUrlWithoutQueryParam());
    return content;
  }

  const {creativeCard} = settings;
  const {content, mediaSubType} = creativeCard;

  const macroReplacedContent = replaceMacro(content);

  let scriptContent = "";
  let scriptSrc = "";
  let bodyContent = "";
  let iframeSrc = "";

  switch (mediaSubType) {
    case MediaSubType.JS_TAG:
      scriptContent = macroReplacedContent;
      break;

    case MediaSubType.JS_URL:
      scriptSrc = macroReplacedContent;
      break;

    case MediaSubType.HTML_URL:
      iframeSrc = macroReplacedContent;
      break;

    case MediaSubType.HTML_TAG:
      bodyContent = macroReplacedContent;
      break;

    default:
      break;
  }

  useEffect(() => appendIframeForCustomHtml(ref, bodyContent, scriptSrc, scriptContent, mediaSubType, iframeSrc,
          false),
      [ref, bodyContent, iframeSrc, scriptSrc, scriptContent, mediaSubType])

  return (
    <>
      {
        <div className={style.displayTagUrlContainer}>
          <CustomEmbedRenderer
            iframeIdentifierGroup = {style.displayTagUrlIframe}
            iframeSrc = {iframeSrc}
            scriptContent = {scriptContent}
            scriptSrc = {scriptSrc}
            bodyContent = {bodyContent}
            enableScroll={false}
          />
        </div>
      }
    </>
  );
}

export default DisplayTagUrl;
