import FontAndColorHelper, {fontLookup, HOTSPOT_FONT_OVERRIDE} from 'utils/fontAndColorHelper';
import Functions from 'utils/functions';
import {canShowInSessionTriggerDetails} from 'utils/inSessionTriggerHelper';
import surveyHelpers from "utils/survey-helper";

import {
  ENGAGEMENT_EXPERIENCE_SOURCE,
  HOTSPOT_ANIMATION_DURATION,
  AdUnitEvent,
  MARKER,
  MARKER_CONFIG,
  EFFECT_TYPE
} from 'utils/constants';
import {getElementBackgroundStyle} from "./engagement-style-helper";
import { DEFAULT_EFFECT_VALUES, EFFECT_BORDER_STYLE_TYPES } from './constants';

export const HOTSPOT_POSITIONS = {
  TOP: 'TOP',
  TOP_RIGHT: 'TOP_RIGHT',
  RIGHT: 'RIGHT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
  BOTTOM: 'BOTTOM',
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  LEFT: 'LEFT',
  TOP_LEFT: 'TOP_LEFT'
};

export const BASE_STYLES = {
  LIGHT: 'LIGHT',
  REGULAR: 'REGULAR',
  SOLID: 'SOLID',
};


export const HOTSPOT_BORDER_RADIUS_TYPE = {
  DEFAULT: 'DEFAULT',
  CUSTOM: 'CUSTOM'
}

export const BORDER_RADIUS_OPTIONS = [
  {
    key: 'borderRadiusTopLeft',
  },
  {
    key: 'borderRadiusTopRight',
  },
  {
    key: 'borderRadiusBottomRight',
  },
  {
    key: 'borderRadiusBottomLeft',
  },
]

export const STYLE_TO_FONT_STYLE_MAPPING = {
  [BASE_STYLES.LIGHT]: 'fal',
  [BASE_STYLES.REGULAR]: 'far',
  [BASE_STYLES.SOLID]: 'fas',
};

const NO_BORDER = 'NO_BORDER' // TODO move BORDER_STYLE type to constants that phoenix-core can use.

export function getCurrentHotspots(settings, playerTime, isSurveySubmitted, isFinaleShowing, triggerState, triggerIdsInPath, playerState, ignoreLogicAndPath) {
  let hotspots = [];
  const allHotspots = (settings.hotspots || []);

  if (playerTime !== undefined && playerState !== undefined) {
    hotspots = (allHotspots
      .filter((hotspot) => isSurveySubmitted ? !isSurveySubmitted(surveyHelpers.getSurveyDetails(hotspot).surveyID) : true))
      .filter(({inSessionTriggerDetails}) => canShowInSessionTriggerDetails(inSessionTriggerDetails, playerTime, triggerState, triggerIdsInPath, false, isFinaleShowing, ignoreLogicAndPath))
      .map((hotspot) => {
        const {useAnimation} = hotspot;
        return {
          ...hotspot,
          timeTriggerIndex: hotspot.inSessionTriggerDetails.elementTimeTriggers.findIndex((it) => {
            // Add Hotspot Animation Duration on to endTime when `useAnimation` is checked.
            const endTimeOffset = useAnimation ? HOTSPOT_ANIMATION_DURATION : 0;
            return it.startTime <= playerTime &&
              (it.endTime + endTimeOffset) > playerTime
          })
        }
      });
  }

  return {hotspots, allHotspots};
}

export function getOverriddenFonts({hotspotText}) {
  const override = HOTSPOT_FONT_OVERRIDE.find(
    (fonts) => {
      const {
        key: {
          font,
          fontWeight
        }
      } = fonts;
      return font === hotspotText.font && fontWeight === hotspotText.fontWeight;
    });
  if (override) {
    hotspotText = {...hotspotText};
    hotspotText.font = fontLookup[override.value.font]?.name;
    hotspotText.fontWeight = override.value.fontWeight;
  } else {
    hotspotText = {...hotspotText};
    hotspotText.font = fontLookup[hotspotText.font]?.name;
  }
  return hotspotText;
}


// Calculate the user-entered border-width percent to be 1/3rd of the player height
// Please note that if changing here, then we need to sync the size calculation between phoenix and campaign ui
export const convertBorderFromPercentageToPixel = (value, height, offset = "0px") => {
  return value ? `${((value / 100) * (height / 3)) - parseFloat(offset)}px` : '0px'
}

// radiusOffset is used to calculate the inner radius of a hotspot which can be pretty different if there's a thick border
export function getHotspotMarkerBorderRadius(rawHotspot, height, radiusOffset = "0px") {
  if (MARKER_CONFIG[rawHotspot?.marker]?.isDefault) {
    const {borderStyle = {}} = rawHotspot;
    const {borderRadiusType} = borderStyle;
    switch (borderRadiusType) {
      case HOTSPOT_BORDER_RADIUS_TYPE.DEFAULT: {
        const {borderRadiusDefault} = borderStyle;
        return convertBorderFromPercentageToPixel(borderRadiusDefault, height, radiusOffset)
      }
      case HOTSPOT_BORDER_RADIUS_TYPE.CUSTOM: {
        let borderRadius = '';
        for (const {key} of BORDER_RADIUS_OPTIONS) {
          borderRadius += `${convertBorderFromPercentageToPixel(borderStyle[key], height, radiusOffset)} `;
        }
        return borderRadius;
      }
      default: {
        return null;
      }
    }
  }
  return null;
}

export function getHotspotStyle(hotspot, playerHeight) {
  const {
    height,
    width,
    positionX,
    positionY,
    borderStyle = {},
    marker = MARKER.DEFAULT
  } = hotspot;

  const {
    color: borderColor,
    borderWidth: propBorderWidth,
    borderType: propBorderType,
  } = borderStyle;

  const isDefaultMarker = marker === MARKER.DEFAULT;
  const hotspotText = getOverriddenFonts(hotspot);

  const {color} = FontAndColorHelper.getFontAndColor(hotspotText, false);
  const {fontSize, fontWeight} = FontAndColorHelper.getFontWeightAndSize(hotspotText, true);

  const borderRadius = getHotspotMarkerBorderRadius(hotspot, playerHeight);
  const borderWidth = isDefaultMarker ? convertBorderFromPercentageToPixel(propBorderWidth, playerHeight) : 0;

  const backgroundStyle = isDefaultMarker ?
    getElementBackgroundStyle({
      ...hotspot,
      backgroundColor: hotspot.hotspotBgColor
    }) :
    undefined;

  const effectStyle = getHotspotEffectStyle(hotspot, playerHeight, borderWidth);

  return {
    // Dimensions
    height: `${height}%`,
    width: `${width}%`,

    // Position
    left: `${positionX}%`,
    top: `${positionY}%`,

    // Border Style
    borderColor,

    borderStyle: propBorderType === NO_BORDER ? undefined : propBorderType?.toLowerCase(),
    borderRadius,
    borderWidth: propBorderType === NO_BORDER ? undefined : borderWidth,

    // Fonts
    color,
    fontWeight,

    // calculate the user-entered font-size percent to be 1/3rd of the player height and round down.
    // Please note that if changing here, then we need to sync the size calculation between phoenix and campaign ui
    fontSize: `${Math.floor((fontSize / 100) * (playerHeight / 3))}px`,

    fontFamily: hotspotText.font,
    ...backgroundStyle,
    // Effect Style
    ...effectStyle
  };
}


export const getFormattedTimeTriggerInterval = (startTime, endTime) => {
  return `${Functions.getFormattedTime(startTime)} - ${Functions.getFormattedTime(endTime)}`;
};

export const captureClickEvent = (srcWidget, hotspotType, hotspotId, startTime, endTime, isDisplayHotspot, overlayID, isShared) => {
  return {
    type: AdUnitEvent.ClickEvent,
    hotspotType,
    hotspotId,
    srcWidget,
    ...(overlayID && {overlayID}),
    ...(isDisplayHotspot && {isDisplayHotspot}),
    isShared: !!isShared,
    engagementExperienceSource: isDisplayHotspot ? ENGAGEMENT_EXPERIENCE_SOURCE.DISPLAY : ENGAGEMENT_EXPERIENCE_SOURCE.HOTSPOT,
    ...(!isDisplayHotspot && {timetriggerinterval: getFormattedTimeTriggerInterval(startTime, endTime)}),
  };
};

export const captureHotspotOverlayClickEvent = (evtData, srcWidget, hotspotDetails) => {
  return {
    ...evtData,
    ...hotspotDetails,
    srcWidget
  };
};

export const TEXT_ALIGN = {
  CENTER: 'CENTER',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  JUSTIFY: 'JUSTIFY',
}

export const getHotspotHorizontalPadding = (rawHotspot, height) => {
  if (MARKER_CONFIG[rawHotspot?.marker]?.isDefault) {
    // Sync with ODC-UI
    return parseFloat(convertBorderFromPercentageToPixel(2, height));
  }
  return 0;
}

/**
 * OV-211 conditionally set variables for configurable hotspot effect properties
 * @param {*} hotspot - hotspot object with effect
 * @param {*} playerHeight - current containerDimension height
 * @returns object containing custom styles for hotspot effect, undefined if no hotspot.effect or type NONE
 * border inherits style from hotspot border style if unset, currently product wants default values for effect border for the UX
 */
const getHotspotEffectStyle = (hotspot, playerHeight, borderWidth) => {
  if (!hotspot.effect || hotspot.effect?.effectType === EFFECT_TYPE.NONE) {
    return undefined
  }

  const hotspotInnerBorderRadius = getHotspotMarkerBorderRadius(hotspot, playerHeight, borderWidth);
  const isBorderEffectType = EFFECT_BORDER_STYLE_TYPES.includes(hotspot.effect.effectType)
  
  return {
    // set primary color variable, use border color as fallback if effect type supports border style
    '--vg-fx-primary-color': hotspot.effect.primaryColor ? 
        hotspot.effect.primaryColor : (isBorderEffectType ? hotspot.borderStyle.color : DEFAULT_EFFECT_VALUES.primaryColor),
    '--vg-fx-secondary-color': hotspot.effect.secondaryColor ? hotspot.effect.secondaryColor : DEFAULT_EFFECT_VALUES.secondaryColor,
    ...(isBorderEffectType && {
      '--vg-fx-border-width': hotspot.effect.borderStyle?.borderWidth ? 
            convertBorderFromPercentageToPixel(hotspot.effect.borderStyle.borderWidth, playerHeight) : 
            convertBorderFromPercentageToPixel(hotspot.borderStyle.borderWidth, playerHeight),
      '--vg-fx-border-radius': hotspot.effect.borderStyle?.borderRadiusType ? 
            getHotspotMarkerBorderRadius({marker: hotspot.marker, borderStyle: hotspot.effect.borderStyle}, playerHeight) :
            getHotspotMarkerBorderRadius(hotspot, playerHeight),
      '--vg-fx-border-type': hotspot.effect.borderStyle?.borderType ? hotspot.effect.borderStyle.borderType :
            hotspot.borderStyle.borderType
    }),
    ...(hotspot.borderStyle.borderType !== NO_BORDER && // if has border, non custom, and fancy effect - add variable for border width to apply to animation
        hotspot.marker !== MARKER.CUSTOM &&
        hotspot.effect.effectType === EFFECT_TYPE.FANCY && {
      '--vg-fx-fancy-hbw': convertBorderFromPercentageToPixel(hotspot.borderStyle.borderWidth, playerHeight) // hbw = hotspot border width
    }),
    '--vg-fx-effect-width': convertBorderFromPercentageToPixel(hotspot.effect.effectWidth !== null && hotspot.effect.effectWidth !== undefined ? hotspot.effect.effectWidth : DEFAULT_EFFECT_VALUES.effectWidth, playerHeight),
    '--hotspotInnerBorderRadius': hotspotInnerBorderRadius,
    // this was used to give product team the ability to change keyframe locations in the browser for immediate feedback, if we ever want to 
    // allow the user to size their own fancy effect, we can use this with variables instead of the hardcoded values
    // ...(hotspot.effect.effectType === EFFECT_TYPE.FANCY && {
    //   '--vg-fx-fancy-keyframe-high': '0.3em',
    //   '--vg-fx-fancy-keyframe-low': '0.1em',
    // })
  }
}
