import React, {useCallback, useEffect, useMemo, useState} from 'react';
import style from './style.module.scss';
import {debounce} from '../../../utils/helpers';
const SLIDER_COLOR = '#23ADF0';
function VolumeControl({setVolume, isMute}) {
  const [currentVolume, setCurrentVolume] = useState(isMute ? 0 : 100);
  const [background, setBackground] = useState(`linear-gradient(to right, ${SLIDER_COLOR} 0%, ${SLIDER_COLOR} ${currentVolume}%, white  ${currentVolume}%, white 100%)`)
  useEffect(() => {
    const vol = currentVolume || 100
    setCurrentVolume(isMute ? 0 : vol)
  }, [isMute, currentVolume])

  useEffect(() => {
    setBackground(`linear-gradient(to right, ${SLIDER_COLOR} 0%, ${SLIDER_COLOR} ${currentVolume}%, white  ${currentVolume}%, white 100%)` );
  }, [currentVolume])

  const mouseMoveHandler =  useCallback((event) => {
    if (!event || !event.target) {
      return;
    }
    const value = event.target.value
    setCurrentVolume(value)
    setVolume(value);
  }, [setVolume]);


  const debouncedHandleMouseMove = useMemo( () => debounce((event) => mouseMoveHandler(event), 10), [mouseMoveHandler]);
  return (
    <input
      onInput={(event) => {
        event.persist();
        debouncedHandleMouseMove(event);
      }}
      step="10"
      value={currentVolume}
      className={style.slider}
      style={{background}}
      type="range"
      onChange={e => setCurrentVolume(e.target.value)}
    />
  )
}

export default VolumeControl;
