// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".iconContainer---rvkd{width:2em;max-width:2em;height:100%;transition:transform .25s;display:flex;align-items:center;cursor:pointer;position:relative;z-index:2}.scrubElementsContainer--3eW_6{height:100%;padding:0 calc(var(--rootFontSize) * 0.25);display:flex;z-index:2;flex-grow:1;flex-shrink:1;align-items:center}.volumeControl--1IEWD{visibility:hidden;left:calc(var(--rootFontSize) * 0.5)}.iconContainer---rvkd:hover .volumeControl--1IEWD{visibility:visible;opacity:1}.timeLeft--1mict{font-size:1.25em;padding-right:calc(var(--rootFontSize) * 0.25);height:100%;align-items:center;display:flex;flex-grow:1;flex-shrink:1;flex-basis:auto;color:#fff;z-index:2;align-self:center;white-space:nowrap;justify-content:center}.volumeControl--1IEWD{position:absolute;top:calc(-1*(calc(var(--rootFontSize) * 3) / 2 + 5px));width:calc(var(--rootFontSize) * 0.3);height:7px;display:flex;left:.625em;justify-content:center}", ""]);
// Exports
exports.locals = {
	"iconContainer": "iconContainer---rvkd",
	"scrubElementsContainer": "scrubElementsContainer--3eW_6",
	"volumeControl": "volumeControl--1IEWD",
	"timeLeft": "timeLeft--1mict"
};
module.exports = exports;
