export const createImage = () => {
  const img = document.createElement('img');
  img.style.position = 'absolute';
  img.style.top = '0';
  img.style.left = '0';
  img.style.zIndex = '-1';
  img.setAttribute('alt', ' ');
  return img;
}

export const doGetPromise = (url) => {
  return new Promise((resolve)=> {
    const img = createImage();
    img.onload = ()=> resolve(true);
    img.onerror = () => resolve(false);
    img.src = url;
  });
}

export const doGet = (url) => {
  const img = createImage();
  img.src = url;
  return img;
}

export const doGetViaScriptTag = (scriptUrl) => {
  const fileRef= document.createElement('script')
  fileRef.setAttribute("type","text/javascript")
  fileRef.setAttribute("src", scriptUrl)
  document.getElementsByTagName("head")[0].appendChild(fileRef)
}

export const isValidUrl = (str) => {
  return (str.startsWith('https://') || str.startsWith('http://'));
}
