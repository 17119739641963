// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".groupRow--CDn-m{display:flex;flex-direction:row;justify-content:space-between;width:100%;z-index:1}.left--1rhvZ{justify-content:flex-start;pointer-events:auto;white-space:nowrap;max-width:50%}.right--3dR7q{justify-content:flex-end;pointer-events:auto;max-width:50%}.playerBar--2uFmI{z-index:3;display:flex;width:100%;flex-direction:column;pointer-events:none;flex:0 0 auto}.topBar--q8au8{}.closeShowing--2ckMG{min-height:calc(2 * calc(var(--rootFontSize) * 0.25) + calc(var(--rootFontSize) * 0.9))}.bottomBar--2LtcT{}", ""]);
// Exports
exports.locals = {
	"groupRow": "groupRow--CDn-m",
	"left": "left--1rhvZ",
	"right": "right--3dR7q",
	"playerBar": "playerBar--2uFmI",
	"topBar": "topBar--q8au8 playerBar--2uFmI",
	"closeShowing": "closeShowing--2ckMG",
	"bottomBar": "bottomBar--2LtcT playerBar--2uFmI"
};
module.exports = exports;
