import {useLayoutEffect, useState} from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export function useHotspotContainerDimension() {
  const [hotspotContainer, setHotspotContainer] = useState(null);
  const [containerDimensions, setContainerDimensions] = useState({
    width: hotspotContainer?.clientWidth || 0,
    height: hotspotContainer?.clientHeight || 0
  });

  useLayoutEffect(() => {
    const ro = new ResizeObserver(() => {
      setContainerDimensions({
        width: hotspotContainer?.clientWidth || 0,
        height: hotspotContainer?.clientHeight || 0
      });
    });
    if (hotspotContainer) {
      ro.observe(hotspotContainer);
    }
    return () => {
      ro.disconnect();
    }
  }, [hotspotContainer]);
  return {containerDimensions, setHotspotContainer, hotspotContainer}
}