import React, {useCallback} from "react";
import classnames from 'classnames';

import {CLICK_SRC_WIDGET, AdUnitEvent} from "utils/constants";
import {openUrl} from "utils/url-helper";

import style from './style.module.scss';

import {isMobileDevice} from "utils/environmentHelper";
import {useFullScreen} from "utils/FullScreenProvider";
import {useConfig} from "utils/ConfigProvider";
import {useEventCapture}  from "utils/EventCaptureProvider";

export const AdChoices = ({creativeDimensions, settingsDimensions, isDisplayCreative}) => {
  const { localConfig, environment } = useConfig();
  const { width, height } = isDisplayCreative ? settingsDimensions : {width: parseFloat(creativeDimensions?.width), height: parseFloat(creativeDimensions?.height)};
  const { isFullScreen } = useFullScreen();
  const { capture } = useEventCapture();
  const isCreativeStudioPreviewMode = environment.isCreativeStudioPreviewMode();

  const useSmall = isMobileDevice() || (isFullScreen ? false : (width < 120 || height < 90));

  const onClick = useCallback((e) => {
    e.preventDefault();

    const payload = {
      type: AdUnitEvent.ClickEvent,
      srcWidget: CLICK_SRC_WIDGET.AD_CHOICES_CLICK,
    };

    capture(payload);

    if (!isCreativeStudioPreviewMode) {
      openUrl(localConfig.adChoicesBaseUrl, '_blank');
    }
  }, [capture, localConfig, isCreativeStudioPreviewMode]);

  return (
    <div
      onClick={onClick}
      className={classnames({
        [style.adChoicesIcon]: true,
        [style.small]: useSmall,
        [style.noPointer]: isCreativeStudioPreviewMode
      })}
    />
  )
}

