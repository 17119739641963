import {useLayoutEffect, useRef, useState} from 'react';
import ResizeObserver from "resize-observer-polyfill";

export function useVideoContainerResize() {
  const [resizeVersion, setResizeVersion] = useState(0);
  const [videoContainerRef, setVideoContainerRef] = useState(null);
  const isInitialMount = useRef(true);
  useLayoutEffect(() => {
    let timeout = null;
    const ro = new ResizeObserver(() => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        timeout && clearTimeout(timeout);
        timeout = setTimeout(() => {
          setResizeVersion((version) => version + 1);
        }, 100);
      }
    });
    if (videoContainerRef) {
      ro.observe(videoContainerRef);
    }
    return () => {
      timeout && clearTimeout(timeout);
      ro.disconnect();
    }
  }, [videoContainerRef]);
  return {
    ref: setVideoContainerRef,
    resizeVersion
  }
}