// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".gradientLayer--raWTN{animation-delay:2s;animation-duration:3s;animation-fill-mode:none;transition:opacity 1.5s,height .5s;background:linear-gradient(rgba(0, 0, 0, 0.5), transparent 20%, transparent, transparent 80%, rgba(0, 0, 0, 0.5));position:absolute;width:100%;height:100%;top:0;left:0;display:block;z-index:1;pointer-events:none;opacity:0}.gradientLayerShowing--1wRk_{opacity:1}", ""]);
// Exports
exports.locals = {
	"gradientLayer": "gradientLayer--raWTN",
	"gradientLayerShowing": "gradientLayerShowing--1wRk_"
};
module.exports = exports;
