// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".icon--2zM4T{font-size:1.25em;cursor:pointer;width:2em;height:100%;text-align:center;display:flex;justify-content:center;align-items:center;transition:.1s ease-out transform}.icon--2zM4T:hover,.icon--2zM4T:focus{cursor:pointer;transform:scale(1.1)}.icon--2zM4T.scale--3w-WK:hover{transform:scale(1.2);transform-style:preserve-3d;-webkit-backface-visibility:hidden;backface-visibility:hidden}.icon--2zM4T:before{font-family:\"Font Awesome 5 Pro\";font-weight:400}.play--2G_EZ:before{content:\"\"}.pause--3Ipj2:before{content:\"\"}.expand--1nKXd:before{content:\"\"}.compress--2cYwa:before{content:\"\"}.replay--EeMK5:before{content:\"\"}.mute--26glR:before{content:\"\"}.unmute--2HVbl:before{content:\"\"}.icon-container--i6q0M{width:calc(var(--rootFontSize) * 0.875);transition:transform .25s}", ""]);
// Exports
exports.locals = {
	"icon": "icon--2zM4T",
	"scale": "scale--3w-WK",
	"play": "play--2G_EZ",
	"pause": "pause--3Ipj2",
	"expand": "expand--1nKXd",
	"compress": "compress--2cYwa",
	"replay": "replay--EeMK5",
	"mute": "mute--26glR",
	"unmute": "unmute--2HVbl",
	"icon-container": "icon-container--i6q0M"
};
module.exports = exports;
