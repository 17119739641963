import {useCallback} from 'react';

import {getTarget, openUrl} from 'utils/url-helper';
import {CLICK_SRC_WIDGET, POSITION_OPTIONS, AdUnitEvent, JourneyTriggerInteractiveEvent} from 'utils/constants';

function useBrandLogoEvents(fireJourneyTrigger, position, isVideoCreative, onClickThrough, triggerElementId, overlayID, setPixelFiringInProgress = () => {}, capture, currentSlideId, isCreativeStudioPreviewMode) {
  const onClick = useCallback((e) => {
    e.preventDefault();

    const element = e.currentTarget;
    const destinationURL = element.getAttribute('href');

    const eventType = AdUnitEvent.ClickEvent;
    let srcWidget;
    const journeyTriggerEvent=position === POSITION_OPTIONS.TOP.toLowerCase() ? JourneyTriggerInteractiveEvent.interactiveTopLogoClickThrough :  JourneyTriggerInteractiveEvent.interactiveBottomLogoClickThrough;
    if (isVideoCreative) {
      srcWidget = position === POSITION_OPTIONS.TOP.toLowerCase() ? CLICK_SRC_WIDGET.VIDEO_SCREEN_IMAGE_TOP_LINK : CLICK_SRC_WIDGET.VIDEO_SCREEN_IMAGE_BOTTOM_LINK;
    } else  {
      srcWidget = position ===  POSITION_OPTIONS.TOP.toLowerCase() ? CLICK_SRC_WIDGET.DISPLAY_SCREEN_IMAGE_TOP_LINK : CLICK_SRC_WIDGET.DISPLAY_SCREEN_IMAGE_BOTTOM_LINK;
    }

    if (destinationURL) {
      if (!isCreativeStudioPreviewMode) {
        const target = getTarget(destinationURL);
        openUrl(destinationURL, target);
      }
      const payload = {type: eventType, srcWidget, overlayID, slideId: currentSlideId}
      setPixelFiringInProgress(true);
      capture(payload).then(() => {
        setPixelFiringInProgress(false);
      }).catch(e => setPixelFiringInProgress(false))
    }

    fireJourneyTrigger(journeyTriggerEvent, triggerElementId, !destinationURL);

    if (destinationURL && onClickThrough) {
      onClickThrough();
    }
  }, [fireJourneyTrigger, position, isVideoCreative, onClickThrough, triggerElementId, overlayID, setPixelFiringInProgress, capture, currentSlideId, isCreativeStudioPreviewMode]);

  return {onClick};
}


export default useBrandLogoEvents;
