// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container--2tnR4{flex:1;display:flex;justify-content:normal;flex-direction:column;height:100%;padding:calc(var(--rootFontSize) * 0.25)}.containerFullScreen--S_aUk{padding:calc(var(--rootFontSize) * 0.5) calc(var(--rootFontSize) * 1)}.titleContainer--2Mykx{display:flex;justify-content:center;align-items:center;font-size:calc(var(--rootFontSize) * 0.8);text-align:center;overflow-wrap:break-word;word-break:break-word;padding:0;flex:0 0 auto;position:relative}.storesContainer--1f0T2{flex:1;display:flex;justify-content:stretch;position:relative;font-family:\"Roboto\",serif;border:1px solid;border-radius:calc(var(--rootFontSize) * 0.2);overflow:hidden}.tabContent--3Pcb3{flex:1;display:flex;justify-content:stretch;position:relative;background:#fff;overflow:auto}.tabs--1ypQd{display:inline-flex;position:absolute;z-index:1;font-size:calc(var(--rootFontSize) * 1);line-height:1.5;color:#303030;font-weight:500;text-align:center;box-sizing:border-box;vertical-align:middle;cursor:pointer;box-shadow:0 0 30px -5px rgba(0,0,0,.4);top:calc(var(--rootFontSize) * 0.25);left:calc(var(--rootFontSize) * 0.25);border-radius:calc(var(--rootFontSize) * 0.2);overflow:hidden}.tab--3kL0E{padding:calc(var(--rootFontSize) * 0.25) calc(var(--rootFontSize) * 0.75);font-size:calc(var(--rootFontSize) * 0.6);-webkit-tap-highlight-color:rgba(0,0,0,0);cursor:pointer;box-sizing:border-box;display:inline-block;font-weight:400;text-align:center;-webkit-user-select:none;-ms-user-select:none;user-select:none;transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;line-height:1.5;position:relative;flex:1 1 auto;margin-left:-1px;vertical-align:middle;border:1px solid rgba(0,0,0,0);color:#000;background-color:#fff;text-transform:capitalize}.activeTab--3ndvb{color:#fff}.staticMapImage--2meE_{width:100%;height:auto}.divider--MPMfW{flex:0 0 1rem}", ""]);
// Exports
exports.locals = {
	"container": "container--2tnR4",
	"containerFullScreen": "containerFullScreen--S_aUk",
	"titleContainer": "titleContainer--2Mykx",
	"storesContainer": "storesContainer--1f0T2",
	"tabContent": "tabContent--3Pcb3",
	"tabs": "tabs--1ypQd",
	"tab": "tab--3kL0E",
	"activeTab": "activeTab--3ndvb",
	"staticMapImage": "staticMapImage--2meE_",
	"divider": "divider--MPMfW"
};
module.exports = exports;
