import React, {useEffect, useRef} from 'react';

import {PLAYER_STATE, SCRUB_ICONS} from 'utils/constants';
import ScrubIcon from 'elements/icons/scrub-icon';
import style from '../style.module.scss';
import {useFullScreen} from "utils/FullScreenProvider";

function FullScreen({playerState}) {
  const {isFullScreen, setFullScreen} = useFullScreen();
  const prevPlayerState = usePrevious(playerState);

  useEffect(() => {
    if (prevPlayerState === PLAYER_STATE.PLAYING && playerState === PLAYER_STATE.COMPLETED && isFullScreen) {
      setFullScreen(false)
    }
  }, [setFullScreen, playerState, isFullScreen, prevPlayerState]);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  return (
    <div className={style.scrubElementsContainer}>
      <div className={style.iconContainer}>
        <ScrubIcon variant={isFullScreen ? SCRUB_ICONS.COMPRESS : SCRUB_ICONS.EXPAND}
                   onClick={() => setFullScreen(!isFullScreen)}/>
      </div>
    </div>
  )
}

export default FullScreen;
