import {useCallback} from 'react';
import {doGet} from 'utils/eventUtil';
import {replaceCacheBustingMacro} from 'utils/macroHelper';

const globalObject = {
  doGet
};

const getJSFunction = (jsFunctionCode) => {
  try {
    return new Function('app', 'action', jsFunctionCode);
  } catch (ex) {
    console.error(`could not parse the following js code ${jsFunctionCode}`);
    return null;
  }
};


const fireEventHandle = (eventHandle, action) => {
  let actualFunction = null;
  try {
    actualFunction = getJSFunction(replaceCacheBustingMacro(eventHandle.jsFunctionCode));
    if (actualFunction) {
      // We have to add "globalObject" as "app" in the argument because our code from playerSettings expects "app" tobe present in global with few functions - ie: doGet, etc
      actualFunction(globalObject, action);
    }
  } catch (ex) {
    console.error(`Failed to execute JS Event Handler: funct:${actualFunction} error:${ex}`);
  }
};


export function use3rdPartyEventTracking(settings) {
  const track3rdPartyEvents = useCallback((evType, elapsedSeconds, viewedSeconds, evParams) => {
    const eventHandlers = (settings || {}).jsEventHandlers;

    if (!eventHandlers) {
      return;
    }

    let eventHandles;

    if (evParams && evParams.hotspotId) {
      eventHandles = eventHandlers.filter(
        (handler) => ((handler.evType === evType) && (handler.hotspotId === evParams.hotspotId))
      );
    } else {
      eventHandles = eventHandlers.filter(
        (handler) => ((handler.evType === evType) && (!handler.hotspotId))
      );
    }

    if (eventHandles.length < 1) {
      return;
    }
    eventHandles.forEach((eventHandle) => {
      fireEventHandle(eventHandle, evType);
    });
  }, [settings]);

  return {
    track3rdPartyEvents
  };
}
