import React, {useCallback, useMemo} from "react";
import {openUrl} from "utils/url-helper";
import {captureClickEvent} from "utils/hotspot-helper";

import style from "./style.module.scss";
import {HotSpotType, AdUnitEvent, JourneyTriggerInteractiveEvent, MARKER} from "utils/constants";
import {useConfig} from "utils/ConfigProvider";
import classnames from "classnames";
import {useHotspotContainerDimension} from 'hooks/useHotspotContainerDimension';
import {HotspotRenderer} from "modules/hotspot-manager/hotspotRenderer";
import {useHotspotInteractions} from "hooks/useHotspotInteractions";

const noOp = () => {
};

/**
 * Responsible for rendering hotspots, and hotspots engagements
 * @param settings
 * @param onEvent
 * @param fireJourneyTrigger
 * @param setHotspotOverlay
 * @param hotspots
 * @param onHotspotClickAway,
 * @param showing
 * @returns {*}
 * @constructor
 */
function HotspotManager(
  {
    onEvent,
    fireJourneyTrigger,
    setHotspotOverlay,
    hotspots,
    allHotspots,
    onHotspotClickAway = noOp,
    onClickThrough,
    showing,
    isCardHotspot = false,
    isCardAndInVideoAndFinale = false,
    isInVideoOverlay = false,
    isFinaleOverlay = false,
    isDisplayHotspot = false,
    useHotspotAnimation = false,
    overlayID,
    setPixelFiringInProgress = noOp, // noop,
    hotspotProps,
    isStandaloneHotspots = false,
    // Used to just render the Hotspots, and the container will be rendered within Overlay (Example in Custom Survey) bringing all the hotspots in same layer
    renderWithoutContainer = false,
    containerDimensions: propContainerDimensions,
    hotspotContainer: propHotspotContainer,
    interactProps: propInteractProps,
    isSharedCard = false
  }
) {

  const {environment} = useConfig();
  const {containerDimensions, setHotspotContainer, hotspotContainer} = useHotspotContainerDimension();

  const isCreativeStudioPreviewMode = useMemo(() => {
    return environment.isCreativeStudioPreviewMode instanceof Function ? environment.isCreativeStudioPreviewMode() : false;
  }, [environment]);

  const isInScreenShooterEmulation = useMemo(() => {
    return environment.isInScreenShooterEmulation instanceof Function ? environment.isInScreenShooterEmulation() : false;
  }, [environment]);

  const interactProps = useHotspotInteractions({
    canInteract: !!(!renderWithoutContainer && isCreativeStudioPreviewMode && (!isSharedCard || hotspotProps?.allowSharedCardEdit)),
    hotspotProps,
    selectedHotspots: hotspotProps?.selectedHotspots,
    onChangeDeltaOnly: isSharedCard,
  });

  const {
    canInteract,
    setHotspotInteractionContainer,
    openHotspot,
    isHotspotSelected,

    selectedHotspotSelector,
    hotspotPopperSelector,
    snappingHotspotSelector,
  } = useMemo(() => {
    if (renderWithoutContainer) {
      return propInteractProps || {};
    }
    return interactProps;
  }, [interactProps, propInteractProps, renderWithoutContainer])

  const setHotspotContainerRef = useCallback((ref) => {
    setHotspotContainer(ref);
    if (setHotspotInteractionContainer instanceof Function) {
      setHotspotInteractionContainer(ref);
    }
  }, [setHotspotContainer, setHotspotInteractionContainer]);

  const onHotspotClick = useCallback((hotspot, index, isSelected, shiftKey) => {
    if (isCreativeStudioPreviewMode) {
      if (canInteract && hotspotProps) {
        if (!isSelected) {
          openHotspot(hotspot, shiftKey);
        }
      }
    } else {
      const {id, inSessionTriggerDetails, hotspotType, linkToUrl, hasChildren, marker} = hotspot;
      const {startTime, endTime} = inSessionTriggerDetails?.elementTimeTriggers?.[hotspot.timeTriggerIndex] || {};
      const triggerElementId = inSessionTriggerDetails?.triggerElementId;
      const cardID = (isCardHotspot || isDisplayHotspot) ? overlayID : null;
      let currentHotspotType = '';
      if (HotSpotType[hotspotType] === HotSpotType.ENGAGEMENT_EXPERIENCE) {
        onHotspotClickAway();
        currentHotspotType = HotSpotType.ENGAGEMENT_EXPERIENCE;
        setHotspotOverlay(hotspot);
      } else {
        if (linkToUrl) {
          onHotspotClickAway();
          currentHotspotType = HotSpotType.DRIVE_TO_SITE;
          if (marker !== MARKER.EMBED) {
            openUrl(linkToUrl, '_blank');
          }
          setPixelFiringInProgress(true)
          onEvent(captureClickEvent(AdUnitEvent.HotSpotDriveToSiteClick, currentHotspotType, id, startTime, endTime, isDisplayHotspot, cardID, isSharedCard))
            .then(() => {
              setPixelFiringInProgress(false);
            }).catch(e => setPixelFiringInProgress(false));
        }
        fireJourneyTrigger(JourneyTriggerInteractiveEvent.interactiveHotspotClickThrough, triggerElementId, !linkToUrl, hasChildren);
        if (linkToUrl) {
          onClickThrough(null, null, true)
        }
      }
      onEvent(captureClickEvent(AdUnitEvent.HotSpotInteraction, currentHotspotType, id, startTime, endTime, isDisplayHotspot, cardID, isSharedCard));
      fireJourneyTrigger(JourneyTriggerInteractiveEvent.interactiveHotspotInteraction, triggerElementId, false, hasChildren);
    }
  }, [canInteract, hotspotProps, openHotspot, isCardHotspot, isDisplayHotspot, overlayID, onEvent, fireJourneyTrigger, onHotspotClickAway, setHotspotOverlay, isCreativeStudioPreviewMode, setPixelFiringInProgress, onClickThrough, isSharedCard]);

  const isShowing = useCallback((hotspot) => {
    // When rendered without container, check for propHotspotContainer instead.
    if (renderWithoutContainer) {
      if (!propHotspotContainer) {
        return false;
      }
    } else {
      if (!hotspotContainer) {
        return false;
      }
    }

    if (isCreativeStudioPreviewMode) {
      return true;
    }

    if (allHotspots) {
      return Boolean(hotspots.find(({id}) => hotspot.id === id));
    }

    return true;
  }, [allHotspots, hotspotContainer, hotspots, isCreativeStudioPreviewMode, propHotspotContainer, renderWithoutContainer]);

  const isStandaloneHotspotShowing = useCallback((index) => {
    if (!isCreativeStudioPreviewMode) {
      return true;
    }

    return hotspotProps?.standaloneSelectedHotspotIndex === index;
  }, [hotspotProps?.standaloneSelectedHotspotIndex, isCreativeStudioPreviewMode]);

  const hotspotsToBeRendered = useMemo(() => {
    return allHotspots ? allHotspots : hotspots;
  }, [allHotspots, hotspots]);

  const renderHotspots = useCallback(() => {
    return (hotspotsToBeRendered).map(
      (hotspot, index) => {
        return <HotspotRenderer
          key={hotspot.id || index}
          isCreativeStudioPreviewMode={isCreativeStudioPreviewMode}
          index={index}
          hotspot={hotspot}
          isCardAndInVideoAndFinale={isCardAndInVideoAndFinale}
          onHotspotClick={onHotspotClick}
          containerDimensions={propContainerDimensions ? propContainerDimensions : containerDimensions}
          isCardHotspot={isCardHotspot}
          useHotspotAnimation={useHotspotAnimation}
          isInVideoOverlay={isInVideoOverlay}
          isFinaleOverlay={isFinaleOverlay}
          isShowing={isShowing}
          isStandaloneHotspots={isStandaloneHotspots}
          isHotspotSelected={isHotspotSelected}
          selectedHotspotSelector={selectedHotspotSelector}
          hotspotPopperSelector={hotspotPopperSelector}
          snappingHotspotSelector={snappingHotspotSelector}
          isStandaloneHotspotShowing={isStandaloneHotspotShowing}
        />
      }
    );
  }, [propContainerDimensions, containerDimensions, hotspotPopperSelector, hotspotsToBeRendered, isCardAndInVideoAndFinale, isCardHotspot, isCreativeStudioPreviewMode, isFinaleOverlay, isHotspotSelected, isInVideoOverlay, isShowing, isStandaloneHotspotShowing, isStandaloneHotspots, onHotspotClick, selectedHotspotSelector, snappingHotspotSelector, useHotspotAnimation])

  if (!showing) {
    return <React.Fragment/>
  }

  if (hotspotsToBeRendered?.length) {
    return renderWithoutContainer ?
      renderHotspots() :
      <div
        className={classnames(style.hotspotContainer, {
          [style.fullContainer]: (isCardHotspot || isDisplayHotspot),
          [style.pointerEventsAll]: isCreativeStudioPreviewMode && !isInScreenShooterEmulation && !(hotspotProps?.standaloneSelectedHotspotIndex === -1)
        })}
        ref={setHotspotContainerRef}
      >
        {renderHotspots()}
      </div>;
  }

  return <React.Fragment/>
}

export default HotspotManager;
