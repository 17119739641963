// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".carouselItemContainer--1UTrg{position:relative;vertical-align:middle;display:flex;flex-direction:column;justify-content:flex-start;cursor:pointer;height:100%;width:100%}.playIcon--XBilD{width:20%;height:auto}.playIcon--XBilD.horizontalDisplay--2SRgW{width:30%}.spacing--16_n1{height:10%}.thumbnailContainer--bVtZp{overflow:hidden;position:relative;display:flex;flex-direction:column;justify-content:space-evenly;border-radius:calc(var(--rootFontSize) * 0.2)}.thumbnailContainer--bVtZp.partialHeight--Eoh5l{height:70%}.containerThreeByFour--myHOx{width:100%;position:relative;height:0;padding-bottom:75%}.bottom--3Rd19{height:30%;display:flex;align-items:center;justify-content:center;flex-wrap:wrap}.descriptionButton--35aWp{min-width:65%;max-width:100%;text-decoration:none;font-size:calc(var(--rootFontSize) * 0.6);text-align:center;line-height:calc(var(--rootFontSize) * 0.9);margin-top:calc(var(--rootFontSize) * 1)}.descriptionButton--35aWp.horizontalDisplay--2SRgW{width:100%;margin-top:calc(var(--rootFontSize) * 0.6)}.thumbnailOverlay--1Cqmu{height:calc(var(--rootFontSize) * 1.5);line-height:calc(var(--rootFontSize) * 1.5);font-size:calc(var(--rootFontSize) * 0.6);display:flex;justify-content:center;align-items:center}.thumbnailOverlay--1Cqmu.horizontalDisplay--2SRgW{height:30%;line-height:initial}.thumbnail--1PBka{max-height:100%;max-width:100%;position:absolute;bottom:0;left:0;height:100%;width:100%}.hidden--37aoN{visibility:hidden;pointer-events:none}.noPointer--Ixvre{cursor:default !important}", ""]);
// Exports
exports.locals = {
	"carouselItemContainer": "carouselItemContainer--1UTrg",
	"playIcon": "playIcon--XBilD",
	"horizontalDisplay": "horizontalDisplay--2SRgW",
	"spacing": "spacing--16_n1",
	"thumbnailContainer": "thumbnailContainer--bVtZp",
	"partialHeight": "partialHeight--Eoh5l",
	"containerThreeByFour": "containerThreeByFour--myHOx",
	"bottom": "bottom--3Rd19",
	"descriptionButton": "descriptionButton--35aWp",
	"thumbnailOverlay": "thumbnailOverlay--1Cqmu",
	"thumbnail": "thumbnail--1PBka",
	"hidden": "hidden--37aoN",
	"noPointer": "noPointer--Ixvre"
};
module.exports = exports;
