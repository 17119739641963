import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {AUTOPLAY_MODE, LOG_DETAILS_TYPE, PLAYER_STATE, DEFAULT_VIDEO_DURATION, CH_UA_IFRAME_ALLOW} from 'utils/constants';

import {replaceCacheBustingMacro} from "utils/macroHelper";
import {getContentOfTag, getEvents, getJSAd} from "./vastHelper";
import useEvents from "./useEvents";
import VPaidHTML from "./vast-js-video.html";
import connectAutoStartModeChange from "../connectAutoStartModeChange";

import {useEventCapture} from 'utils/EventCaptureProvider';
import {useConfig} from 'utils/ConfigProvider';
import style from './style.module.scss';
import {shouldShowThumbnail} from 'utils/thumbnail-helper';
import {PauseRequestedQueue} from './pauseRequestedQueue';


function VPAIDPlayer(
  {
    state,
    autoPlayMode,
    isMute = false,
    onPlayerTimeChange,
    onPlayerStateChange,
    onDurationFetch,
    scrubToHandler,
    setAutoplayUnMutedError,
    onEvent,
    getSpecialSettingPlatformValue,
    getThumbnail,
    track3rdPartyEvents,
    onClickThrough,
    settings,
    onMuteStateChange,
    setPlayerControlWrapper,
    reloadAd,
    setQuartile,
    setImpressionEvent,
    setVastTrackingPixels,
    creativeDimensions
  }
) {

  const [config, set] = useState(undefined);
  const {vastTagUrl, mediaType, video} = settings;
  const {durationSeconds} = video;
  const [started, setStarted] = useState(false);

  const {captureSentryError, shouldPhoenixSkipFiringStandardVastPixel, captureAssetLoadingTime} = useEventCapture();
  const {environment} = useConfig();

  const vastTagUrlActual = !vastTagUrl && mediaType && video?.content ? video.content : vastTagUrl;

  const getCreative = useCallback((setVastTrackingPixels) => {
    captureAssetLoadingTime(LOG_DETAILS_TYPE.PLAYER_INITIALIZATION, true)
    captureAssetLoadingTime(LOG_DETAILS_TYPE.VPAID_ENDPOINT_RESPONSE,false);
    const init = (xml) => {
      captureAssetLoadingTime(LOG_DETAILS_TYPE.VPAID_ENDPOINT_RESPONSE, true);
      captureAssetLoadingTime(LOG_DETAILS_TYPE.VPAID_AD_LOAD, false);
      const events = getEvents(xml);
      const jsAd = getJSAd(xml);
      if (!jsAd) {
        console.error('Supported Media File not found');
        return;
      }
      const adParams = xml?.getElementsByTagName('AdParameters')?.item(0)?.textContent;
      const src = getContentOfTag(jsAd);
      set({
        tag: src.trim(),
        events,
        // Studio preview initializes in MUTED state so that we can play/pause to load the creative and fetch duration etc
        autoPlayMuted: environment.isCreativeStudioPreviewMode() || (autoPlayMode === AUTOPLAY_MODE.AUTOPLAY_MUTED && !environment.isShareLandingPage()),
        autoPlay: autoPlayMode === AUTOPLAY_MODE.AUTOPLAY_MUTED || autoPlayMode === AUTOPLAY_MODE.AUTOPLAY_UNMUTED,
        adParams: adParams ? encodeURIComponent(adParams) : {}
      });
      setVastTrackingPixels(events);
    }

    if(!vastTagUrlActual) {
      return;
    }

    const url = replaceCacheBustingMacro(vastTagUrlActual);
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw response
        }
        return response.text()
      })
      .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
      .then(xml => init(xml))
      .catch(e => console.log('error loading vast xml.', e))
  }, [vastTagUrlActual, autoPlayMode, environment, captureAssetLoadingTime])

  useEffect(() => {
    getCreative(setVastTrackingPixels);
  }, [getCreative, setVastTrackingPixels]);

  const ref = useRef();


  useEffect(() => {
    if (!ref || !ref.current || !config || ref.current.getElementsByTagName('iframe').length > 0) {
      return;
    }

    // NOTE: will this cause a collision in studio if there are multiple phoenix instances rendering videos, but only 1 instance is used for video in studio currently
    window.videoSlot = document.getElementById('video-slot');

    const iframe = document.createElement('iframe');
    const params = {
      tag: config.tag,
      width: Math.round(parseFloat(creativeDimensions?.width)),
      height: Math.round(parseFloat(creativeDimensions?.height)),
      adParams: config.adParams,
      autoPlayMuted: config.autoPlayMuted,
      autoPlay: config.autoPlay,
    };

    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('scrolling', 'no');
    iframe.setAttribute('allow', `autoplay; ${CH_UA_IFRAME_ALLOW}`);
    iframe.setAttribute('width', '100%');
    iframe.setAttribute('height', '100%');
    iframe.style.position = 'absolute';

    // wait for iframe to load otherwise contentWindow can be undefined
    iframe.onload = function () {
      iframe.onload = null; // ie 11 seems to loop the onload call if we don't remove the reference after the first call
      iframe.contentWindow.document.open();
      const template = VPaidHTML.replace('__PARAMS__', `'${JSON.stringify(params)}'`);
      iframe.contentWindow.document.write(template);
      iframe.contentWindow.document.close();
    }

    ref.current.appendChild(iframe);
  }, [ref, config, creativeDimensions]);

  const getCreativeElement = useCallback(_ => {
    if (!ref || !ref.current) {
      return;
    }

    const frames = ref.current.getElementsByTagName('iframe');
    if (!frames || frames.length < 1) {
      return null;
    }
    return frames[0]?.contentWindow?._VGObject?._creative;
  }, [ref]);

  const getVideoSlot = useCallback(_ => {
    if (!ref || !ref.current) {
      return;
    }

    const frames = ref.current.getElementsByTagName('iframe');
    if (!frames || frames.length < 1) {
      return null;
    }

    return frames[0]?.contentWindow?._VGObject?._video;
  }, [ref]);

  const pauseRequestedQueue = useMemo(()=> new PauseRequestedQueue(getCreativeElement), [getCreativeElement]);

  const api = useMemo(() => ({
    play: () => {
      console.log(`Playing vpaid ad. already started: ${started}`);
      const creativeElement = getCreativeElement();
      if(started) {
        if (creativeElement) {
          creativeElement.resumeAd();
        }
      } else {
        if (creativeElement) {
          creativeElement.startAd();
        }
      }
    },
    pause: () => {
      if (environment.isCreativeStudioPreviewMode() && pauseRequestedQueue) {
        pauseRequestedQueue.pauseAd();
      } else if (getCreativeElement()) {
        getCreativeElement().pauseAd();
      }
    },
    mute: (muteIt) => {
      if (getCreativeElement()) {
        getCreativeElement().setAdVolume(muteIt ? 0 : 1);
      }
      if (getVideoSlot()) {
        getVideoSlot().muted = muteIt ? 1 : 0;
      }
    },
  }),[environment, getCreativeElement, getVideoSlot, pauseRequestedQueue, started]);


  useEffect(() => {
    setPlayerControlWrapper({
      play: () => {
        if(environment.isCreativeStudioPreviewMode() && state === PLAYER_STATE.COMPLETED) {
          reloadAd();
        } else {
          api.play();
        }
      },
      pause: () => {
        api.pause();
      },
      mute: (mute) => {
        api.mute(mute);
      },
    })
  }, [setPlayerControlWrapper, onPlayerStateChange, onMuteStateChange, state, api, reloadAd, environment])

  connectAutoStartModeChange(autoPlayMode, state, setAutoplayUnMutedError, api);

  const enableVgOverlay = getSpecialSettingPlatformValue('enableVgOverlay');
  const dismissVgOverlay = getSpecialSettingPlatformValue('dismissVgOverlay');

  useEvents({
    onPlayerStateChange: (state, previewReady, isImpression) => {
      // Load the creative in studio so we can see initial screen and get duration
      if (environment.isCreativeStudioPreviewMode() && state === PLAYER_STATE.READY && previewReady === false) {
        api.play();
      }
      if(state === PLAYER_STATE.PLAYING && !isImpression) {
        setStarted(true)
      }
      onPlayerStateChange(state);
    },
    previewInitialPlayingHandler: () => {
      if (environment.isCreativeStudioPreviewMode()) {
        api.pause();
        onPlayerTimeChange(0);
        api.mute(environment.isInScreenShooterEmulation());
        setStarted(true)
      }
    },
    onMuteStateChange,
    onDurationFetch,
    onPlayerTimeChange,
    isMute,
    onEvent,
    vastEvents: config?.events,
    playerState: state,
    getCreativeElement,
    track3rdPartyEvents,
    onClickThrough,
    modelDurationSeconds: durationSeconds || DEFAULT_VIDEO_DURATION,
    autoPlayMode,
    setQuartile,
    setImpressionEvent,
    captureSentryError,
    shouldPhoenixSkipFiringStandardVastPixel,
    isVPAIDEnabled: environment.isVPAIDEnabled(),
    isEditMode: environment.isEditMode(),
    isCreativeStudioPreviewMode: environment.isCreativeStudioPreviewMode(),
    captureAssetLoadingTime,
    notifyOnAdVideoStart: pauseRequestedQueue?.onAdStarted
  });

  useEffect(() => {
    scrubToHandler(() => {
      return (time) => {
        const videoSlot = getVideoSlot();
        if (videoSlot) {
          videoSlot.currentTime = time || 0;
        }
        onPlayerTimeChange(time);
      }
    })
  }, [scrubToHandler, onPlayerTimeChange, getVideoSlot])

  // resizing
  useEffect(() => {
    const resize = () => {
      const creative = getCreativeElement();

      if (!creative) {
        return false;
      }
      const width = parseFloat(creativeDimensions?.width);
      const height = parseFloat(creativeDimensions?.height);

      if(!width || !height) {
        return false;
      }

      try {
        typeof creative.resizeAd === "function" && creative.resizeAd(width, height);
        return true;
      } catch (err) {
        return false;
      }
    }

    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    }
  }, [getCreativeElement, creativeDimensions]);
  const thumbnailShowing = shouldShowThumbnail(state);
  const hideOverlay = !thumbnailShowing && dismissVgOverlay;
  return <React.Fragment>
    {(enableVgOverlay && getThumbnail && !hideOverlay) ? getThumbnail() : <React.Fragment/>}
    <div ref={ref}>
      <video id="video-slot" playsInline muted preload="auto" className={style.videoSlot}></video>
    </div>
  </React.Fragment>
}


export default VPAIDPlayer;
