import RGBHelper from "utils/rgbHelper";

export const getGradient = (settings) => {
  const {
    callToAction: {visible: isCTAEnabled, isCTATopAligned} = {}
  } = settings;
  const playerBackgroundColor = '#000000';
  const playerBackgroundOpacity = 0.5;
  const playerBackgroundOpacityRgbaTop = isCTAEnabled && isCTATopAligned ? RGBHelper.convertHexToRGBA(playerBackgroundColor, playerBackgroundOpacity) : 'transparent';
  const playerBackgroundOpacityRgbaBottom = RGBHelper.convertHexToRGBA(playerBackgroundColor, playerBackgroundOpacity);
  return `linear-gradient(${playerBackgroundOpacityRgbaTop}, transparent 20%, transparent, transparent 80%, ${playerBackgroundOpacityRgbaBottom})`;
};
