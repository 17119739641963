import React, {useState} from "react";
import {Portal} from "react-portal";
import classnames from "classnames";

import useSocialClickHandler from "./socialClickHandler";

import Popover from "elements/popover";
import EmailIcons from "compounds/email-icons";

import style from "./style.module.scss";
import {SHARE_ICON_POSITION, ENGAGEMENT_DISPLAY_MODE} from "utils/constants";
import {useFullScreen} from "utils/FullScreenProvider";
import {useConfig} from "utils/ConfigProvider";

import {getDeviceType, isMobileDevice} from "utils/environmentHelper";

const COPY_LINK_WAIT_FADE = 3000;

export const SOCIAL_ICONS = ['facebook', 'twitter', 'email', 'linkedin', 'pinterest', 'copyandpasteshareurl', 'sms'];

function SocialIcon({variant, boxStyle = 'circle', iconStyle = 'default', toggleEmail, emailOpened, children, onClick, onEmailToggle, shareUrlConfig = {}, shareIconPosition, inVideoEngagementPosition, mode = ENGAGEMENT_DISPLAY_MODE.FULL_MODE, creativeDimensions, ...rest}) {
  const [emailOpen, setEmailOpen] = useState(false);
  const {localConfig, environment, appRootId} = useConfig();
  const [handleSocialClick, emailText] = useSocialClickHandler(shareUrlConfig, localConfig.fbApiKey, isMobileDevice(), environment.isDraft(), getDeviceType(), appRootId);

  const [copyPasteLocation, setCopyPasteLocation] = useState(undefined);
  const [showingCopyPasteNotification, toggleCopyPasteNotification] = useState(false);

  const {isFullScreen, isFullScreenEnabled } = useFullScreen();

  const _onEmailOpen = (e) => {
    onEmailToggle && onEmailToggle(true);
    setEmailOpen(true);
  }

  const _onClick = (e, variant) => {
    if (environment.isCreativeStudioPreviewMode()) {
      return;
    }

    if(variant === 'email' && !isMobileDevice()) {
      return _onEmailOpen(e);
    }
    if (environment.isDraft()) {
      return e.preventDefault();
    }
    handleSocialClick(e, variant);
    onClick && onClick(e, variant);

    if(variant === 'copyandpasteshareurl') {
      toggleCopyPasteNotification(true);
      const {top, left, height, width} = e.target.getBoundingClientRect();
      const parent = document.getElementById(appRootId).getBoundingClientRect();
      setCopyPasteNotificationPosition(top - parent.top, left - parent.left, height, width);
      setTimeout(() => toggleCopyPasteNotification(false), COPY_LINK_WAIT_FADE);
    }
  }

  const _onEmailClose = (e, emailVariant) => {
    onEmailToggle && onEmailToggle(false);
    setEmailOpen(false)
    if(emailVariant) {
      onClick && onClick(e, emailVariant);
    }
  }
  const setCopyPasteNotificationPosition = (top, left, height, width) => {
    const availableHeight = parseFloat(creativeDimensions?.height);
    const availableWidth = parseFloat(creativeDimensions?.width);

    const w = availableHeight/21*10.5;

    if(left < w/2) {
      left = w/2 + 5;
    } else if(left + w/2 > availableWidth) {
      left = availableWidth - 5 - w/2;
    }


    if (shareIconPosition) {
      switch (shareIconPosition) {
        case SHARE_ICON_POSITION.TOP_LEFT:
          setCopyPasteLocation({top, left, marginTop: height + 5});
          break;
        case SHARE_ICON_POSITION.BOTTOM_LEFT:
          setCopyPasteLocation({top: `calc(${top}px - 2.7em`, left});
          break;
        case SHARE_ICON_POSITION.TOP_RIGHT:
          setCopyPasteLocation({top, left, marginTop: height + 5});
          break;
        case SHARE_ICON_POSITION.BOTTOM_RIGHT:
          setCopyPasteLocation({top: `calc(${top}px - 2.7em`, left});
          break;
        default:
          setCopyPasteLocation({top, left, marginTop: height + 5});
      }
    } else if (inVideoEngagementPosition && mode !== ENGAGEMENT_DISPLAY_MODE.FULL_MODE) {
      switch (inVideoEngagementPosition) {
        case 'Right':
          setCopyPasteLocation({top, left, marginTop: height + 5});
          break;
        case 'Left':
          setCopyPasteLocation({top, left, marginTop: height + 5});
          break;
        default:
          setCopyPasteLocation({top, left, marginTop: height + 5});
      }
    } else {
      setCopyPasteLocation({top, left, marginTop: height + 5});
    }
  }
  const fontAwesomeStyleClass = variant === 'twitter' ? `x-${variant}` : variant
  const icon = <div data-testid={variant} className={classnames(style.icon, style[fontAwesomeStyleClass], style[`boxStyle-${boxStyle}`], style[`iconStyle-${iconStyle}`], {[style.noPointer]: environment.isCreativeStudioPreviewMode()})}  {...rest} onClick={(e) => _onClick(e, variant)}>{children}</div>;
  return (
    <>
      {variant !== 'email' && icon}

      {variant === 'email' && <Popover content={<EmailIcons shareText={emailText} shareUrl={shareUrlConfig.shortUrl} onClick={_onEmailClose}/>} onClose={_onEmailClose} open={onEmailToggle ? emailOpened : emailOpen} creativeDimensions={creativeDimensions}>{icon}</Popover>}

      {variant === 'copyandpasteshareurl' &&
        <Portal node={document.getElementById(appRootId)}>
        <div style={{...copyPasteLocation}}
           className={classnames(style.copyPasteNotification, showingCopyPasteNotification ? style.showCopyPasteNotification : null)}
        />
        </Portal>
      }
    </>
  )
}

export default SocialIcon;
