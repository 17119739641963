import React from "react";
import style from './style.module.scss';
import classnames from "classnames";


export const TopBar = function ({isCloseButtonShowing, children}) {
  return (
    <div className={classnames({
      [style.topBar]: true,
      [style.closeShowing]: isCloseButtonShowing})
    }>
      {children}
    </div>
  )
}
export const BottomBar = function ({children}) {
  return (
    <div  className={style.bottomBar}>
      {children}
    </div>
  )
}

export const GroupRow = function ({groupRowStyle, children}) {
  return (
    <div className={style.groupRow} style={groupRowStyle}>
      {children}
    </div>
  )
}

export const PositionLeft = function ({children}) {
  return (
    <div className={style.left}>
      {children}
    </div>
  )
}

export const PositionRight = function ({children}) {
  return (
    <div className={style.right}>
      {children}
    </div>
  )
}
