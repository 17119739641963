// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".adStoppedIcon--2vuZo{position:absolute;top:calc(var(--rootFontSize) * 0.25);right:calc(var(--rootFontSize) * 0.25);display:flex;z-index:2}.adStoppedIcon--2vuZo img{width:calc(var(--rootFontSize) * 0.9);height:calc(var(--rootFontSize) * 0.9)}", ""]);
// Exports
exports.locals = {
	"adStoppedIcon": "adStoppedIcon--2vuZo"
};
module.exports = exports;
