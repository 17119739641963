// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../images/left.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../images/right.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".flexFull--2hnVJ{display:flex;width:100%;height:100%}.carouselContainer--tZ4Gj{display:flex;width:100%;justify-content:space-around;align-content:flex-start;align-items:flex-start;text-align:center;height:auto}.carouselSlideContainer--2sjbl{width:92%;margin-left:auto;margin-right:auto;height:auto}.carouselItemWrapper--2gqZY{display:flex;flex:1;justify-content:center;padding:0 2%;min-width:0}.carouselItemWrapper--2gqZY.horizontalDisplay--2GWxN{padding:2px 4.5%}.carouselItemWrapper--2gqZY.verticalDisplay--2FifQ{flex:.7 !important}.carouselControlButton--3NhXC{position:absolute;z-index:2;top:50%;height:100%;transform:translateY(-50%);cursor:pointer;border:none;background:none;display:flex;outline:none;width:calc(var(--rootFontSize) * 1);padding:0}.carouselControlButton--3NhXC.horizontalDisplay--2GWxN{width:3.5%}.carouselControlButton--3NhXC.verticalDisplay--2FifQ{width:7%}.hybrid--1dGSk{top:0;height:70%;transform:unset}.carouselArrow--dUDOJ{width:100%;height:100%;background-repeat:no-repeat;background-size:contain;background-position:0% 50%;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}.carouselControlLeft--1ANYt{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.carouselControlRight--1CnvB{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.carousel-root{display:flex;width:100%;justify-content:center;align-items:center}.slider-wrapper{height:100%;display:flex}.carousel.carousel-slider{display:flex;width:100%}.carousel .slide{background-color:initial !important;display:flex}", ""]);
// Exports
exports.locals = {
	"flexFull": "flexFull--2hnVJ",
	"carouselContainer": "carouselContainer--tZ4Gj",
	"carouselSlideContainer": "carouselSlideContainer--2sjbl carouselContainer--tZ4Gj",
	"carouselItemWrapper": "carouselItemWrapper--2gqZY",
	"horizontalDisplay": "horizontalDisplay--2GWxN",
	"verticalDisplay": "verticalDisplay--2FifQ",
	"carouselControlButton": "carouselControlButton--3NhXC",
	"hybrid": "hybrid--1dGSk",
	"carouselArrow": "carouselArrow--dUDOJ",
	"carouselControlLeft": "carouselControlLeft--1ANYt carouselArrow--dUDOJ",
	"carouselControlRight": "carouselControlRight--1CnvB carouselArrow--dUDOJ",
	"carousel-root": "carousel-root",
	"slider-wrapper": "slider-wrapper",
	"carousel": "carousel",
	"carousel-slider": "carousel-slider",
	"slide": "slide"
};
module.exports = exports;
