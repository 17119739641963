// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".animation--2jiZn{animation-delay:2s;animation-duration:3s;animation-fill-mode:none;transition:opacity 1.5s,height .5s}.brandLogoContainer--WeP-i{display:flex;height:calc(var(--rootFontSize) * 2)}.brandLogo--3uU_E{position:relative;height:100%;padding:calc(var(--rootFontSize) * 0.2);z-index:6}.flexEnd--32rf7{display:flex;justify-content:flex-end}.hideBottomPadding--2azVE{padding-bottom:0;padding-top:calc(var(--rootFontSize) * 0.4)}.cursorPointer--13hZz{cursor:pointer}.image-container-bottom--3GQrI{text-align:right}.logo--3Wn4o{height:100%;width:100%;z-index:2147483647;background-size:contain;background-repeat:no-repeat;display:inline-block;background-position:right;transition:transform .25s}.show--2X2d8{opacity:1;transition:.6s ease-in-out}.hideFromTop--3f-vG{transform:translateY(-100%)}.hideFromBottom--2JlBr{transform:translateY(100%)}.hide--17LCO{opacity:0;transition:.6s ease-in-out}", ""]);
// Exports
exports.locals = {
	"animation": "animation--2jiZn",
	"brandLogoContainer": "brandLogoContainer--WeP-i animation--2jiZn",
	"brandLogo": "brandLogo--3uU_E animation--2jiZn",
	"flexEnd": "flexEnd--32rf7",
	"hideBottomPadding": "hideBottomPadding--2azVE",
	"cursorPointer": "cursorPointer--13hZz",
	"image-container-bottom": "image-container-bottom--3GQrI",
	"logo": "logo--3Wn4o",
	"show": "show--2X2d8",
	"hideFromTop": "hideFromTop--3f-vG",
	"hideFromBottom": "hideFromBottom--2JlBr",
	"hide": "hide--17LCO"
};
module.exports = exports;
