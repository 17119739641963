import React, {useEffect, useMemo, useState} from "react";
import loadGoogleMapsAPI from "load-google-maps-api";
import {getImageSize} from "utils/store-locator-helper";
import {Map} from "elements/map";

import {useConfig} from "utils/ConfigProvider";

export function MapManager(
  {
    mapLocatorImage,
    markerColor,
    markerFontColor,
    isVGDefaultMapPin,
    markers,
    userCurrentLocation,
    onMarkerClick
  }
) {

  const [scaledSize, setScaledSize] = useState(null);
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const {localConfig} = useConfig();

  useEffect(() => {
    loadGoogleMapsAPI({
      v: 3,
      key: localConfig.googleMapsApiKey
    }).then(() =>{
      console.log('Map Initialized')
      setGoogleMapsLoaded(true);
    }).catch((e) => console.log('Error loading Map', e));
  }, [localConfig]);

  useEffect(() => {
    if (googleMapsLoaded) {
      getImageSize(mapLocatorImage?.url).then(setScaledSize);
    }
  }, [mapLocatorImage, googleMapsLoaded]);

  const iconImage = useMemo(() => scaledSize ? {
    url: mapLocatorImage?.url,
    scaledSize
  } : null, [mapLocatorImage, scaledSize]);

  const useDefaultIcon = useMemo(() => isVGDefaultMapPin || !iconImage, [iconImage, isVGDefaultMapPin]);

  return googleMapsLoaded ? <Map
    centerLat={userCurrentLocation?.lat}
    centerLng={userCurrentLocation?.lng}
    useDefaultIcon={useDefaultIcon}
    iconImage={iconImage}
    markers={markers}
    markerColor={markerColor}
    markerFontColor={markerFontColor}
    onMarkerClick={onMarkerClick}
  /> : null;
}
