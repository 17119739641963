import {useCallback, useState} from 'react';
import {PLAYER_STATE} from "utils/constants";

export function useSurveys(isODCEngagementPreviewEnabled, hotspotOverlay, playerState, setHotspotOverlay, playerControlWrapper) {
  const [surveySubmitted, setSurveySubmitted] = useState({});

  const isSurveySubmitted = useCallback((surveyId) => {
    return Boolean(surveySubmitted[surveyId]);
  }, [surveySubmitted]);

  const setPreviousSessionSubmittedSurveys = (surveyList) => {
     const submittedSurveys = {}
     surveyList.forEach((surveyId) => {
       submittedSurveys[surveyId] = true;
     })
    setSurveySubmitted(submittedSurveys);
  }

  const submitSurvey = useCallback((surveyId) => {
    if (hotspotOverlay) {
      setHotspotOverlay(null);
      if (playerState !== PLAYER_STATE.COMPLETED) {
        playerControlWrapper.play();
      }
    }
    if(isODCEngagementPreviewEnabled) {
      return;
    }
    const surveySubmittedState = {
      ...surveySubmitted,
      [surveyId]: {
        submitted: true,
      }
    };
    setSurveySubmitted(surveySubmittedState);
  }, [hotspotOverlay, surveySubmitted, setHotspotOverlay, playerState, playerControlWrapper, isODCEngagementPreviewEnabled]);

  // Hotspot Click Through for Survey Cards are treated as Survey Submitted Event - Refer VGO-3628
  const submitSurveyByHotspotClickThrough = useCallback((surveyId)=> {
    const surveySubmittedState = {
      ...surveySubmitted,
      [surveyId]: {
        submitted: true,
      }
    };
    setSurveySubmitted(surveySubmittedState);
  }, [surveySubmitted]);

  const fetchAllSurveySubmitted = useCallback(() => {
    return Object.keys(surveySubmitted)
  }, [surveySubmitted])

  return {
    isSurveySubmitted,
    submitSurvey,
    submitSurveyByHotspotClickThrough,
    fetchAllSurveySubmitted,
    setPreviousSessionSubmittedSurveys
  };
}
