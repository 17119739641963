import {useCallback, useRef} from 'react';

import surveyHelpers from 'utils/survey-helper';
import {ENGAGEMENT_TYPES, AdUnitEvent, JourneyTriggerInteractiveEvent, MC_POLLING_TIMEOUT} from 'utils/constants';

function useMultipleChoiceSurveyEvents(onEvent, isInVideo, fireJourneyTrigger, submitSurvey, trackSurveyClickThrough, onClickThrough, triggerElementId, isVPAIDEnabled, setShowPollingScreen, pollingEnabled, setsubmissionDetailsTime, isDisplayCreative, surveyId, submissionDetails) {
  const surveyIdsSubmitted = useRef([]);

  const setPollingScreenWithDelay = useCallback((surveyId) => {
    setTimeout(()=>{
      submitSurvey(surveyId)
      setShowPollingScreen(false);
    },MC_POLLING_TIMEOUT);
  },[submitSurvey, setShowPollingScreen])

  const handleSubmitSurvey = useCallback((surveyID) => {
    if (pollingEnabled) {
      setShowPollingScreen(true);
      if(isDisplayCreative) {
        setPollingScreenWithDelay(surveyID);
      }
    } else {
      submitSurvey(surveyID)
    } 
  },[setShowPollingScreen, isDisplayCreative, submitSurvey, pollingEnabled, setPollingScreenWithDelay]);

  if (pollingEnabled && submissionDetails && submissionDetails[surveyId]) {
    setPollingScreenWithDelay(surveyId);
  }

  const onClick = useCallback((surveyID, questionId, position, optionIndex, selectedQuestionOptionID, surveyRule) => {
    const engagementTemplate = ENGAGEMENT_TYPES.MultipleChoiceSurvey;
    const payload = surveyHelpers.getSurveyResponsePayload(surveyID, position, questionId, selectedQuestionOptionID, engagementTemplate);
    payload.action = AdUnitEvent.SurveyAttemptEvent;
    payload.shouldSendSurveySubmissionEvent = 'false';
    onEvent(payload);

    const jtPosition = optionIndex === null || optionIndex === undefined ? position : optionIndex;
    fireJourneyTrigger(JourneyTriggerInteractiveEvent[`interactiveMultipleChoiceSurveyOption${jtPosition}Response`], triggerElementId, false, pollingEnabled);
  }, [onEvent, fireJourneyTrigger, triggerElementId, pollingEnabled]);

  const onSubmit = useCallback((surveyID, questionId, position, optionIndex, selectedQuestionOptionID, surveyRule) => {
    // OV-790 - use local state to prevent refiring of onSubmit for a given surveyId, this is to account for the delayed firing of submitSurvey/fireJourneyTrigger in the vpaid/finale case below
    // OV-1669 - updated to useRef instead of useState, useState has closure issues (missing rerender) when wrapped in setTimeout, which we are doing for animation in mobile.
    if (surveyIdsSubmitted.current.includes(surveyID)) {
      console.log("Duplicate survey submission rejected")
      return
    }
    surveyIdsSubmitted.current = [...surveyIdsSubmitted.current, surveyID];
    const engagementTemplate = ENGAGEMENT_TYPES.MultipleChoiceSurvey;
    surveyHelpers.executeSurveyRules({surveyRule, surveyID, engagementTemplate, onEvent, isInVideo, fireJourneyTrigger, trackSurveyClickThrough, onClickThrough, triggerElementId});
    const surveyResponsePromise = onEvent(surveyHelpers.getSurveyResponsePayload(surveyID, position, questionId, selectedQuestionOptionID, engagementTemplate))
    const jtPosition = optionIndex === null || optionIndex === undefined ? position : optionIndex;
    if(pollingEnabled && setsubmissionDetailsTime) {
    setsubmissionDetailsTime(pollingEnabled, surveyID , position, optionIndex, selectedQuestionOptionID);
    }
    if (isVPAIDEnabled && !isInVideo) { // When vpaid is enabled then wait for all the submission and response pixel to be fired before sending ad stopped event.
      surveyResponsePromise.then(() => {
        // Note: helper event for in-session journey, is converted to interactiveMultipleChoiceSurveySubmission before sending to event ingestion/druid
        fireJourneyTrigger(JourneyTriggerInteractiveEvent[`interactiveMultipleChoiceSurveyOption${jtPosition}Submission`], triggerElementId, false, pollingEnabled);
        // OV:700 Wait for 5 secs to show screenPollScreen
        handleSubmitSurvey(surveyID);
      })
    } else { // This is the case of display creative where we dont need to wait for showing thankyou screen
      // Note: helper event for in-session journey, is converted to interactiveMultipleChoiceSurveySubmission before sending to event ingestion/druid
      fireJourneyTrigger(JourneyTriggerInteractiveEvent[`interactiveMultipleChoiceSurveyOption${jtPosition}Submission`], triggerElementId, false, pollingEnabled);
        // OV:700 Wait for 5 secs to show screenPollScreen
        handleSubmitSurvey(surveyID);
    }
  }, [onEvent, isInVideo, fireJourneyTrigger, trackSurveyClickThrough, onClickThrough, triggerElementId, isVPAIDEnabled, pollingEnabled, setsubmissionDetailsTime, handleSubmitSurvey]);
  return {onClick, onSubmit};
}

export default useMultipleChoiceSurveyEvents;
