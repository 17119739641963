export default class RGBHelper {
  static hexToR(h) {
    return parseInt((RGBHelper.cutHex(h)).substring(0, 2), 16);
  }

  static hexToG(h) {
    return parseInt((RGBHelper.cutHex(h)).substring(2, 4), 16);
  }

  static hexToB(h) {
    return parseInt((RGBHelper.cutHex(h)).substring(4, 6), 16);
  }

  static cutHex(h) {
    return (h.charAt(0) === '#') ? h.substring(1, 7) : h;
  }

  /**
   * @description it takes color in hex code like #ccc343 and opacity and converts into rgba format
   * @param {String} hexColor code with # in the beginning
   * @param {number} opacity between 0 to 1
   * @returns {String} rgba color code
   */
  static convertHexToRGBA(hexColor, opacity = 1) {
    if (typeof hexColor !== "string" || !RGBHelper.isHex(hexColor)) {
      return hexColor;
    }

    if (isNaN(parseFloat(opacity))) {
      opacity = 1;
    }

    return `rgba(${RGBHelper.hexToR(hexColor)}, ${RGBHelper.hexToG(hexColor)}, ${RGBHelper.hexToB(hexColor)}, ${opacity})`;
  }

  static isHex(color) {
    return /^#[0-9a-f]{3}(?:[0-9a-f]{3})?$/i.test(color);
  }

  static colorSchemes(index, defaultType) {
    const defaultRgba =
      [ 'rgba(238,98,47,0.80)',
        'rgba(245,165,35,0.80)',
        'rgba(247,231,27,0.80)',
        'rgba(7,222,190,0.80)',
        'rgba(32,150,246,0.80)'
      ];
    const defaultHex =
      ['#F18158',
        '#F7B74F',
        '#F8EB48',
        '#38E4CB',
        '#4CABF7'
      ];

    const numToAplha =
      ['#F13933',
        '#FE8A09',
        '#FFE110',
        '#08D2B5',
        '#2096F6'
      ];

    switch (defaultType) {
      case 'defaultRgba':
        return defaultRgba[index];
      case 'defaultHex':
        return defaultHex[index];
      case 'numToAplha':
        return numToAplha[index];
      default:
        return numToAplha[index];
    }
  }

}
