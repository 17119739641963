import {useCallback, useEffect, useMemo} from 'react';

class PlayerTimeStore {
  time = 0;

  setTime(time = 0) {
    this.time = time;
  }

  getTime() {
    return isNaN(this.time) ? 0 : this.time;
  }

  getPayload(obj) {
    return {
      ...obj,
      videotime: this.getTime()
    }
  }
}

export function usePlayerTimeEvent(playerTime, capture, setExplorerParentCreativeDetails) {
  const playerTimeSeconds = Math.floor(playerTime);

  // Separating it from React State as it was causing rerender for all the underlying components on every tick.
  const playerTimeStore = useMemo(() => new PlayerTimeStore(), []);

  useEffect(() => {
    playerTimeStore.setTime(playerTimeSeconds);
  }, [playerTimeSeconds, playerTimeStore])

  const onEvent = useCallback((obj) => {
    return capture(playerTimeStore.getPayload(obj));
  }, [playerTimeStore, capture]);

  const setRuntimeProperties = useCallback((obj) => {
    // TODO: refactor to be state driven
    setExplorerParentCreativeDetails(playerTimeStore.getPayload(obj))
  }, [playerTimeStore, setExplorerParentCreativeDetails]);

  return {
    onEvent,
    setRuntimeProperties,
  }
}
