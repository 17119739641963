import React, {useCallback, useEffect, useMemo} from 'react';

import DisplayEngagement from "compounds/display-engagement";
import DisplayRawImage from "compounds/display-raw-image";
import DisplayTagUrl from "compounds/display-tag-url";

import HotspotManager from "modules/hotspot-manager";
import {DISPLAY_MEDIA_TYPES} from "utils/constants";
import {isFullDisplayContainer, captureEvent, renderHotspotOutsideCard} from "utils/engagement-helper";

import style from './style.module.scss';
import {CREATIVE_CARD_TYPE} from "../../utils/constants";

function DisplayCreativeRenderer(
  {
    displayEngagement,
    settings,
    trackImpression,
    trackClickThrough,
    fireJourneyTrigger,
    submitSurvey,
    trackSurveyClickThrough,
    fireCallback,
    isSurveySubmitted,
    overlayID,
    creativeDimensions,
    isContentFlowCreative,
    capture,
    setCurrentSlideId,
    hotspotProps
  }
) {

  useEffect(() => {
    trackImpression()
    // TODO: maybe remove the dependency in case there are bugs that cause it to change and we want to guarantee this only fires once
  }, [trackImpression]);

  const cardHotspotFireJourneyTriggerWrapper = useCallback((event, elementTriggerId, suppressJourneyTrigger=false) => {
    fireJourneyTrigger(`${event}_${elementTriggerId}`, displayEngagement?.inSessionTriggerDetails?.triggerElementId, suppressJourneyTrigger)

  }, [fireJourneyTrigger, displayEngagement]);

  let DisplayComponent;
  const creativeCard = displayEngagement?.creativeCard
  const isSharedCard = displayEngagement?.isSharedCard
  const creativeCardType = creativeCard?.creativeCardType
    // TODO: does this apply for pathmaker?
  const isFull = useMemo(() => isFullDisplayContainer(settings?.mediaType) || isFullDisplayContainer(creativeCardType), [creativeCardType, settings]);
  const onEvent = useCallback((evData, displayEngagement) => {
    return capture(captureEvent(evData, displayEngagement, null, false, true));
  }, [capture]);

  const onEventWrapper = useCallback((evData) => {
    return onEvent(evData, displayEngagement)
  }, [displayEngagement, onEvent]);

  const renderHotspots = useCallback(({renderWithoutContainer = false, hotspotContainer, containerDimensions, interactProps} = {}) => {
    return creativeCard?.hotspots?.length ?
      <HotspotManager
        hotspots={creativeCard.hotspots}
        onEvent={onEventWrapper}
        fireJourneyTrigger={cardHotspotFireJourneyTriggerWrapper}
        showing={true}
        onClickThrough={trackClickThrough}
        isDisplayHotspot={true}
        isCardHotspot={true}
        overlayID={overlayID}
        hotspotProps={hotspotProps}
        renderWithoutContainer={renderWithoutContainer}
        hotspotContainer={hotspotContainer}
        containerDimensions={containerDimensions}
        interactProps={interactProps}
        isSharedCard={isSharedCard}
      /> :
      <React.Fragment/>
  }, [isSharedCard, cardHotspotFireJourneyTriggerWrapper, creativeCard, hotspotProps, onEventWrapper, overlayID, trackClickThrough]);

  if (!settings || !settings.mediaType || !displayEngagement) {
    return null;
  }

  if (creativeCardType === CREATIVE_CARD_TYPE.TAG_URL) {
    DisplayComponent = (
      <DisplayTagUrl
        settings={displayEngagement}
      />
    );
  } else if (creativeCardType === CREATIVE_CARD_TYPE.RAW_IMAGE) {
    DisplayComponent = (
      <DisplayRawImage
        settings={settings}
        displayEngagement={displayEngagement}
        fireJourneyTrigger={fireJourneyTrigger}
        trackClickThrough={trackClickThrough}
        onEvent={onEventWrapper}
      />
    );
  } else {
    DisplayComponent = (
      <DisplayEngagement
        settings={settings}
        displayEngagement={displayEngagement}
        fireJourneyTrigger={fireJourneyTrigger}
        submitSurvey={submitSurvey}
        onEvent={onEventWrapper}
        trackSurveyClickThrough={trackSurveyClickThrough}
        fireCallback={fireCallback}
        isSurveySubmitted={isSurveySubmitted}
        cardHotspotFireJourneyTriggerWrapper={cardHotspotFireJourneyTriggerWrapper}
        onHotspotClickThrough={trackClickThrough}
        renderHotspots={renderHotspots}
        creativeDimensions={creativeDimensions}
        isContentFlowCreative={isContentFlowCreative}
        setCurrentSlideId={setCurrentSlideId}
        hotspotProps={hotspotProps}
      />
    );
  }

  return <div className={style.displayContainerWithHotspot}>
    {
      isFull ?
        <div className={style.fullContainer}>{DisplayComponent}</div> :
        DisplayComponent
    }
    {
      renderHotspotOutsideCard(creativeCardType, true, isSurveySubmitted, creativeCard) ?
        renderHotspots() :
        <React.Fragment/>
    }
  </div>

}

export default DisplayCreativeRenderer;
