export {debounce} from 'lodash-es';

export const isNumber = (value) => {
  return typeof value === 'number';
}

export const isString = (value) => {
  return typeof value === 'string';
}

export const isObject = (value) => {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function');
}

export const isArray = (value) => {
  return Array.isArray(value);
}

// Trimmed down implementation by removing checks for Map/Sets (which were there in lodash)
export const isEmpty = (value) => {
  if (!value) {
    return true;
  }
  if (isArray(value) || isString(value)) {
    return !value.length;
  }
  for (const key in value) {
    if (hasOwnProperty.call(value, key)) {
      return false;
    }
  }
  return true;
}

export const getResponseHeader = (header, key) => {
  try {
    return header.get(key);
  } catch (ex) {
    console.error('Error in fetching response header.', ex)
  }
  return null;
}

export const roundToDecimalPlace = (number, decimalPlace = 0) => {
  const power = Math.pow(10, decimalPlace);
  return Math.round((number + Number.EPSILON) * power) / power;
}
