import React, {useEffect, useState} from "react";
import classnames from "classnames";

import {
  getShareIconsInfoFromSettings,
  getSharePostImgUrl,
  getVideoTitle,
} from "utils/engagement-helper";
import {ShareIcon} from "elements/icons";
import SocialIconsGroup from "compounds/social-icons-group";

import style from './style.module.scss';

import {
  SHARE_ICON_POSITION,
  STYLE_MATCH_DISPLAY_NONE_WITH_DELAY,
  JourneyTriggerInteractiveEvent
} from "utils/constants";
import {useEventCapture} from "utils/EventCaptureProvider";
import {isMobileDevice} from 'utils/environmentHelper';

function SocialIcons({settings, showing = true, fireJourneyTrigger, creativeDimensions}) {
  const {shareIconPosition = ''} = ((settings || {}).socialShare || {});
  const sharePostImgUrl = getSharePostImgUrl(settings);
  const videoTitle = getVideoTitle(settings);
  const isMobile = isMobileDevice();

  // TOP_RIGHT, TOP_LEFT, BOTTOM_LEFT, BOTTOM_RIGHT
  const {
    boxStyle,
    iconStyle,
    icons,
    shareUrlConfig,
    triggerElementId
  } = getShareIconsInfoFromSettings(settings.socialShare, isMobile);

  const [isMouseOver, setMouseOver] = useState(false);

  const [emailOpened, setEmailOpen] = useState(false);

  const {capture} = useEventCapture();

  const handleClick = (variant) => {
    const payload = {
      type: 'ShareEvent',
      network: variant,
      action: 'Post',
      srcWidget: 'VideoScreen',
    };

    capture(payload);
    fireJourneyTrigger(JourneyTriggerInteractiveEvent.interactiveShare, triggerElementId);
  }

  useEffect(() => {
    if (!showing) {
      setEmailOpen(false);
    }
  }, [showing])

  if (icons.length === 0) {
    return null;
  }

  const getShowingClassnameByPosition = (showing) => {
    if (!shareIconPosition) {
      return null
    } else {
      if (shareIconPosition === SHARE_ICON_POSITION.TOP_LEFT || shareIconPosition === SHARE_ICON_POSITION.TOP_RIGHT) {
        return showing ? style.showing : [style.hideFromTop, style.hidden];
      }

      if (shareIconPosition === SHARE_ICON_POSITION.BOTTOM_LEFT || shareIconPosition === SHARE_ICON_POSITION.BOTTOM_RIGHT) {
        return showing ? style.showing : [style.hideFromBottom, style.hidden];
      }
    }

  }

  // This make sures that the slide out animation completes before the share icon disappears
  const getShareIconStyleForDisplay = (isLogoShowing) => {
    if (!isLogoShowing) {
      return STYLE_MATCH_DISPLAY_NONE_WITH_DELAY;
    } else {
      return {height: '100%', width: '100%'}
    }
  }

  return (
    <div style={getShareIconStyleForDisplay(showing)}>
      <div
        className={classnames(style.container, getShowingClassnameByPosition(showing), (isMouseOver || emailOpened) ? style.active : null, style[shareIconPosition.toLowerCase()])}
        onMouseLeave={() => setMouseOver(false)}
      >
        <ShareIcon
          className={style.shareIcon}
          onMouseEnter={() => {
            if (!isMobile) {
              setMouseOver(true)
            }
          }}
          onClick={() => {
            if (isMobile) {
              setMouseOver(!isMouseOver)
            }
          }}
        />
        <div className={style.icons}>
          <SocialIconsGroup
            iconStyle={iconStyle}
            boxStyle={boxStyle}
            icons={icons}
            emailOpened={emailOpened}
            onClick={(e, variant) => handleClick(variant)}
            shareUrlConfig={{...shareUrlConfig, sharePostImgUrl, videoTitle}}
            creativeDimensions={creativeDimensions}
            shareIconPosition={shareIconPosition}
            // --socialIconFontSize CSS variable added to get REM Sizing as a prop.
            fontSize={`var(--socialIconFontSize)`}
            onEmailToggle={(open) => {
              if (open) {
                setMouseOver(false);
              }
              setEmailOpen(open)
            }}/>
        </div>
      </div>
    </div>
  )
}

export default SocialIcons;
