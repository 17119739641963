import React, {useEffect, useRef, useState} from "react";
import classnames from "classnames";

import PlayButton from 'elements/icons/play-icon';
import {Button, Overlay} from 'elements/typography';
import {DESCRIPTION_LAYOUT_TYPE, CAROUSEL_ITEM_TYPE, ENGAGEMENT_DISPLAY_MODE} from 'utils/constants';
import {AUTO_RESIZE_GROUPS} from "utils/AutoResizeProvider";
import keyframe from 'common/styles/keyframes.module.scss'

import placeholderImage from 'images/splash-screen-placeholder.png';

import style from './style.module.scss';

function CarouselItem(
    {
    itemId,
    adUnitId,
    position,
    optionIndex,
    imageSrcUrl,
    imageLandingSrcUrl,
    mode = ENGAGEMENT_DISPLAY_MODE.FULL_MODE,
    itemType,
    titleFontSettings,
    layoutType,
    buttonColor,
    title,
    onClick,
    isHybrid = false,
    containerHeight,
  }
) {
  const bottomRef = useRef(null);
  const imageContainerRef = useRef(null);
  const [imageWidth, setImageWidth] = useState('100%')
  const [videoThumbnailLoaded, setVideoThumbnailLoaded] = useState(false)

  useEffect(() => {
    if (containerHeight) {
      const bottomEl = bottomRef?.current;
      const imageContainerEl = imageContainerRef?.current;

      const maxHeight = containerHeight - (bottomEl?.clientHeight || 0);
      const maxWidth = imageContainerEl?.clientWidth || Infinity;

      const currentImageWidth = Math.floor(Math.min(4 / 3 * maxHeight, maxWidth));

      if (currentImageWidth > 0) {
        setImageWidth(currentImageWidth);
      }
    }
  }, [containerHeight, bottomRef, imageContainerRef]);

  if (!itemType) {
    return (<div className={classnames(style.carouselItemContainer, {[style.noPointer] : !onClick})}/>)
  }
  const clickHandler = () => onClick && onClick(itemId, adUnitId, position, optionIndex, imageLandingSrcUrl, itemType);
  const imageSrcUrlWithDefault = imageSrcUrl ? imageSrcUrl : placeholderImage;

  return (
    <div className={classnames(style.carouselItemContainer, {[style.noPointer] : !onClick})} style={{width: imageWidth}}>
      {isHybrid && mode === ENGAGEMENT_DISPLAY_MODE.FULL_MODE && <div className={style.spacing}/>}
      <div className={classnames(getThumbnailClasses(layoutType, isHybrid))}>
        <div className={classnames(style.containerThreeByFour)} data-testid={`carousel-item-${itemId}`}
             onClick={clickHandler}
             ref={imageContainerRef}
        >
          {/* adding onError/onLoad here as we always have a URL in studio preview (media service cache url), but it could return an error and result in a broken image icon.  This was previously checked in the backend and was translated into undefined before getting here*/}
          {!(imageSrcUrlWithDefault === placeholderImage && itemType === 'Video') && <img 
            className={style.thumbnail} src={imageSrcUrlWithDefault} 
            style={videoThumbnailLoaded ? undefined : {display: 'none'}}
            onError={() => setVideoThumbnailLoaded(false)} 
            onLoad={() => setVideoThumbnailLoaded(true)}
          />}
          {itemType === CAROUSEL_ITEM_TYPE.VIDEO && (<PlayButton className={classnames(getPlayIconClasses(mode), {[style.noPointer] : !onClick})}/>)}
          {layoutType === DESCRIPTION_LAYOUT_TYPE.OVERLAY &&
          (
            <Overlay
              className={classnames(getOverlayClasses(mode))}
              autoResize={true}
              fontSettings={titleFontSettings}
              resizeGroup={AUTO_RESIZE_GROUPS.EXPLORER_OVERLAY}
            >{title}</Overlay>
          )
          }
        </div>
      </div>
      {isHybrid && (<div
        className={classnames(style.bottom, layoutType !== DESCRIPTION_LAYOUT_TYPE.BUTTON ? style.hidden : '')}
        ref={bottomRef}>
        <div
          style={{width: imageWidth}}
          className={classnames({
            [style.descriptionButton]: true,
            [style.horizontalDisplay]: mode === ENGAGEMENT_DISPLAY_MODE.HORIZONTAL
          })}>
          <Button
            autoResize={true}
            padding={mode === ENGAGEMENT_DISPLAY_MODE.HORIZONTAL ? '.3em' : '.7em'}
            fontSettings={titleFontSettings} buttonColor={buttonColor}
            onClick={clickHandler}
            noPointer={!onClick}
            resizeGroup={AUTO_RESIZE_GROUPS.EXPLORER_BUTTON}
          >{title}</Button>
        </div>
      </div>)
      }
    </div>
  )
}

function getThumbnailClasses(layoutType, mode, isHybrid) {
  const classes = [style.thumbnailContainer, keyframe.pulse]
  mode === ENGAGEMENT_DISPLAY_MODE.HORIZONTAL && (layoutType === DESCRIPTION_LAYOUT_TYPE.BUTTON || isHybrid) && classes.push(style.partialHeight)
  return classes
}

function getOverlayClasses(mode) {
  const classes = [style.thumbnailOverlay]
  mode === ENGAGEMENT_DISPLAY_MODE.HORIZONTAL && classes.push(style.horizontalDisplay)
  return classes
}

function getPlayIconClasses(mode) {
  const classes = [style.playIcon]
  mode === ENGAGEMENT_DISPLAY_MODE.HORIZONTAL && classes.push(style.horizontalDisplay)
  return classes
}
export default CarouselItem;
