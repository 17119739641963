import React from "react";
import classnames from 'classnames';

import style from './style.module.scss';

import {useConfig} from "utils/ConfigProvider";
import {openUrl} from "utils/url-helper";

const TEXTMAP = {
  gmail: 'Gmail',
  aol: 'Aol',
  outlook: 'Outlook',
  yahoo: 'Yahoo',
}

const EMAIL_URLS = {
  gmail: 'https://mail.google.com/mail/?view=cm&fs=1&to=&su=&body=',
  yahoo: 'http://compose.mail.yahoo.com/?to=&subj=&body=',
  outlook: 'https://mail.live.com/default.aspx?rru=compose&to=&subject=&body=',
  aol: 'http://mail.aol.com/mail/compose-message.aspx?to=&subject=&body=',
};

export const ICONS = Object.keys(TEXTMAP);
function EmailIcon({variant, onClick, shareText, shareUrl, ...rest}) {
  const { environment } = useConfig();

  const shareEmail = () => {
    let body = `Watch the video here: ${  shareUrl}`;

    body = `${shareText}%0D%0A%0D%0A${body}`; // will work for gmail only, Yahoo and Outlook will replace this new line char with white space
    // this is to replace new line char(%0D%0A) with white space(%20) as AOL gives a blank page if body contains new line char.

    if(variant === 'aol') {
      body = body.replace(/%0D%0A/g, '%20');
    }
    openUrl(EMAIL_URLS[variant] + body);
  }

  const _onClick = (e) => {
    try {
      if (environment.isDraft()) {
        return e.preventDefault();
      }
      shareEmail();
    } catch (e) {
      // ignore
    }
    onClick();
  }



  return (
    <div className={style.iconContainer} onClick={_onClick} {...rest}>
      <div className={classnames(style.icon, style[variant])}/><span>{TEXTMAP[variant]}</span>
    </div>
  )
}

export default EmailIcon;
