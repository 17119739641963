import React, {useCallback, useMemo} from "react";
import style from "./style.module.scss";

export const SVGTextContainer = function (
  {
    text,
    fontSize,
    width,
    height,
    textAlignStyle,
    renderAsSVG = true
  }
) {
  const textStyle = useMemo(() => {
    return {
      fontSize,
      width,
      height,
      ...textAlignStyle
    };
  }, [fontSize, height, textAlignStyle, width]);

  const svgProps = useMemo(() => {
    return {
      width,
      height,
      style: {
        overflow: 'visible'
      }
    }
  }, [height, width]);

  const TextRenderer = useCallback(() => {
    return <div
      style={textStyle}
      className={style.svgContainer}
    >
      {text}
    </div>
  }, [text, textStyle]);

  return renderAsSVG ?
    <svg {...svgProps}>
      <foreignObject {...svgProps}>
        <TextRenderer/>
      </foreignObject>
    </svg> :
    <TextRenderer/>;
}
