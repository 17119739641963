import React from 'react';
import classnames from "classnames";

import {AutoSize} from "elements/autosize";

import style from './style.module.scss';

export const AbsoluteContainer = ({children, fullWidth, fullHeight, ...rest}) =>
   <div className={classnames(style.absolute, ...getClasses(fullWidth, fullHeight))} {...rest}>{children}</div>

export const FlexContainer = ({children, ...rest}) => {
  return <div className={style.flex} {...rest}>{children}</div>
}

export const JUSTIFY = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
  AROUND: 'around'
}

export default FlexContainer;

const getComponent = (autosize) => {
  return autosize ? AutoSize : (props) => <div {...props}/>
}

export const Flex = {
  Center({children, fullHeight = false, fullWidth = false, spacing, autosize}) {
    const Component = getComponent(autosize);
    return <Component className={classnames(style.flex, style.center, ...getClasses(fullWidth, fullHeight, spacing))}>{children}</Component>
  },
  Row({children, fullHeight = false, fullWidth = false, flex = null, spacing, autosize, reverse = false, justify}) {
    const props = {
      className: classnames(style.row, reverse ? style.rowReverse : null, ...getClasses(fullWidth, fullHeight, spacing, justify)),
      style: flex ? {flex} : {}
    }
    if (autosize) {
      return <AutoSize {...props}>{children}</AutoSize>
    } else {
      return <div {...props}>{children}</div>
    }
  },
  Column({children, fullHeight = false, fullWidth = false, flex = null, spacing, autosize, justify, reverse, autosizeMulti = false}) {
    const props = {
      className: classnames(style.column, reverse ? style.columnReverse : null, ...getClasses(fullWidth, fullHeight, spacing, justify)),
      style: flex ? {flex} : {},
    }
    if (autosize) {
      return <AutoSize {...props} isMulti={autosizeMulti}>{children}</AutoSize>
    } else {
      return <div {...props}>{children}</div>
    }
  }
}


const getClasses = (fullWidth = false, fullHeight = false, spacing = null, justify) => {
  const classes = [];

  fullWidth && classes.push(style.fullWidth);
  fullHeight && classes.push(style.fullHeight);

  if(spacing) {
    classes.push(style[`spacing${spacing}`])
  }

  if(justify) {
    classes.push(style[`justify-${justify}`])
  }

  return classes;
}
