import React from "react";

import {
  CUSTOM_ENGAGEMENT_TYPE,
  ENGAGEMENT_EXPERIENCE_SOURCE,
  ENGAGEMENT_STATUS,
  PLAYER_STATE,
  DISPLAY_MEDIA_TYPES,
  POSITION_OPTIONS,
  JourneyTriggerInteractiveEvent,
  HotSpotType,
  DEFAULT_ASPECT_RATIO,
  MEDIA_TYPE,
  SHARE_BUTTON_THEME,
  SHARE_ITEM_THEME_COLOR,
  ENGAGEMENT_TEMPLATE_MAP,
  MARKER,
  MARKER_CONFIG,
  MC_POLLING_TIMEOUT, CREATIVE_MODE, CREATIVE_CARD_TYPE, DISPLAY_ENGAGEMENT_INDEX
} from "./constants";

import SocialConnector from "compounds/engagement-social";
import Explorer from "compounds/engagement-explorer";
import ImageSurvey from "compounds/engagement-image-survey";
import CustomEngagement from "compounds/engagement-custom";
import LikertSurvey from "compounds/engagement-likert-survey";
import StoreLocator from "compounds/engagement-store-locator";
import MultipleChoiceSurvey from "compounds/engagement-multiple-choice-survey";
import {CustomSurvey} from 'compounds/engagement-custom-survey';
import {ProductExplorer} from 'compounds/engagement-product-explorer';

import Functions from "utils/functions";
import {canShowInSessionTriggerDetails} from 'utils/inSessionTriggerHelper';
import surveyHelpers from "utils/survey-helper";

export function isFinaleShowingOnComplete(overlayState) {
  return overlayState === ENGAGEMENT_STATUS.SHOWING_ON_COMPLETE
}

export function getCreativeCardSettings(creativeCardSettings, isSurveySubmitted) {
  return (creativeCardSettings || []).filter((creativeCardSetting) => isSurveySubmitted ? !isSurveySubmitted(surveyHelpers.getSurveyDetails(creativeCardSetting.creativeCard).surveyID) : true);
}

// This function is used to configure useVPAIDinterface to see if we need to trigger removeAd()
export function willFinaleShow(settings, isSurveySubmitted, triggerState, triggerIdsInPath, ignoreLogicAndPath) {

  const creativeCardSettings = getCreativeCardSettings(settings.creativeCardSettings, isSurveySubmitted);
  if (!creativeCardSettings) {
    return false;
  }

  const finalesToShowOnVideoComplete = creativeCardSettings
    .filter(({finaleEnabled}) => finaleEnabled)
    .filter(({inSessionTriggerDetails, finaleEnabled}) => canShowInSessionTriggerDetails(inSessionTriggerDetails, null, triggerState, triggerIdsInPath, finaleEnabled, true, ignoreLogicAndPath));

  return finalesToShowOnVideoComplete && finalesToShowOnVideoComplete.length > 0;
}


export function getCurrentOverlayEngagement(settings, playerTime, playerState, isSurveySubmitted, hotspotOverlay, triggerState,
  triggerIdsInPath, isContentClosed, ignoreLogicAndPath, isInScreenShooterEmulation, creativeId, isCreativeStudioPreviewMode, allInSessionTriggerDetails, submissionDetails, inVideoOverlays, finaleVariationAppliedOverlays) {
  if (isContentClosed) {
    return {inVideoOverlays : [], overlay: {}}
  }

  const isVideoComplete = (playerState === PLAYER_STATE.COMPLETED || playerState === PLAYER_STATE.STOPPED) && !settings.forceInVideo;

  const overlays = getCreativeCardSettings(inVideoOverlays, isSurveySubmitted);
  const finaleAppliedOverlays = getCreativeCardSettings(finaleVariationAppliedOverlays, isSurveySubmitted);
  
  // If we are in screenshot emulation, just return first finale for now.
  // TODO: Need to confirm from product, which specific finale should we pick for screenshot in multi in finale in-session journey scenario.
  if (isInScreenShooterEmulation) {
    if (inVideoOverlays && inVideoOverlays.length > 0) {
      return {
        inVideoOverlays,
        overlay: inVideoOverlays[0],
        overlayState: inVideoOverlays[0].finaleEnabled ? ENGAGEMENT_STATUS.SHOWING_ON_COMPLETE: ENGAGEMENT_STATUS.SHOWING_IN_VIDEO
      };
    }
  }
  // We don't need to check conditions on hotspot engagements since the conditions are already checked on the hotspot
  // Future idea: break apart hotspot and engagement so we don't have extra rules like this
  if (hotspotOverlay) {
    const isHotspotOverlaySubmitted = isSurveySubmitted ? isSurveySubmitted(surveyHelpers.getSurveyDetails(hotspotOverlay).surveyID) : false;
    if (!isHotspotOverlaySubmitted) {
      return {inVideoOverlays, overlay: hotspotOverlay, overlayState: ENGAGEMENT_STATUS.SHOWING_ON_HOTSPOT};
    }
  }

  const {video: {deliveredViewDurationSeconds = NaN}} = settings;

  if (playerState === PLAYER_STATE.PAUSED && !isNaN(deliveredViewDurationSeconds) && deliveredViewDurationSeconds<= playerTime) {
    // showOnPause finales follow trigger and path logic matching just like finales.
    const allPauseEnabledFinaleOverlays = finaleAppliedOverlays
      .filter(({enableEngagementExperienceOnPause, finaleEnabled}) => enableEngagementExperienceOnPause && finaleEnabled)
      .filter(({inSessionTriggerDetails}) => canShowInSessionTriggerDetails(inSessionTriggerDetails, playerTime, triggerState, triggerIdsInPath, true, true, ignoreLogicAndPath, true, allInSessionTriggerDetails));

    if (allPauseEnabledFinaleOverlays.length > 0) {
      const pauseEnabledFinaleOverlay = allPauseEnabledFinaleOverlays[0];

      const unsubmittedPauseEnabledFinaleOverlays = allPauseEnabledFinaleOverlays
        .filter(overlay => triggerState && !triggerState[overlay.inSessionTriggerDetails.triggerElementId])

      if (unsubmittedPauseEnabledFinaleOverlays.length > 1) {
        const errorMessage = 'Multiple pause enabled finales detected, defaulted to first in list';
        const multipleOverlayExtraErrorData = {
          extra: {
            creativeId,
            overlayIds: allPauseEnabledFinaleOverlays.map(({id}) => id),
            playerTime,
            playerState,
            triggerIdsInPath,
            triggerState,
          }
        };
        console.log(errorMessage, multipleOverlayExtraErrorData)
      }

      return {inVideoOverlays, overlay: pauseEnabledFinaleOverlay, overlayState: ENGAGEMENT_STATUS.SHOWING_ON_PAUSE};
    }
  }


  const overlaysAtCurrentTime = overlays
    .filter(({inVideoEngagementEnabled, finaleEnabled}) => isVideoComplete ? finaleEnabled : inVideoEngagementEnabled)
    .filter(({inSessionTriggerDetails, finaleEnabled}) => canShowInSessionTriggerDetails(inSessionTriggerDetails, playerTime, triggerState, triggerIdsInPath, finaleEnabled, isVideoComplete, ignoreLogicAndPath, true, allInSessionTriggerDetails));

  // In real running only 1 overlay should be active at any given time, but in studio edit mode, we can have multiple overlapping overlays
  // Show the currently selected one, only if the video is scrubbed to the appropriate time
  let overlay;

  if (overlaysAtCurrentTime.length > 0) {
    // Selected overlay has priority
    overlay = overlaysAtCurrentTime.find((overlay) => overlay.overlayIndex === settings.selectedOverlay);
    if (!overlay && playerTime !== 0) {
      // if none of the current overlays are the selected one then choose the first one
      // only selectedOverlays should be shown before the start of a video (time 0)
      overlay = overlaysAtCurrentTime[0];

      const unsubmittedOverlaysAtCurrentTime = overlaysAtCurrentTime
        .filter(overlay => triggerState && !triggerState[overlay.inSessionTriggerDetails.triggerElementId])

      if (unsubmittedOverlaysAtCurrentTime.length > 1) {
        // TODO: potentially introduce layerId/priority for conflict resolution
        if (!isCreativeStudioPreviewMode) {
          const multipleOverlayExtraErrorData = {
            extra: {
              creativeId,
              overlayIds: overlaysAtCurrentTime.map(({creativeCard}) => creativeCard.id),
              playerTime,
              playerState,
              triggerIdsInPath,
              triggerState,
            }
          };
          const errorMessage = 'Multiple overlays detected, defaulted to first in list';
          console.log(errorMessage, multipleOverlayExtraErrorData)
        }
      }
    }
  }

  if (overlay) {
    const overlayIntervalObj = getOverlayInterval(overlay, playerTime);
    const pollingEnabled = overlay?.creativeCard?.multipleChoiceEngagement?.pollingEnabled
    const surveyId = overlay?.creativeCard?.multipleChoiceEngagement?.id
    if(submissionDetails && submissionDetails[surveyId] && pollingEnabled) {
      if(overlayIntervalObj) {
      overlayIntervalObj.endTime = submissionDetails[surveyId]?.time + MC_POLLING_TIMEOUT/1000;
      }
    }  
    return {
      inVideoOverlays,
      overlay,
      overlayState: isVideoComplete ? ENGAGEMENT_STATUS.SHOWING_ON_COMPLETE : ENGAGEMENT_STATUS.SHOWING_IN_VIDEO,
      ...(!isVideoComplete && {overlayInterval: overlayIntervalObj })
    };
  }

  return {inVideoOverlays, overlay: {}}
}

export function getCurrentDisplayEngagement(settings, triggerState, triggerIdsInPath, ignoreLogicAndPath, isInScreenShooterEmulation, engagementItemId, isCreativeStudioPreviewMode, creativeId) {
  if (settings.creativeType === CREATIVE_MODE.VIDEO) {
    return {}
  }
  const {creativeCardSettings, selectedDisplayCardIndex = -1} = settings;
  
  if (isInScreenShooterEmulation && engagementItemId) {
    return creativeCardSettings.find(creativeCardSetting => creativeCardSetting.id === engagementItemId);
  }
  const potentialDisplayEngagements = creativeCardSettings
    .filter(({inSessionTriggerDetails}) => canShowInSessionTriggerDetails(inSessionTriggerDetails, null, triggerState, triggerIdsInPath, true, true, ignoreLogicAndPath));

  if (isCreativeStudioPreviewMode && !isInScreenShooterEmulation) {
    return selectedDisplayCardIndex !== -1 ? potentialDisplayEngagements.find((creativeCardSetting) => creativeCardSetting.displayEngagementIndex === selectedDisplayCardIndex) : potentialDisplayEngagements.find((displayEng) => !(displayEng.inSessionTriggerDetails?.triggerLogic));
  }

  // For video creatives, elements connected to start are always kept on screen, but we want to hide the starting display engagement once we are able to show the next item in the path
  //    - triggerIdsInPath are not updated if we reach the end of a path (see: creative-display.fireJourneyTriggerWrapper) so last displayEngagement will be kept on
  //      screen in order to show thank you message when paired with isSurveySubmitted look up in display-engagement/index.js
  //    - potentialDisplayEngagements should always be length = 1 or 2 in normal running, 1 is always the starting DE and the other is the next in the path if one is available.
  //    - in studio preview, we can have more when new DEs are unmapped (will have validation error)
  const showableDisplayEngagements = potentialDisplayEngagements.length > 1 ?
    potentialDisplayEngagements.filter(({inSessionTriggerDetails}) => inSessionTriggerDetails.triggerLogic) : potentialDisplayEngagements;

  let currentDisplayEngagement;

  // In real running 1 and only 1 displayEngagement should be active at any given time, but in studio edit mode, we can have multiple displayEngagements that are not mapped yet
  if (showableDisplayEngagements.length > 0) {
    // Selected displayEngagement has priority

    currentDisplayEngagement = showableDisplayEngagements.find((displayEngagement) => displayEngagement[DISPLAY_ENGAGEMENT_INDEX] === selectedDisplayCardIndex);

    if (!currentDisplayEngagement) {
      // if none of the displayEngagements are the selected one then choose the first one
      currentDisplayEngagement = showableDisplayEngagements[0];

      const unsubmittedDisplayEngagementsAtCurrentTime = showableDisplayEngagements
        .filter(displayEngagement => triggerState && !triggerState[displayEngagement.inSessionTriggerDetails.triggerElementId])

      if (unsubmittedDisplayEngagementsAtCurrentTime.length > 1) {
        if (!isCreativeStudioPreviewMode) {
          const multipleDisplayEngagementExtraErrorData = {
            extra: {
              creativeId,
              displayEngagementIds: showableDisplayEngagements.map(({creativeCard}) => creativeCard.id),
              triggerIdsInPath,
              triggerState,
            }
          };
          const errorMessage = 'Multiple displayEngagements detected, defaulted to first in list';
          console.log(errorMessage, multipleDisplayEngagementExtraErrorData)
        }
      }
    }
  }


  if (currentDisplayEngagement) {
    return currentDisplayEngagement;
  } else if (!isCreativeStudioPreviewMode) {
    const noDisplayEngagementExtraErrorData = {
      extra: {
        creativeId,
        triggerIdsInPath,
        triggerState,
      }
    };
    const errorMessage = 'No displayEngagements detected!';
    console.log(errorMessage, noDisplayEngagementExtraErrorData)
  }

  return {};
}

export function getCTA(settings) {
  return settings.callToAction || {}
}

export const getBrandLogoInfoFromSettings = (settings, isVideoCreative, position, generateImageSrcFromCache) => {
  let imageSrc = '';
  let visible = false;
  let ctaUrlImage = '';
  let triggerElementId;
  let mediaId;

  if (position === POSITION_OPTIONS.TOP.toLowerCase()) {
    visible = settings.topLogo?.visible;
    imageSrc = visible && settings.topLogo?.imageSrc;
    ctaUrlImage = settings.topLogo?.linkURL;
    triggerElementId = settings.topLogo?.inSessionTriggerDetails?.triggerElementId;
    mediaId = settings.topLogo?.mediaId;
  } else {
    visible = settings.bottomLogo?.visible;
    imageSrc = visible && settings.bottomLogo?.imageSrc;
    ctaUrlImage = settings.bottomLogo?.linkURL;
    triggerElementId = settings.bottomLogo?.inSessionTriggerDetails?.triggerElementId;
    mediaId = settings.bottomLogo?.mediaId;
  }

  imageSrc = !imageSrc && visible && mediaId && generateImageSrcFromCache ? generateImageSrcFromCache(settings.id, mediaId) : imageSrc;

  return [imageSrc, ctaUrlImage, triggerElementId]
}


const getShareIconStyling = (socialShare) => {
  const {shareButtonTheme, shareItemThemeColor} = socialShare;

  if (shareButtonTheme === SHARE_BUTTON_THEME.SQUARE && shareItemThemeColor === SHARE_ITEM_THEME_COLOR.DEFAULT) {
    return {boxStyle: 'square', iconStyle: 'default'};
  } else if (shareButtonTheme === SHARE_BUTTON_THEME.SQUARE && shareItemThemeColor === SHARE_ITEM_THEME_COLOR.WHITE) {
    return {boxStyle: 'square', iconStyle: 'white'};
  } else if (shareButtonTheme === SHARE_BUTTON_THEME.SQUARE && shareItemThemeColor === SHARE_ITEM_THEME_COLOR.BLACK) {
    return {boxStyle: 'square', iconStyle: 'black'};
  } else if (shareButtonTheme === SHARE_BUTTON_THEME.CIRCLE && shareItemThemeColor === SHARE_ITEM_THEME_COLOR.DEFAULT) {
    return {boxStyle: 'circle', iconStyle: 'colorful'};
  } else if (shareButtonTheme === SHARE_BUTTON_THEME.CIRCLE && shareItemThemeColor === SHARE_ITEM_THEME_COLOR.BLACK) {
    return {boxStyle: 'circle', iconStyle: 'black'};
  } else if (shareButtonTheme === SHARE_BUTTON_THEME.CIRCLE && shareItemThemeColor === SHARE_ITEM_THEME_COLOR.WHITE) {
    return {boxStyle: 'circle', iconStyle: 'white'};
  } else {
    return {boxStyle: 'square', iconStyle: 'default'};
  }
}

const getShareIconsFromSettings = (socialShare, isMobileDevice) =>
  socialShare.socialShareItemOrder.reduce((com, order) => {
    const item = socialShare.items.find(it => it.type === order && it.visible);
    if (item && (item.type === 'SMS' ? isMobileDevice : true)) {
      com = [...com, item.name.toLowerCase()];
    }
    return com;
  }, [])

const getShareUrlConfig = (socialShare) => {
  return {
    shortUrl: socialShare.items?.[0]?.shortUrl,
    header: socialShare.header || "",
    description: socialShare.description || ""
  };
}

export const getShareIconsInfoFromSettings = (socialShare, isMobileDevice) => {
  const {boxStyle, iconStyle} = getShareIconStyling(socialShare);
  const icons = getShareIconsFromSettings(socialShare, isMobileDevice);
  const shareUrlConfig = getShareUrlConfig(socialShare);
  const triggerElementId = socialShare?.inSessionTriggerDetails?.triggerElementId;

  return {
    boxStyle,
    iconStyle,
    icons,
    shareUrlConfig,
    triggerElementId,
  }
}

// Note: Use generateImageSrcFromCache if we need this from studio preview in the future, for now share links will not be clickable from studio.
export const getSharePostImgUrl = ({mediaType, video, socialShare}) => {
  const thumbnailUrlDefault =
    (mediaType.includes(MEDIA_TYPE.RAW_VIDEO) || mediaType === MEDIA_TYPE.VPAID_URL) &&
    video?.thumbnail?.mediaId ? video.thumbnail.linkURL : null;

  return socialShare?.sharePostImageUrl || thumbnailUrlDefault;
}

export const getVideoTitle = ({video}) => {
  return video.title;
}

function getOverlayInterval(overlay, playerTime) {
  return overlay.inSessionTriggerDetails?.elementTimeTriggers?.find(it => it.startTime <= playerTime && it.endTime > playerTime);
}


export function getProps(creativeCardSettings) {
  if (!creativeCardSettings) {
    return {}
  }
  const {inVideoEngagementPosition, creativeCard} = creativeCardSettings;
  const creativeCardType = creativeCard?.creativeCardType

  let engagement = {}, fontSettings, backgroundColor;
  let isSurvey = false;

  // TODO: this seems oddly inconsistent, can we fix the actual data model?
  switch (creativeCardType) {
    case CREATIVE_CARD_TYPE.THANK_YOU:
      engagement = creativeCard.thankYouEngagement;
      backgroundColor = engagement.bgColor;
      fontSettings = {
        font: engagement.font, // backend font is font string
        color: engagement.color
      };
      break;
    case CREATIVE_CARD_TYPE.EXPLORER:
      engagement = creativeCard.explorerEngagement;
      backgroundColor = engagement.bgColor;
      fontSettings = {
        ...engagement.font,
        color: engagement?.title?.color || ""
      };
      break;
    case CREATIVE_CARD_TYPE.IMAGE_SURVEY:
      engagement = creativeCard.imageSurveyEngagement;
      backgroundColor = engagement.bgColor;
      fontSettings = {
        ...engagement.font,
        color: engagement.questions?.[0]?.text?.color || engagement?.title?.color || ""
      };
      isSurvey = true;
      break;
    case CREATIVE_CARD_TYPE.CUSTOM:
      engagement = creativeCard.customHTMLEngagement;
      backgroundColor = engagement.bgColor;
      break;
    case CREATIVE_CARD_TYPE.LIKERT_SURVEY:
      engagement = creativeCard.starSurveyEngagement;
      backgroundColor = engagement.bgColor;
      fontSettings = {
        ...engagement.font,
        color: engagement?.title?.color || ""
      };
      isSurvey = true;
      break;
    case CREATIVE_CARD_TYPE.STORE_LOCATOR:
      engagement = creativeCard.mapEngagement;
      backgroundColor = engagement.bgColor;
      fontSettings = engagement.font; // backend font = fontDTO
      break;
    case CREATIVE_CARD_TYPE.MULTIPLE_CHOICE_SURVEY:
      engagement = creativeCard.multipleChoiceEngagement;
      backgroundColor = engagement.questions?.[0]?.text?.bgColor || engagement?.bgColor;
      fontSettings = {
        ...engagement.font,
        color: engagement.questions?.[0]?.text?.color || null,
        font: engagement.questions?.[0]?.text?.font || engagement?.font?.font
      };
      isSurvey = true;
      break;
    case CREATIVE_CARD_TYPE.CUSTOM_SURVEY:
      engagement = creativeCard.customSurveyEngagement;
      backgroundColor = engagement?.bgColor;
      fontSettings = {
        ...engagement.font,
        color: engagement.questions?.[0]?.text?.color || engagement?.title?.color || "",
        font: engagement.questions?.[0]?.text?.font || engagement?.font?.font
      };
      isSurvey = true;
      break;
    case CREATIVE_CARD_TYPE.PRODUCT_EXPLORER:
      engagement = creativeCard.productExplorerEngagement;
      backgroundColor = engagement.bgColor;
      break;
    default:
      // no-op

  }

  const {bgImage={}, bgGradient={}, bgStyle} = engagement;

  const noPadding = ![CREATIVE_CARD_TYPE.THANK_YOU].includes(creativeCardType);
  const isFull = [CREATIVE_CARD_TYPE.LIKERT_SURVEY].includes(creativeCardType) ||
    (creativeCardType === CREATIVE_CARD_TYPE.CUSTOM && engagement.type === CUSTOM_ENGAGEMENT_TYPE.IMAGE);

  return {
    backgroundColor,
    fontSettings,
    position: inVideoEngagementPosition,
    noPadding,
    isFull,
    bgImage,
    bgGradient,
    bgStyle,
    ...(isSurvey && {surveyID: engagement?.id}),
    engagementTemplate: ENGAGEMENT_TEMPLATE_MAP[creativeCardType] // For eventing
  };
}

export function getOverlay(creativeCard, settings) {
  if (!creativeCard?.creativeCardType) {
    return {OverlayComponent: React.Fragment}
  }
  const creativeCardType = creativeCard?.creativeCardType
  const {userInfo, video } = settings;
  const aspectRatio = video?.videoAspectRatio || DEFAULT_ASPECT_RATIO;

  let OverlayComponent, extraProps;

  switch (creativeCardType) {
    case CREATIVE_CARD_TYPE.THANK_YOU:
      OverlayComponent = SocialConnector;
      extraProps = {socialShare: settings.socialShare, sharePostImgUrl: getSharePostImgUrl(settings), videoTitle: getVideoTitle(settings)};
      break;
    case CREATIVE_CARD_TYPE.EXPLORER:
      OverlayComponent = Explorer;
      extraProps = {creativeAspectRatio: aspectRatio};
      break
    case CREATIVE_CARD_TYPE.IMAGE_SURVEY:
      OverlayComponent = ImageSurvey;
      extraProps = {creativeAspectRatio: aspectRatio};
      break
    case CREATIVE_CARD_TYPE.CUSTOM:
      OverlayComponent = CustomEngagement;
      extraProps = {type: creativeCard.customHTMLEngagement?.type === CUSTOM_ENGAGEMENT_TYPE.EMBED ? CUSTOM_ENGAGEMENT_TYPE.EMBED : CUSTOM_ENGAGEMENT_TYPE.IMAGE}
      break
    case CREATIVE_CARD_TYPE.LIKERT_SURVEY:
      OverlayComponent = LikertSurvey;
      break;
    case CREATIVE_CARD_TYPE.STORE_LOCATOR:
      OverlayComponent = StoreLocator;
      extraProps = {userInfo};
      break;
    case CREATIVE_CARD_TYPE.MULTIPLE_CHOICE_SURVEY:
      OverlayComponent =  MultipleChoiceSurvey;
      extraProps = {rendererKey: settings.studioPreviewRenderKey}
      break;
    case CREATIVE_CARD_TYPE.CUSTOM_SURVEY:
      OverlayComponent = CustomSurvey;
      break;
    case CREATIVE_CARD_TYPE.PRODUCT_EXPLORER:
      OverlayComponent = ProductExplorer;
      // pass a setter here to enable controls in creative studio preview?
      extraProps = {selectedProductExplorerSlideIndex: settings.selectedProductExplorerSlideIndex}
      break;
    default:
      OverlayComponent = React.Fragment
      break;
  }

  return {OverlayComponent, extraProps};
}

export function captureEvent(evData, overlay, interval, isShowingOnHotspot, isDisplayCreative) {
  const {isDisplayHotspot} = evData;
  if (isDisplayHotspot) {
    return {...evData, engagementTemplate: getProps(overlay).engagementTemplate}
  }
  return {
    ...evData,
    ...getOverlayPayload(overlay, interval, isShowingOnHotspot, isDisplayCreative)
  };
}

export function getOverlayPayload(overlay, interval, isShowingOnHotspot, isDisplayCreative) {
  const engagementTemplate = getProps(overlay).engagementTemplate;
  if (isShowingOnHotspot) {
    const {id, startTime, endTime} = overlay;
    return {
      engagementExperienceSource: ENGAGEMENT_EXPERIENCE_SOURCE.HOTSPOT,
      hotspotId: id,
      engagementTemplate,
      hotspotType: HotSpotType.ENGAGEMENT_EXPERIENCE,
      ...(!isDisplayCreative && {timetriggerinterval: `${Functions.getFormattedTime(startTime)} - ${Functions.getFormattedTime(endTime)}`}),
      isShared: !!overlay?.isSharedCard // NOTE: we're not supporting sharedCards in hotspot driven cards, but sending it here for completeness
    };
  } else {
    return {
      overlayID: overlay.creativeCard.id,
      engagementTemplate,
      engagementExperienceSource: getEngagementExperienceSource(interval, isDisplayCreative),
      ...(interval && {invideoposition: overlay.inVideoEngagementPosition, timetriggerinterval: `${Functions.getFormattedTime(interval.startTime)} - ${Functions.getFormattedTime(interval.endTime)}`}),
      isShared: !!overlay?.isSharedCard
    }
  }
}

function getEngagementExperienceSource(interval, isDisplayCreative) {
  if (isDisplayCreative) {
    return ENGAGEMENT_EXPERIENCE_SOURCE.DISPLAY;
  }
  if (interval) {
    return ENGAGEMENT_EXPERIENCE_SOURCE.IN_VIDEO;
  }
  return ENGAGEMENT_EXPERIENCE_SOURCE.FINALE;
}

export function getJourneyTriggerDisplayEvent(mediaType) {
  if (mediaType === DISPLAY_MEDIA_TYPES.IMAGE_SURVEY) {
    return JourneyTriggerInteractiveEvent.interactiveImageSurveyClickThrough;
  } else if (mediaType === DISPLAY_MEDIA_TYPES.MULTIPLE_CHOICE_SURVEY) {
    return JourneyTriggerInteractiveEvent.interactiveMultipleChoiceSurveyClickThrough;
  } else if (mediaType === DISPLAY_MEDIA_TYPES.CUSTOM_SURVEY) {
    return JourneyTriggerInteractiveEvent.interactiveCustomSurveyClickThrough;
  } else {
    return JourneyTriggerInteractiveEvent.interactiveLikertClickThrough;
  }
}

export function isFullDisplayContainer(mediaType) {
  return [DISPLAY_MEDIA_TYPES.RAW_IMAGE, DISPLAY_MEDIA_TYPES.TAG_URL, DISPLAY_MEDIA_TYPES.PRODUCT_EXPLORER].includes(mediaType);
}

export function getOpacityStyle(opacity = 100) {
  return {opacity: Number(opacity) / 100};
}

export function renderHotspotOutsideCard(creativeCardType, isDisplay, isSurveySubmitted, creativeCard) {
  /* In Custom survey we are managing hotspots under engagement-custom-surveu/index.js.We want to render hotspots in custom survey
  only when thankyou is appearing that's why checking if survey is submitted before rendering hotppots
   */
  if (isDisplay && creativeCardType === CREATIVE_CARD_TYPE.CUSTOM_SURVEY) {
    return isSurveySubmitted(creativeCard?.customSurveyEngagement.id);
  }
  return creativeCardType !== CREATIVE_CARD_TYPE.CUSTOM_SURVEY;
}

// Sync with Creative Studio
export const getMarkerRatio = (marker = MARKER.DEFAULT, customMarker) => {
  const hasIcon = MARKER_CONFIG[marker]?.hasIcon;

  if (hasIcon) {
    return 1;
  }

  if (marker === MARKER.CUSTOM) {
    return customMarker?.ratio;
  }

  return null;
}
