import React from "react";
import classnames from 'classnames';

import {AutoSize} from 'elements/autosize';

import style from './style.module.scss';

import FontAndColorHelper from 'utils/fontAndColorHelper';

export const Title = function (
  {
    children,
    color,
    autoResize = false,
    fontSize = 'auto',
    padding,
    centerAlign,
    topLeft,
    fullWidth = true,
    fullHeight,
    fontSettings,
    resizeGroup,
    containerStyle,
    autoSizeContainerStyle,
    ...rest
  }
) {
  const fontDetails = getFontDetails(fontSettings)
  const fontColor = fontSettings ? fontDetails.color : color
  const fontFamily = fontDetails.font
  const fontWeight = fontDetails.weight
  return (
    <div
      className={classnames({
        [style.title]: true,
        [style.titleFullWidth]: fullWidth,
        [style.titleFullHeight]: fullHeight,
        [style.center]: centerAlign,
        [style.topLeft]: topLeft
      })}
      style={{color: fontColor, padding, fontFamily, fontWeight, fontSize, ...containerStyle}}
      {...rest}>
      {autoResize ? <AutoSize resizeGroup={resizeGroup} style={autoSizeContainerStyle} className={classnames({
        [style.titleFullWidth]: fullWidth,
        [style.titleFullHeight]: fullHeight,
      })} isMulti={true}>{children}</AutoSize> : children}
    </div>
  )
}

const getFontDetails = (fontSettings) => {
  const fontAndColor = FontAndColorHelper.getFontAndColor(fontSettings, false);
  const fontWeightAndSize = FontAndColorHelper.getFontWeightAndSize(fontSettings, false);
  return {...fontAndColor, ...fontWeightAndSize}
}

export const Button = function (
  {
    children,
    buttonColor,
    autoResize = false,
    fontSettings,
    padding,
    onClick,
    dataTestId,
    resizeGroup,
    centerAlign,
    noPointer,
    ...rest
  }
) {
  const fontDetails = getFontDetails(fontSettings)
  const color = fontDetails.color
  const fontFamily = fontDetails.font
  const fontWeight = fontDetails.fontWeight
  const fontSize = fontDetails.fontSize

  return (
    <div
      className={classnames({[style.button]: true, [style.center]: centerAlign, [style.noPointer]: noPointer})}
      style={{color, backgroundColor: buttonColor, padding, fontFamily, fontWeight, fontSize}}
      onClick={onClick}
      data-testid={dataTestId}
      {...rest}>
      {autoResize ? <AutoSize resizeGroup={resizeGroup}> {children} </AutoSize> : children}
    </div>
  )
}

export const Overlay = function (
  {
    children,
    className,
    autoResize = false,
    fontSettings,
    padding,
    resizeGroup,
    width,
    ...rest
  }
) {
  const fontDetails = getFontDetails(fontSettings)
  const color = fontDetails.color
  const fontFamily = fontDetails.font
  const fontWeight = fontDetails.fontWeight

  return (
    <div
      className={className ? classnames(className, style.overlay) : style.overlay}
      style={{color, padding, fontFamily, fontWeight, width}}
      {...rest}>
      {autoResize ? <AutoSize resizeGroup={resizeGroup}> {children} </AutoSize> : children}
    </div>
  )
}
