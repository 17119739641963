import React, {useEffect, useMemo, useState} from 'react';

import {HTMLPlayer, JWPlayer, VPAIDPlayer} from "./video-players";
import canaryVideoFile from './canary.mp4';
import classnames from 'classnames';

import PlayButton from "elements/icons/play-icon";
import {AUTOPLAY_MODE, PLAYER_STATE, PLAYER_HOSTS, LOG_DETAILS_TYPE} from 'utils/constants';

import style from './style.module.scss';

import {useConfig} from "utils/ConfigProvider";
import {useEventCapture}  from "utils/EventCaptureProvider";
import {shouldShowThumbnail} from 'utils/thumbnail-helper';
import useComponentWillMount from '../../hooks/useComponentWillMount';
import {useAutoPlayState} from "hooks/useAutoPlayState";
import {shouldTryCanary} from "utils/testCanary";
import {isMobileDevice, isChrome} from "utils/environmentHelper";
import {getVideoHost} from 'utils/settingsHelper';

function Player(
  {
    state,
    isMute,
    onPlayerTimeChange,
    onPlayerStateChange,
    scrubToHandler,
    onPlayerDurationChange,
    playButtonShowing,
    engagementShowing,
    onEvent,
    getSpecialSettingPlatformValue,
    track3rdPartyEvents,
    onClickThrough,
    settings,
    onMuteStateChange,
    playerControlWrapper,
    setPlayerControlWrapper,
    reloadAd,
    setClickToPlay,
    isClickToPlay,
    setQuartile,
    setImpressionEvent,
    setVastTrackingPixels,
    onPlayerClick,
    creativeDimensions,
    preventClickThrough
  }
) {
  const {environment, runtimeProperties} = useConfig();
  const {captureAssetLoadingTime} = useEventCapture();

  useComponentWillMount(() => {
    captureAssetLoadingTime(LOG_DETAILS_TYPE.VIDEO_CREATIVE_COMPONENT_MOUNT, true)
    captureAssetLoadingTime(LOG_DETAILS_TYPE.PLAYER_MOUNT, false)
  });
  const {autoPlay, autoPlayMuted} = settings;


  // only in case the underlying player gives exception playing autoplay with sound
  const [autoplayUnMuteError, setAutoplayUnMutedError] = useState(false);

  const {
    ref: canaryVideo,
    autoPlayMode,
    ...extraParams
  } = useAutoPlayState(autoPlay && !environment.isInAdminTool(), autoPlayMuted, settings, environment.inHeavyAdsEmulation(), isChrome(), environment.isInScreenShooterEmulation(), runtimeProperties.setBrowserAutoplayDisabled, captureAssetLoadingTime)

  const host = useMemo(() => getHost(settings), [settings]);

  const VideoPlayer = useMemo(() => {
    const status = getStatus(settings);
    return getPlayer(host, status, environment.isCreativeStudioPreviewMode(), environment.isEditMode(), environment.isShareLandingPage())
  }, [environment, host, settings]);

  useEffect(() => {
    if (autoPlayMode) {
      setClickToPlay(autoPlayMode === AUTOPLAY_MODE.NO_AUTOPLAY);
      onMuteStateChange(autoPlayMode === AUTOPLAY_MODE.AUTOPLAY_MUTED);
    }
  }, [autoPlayMode, onMuteStateChange, setClickToPlay])

  useEffect(() => {
    if (autoPlayMode) {
      // TODO: refactor to be in a state rather than mutating global
      runtimeProperties.setAutoplayMode(autoPlayMode);
    }
  }, [autoPlayMode, runtimeProperties])

  return (
    <div className={classnames(style.player, engagementShowing ? style.blurPlayer : null)} onClick={onPlayerClick}>
      {((isMute !== undefined) && (isClickToPlay !== undefined)) && <VideoPlayer
        autoPlayMode={autoplayUnMuteError ? AUTOPLAY_MODE.AUTOPLAY_MUTED : autoPlayMode}
        state={state}
        isMute={isMute}
        scrubToHandler={scrubToHandler}
        onPlayerStateChange={onPlayerStateChange}
        onMuteStateChange={onMuteStateChange}
        onPlayerTimeChange={onPlayerTimeChange}
        onDurationFetch={onPlayerDurationChange}
        setAutoplayUnMutedError={setAutoplayUnMutedError}
        onEvent={onEvent}
        getThumbnail={() => getThumbnail(host, settings, state, playerControlWrapper, isMobileDevice())}
        getSpecialSettingPlatformValue={getSpecialSettingPlatformValue}
        track3rdPartyEvents={track3rdPartyEvents}
        onClickThrough={onClickThrough}
        settings={settings}
        setPlayerControlWrapper={setPlayerControlWrapper}
        reloadAd={reloadAd}
        setQuartile={setQuartile}
        setImpressionEvent={setImpressionEvent}
        setVastTrackingPixels={setVastTrackingPixels}
        onPlayerClick = {onPlayerClick}
        creativeDimensions={creativeDimensions}
        {...extraParams}
      />
      }

      {playButtonShowing &&
        <PlayButton
          onClick={() => {
            if (playerControlWrapper) {
              playerControlWrapper.play();
            }
          }}
          className={style.playIcon}
        />
      }
      {
        shouldTryCanary(autoPlay, autoPlayMuted) &&
        <video
          ref={canaryVideo}
          id="canary"
          src={canaryVideoFile}
          width="1"
          height="1"
          webkit-playsinline="true"
          playsInline
        />
      }
      {
        /*
        * Prevent Click through for Video when Hotpots are selected in Creative Studio Preview Mode,
        * This is added because we wanted to unselect the hotspot, when we click out of the MultiSelect of Hotspot
        * */
        (environment.isCreativeStudioPreviewMode() && preventClickThrough) ?
          <div className={style.preventClickThrough}/> :
          <React.Fragment/>
      }
    </div>
  )
}

const getPlayer = (host, status, isCreativeStudioPreviewMode, isEditMode, isShareLandingPage) => {
  switch (host) {
    case PLAYER_HOSTS.JW:
      return shouldShowHTML5View(status, isCreativeStudioPreviewMode, isEditMode, isShareLandingPage) ? HTMLPlayer : JWPlayer;
    case PLAYER_HOSTS.VAST:
      return VPAIDPlayer;
    default:
      console.log("type not defined for player of type host", host)
  }
}

const getThumbnailUrl = (settings, isMobileDevice) => {
  const {video: {thumbnail, mobileThumbnailSrc}} = settings;

  return isMobileDevice ? mobileThumbnailSrc : thumbnail?.linkURL;
}

const getVPAIDOverlay = (thumbnailShowing, thumbnailUrl, state, playerControlWrapper) => {
  return <div
    className={style.vpaidOverlay}
    onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
      if (state === PLAYER_STATE.PLAYING) {
        playerControlWrapper.pause();
      } else {
        playerControlWrapper.play();
      }
    }}
    style={{backgroundImage: thumbnailShowing && thumbnailUrl ? `url('${thumbnailUrl}')` : 'none'}}
  />
}

const getThumbnailImage = (thumbnailShowing, thumbnailUrl) => {
  return thumbnailShowing && thumbnailUrl ?
    <span className={style.thumbnail} style={{backgroundImage: `url('${thumbnailUrl}')`}}/> :
    <React.Fragment/>
}

const getThumbnail = (host, settings, state, playerControlWrapper, isMobileDevice) => {
  const thumbnailShowing = shouldShowThumbnail(state);
  const thumbnailUrl = getThumbnailUrl(settings, isMobileDevice);

  return host === PLAYER_HOSTS.VAST ? getVPAIDOverlay(thumbnailShowing, thumbnailUrl, state, playerControlWrapper) : getThumbnailImage(thumbnailShowing, thumbnailUrl);
}

const shouldShowHTML5View = (status, isCreativeStudioPreviewMode, isEditMode, isShareLandingPage) => {
  return isCreativeStudioPreviewMode ||
    ((status !== 'READY_TO_USE' && status !== 'READY_TO_USE_WITH_ENCODINGS') && (isEditMode || isShareLandingPage));
}

const getHost = settings => getVideoHost(settings.mediaType);
const getStatus = settings => settings.video.status;
export default Player;
