// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".displayTagUrlContainer--2VGA-{width:100%;height:100%}.displayTagUrlIframe--1m2_s{margin:0;padding:0;width:100%;height:100%;border:0;display:block}", ""]);
// Exports
exports.locals = {
	"displayTagUrlContainer": "displayTagUrlContainer--2VGA-",
	"displayTagUrlIframe": "displayTagUrlIframe--1m2_s"
};
module.exports = exports;
