import React, {useMemo} from 'react';
import { v4 as uuidv4 } from 'uuid';

import LikertSurveyRenderer from "./renderer";
import useLikertSurveyEvents from "./useLikertSurveyEvents";

import {ENGAGEMENT_DISPLAY_MODE, LIKERT_OPTION_STYLE, LIKERT_SUBMIT_BUTTON_TYPE, MARKER} from "utils/constants";
import surveyHelpers from "utils/survey-helper";
import {useConfig} from "utils/ConfigProvider";

const DEFAULT_OPTIONS = Array.from([1, 2, 3, 4, 5], numStar => ({numStar}))

function LikertSurvey(props) {

  const {
    overlay,
    inVideo = false,
    mode = ENGAGEMENT_DISPLAY_MODE.FULL_MODE,
    onEvent,
    fireJourneyTrigger,
    submitSurvey,
    trackSurveyClickThrough,
    showing,
    getSelectedPosition,
    setSelectedOption,
    onClickThrough,
    isDisplayCreative,
    creativeDimensions
  } = props;

  const {environment} = useConfig();

  const {
    onClick,
    onSubmit
  } = useLikertSurveyEvents(onEvent, inVideo, fireJourneyTrigger, submitSurvey, trackSurveyClickThrough, onClickThrough, overlay?.inSessionTriggerDetails?.triggerElementId, environment.isVPAIDEnabled());

  const {creativeCard} = overlay
  const {starSurveyEngagement} = creativeCard;
  const surveyID = starSurveyEngagement.id;
  const question = starSurveyEngagement.questions?.[0];
  const questionId = question?.id;
  const surveyRules = starSurveyEngagement?.surveyRules;

  const selectedPosition = useMemo(() => {
    return getSelectedPosition ? getSelectedPosition(surveyID) : null;
  }, [getSelectedPosition, surveyID]);

  const {width, height} = useMemo(() => (creativeDimensions || {}), [creativeDimensions]);

  return (
    <LikertSurveyRenderer
      onClick={(selectedQuestionOptionID, position) => {
        if (setSelectedOption) {
          setSelectedOption({[surveyID]: position});
        } 
        onClick(surveyID, questionId, position, selectedQuestionOptionID, surveyHelpers.getSelectedQuestionOptionRuleForLikert(surveyRules, position));
      }}
      onSubmit={(selectedQuestionOptionID, position) => {
        onSubmit(surveyID, questionId, position, selectedQuestionOptionID, surveyHelpers.getSelectedQuestionOptionRuleForLikert(surveyRules, position));
      }}
      selectedPosition={selectedPosition}
      isDisplayCreative={isDisplayCreative}
      height={height}
      width={width}
      {...getProps(overlay, inVideo, mode, showing)}/>
  )

}

const getProps = (overlay, inVideo, mode, showing) => {
  const {creativeCard, inVideoEngagementPosition} = overlay
  const {starSurveyEngagement} = creativeCard
  const {submitEnabled, questions, button, font, submitButtonType, starColor, title} = starSurveyEngagement;
  const surveyQuestion = questions?.[0];

  const submitButtonText = submitEnabled && button?.text;
  const submitButtonColor = submitEnabled && button?.bgColor;
  const submitButtonTextColor = submitEnabled && submitButtonType === LIKERT_SUBMIT_BUTTON_TYPE.BUTTON && button?.color
  const submitButtonFont = submitEnabled && submitButtonType === LIKERT_SUBMIT_BUTTON_TYPE.BUTTON && font?.font;
  const submitFontSettings = submitEnabled && submitButtonType === LIKERT_SUBMIT_BUTTON_TYPE.BUTTON && {
    color: button?.color,
    font: font?.font 
  };

  const iconType = (surveyQuestion?.optionStyle === LIKERT_OPTION_STYLE.ICON && surveyQuestion?.iconType) || MARKER.STAR;
  const iconSize = (surveyQuestion?.optionStyle === LIKERT_OPTION_STYLE.ICON && surveyQuestion?.iconSize) || 100;
  const iconColor = submitEnabled && submitButtonType === LIKERT_SUBMIT_BUTTON_TYPE.CHECKMARK && button?.color;
  const optionStyle = surveyQuestion?.optionStyle || LIKERT_OPTION_STYLE.ICON;
  const sliderColors = (surveyQuestion?.optionStyle === LIKERT_OPTION_STYLE.SLIDER && surveyQuestion?.sliderColors) || ['#ffffff', '#ffffff'];
  const singleIcon = surveyQuestion?.singleIcon;
  const customIcon = surveyQuestion?.optionStyle === LIKERT_OPTION_STYLE.ICON && surveyQuestion?.iconType === MARKER.CUSTOM && surveyQuestion?.singleIcon && surveyQuestion?.customIcon;
  const question = {
    id: surveyQuestion?.id,
    text: surveyQuestion?.text?.text,
    options: (surveyQuestion?.options || [DEFAULT_OPTIONS]).map(option => ({
      id: option.id ? option.id : uuidv4(),
      scale: `Scale_${option.numStar}`,
      label: option.optionLabel,
      customMarker: surveyQuestion?.optionStyle === LIKERT_OPTION_STYLE.ICON && surveyQuestion?.iconType === MARKER.CUSTOM && !surveyQuestion?.singleIcon && option.customMarker
    })),
    fontSettings: {
      ...font,
      color: title?.color || ""
    }
  }
  return {
    submitEnabled,
    submitButtonText,
    submitButtonColor,
    submitButtonFont,
    submitButtonTextColor,
    starColor,
    question,
    inVideo,
    mode,
    inVideoEngagementPosition,
    showing,
    submitFontSettings,
    iconType,
    iconSize,
    optionStyle,
    sliderColors,
    customIcon,
    singleIcon,
    iconColor,
    submitButtonType: submitButtonType || LIKERT_SUBMIT_BUTTON_TYPE.BUTTON
  }
}

export default LikertSurvey;
