import React, {useCallback, useMemo} from "react";
import {Hotspot} from "elements/hotspot";
import {MARKER} from "utils/constants";

export const CustomSurveyQuestionRenderer = (
  {
    question,
    onClick,
    containerDimensions,
    hotspotContainer,
    openHotspot,
    isHotspotSelected,
    selectedHotspotSelector,
    hotspotPopperSelector,
    snappingHotspotSelector,
    isCreativeStudioPreviewMode
  }
) => {
  const questionToBeUsed = useMemo(() => {
    return {
      ...question,
      hotspotText: question?.text || {},
      marker: MARKER.DEFAULT
    }
  }, [question]);

  const onQuestionClick = useCallback((question, index, isSelected, shiftKey) => {
    if (openHotspot instanceof Function) {
      openHotspot(questionToBeUsed, shiftKey);
    }
  }, [openHotspot, questionToBeUsed]);

  const isItemSelected = useCallback((item) => {
    if (isHotspotSelected instanceof Function) {
      return isHotspotSelected(item)
    }
    return false;
  }, [isHotspotSelected]);

  const isQuestionSelected = useMemo(() => {
    return isItemSelected(questionToBeUsed);
  }, [isItemSelected, questionToBeUsed]);

  const onOptionClick = useCallback((option, index, isSelected, shiftKey) => {
    if (openHotspot instanceof Function) {
      openHotspot(option, shiftKey);
    } else {
      onClick(option.id, index + 1, option.optionIndex, question.id);
    }
  }, [onClick, openHotspot, question.id]);

  return <>
    <Hotspot
      hotspot={questionToBeUsed}
      containerDimensions={containerDimensions}
      showing={Boolean(hotspotContainer)}
      useHotspotAnimation={false}
      noPointer={true}
      isCardHotspot={true}
      onClick={onQuestionClick}
      isSelected={isQuestionSelected}
      selectedHotspotSelector={selectedHotspotSelector}
      hotspotPopperSelector={hotspotPopperSelector}
      snappingHotspotSelector={snappingHotspotSelector}
      isCreativeStudioPreviewMode={isCreativeStudioPreviewMode}
    />
    {
      question.options.map((option, index) => <Hotspot
          key={option.id || index}
          hotspot={{
            ...option,
            hotspotText: option?.title || {}
          }}
          onClick={onOptionClick}
          containerDimensions={containerDimensions}
          showing={Boolean(hotspotContainer)}
          useHotspotAnimation={false}
          isCardHotspot={true}
          isSurveyOption={true}
          index={index}
          isSelected={isItemSelected(option)}
          selectedHotspotSelector={selectedHotspotSelector}
          hotspotPopperSelector={hotspotPopperSelector}
          snappingHotspotSelector={snappingHotspotSelector}
          isCreativeStudioPreviewMode={isCreativeStudioPreviewMode}
        />
      )
    }
  </>
}
