import React, {useMemo} from "react";
import CustomEngagementRenderer from "./renderer";
import useCustomEngagementEvents from "./useCustomEngagementEvents";
import {CUSTOM_ENGAGEMENT_TYPE, MediaSubType} from "utils/constants";
import {useConfig} from "utils/ConfigProvider";

function CustomEngagement({overlay, onEvent, fireJourneyTrigger, onClickThrough, inVideo = false, type}) {
  const {environment} = useConfig();

  const {onClick} = useCustomEngagementEvents(onEvent, fireJourneyTrigger, inVideo, onClickThrough, overlay?.inSessionTriggerDetails?.triggerElementId);

  const isCreativeStudioPreviewMode = environment.isCreativeStudioPreviewMode();
  const {creativeCard} = overlay

  const rendererProps = useMemo(() => getProps(creativeCard, inVideo, type, isCreativeStudioPreviewMode), [creativeCard, inVideo, type, isCreativeStudioPreviewMode]);

  const clickHandler = !isCreativeStudioPreviewMode ? onClick : () => {};

  return (
    <CustomEngagementRenderer
      {...rendererProps}
      type={type}
      onClick={clickHandler}
    />
  )
}

const getProps = (creativeCard, inVideo, type, isCreativeStudioPreviewMode) => {
  const {customHTMLEngagement} = creativeCard;
  const {
    brandedCTAImage = {},
    inVideoCTAImage = {},
    inVideoHyperlink,
    hyperlink,
    embedURL,
    mediaSubType
  } = customHTMLEngagement;

  if (inVideo) {
    return {
      imageUrl: inVideoCTAImage?.linkURL,
      imageCTA: !isCreativeStudioPreviewMode ? inVideoHyperlink : null,
      imageOpacity: inVideoCTAImage?.opacity
    }
  } else {
    if (type === CUSTOM_ENGAGEMENT_TYPE.EMBED) {
      return {
        embedUrl: embedURL,
        mediaSubType,
      }
    } else {
      return {
        imageUrl: brandedCTAImage?.linkURL,
        imageCTA: !isCreativeStudioPreviewMode ? hyperlink : null,
        imageOpacity: brandedCTAImage?.opacity
      }
    }
  }
}


export default CustomEngagement;
