import {BACKGROUND_IMAGE_TYPE, BACKGROUND_STYLES, GRADIENT_ANGLES, GRADIENT_TYPES} from './constants';

export function getElementBackgroundStyle(
  {
    bgStyle = BACKGROUND_STYLES.SOLID,
    bgImage = {},
    bgGradient = {},
    backgroundColor,
    isInVideo = false // This will be false for Hotspot Background
  }
) {
  switch (bgStyle) {
    case BACKGROUND_STYLES.SOLID: {
      return {backgroundColor};
    }
    case BACKGROUND_STYLES.IMAGE: {
      const imageObject = bgImage[isInVideo ? BACKGROUND_IMAGE_TYPE.IN_VIDEO : BACKGROUND_IMAGE_TYPE.CARD];
      const {opacity: opacityPercentage = 100, linkURL} = imageObject || {};
      const opacity = opacityPercentage / 100;
      return {backgroundImage: `url(${linkURL})`, opacity};
    }
    case BACKGROUND_STYLES.GRADIENT: {
      const {
        angle = GRADIENT_ANGLES.TOP_TO_BOTTOM,
        gradientColor1 = 'rgba(0,0,0, 0.7)',
        gradientColor2 = 'rgba(0,0,0, 0.7)'
      } = bgGradient || {};

      const {type = 0, modifier = GRADIENT_TYPES.LINEAR} = getGradientTypeAndAngle(angle);
      return {
        background: `${type}(${modifier}, ${gradientColor1} 0%, ${gradientColor2} 100%)`
      };
    }
    default: {
      return {}
    }
  }
}

export function getGradientTypeAndAngle(angle) {
  switch (angle) {
    case GRADIENT_ANGLES.TOP_TO_BOTTOM: {
      return {
        type: GRADIENT_TYPES.LINEAR,
        modifier: '180deg'
      };
    }
    case GRADIENT_ANGLES.BOTTOM_TO_TOP: {
      return {
        type: GRADIENT_TYPES.LINEAR,
        modifier: '0deg'
      };
    }
    case GRADIENT_ANGLES.LEFT_TO_RIGHT: {
      return {
        type: GRADIENT_TYPES.LINEAR,
        modifier: '90deg'
      };
    }
    case GRADIENT_ANGLES.RIGHT_TO_LEFT: {
      return {
        type: GRADIENT_TYPES.LINEAR,
        modifier: '270deg'
      };
    }
    case GRADIENT_ANGLES.CENTRE: {
      return {
        type: GRADIENT_TYPES.RADIAL,
        modifier: 'circle'
      };
    }
    default: {
      return {};
    }
  }
}