import {useCallback, useRef} from 'react';

import surveyHelpers from 'utils/survey-helper';
import {ENGAGEMENT_TYPES, JourneyTriggerInteractiveEvent} from 'utils/constants';

function useCustomSurveyEvents(onEvent, isInVideo, fireJourneyTrigger, submitSurvey, trackSurveyClickThrough, onClickThrough, triggerElementId, isVPAIDEnabled) {
  const surveyIdsSubmitted = useRef([]);

  const onClick = useCallback((surveyID, questionId, position, optionIndex, selectedQuestionOptionID, surveyRule) => {
    // OV-790 - use local state to prevent refiring of onSubmit for a given surveyId, this is to account for the delayed firing of submitSurvey/fireJourneyTrigger in the vpaid/finale case below
    // OV-1669 - updated to useRef instead of useState, useState has closure issues (missing rerender) when wrapped in setTimeout, which we are doing for animation in mobile.
    if (surveyIdsSubmitted.current.includes(surveyID)) {
      console.log("Duplicate survey submission rejected")
      return
    }

    surveyIdsSubmitted.current = [...surveyIdsSubmitted.current, surveyID]
    
    const engagementTemplate = ENGAGEMENT_TYPES.CustomSurvey;

    const payload = surveyHelpers.getSurveyResponsePayload(surveyID, position, questionId, selectedQuestionOptionID, engagementTemplate);
    payload.shouldSendSurveySubmissionEvent = 'true';

    surveyHelpers.executeSurveyRules({
      surveyRule,
      surveyID,
      engagementTemplate,
      onEvent,
      isInVideo,
      fireJourneyTrigger,
      trackSurveyClickThrough,
      onClickThrough,
      triggerElementId
    });

    const surveyResponsePromise = onEvent(payload);

    const callback = () => {
      // When vpaid is enabled then wait for all the submission and response pixel to be fired before sending ad stopped event.
      const jtPosition = optionIndex === null || optionIndex === undefined ? position : optionIndex;
      fireJourneyTrigger(JourneyTriggerInteractiveEvent[`interactiveCustomSurveyOption${jtPosition}Response`], triggerElementId);
      // Note: helper event for in-session journey, is converted to interactiveCustomSurveySubmission before sending to event ingestion/druid
      fireJourneyTrigger(JourneyTriggerInteractiveEvent[`interactiveCustomSurveyOption${jtPosition}Submission`], triggerElementId);
      submitSurvey(surveyID);
    }

    if (isVPAIDEnabled && !isInVideo) { // When vpaid is enabled then wait for all the submission and response pixel to be fired before sending ad stopped event.
      surveyResponsePromise.then(() => {
        callback();
      })
    } else {
      callback();
    }
  }, [onEvent, isInVideo, fireJourneyTrigger, trackSurveyClickThrough, onClickThrough, triggerElementId, submitSurvey, isVPAIDEnabled]);

  return {onClick};
}

export default useCustomSurveyEvents;
