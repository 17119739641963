// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".shareIcon--2g-zO{width:calc(var(--rootFontSize) * 1.7);height:calc(var(--rootFontSize) * 1.7)}.mobileShareIcon--1MKQm{width:calc(var(--rootFontSize) * 2);height:calc(var(--rootFontSize) * 2)}", ""]);
// Exports
exports.locals = {
	"shareIcon": "shareIcon--2g-zO",
	"mobileShareIcon": "mobileShareIcon--1MKQm"
};
module.exports = exports;
