import {useCallback} from 'react';

export function useHotspotEvents(playerControlWrapper) {
  const onHotspotClickAway = useCallback(() => {
    playerControlWrapper.pause()
  }, [playerControlWrapper]);

  return {
    onHotspotClickAway
  }
}
