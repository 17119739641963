// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".slider--268fp{-webkit-appearance:none;width:calc(var(--rootFontSize) * 3);height:calc(var(--rootFontSize) * 0.3);transform:rotate(-90deg);z-index:0;-ms-writing-mode:bt-lr;writing-mode:bt-lr;transition:opacity .2s;border-radius:calc(calc(var(--rootFontSize) * 0.3) * 1.75)}.slider--268fp::-webkit-slider-thumb{-webkit-appearance:none;appearance:none;width:calc(calc(var(--rootFontSize) * 0.3) * 1.75);height:calc(calc(var(--rootFontSize) * 0.3) * 1.75);border-radius:50%;background:#23adf0;cursor:pointer;border:calc((calc(var(--rootFontSize) * 0.3) * 1.75)*.15) solid #23adf0}.slider--268fp::-moz-range-thumb{width:calc(calc(var(--rootFontSize) * 0.3) * 1.75);height:calc(calc(var(--rootFontSize) * 0.3) * 1.75);border-radius:50%;background:#23adf0;cursor:pointer;border:calc((calc(var(--rootFontSize) * 0.3) * 1.75)*.15) solid #23adf0}", ""]);
// Exports
exports.locals = {
	"slider": "slider--268fp"
};
module.exports = exports;
