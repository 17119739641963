// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/ad-choices-icon.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./assets/ad-choices-icon-small.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".adChoicesIcon--2FNI3{position:relative;background-size:contain;display:inline-block;cursor:pointer;z-index:2147483648;height:15px;width:78px;margin:0;margin-top:calc(var(--rootFontSize) * 0.2);right:0;bottom:0;pointer-events:auto;align-self:flex-end;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;min-width:78px}.small--34mcY{width:15px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;min-width:15px;width:15px}.noPointer--OFyxZ{cursor:default !important}", ""]);
// Exports
exports.locals = {
	"adChoicesIcon": "adChoicesIcon--2FNI3",
	"small": "small--34mcY",
	"noPointer": "noPointer--OFyxZ"
};
module.exports = exports;
