// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".closeIconAbsolute--2wR8F{position:absolute;top:calc(var(--rootFontSize) * 0.25);right:calc(var(--rootFontSize) * 0.25);line-height:0;z-index:3}.closeIconAbsolute--2wR8F img,.closeIcon--2Hwph img{width:calc(var(--rootFontSize) * 0.9);height:calc(var(--rootFontSize) * 0.9)}.engagementContentContainer--1B7Dl{height:100%;width:100%;display:flex;justify-content:center;align-items:center;z-index:2}.engagementContentContainerWithoutZIndex--1I8c1{z-index:unset}.finaleContainer--_JTtH{display:flex;height:auto;justify-content:center;align-items:center;width:100%;flex:1;flex-direction:column}.fullFinaleContainer--31NYa{position:absolute;top:0;left:0;width:100%;height:100%}.backgroundLayer--Sd02S{position:absolute;top:0;left:0;width:100%;height:100%;z-index:2}", ""]);
// Exports
exports.locals = {
	"closeIconAbsolute": "closeIconAbsolute--2wR8F",
	"closeIcon": "closeIcon--2Hwph",
	"engagementContentContainer": "engagementContentContainer--1B7Dl",
	"engagementContentContainerWithoutZIndex": "engagementContentContainerWithoutZIndex--1I8c1",
	"finaleContainer": "finaleContainer--_JTtH",
	"fullFinaleContainer": "fullFinaleContainer--31NYa",
	"backgroundLayer": "backgroundLayer--Sd02S"
};
module.exports = exports;
