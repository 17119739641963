import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import classnames from 'classnames';

import Player from "modules/player";
import PlayerEventRecorder from "modules/player-event-recorder";
import HotspotManager from "modules/hotspot-manager";
import SocialIcons from "modules/social-icons";
import Overlay from "modules/overlay";
import CTARenderer from 'modules/cta-renderer';

import ScrubBar from "compounds/scrub-bar";
import {VideoGradient} from "elements/videoGradient";
import {getCurrentOverlayEngagement, willFinaleShow} from "utils/engagement-helper";
import {getAllInSessionTriggerDetails, getChildrenTriggerElementIds, getNewTriggerState} from "utils/inSessionTriggerHelper";
import {
  PLAYER_STATE,
  POSITION_OPTIONS,
  SHARE_ICON_POSITION,
  IN_SESSION_JOURNEY_EVENT_MAP,
  DEFAULT_VIDEO_CLEANUP_TIMEOUT_SECONDS,
  LOG_DETAILS_TYPE,
  DEFAULT_VIDEO_DURATION,
  CTA_POSITIONS,
  MC_POLLING_TIMEOUT
} from 'utils/constants';
import {getCurrentHotspots} from "utils/hotspot-helper";
import {getGradient} from "utils/gradient-helper";
import {getVideoHost, getCreativeCardFromSingleSetting} from 'utils/settingsHelper';

import {useJourneyTriggerEvents} from "hooks/useJourneyTriggerEvents";
import useShowElements from "hooks/useShowElements";
import {useSurveys} from "hooks/useSurveys";
import {useHotspotEvents} from "hooks/useHotspotsEvents";
import {usePlayerTimeEvent} from "hooks/usePlayerTimeEvents";
import {useOverlayEvents} from "hooks/useOverlayEvents";
import {useSpecialSettings} from "hooks/useSpecialSettings";
import BrandLogoManager from "modules/brand-logo-manager";

import style from '../../index.module.scss';

import {BottomBar, GroupRow, PositionLeft, PositionRight, TopBar} from 'elements/player-layout';
import useVPAIDInterface from "hooks/useVPAIDInterface";

import {AdStoppedIcon} from 'elements/adStopped';
import {useAdStoppedEvent} from 'hooks/useAdStoppedEvent';
import {useClickThroughEvents} from 'hooks/useClickThroughEvents';
import {useVideoContainerResize} from "hooks/useVideoContainerResize";
import {usePlayerTimeLogger} from "hooks/usePlayerTimeLogger";
import useCleanupCounter from 'hooks/useCleanupCounter';
import useComponentWillMount from '../../hooks/useComponentWillMount';
import {executeLiveRampIdPullFlow} from "utils/liveRampHelper";
import {getPlatformType, isMobileDevice} from "utils/environmentHelper";
import {generateVideoTimeToPercentages} from 'utils/contentFlowHelper';
import {useConfig} from "utils/ConfigProvider";
import {useEventCapture}  from "utils/EventCaptureProvider";

const noop = () => {}

function VideoCreative(
  {
    settings,
    track3rdPartyEvents,
    reloadAd,
    removeAd,
    onSetPlayerControlWrapper,
    onPlaybackStateChange,
    onPlayerTimeChange,
    onSetScrubHandle,
    creativeDimensions,
    isContentFlowCreative,
    hotspotProps
  }
) {

  const {localConfig, environment, runtimeProperties} = useConfig();
  const {capture, shouldPhoenixSkipFiringStandardVastPixel, captureAssetLoadingTime} = useEventCapture();

  useComponentWillMount(() => {
    captureAssetLoadingTime(LOG_DETAILS_TYPE.VIDEO_CREATIVE_COMPONENT_MOUNT, false)
  });

  const [playerState, setPlayBackState] = useState(environment.isODCEngagementPreviewEnabled() && !environment.isDisplayCreative() ? PLAYER_STATE.COMPLETED : undefined);
  const [playerTime, setPlayerTime] = useState(0);

  /* IMPORTANT: play/pause/mute should be requested through playerControlWrapper, setPlayBackState and toggleMuteUnmute should only be called by playerControlWrapper as well*/
  const [playerControlWrapper, setPlayerControlWrapper] = useState({
    pause: ()=>{},
    play: ()=>{}
  });
  const [scrubHandle, setScrubHandle] = useState(undefined);

  const [playerDuration, setPlayerDuration] = useState(undefined);
  const [submissionDetails, setsubmissionDetails] = useState(undefined);
  const [scrubbedOnce, setScrubbedOnce] = useState(false);
  const [isMute, toggleMuteUnmute] = useState(undefined);
  const [isClickToPlay, setClickToPlay] = useState(undefined);
  const [isPixelFiringInProgress, setPixelFiringInProgress] = useState(false);
  const [userUnmuted, setUserUnmuted] = useState(false);

  const {fireJourneyTrigger} = useJourneyTriggerEvents(settings.journeyTriggers, environment.isVPAIDEnabled());
  const [triggerState, setTriggerState] = useState({});
  const [triggerIdsInPath, setTriggerIdsInPath] = useState([]);
  const [lastTrigger, setLastTrigger] = useState({});

  const [q1, setQ1] = useState(false);
  const [q2, setQ2] = useState(false);
  const [q3, setQ3] = useState(false);
  const [impressionEvent, setImpressionEvent] = useState(false);
  const [vastTrackingPixels, setVastTrackingPixels] = useState(null);
  const impressionEventPixelFired = useRef(false);

  const setPlayerTimeWrapper = useCallback((playerTime) => {
    setPlayerTime(playerTime);
    if (onPlayerTimeChange) {
      onPlayerTimeChange(playerTime);
    }
  }, [onPlayerTimeChange]);

  const setPlayBackStateWrapper = useCallback((playBackState) => {
    setPlayBackState(playBackState);
    if (onPlaybackStateChange) {
      onPlaybackStateChange(playBackState);
    }
  }, [onPlaybackStateChange]);

  const setPlayerControlWrapperWrapper = useCallback((playerControlWrapper) => {
    setPlayerControlWrapper(playerControlWrapper);
    if (onSetPlayerControlWrapper) {
      onSetPlayerControlWrapper(playerControlWrapper);
    }
  }, [onSetPlayerControlWrapper]);

  const setScrubHandleWrapper = useCallback((scrubHandle) => {
    setScrubHandle(scrubHandle);
    if (onSetScrubHandle) {
      onSetScrubHandle(scrubHandle);
    }
  }, [onSetScrubHandle]);

  const settingsDimensions = useMemo(() => ({width: settings.width, height: settings.height}), [settings]);

  const setQuartileWrapper = useCallback((quartile) => {
    if (quartile === 1 && !q1) {
      setQ1(true);
    } else if (quartile === 2 && !q2) {
      setQ2(true);
    } else if (quartile === 3 && !q3) {
      setQ3(true);
    } else {
      // ignore
    }
  }, [q1, q2, q3]);

  const resetQuartile = (quartile) => {
    if (quartile === 1) {
      setQ1(false);
      setQ2(false);
      setQ3(false);
    } else if (quartile === 2) {
      setQ2(false);
      setQ3(false);
    } else if (quartile === 3) {
      setQ3(false);
    } else {
      // ignore
    }
  };

  const isLiveRampEnabled = settings?.liveRampEnabled;
  const rampIdImpressionIdApiGatewayPixel = settings?.rampIdImpressionIdApiGatewayPixel;
  const setImpressionEventWrapper = useCallback(() => {
    if (!impressionEventPixelFired.current) {
      impressionEventPixelFired.current = true;
      setImpressionEvent(true);
      executeLiveRampIdPullFlow(isLiveRampEnabled, rampIdImpressionIdApiGatewayPixel, localConfig.isRealLiveRampIdFetchEnabled, localConfig.liveRampIdFetchUrl, capture, environment.isEditMode() || environment.isInAdminTool());
    }
  }, [isLiveRampEnabled, rampIdImpressionIdApiGatewayPixel, capture, environment, localConfig]);


  useEffect(() => {
    if (isClickToPlay && playerState === PLAYER_STATE.READY && !impressionEventPixelFired.current) {
      setImpressionEventWrapper();
    }
  }, [isClickToPlay, playerState, setImpressionEventWrapper]);

  const allInSessionTriggerDetails = useMemo(() => getAllInSessionTriggerDetails(settings), [settings]);

  const videoDuration = useMemo(() => {
    // Ideally video duration should be the one sent from backend.
    // In some case when duration is in decimal then this can be different from the once sent by video player.
    return playerDuration || settings?.video?.durationSeconds || DEFAULT_VIDEO_DURATION
  }, [playerDuration, settings])
  
  const videoTimeToPercentageMapping = useMemo(() => {
    if (!isContentFlowCreative) {
      return null;
    }
    if (!videoDuration || videoDuration <= 0) {
      return null;
    }
    return generateVideoTimeToPercentages(videoDuration);
  },[videoDuration, isContentFlowCreative]);

  const playerTimeRef = useRef(0);

  useEffect(() => {
    playerTimeRef.current = playerTime;
  }, [playerTime]);
  
  const setsubmissionDetailsTime = useCallback(( pollingEnabled=false, surveyID=undefined, position=undefined, optionIndex=undefined, selectedQuestionOptionID=undefined)=>{
    if(pollingEnabled && surveyID && position && optionIndex && selectedQuestionOptionID) {
      const surveyObject = {
        time : playerTimeRef.current,
        position, 
        optionIndex, 
        selectedQuestionOptionID
      }
      setsubmissionDetails({...submissionDetails,[surveyID]:surveyObject});
      }
  }, [submissionDetails])
  /*
    Important: this function should not update (i.e none of it's dependencies should change) after initialization
      This is used as a dependency in useEffect functions (e.g useCardExposureEvents) that would trigger again if 
      the fireJourneyTriggerWrapper reference changes
    TODO: the dependent useEffects probably should be refactored to be less fragile
  */ 
  const fireJourneyTriggerWrapper = useCallback((event, elementTriggerId, suppressJourneyTrigger=false, pollingEnabled=false) => {
    if (!suppressJourneyTrigger) {
      fireJourneyTrigger(IN_SESSION_JOURNEY_EVENT_MAP[event] ? IN_SESSION_JOURNEY_EVENT_MAP[event] : event);
    }
    
    
    if (!environment.isODCEngagementPreviewEnabled()) {
      if (elementTriggerId) {
        // Multiple JT events can fire simultaenously e.g interactiveHotspotInteraction and interactiveHotspotClickThrough so filter out non-in-session triggerable events to prevent overwrite
        // in session events should be 1 event per user interaction
        if (IN_SESSION_JOURNEY_EVENT_MAP[event] || IN_SESSION_JOURNEY_EVENT_MAP[event.split("_")[0]]) {
          pollingEnabled ?
          setTimeout(() => {
            setTriggerState((prevState) => {
              return getNewTriggerState(prevState, elementTriggerId, event)
            });
          }, MC_POLLING_TIMEOUT) : setTriggerState((prevState) => { 
            return getNewTriggerState(prevState, elementTriggerId, event)
          });
          setLastTrigger({elementTriggerId, event});
        }
      } else {
        console.log("fireJourneyTrigger called without elementTriggerId on event: ", event);
      }
    }
  }, [fireJourneyTrigger, setTriggerState, environment]);

  // Detect triggerState changes made by fireJourneyTriggerWrapper to update trigger path element lookup
  // as well as store trigger state history to enabling video scrubbing resetting of trigger state
  useEffect(() => {
    const {elementTriggerId, event} = lastTrigger;
    if (triggerState && elementTriggerId && event) {
      const triggerIds = getChildrenTriggerElementIds(allInSessionTriggerDetails, elementTriggerId, event, triggerState);
      if (triggerIds && triggerIds.length !== 0) {
        setTriggerIdsInPath(triggerIds);
      }
    }
  }, [triggerState, lastTrigger, setTriggerIdsInPath, allInSessionTriggerDetails, environment])

  const {fireAdStoppedEvent} = useAdStoppedEvent();

  const {getSpecialSettingPlatformValue} = useSpecialSettings(settings, getPlatformType());

  const [inVideoCollapsed, setInVideoCollapseToggle] = useState(false);
  const [hotspotOverlay, setHotspotOverlay] = useState(null);
  const [engagementMode, setEngagementMode] = useState(null);
  const [isPreviousSessionSurveySetDone, setPreviousSessionSurvey] = useState(false);

  const [isSimulateAdStopped, setSimulateAdStopped] = useState(false);

  const isContentClosed = useMemo(() => (isContentFlowCreative && isSimulateAdStopped), [isSimulateAdStopped, isContentFlowCreative])
  const isShareLandingPageOrPreview = !isContentFlowCreative && (environment.isCreativeStudioEngagementPreviewEnabled() || environment.isShareLandingPage());
  const isEngagementPreview = environment.isCreativeStudioEngagementPreviewEnabled();

  const {ref, resizeVersion} = useVideoContainerResize();

  const {isSurveySubmitted, submitSurvey, submitSurveyByHotspotClickThrough, fetchAllSurveySubmitted, setPreviousSessionSubmittedSurveys} =
    useSurveys(environment.isODCEngagementPreviewEnabled(), hotspotOverlay, playerState, setHotspotOverlay, playerControlWrapper);

  useEffect(() => {
    if (isContentFlowCreative && !isPreviousSessionSurveySetDone && setPreviousSessionSubmittedSurveys && runtimeProperties.getSubmittedSurveys()) {
      // TODO: this needs to be converted to state rather than using global runtime properties
      const submittedSurveys = runtimeProperties.getSubmittedSurveys();
      setPreviousSessionSurvey(true)
      setPreviousSessionSubmittedSurveys(submittedSurveys);
      runtimeProperties.setSubmittedSurveys(null);
    }
  }, [setPreviousSessionSubmittedSurveys, isPreviousSessionSurveySetDone, runtimeProperties, isContentFlowCreative])

  const replayContent = useCallback(() => {
    const surveySubmitted = fetchAllSurveySubmitted();
    runtimeProperties.setSubmittedSurveys(surveySubmitted);
    reloadAd();
  }, [fetchAllSurveySubmitted,  reloadAd, runtimeProperties]);

  const trackClickThroughEvents = useClickThroughEvents(track3rdPartyEvents);
  const {
    callToAction = {},
    video: {
      deliveredViewDurationSeconds = NaN,
    },
    socialShare = {},
    showPlayer = true,
    adChoicesEnabled = true,
  } = settings;
  const [allCompleteEventsSend, setAllCompleteEventsSend] = useState(false);
  
  const {verticalLocation, visible} = callToAction
  const shareIconPosition = socialShare && socialShare.visible ? (socialShare?.shareIconPosition || SHARE_ICON_POSITION.TOP_RIGHT) : null;

  const {onEvent, setRuntimeProperties} = usePlayerTimeEvent(playerTime, capture, runtimeProperties.setExplorerParentCreativeDetails);

  const isVideoComplete = (playerState === PLAYER_STATE.COMPLETED || playerState === PLAYER_STATE.STOPPED) && !settings.forceInVideo;

  const inVideoOverlays = useMemo(()=> (settings.creativeCardSettings || []).map((creativeCardSetting) => ({
    ...creativeCardSetting,
    creativeCard: getCreativeCardFromSingleSetting(creativeCardSetting,
        {creativeCardType: creativeCardSetting.creativeCard.creativeCardType, isDisplay: false, finaleEnabled: isVideoComplete, inVideoEngagementEnabled: !isVideoComplete, inVideoEngagementPosition: creativeCardSetting.inVideoEngagementPosition}
    )
  })), [settings.creativeCardSettings, isVideoComplete])

  const finaleVariationAppliedOverlays = useMemo(()=> (settings.creativeCardSettings || []).map((creativeCardSetting) => ({
    ...creativeCardSetting,
    creativeCard: getCreativeCardFromSingleSetting(creativeCardSetting,
        {creativeCardType: creativeCardSetting.creativeCard.creativeCardType, isDisplay: false, finaleEnabled: true, inVideoEngagementEnabled: false}
    )
  })), [settings.creativeCardSettings])

  const {overlay, overlayState, overlayInterval} = useMemo(() => getCurrentOverlayEngagement(settings, playerTime, playerState, isSurveySubmitted, hotspotOverlay, triggerState, triggerIdsInPath, isContentClosed,
    environment.ignoreLogicAndPath(), environment.isInScreenShooterEmulation(), environment.getAdUnitId(), environment.isCreativeStudioPreviewMode(), allInSessionTriggerDetails, submissionDetails, inVideoOverlays, finaleVariationAppliedOverlays),
    [settings, playerTime, playerState, isSurveySubmitted, hotspotOverlay, triggerState, triggerIdsInPath, isContentClosed, environment, allInSessionTriggerDetails, submissionDetails, inVideoOverlays, finaleVariationAppliedOverlays]);


  const {
    onFinaleEEClose,
    isInVideoShowing,
    isShowingOnPause,
    isShowingOnHotspot,
    isFinaleShowing,
  } = useOverlayEvents(playerControlWrapper, overlayState, setHotspotOverlay);
  const finaleEnabled = useMemo(() => willFinaleShow(settings, isSurveySubmitted, triggerState, triggerIdsInPath, environment.ignoreLogicAndPath()),
    [settings, isSurveySubmitted, triggerState, triggerIdsInPath, environment]);
  const {hotspots, allHotspots} = useMemo(() => getCurrentHotspots(settings, playerTime, isSurveySubmitted, isFinaleShowing, triggerState, triggerIdsInPath, playerState, environment.ignoreLogicAndPath()),
    [settings, playerTime, isSurveySubmitted, isFinaleShowing, triggerState, triggerIdsInPath, playerState, environment]);
  const {onHotspotClickAway} = useHotspotEvents(playerControlWrapper);

  const [isHovered, setHovered] = useState(false);

  const videoHost = useMemo(() => getVideoHost(settings?.mediaType), [settings]);
  const cleanupTimeout = DEFAULT_VIDEO_CLEANUP_TIMEOUT_SECONDS;
  // Starts the cleanup counter timeout
  useCleanupCounter({cleanupTimeout, testCleanupTimeout: localConfig.testCleanupTimeout, isTestTimeoutMode: environment.isTestTimeoutMode(), removeAd});
  usePlayerTimeLogger(playerTime, playerState);

  const {
    showCTA, showScrub, showTopBrandLogo, showBottomBrandLogo,
    playButtonShowing, isGradientShowing, isCloseButtonShowing, isAdStoppedButtonShowing, showShareIcons
  } = useShowElements(isHovered, playerState, overlayState, engagementMode, overlay, getSpecialSettingPlatformValue,
    videoHost, inVideoCollapsed, settings, playerTime, triggerState, triggerIdsInPath,
    environment.isCreativeStudioPreviewMode(), environment.inHeavyAdsEmulation(), environment.ignoreLogicAndPath());
  const togglePlayPause = useCallback(() => {
    if (playerState === PLAYER_STATE.PLAYING) {
      playerControlWrapper.pause();
    } else {
      playerControlWrapper.play();
    }
  }, [playerState, playerControlWrapper]);

  const setVolumeWrapper = useCallback((vol) => {
      playerControlWrapper.setVolume(vol)
  }, [playerControlWrapper]);

  const videoGradient = useMemo(() => getGradient(settings), [settings]);

  const shouldRecordEvents = !environment.isODCEngagementPreviewEnabled();

  const shouldShowCTA = visible && showCTA;

  useEffect(() => {
    if(!isMute && !userUnmuted) {
      setUserUnmuted(true);
    }
  }, [userUnmuted, setUserUnmuted, isMute])

  const isHotspotSelectedForInteraction = useMemo(() => {
    /*
    * Prevent Click through for Video when Hotpots are selected in Creative Studio Preview Mode,
    * This is added because we wanted to unselect the hotspot, when we click out of the MultiSelect of Hotspot
    * */
    return environment.isCreativeStudioPreviewMode() && hotspotProps?.selectedHotspots?.length;
  }, [environment, hotspotProps?.selectedHotspots?.length]);

  useVPAIDInterface({
    play: () => playerControlWrapper.play(),
    pause: () => playerControlWrapper.pause(),
    isMute,
    vastTrackingPixels,
    q1,
    q2,
    q3,
    impressionEvent,
    playerState,
    allCompleteEventsSend,
    finaleEnabled,
    canUnmute: isMute ? userUnmuted: true,
    currentTime: playerTime,
    playerDuration,
    isClickToPlay,
    toggleMuteUnmute: (mute) => playerControlWrapper.mute(mute),
    settings,
    isPixelFiringInProgress,
    shouldPhoenixSkipFiringStandardVastPixel,
    platformType: getPlatformType(),
    isInAdminTool: environment.isInAdminTool(),
    isVPAIDEnabled: environment.isVPAIDEnabled(),
    width: Math.round(parseFloat(creativeDimensions?.width)),
    height: Math.round(parseFloat(creativeDimensions?.height)),
    isDebug: environment.isDebug(),
  })

  const getCtaShowClassNames = (isShowing) => {
    if(verticalLocation === CTA_POSITIONS.TOP) {
      return isShowing ? style.ctaShow : [style.ctaHideFromTop, style.ctaHide];
    } else {
      return isShowing ? style.ctaShow : [style.ctaHideFromBottom, style.ctaHide];
    }
  }

  const creativeVideoContent = (
    <>
      <TopBar isCloseButtonShowing={isCloseButtonShowing || isAdStoppedButtonShowing}>
        <GroupRow>
          <PositionLeft>
            <BrandLogoManager
                settings={settings}
                fireJourneyTrigger={fireJourneyTriggerWrapper}
                onClickThrough = {trackClickThroughEvents}
                showing={showTopBrandLogo}
                position={POSITION_OPTIONS.TOP.toLowerCase()}
                setPixelFiringInProgress = {setPixelFiringInProgress}
                creativeDimensions={creativeDimensions}
            />
            {verticalLocation === CTA_POSITIONS.TOP  && visible && <div data-testid='cta-container' className={classnames(style.ctaTop, getCtaShowClassNames(shouldShowCTA))}>
              <CTARenderer
                  showing={visible && showCTA}
                  fireJourneyTrigger={fireJourneyTriggerWrapper}
                  onClickThrough = {trackClickThroughEvents}
                  settings={settings}
              />
            </div>}
            {shareIconPosition === SHARE_ICON_POSITION.TOP_LEFT &&
            <SocialIcons
              fireJourneyTrigger={fireJourneyTriggerWrapper}
              showing={showShareIcons}
              settings={settings}
              creativeDimensions={creativeDimensions}
            />}
          </PositionLeft>
          <PositionRight>
            {shareIconPosition === SHARE_ICON_POSITION.TOP_RIGHT &&
            <SocialIcons
              fireJourneyTrigger={fireJourneyTriggerWrapper}
              showing={showShareIcons}
              settings={settings}
              creativeDimensions={creativeDimensions}
            />}
          </PositionRight>
        </GroupRow>
      </TopBar>
      {/* Engagement Experience Slot */}
      <div className={style.fillSpace}>
        <Overlay
          onHotspotClickAway={onHotspotClickAway}
          onFinaleEEClose={onFinaleEEClose}
          onAdSwitch={reloadAd}
          settings={settings}
          overlay={overlay}
          isInVideoShowing={isInVideoShowing}
          isShowingOnPause={isShowingOnPause}
          isShowingOnHotspot={isShowingOnHotspot}
          isFinaleShowing={isFinaleShowing}
          isCloseButtonShowing={isCloseButtonShowing}
          interval={overlayInterval}
          setEngagementMode={setEngagementMode}
          onInVideoCollapseToggle={setInVideoCollapseToggle}
          fireJourneyTrigger={fireJourneyTriggerWrapper}
          submitSurvey={submitSurvey}
          submitSurveyByHotspotClickThrough={submitSurveyByHotspotClickThrough}
          track3rdPartyEvents={track3rdPartyEvents}
          onClickThrough={trackClickThroughEvents}
          onEvent={onEvent}
          setRuntimeProperties={setRuntimeProperties}
          inVideoOverlays={inVideoOverlays}
          resizeVersion={resizeVersion}
          scrubBarShowing={showScrub}
          setPixelFiringInProgress = {setPixelFiringInProgress}
          creativeDimensions={creativeDimensions}
          hotspotProps={hotspotProps}
          isSurveySubmitted={isSurveySubmitted}
          submissionDetails={submissionDetails}
          setsubmissionDetailsTime={setsubmissionDetailsTime}
          overlayState={overlayState}
        />
      </div>
      {/* Bottom Bar goes here */}
      <BottomBar>
        <GroupRow groupRowStyle={{ alignItems: 'flex-end' }}>
          <PositionLeft>
            {verticalLocation !== CTA_POSITIONS.TOP && visible && <div className={classnames(getCtaShowClassNames(shouldShowCTA))}>
              <CTARenderer
                  showing={visible && showCTA}
                  fireJourneyTrigger={fireJourneyTriggerWrapper}
                  onClickThrough = {trackClickThroughEvents}
                  settings={settings}
              />
            </div>}
            {shareIconPosition === SHARE_ICON_POSITION.BOTTOM_LEFT &&
            <SocialIcons
              fireJourneyTrigger={fireJourneyTriggerWrapper}
              showing={showShareIcons}
              settings={settings}
              creativeDimensions={creativeDimensions}
            />}
          </PositionLeft>
          <PositionRight>
            <BrandLogoManager
              settings={settings}
              fireJourneyTrigger={fireJourneyTriggerWrapper}
              onClickThrough = {trackClickThroughEvents}
              showing={showBottomBrandLogo}
              position={POSITION_OPTIONS.BOTTOM}
              setPixelFiringInProgress = {setPixelFiringInProgress}
              creativeDimensions={creativeDimensions}
              hideBottomPadding={adChoicesEnabled}
            />
            {shareIconPosition === SHARE_ICON_POSITION.BOTTOM_RIGHT &&
            <SocialIcons
              fireJourneyTrigger={fireJourneyTriggerWrapper}
              showing={showShareIcons}
              settings={settings}
              creativeDimensions={creativeDimensions}
            />}
          </PositionRight>
        </GroupRow>
        {showPlayer && <ScrubBar
          isShowing={showScrub}
          playerDuration={playerDuration}
          playerTime={playerTime}
          playerState={playerState}
          isMute={isMute}
          onMuteUnmute={isMute === undefined ? noop : (mute) => {
            playerControlWrapper.mute(mute);
            if(!mute && !userUnmuted) {
              setUserUnmuted(true);
            }
          }}
          togglePlayPause={togglePlayPause}
          adChoicesEnabled={adChoicesEnabled}
          scrubHandle={scrubHandle}
          handleReplay = {replayContent}
          setVolume = {setVolumeWrapper}
          onScrub={() => {
            setScrubbedOnce(true);
          }}
          creativeDimensions={creativeDimensions}
          settingsDimensions={settingsDimensions}
          isContentFlowCreative={isContentFlowCreative}
        />}
      </BottomBar>
      {/* Absolute layered elements goes here */}
      {showPlayer && <Player
        onMuteStateChange={toggleMuteUnmute}
        state={playerState}
        isMute={isMute}
        isClickToPlay={isClickToPlay}
        playButtonShowing={playButtonShowing}
        onPlayerTimeChange={setPlayerTimeWrapper}
        onPlayerStateChange={setPlayBackStateWrapper}
        onPlayerDurationChange={setPlayerDuration}
        scrubToHandler={setScrubHandleWrapper}
        engagementShowing={Boolean(isFinaleShowing || hotspotOverlay)}
        onEvent={onEvent}
        track3rdPartyEvents={track3rdPartyEvents}
        getSpecialSettingPlatformValue={getSpecialSettingPlatformValue}
        onClickThrough={trackClickThroughEvents}
        settings={settings}
        playerControlWrapper={playerControlWrapper}
        setPlayerControlWrapper={setPlayerControlWrapperWrapper}
        reloadAd={reloadAd}
        setClickToPlay={setClickToPlay}
        setQuartile={setQuartileWrapper}
        setImpressionEvent={setImpressionEventWrapper}
        setVastTrackingPixels = {setVastTrackingPixels}
        onPlayerClick = {isContentFlowCreative ? togglePlayPause : noop}
        creativeDimensions={creativeDimensions}
        preventClickThrough={isHotspotSelectedForInteraction}
      />}
      <HotspotManager
        hotspots={hotspots}
        allHotspots={allHotspots}
        onHotspotClickAway={onHotspotClickAway}
        fireJourneyTrigger={fireJourneyTriggerWrapper}
        onEvent={onEvent}
        showing={!isFinaleShowing && playerState !== PLAYER_STATE.READY}
        setHotspotOverlay={setHotspotOverlay}
        onClickThrough={trackClickThroughEvents}
        useHotspotAnimation={!environment.isCreativeStudioPreviewMode() && true}
        hotspotProps={hotspotProps}
        isStandaloneHotspots={true}
      />
      {shouldRecordEvents && <PlayerEventRecorder
        q1={q1}
        q2={q2}
        q3={q3}
        impressionEvent={impressionEvent}
        state={playerState}
        time={playerTime}
        duration={playerDuration}
        isMute={isMute}
        deliveredViewDurationSeconds={deliveredViewDurationSeconds}
        track3rdPartyEvents={track3rdPartyEvents}
        isClickToPlay={isClickToPlay}
        fireAdStoppedEvent={fireAdStoppedEvent}
        videoTimeToPercentageMapping = {videoTimeToPercentageMapping}
        resetQuartile = {resetQuartile}
        scrubbedOnce = {scrubbedOnce}
        onAdCompleteEventFired={() => {
          setAllCompleteEventsSend(true);
        }}
        fireJourneyTrigger={fireJourneyTriggerWrapper}
        isContentFlowCreative={isContentFlowCreative}
      />
      }
      <VideoGradient
        showing={isGradientShowing}
        gradient={videoGradient}
      />
      {isAdStoppedButtonShowing && allCompleteEventsSend && <AdStoppedIcon
        isContentFlowCreative={isContentFlowCreative}
        onClick={() => {
          if (isShareLandingPageOrPreview || isContentFlowCreative) {
            setSimulateAdStopped(true);
          } else {
            fireAdStoppedEvent();
          }
        }
      }/>}
    </>
  );

  const eventHandlers = useMemo(() => {
    const startHover = () => {
      setHovered(true)
    };

    const endHover = () => {
      setHovered(false)
    };

    /*
      Added `onMouseDown` for mobile device as in iOS `onMouseEnter` was not getting bubble up and forcing user to do a double tap to play a video, Refer OC-936

      From React Documentation:
      The onMouseEnter and onMouseLeave events propagate from the element being left to the one being entered instead of ordinary bubbling and do not have a capture phase.
    */
    return isMobileDevice() ?
      {
        onMouseDown: startHover,
        onMouseLeave: endHover
      } :
      {
        onMouseEnter: startHover,
        onMouseLeave: endHover
      }
  }, []);

  return (
    <div
      data-testid='video-container'
      className={isEngagementPreview ? style.phoenixEngagementPreview : style.phoenix}
      ref={ref}
      {...eventHandlers}
    >
      {!(isShareLandingPageOrPreview && isSimulateAdStopped) && creativeVideoContent}
    </div>
  );
}


export default VideoCreative;
