import React from "react";
import style from './style.module.scss';
import classnames from "classnames";

import icon from 'images/play-btn-white.svg';


function PlayButton({onClick, className, width, height, ...rest}) {
  return <span data-testid="playbtn" className={classnames(style.playButtonContainer, className || '')} onClick={onClick} style={{cursor: 'pointer', width, height}} {...rest}><img className={style.playButton} src={icon}/> </span>
}

export default PlayButton;
