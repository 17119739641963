import {useCallback, useEffect, useState} from "react";
import {constants} from "@phoenix/core";
import env from "utils/environment";
import LoggerHelper from 'utils/loggerHelper';

const {ASPECT_RATIO_VALUES, DEFAULT_ASPECT_RATIO, CREATIVE_MODE, LOG_DETAILS_TYPE, DisplayMediaType} = constants;

export const getAspectRatioValue = (aspectRatio = DEFAULT_ASPECT_RATIO) => ASPECT_RATIO_VALUES[aspectRatio];
const logger = LoggerHelper.instance

function doesCreativeFit(width, height, containerWidth, containerHeight) {
  return (width <= containerWidth) && (height <= containerHeight);
}

function adjustDimensions(width, height, containerWidth, containerHeight) {
  if (width > containerWidth) {
    return {
      width: containerWidth,
      height: (height / width) * containerWidth
    };
  }

  if (height > containerHeight) {
    return {
      height: containerHeight,
      width: (width / height) * containerHeight
    };
  }

  return {width, height};
}

function getContainerDimension() {
  return {
    width: Math.round(parseFloat(getComputedStyle(document.body).width)),
    height: Math.round(parseFloat(getComputedStyle(document.body).height))
  };
}

// Note: Should only be run within an iframe, as there are dependency on document.body and getElementById('app')
export default function ({aspectRatio, creativeType, mediaType, width, height}) {
  const [isResized, setResized] = useState(false);
  const [creativeDimensions, setCreativeDimensions] = useState(null);

  const ensureRatio = useCallback((creativeWidth, creativeHeight) => {
    const containerDimensions = getContainerDimension();

    const MAX_ITERATION = 50;

    let i = 0;
    while (!doesCreativeFit(creativeWidth, creativeHeight, containerDimensions.width, containerDimensions.height)) {
      const newDimensions = adjustDimensions(creativeWidth, creativeHeight, containerDimensions.width, containerDimensions.height);

      creativeWidth = newDimensions.width;
      creativeHeight = newDimensions.height;

      i++;
      // Kill Switch
      if (i > MAX_ITERATION) {
        break;
      }
    }

    return {width: creativeWidth, height: creativeHeight};
  }, []);

  const getDisplayDimensions = useCallback(() => {
    const dimension = {
      width: '100%',
      height: '100%'
    };

    if (env.isCreativeStudioPreviewMode() && mediaType === DisplayMediaType.RAW_IMAGE) {
      return dimension;
    }

    if (width) {
      if (!env.isCreativeStudioPreviewMode() && creativeType === CREATIVE_MODE.DISPLAY) {
        const {height: updatedHeight, width: updatedWidth} = ensureRatio(width, height);

        dimension.width = `${updatedWidth}px`;
        dimension.height = `${updatedHeight}px`;
      } else {
        dimension.width = `${width}px`;
        dimension.height = `${height}px`;
      }
    }
    return dimension;
  }, [creativeType, ensureRatio, mediaType, width, height])

  const update = useCallback(() => {
    if (!creativeType) {
      return
    }
    let w, h;
    const app = document.getElementById('app');
    if (creativeType === CREATIVE_MODE.VIDEO) {
      const aspectRatioValue = getAspectRatioValue(aspectRatio);
      if (document.body.clientHeight * aspectRatioValue <= document.body.clientWidth) {
        const width = Math.floor(document.body.clientHeight * aspectRatioValue);
        w = `${width}px`;
        h = '100%';
      } else {
        const height = Math.ceil(document.body.clientWidth / aspectRatioValue);
        w = '100%';
        h = `${height}px`;
      }
      app.style.width = w;
      app.style.height = h;
    } else {
      const {width, height} = getDisplayDimensions();
      app.style.width = width;
      app.style.height = height;
    }
    setResized(true);
    setCreativeDimensions({width: getComputedStyle(app).width, height: getComputedStyle(app).height});
    const rootFontSize = `${app.clientHeight / 21}px`;
    document.querySelector('html').style.fontSize = rootFontSize;
    document.querySelector('html').style.setProperty('--rootFontSize', rootFontSize);
  }, [creativeType, aspectRatio, getDisplayDimensions])


  useEffect(() => {
    if (creativeType) {
      logger.captureAssetLoadingTime(LOG_DETAILS_TYPE.RESIZE, false)
    }
    update();
    if (creativeType) {
      logger.captureAssetLoadingTime(LOG_DETAILS_TYPE.RESIZE, true)
    }
    window.addEventListener('resize', update);

    return () => {
      window.removeEventListener('resize', update)
    }
  }, [aspectRatio, creativeType, update]);

  return {isResized, creativeDimensions};
}
