import React from 'react';
import classnames from 'classnames';

import style from './style.module.scss';

const previewClickHandler = (e) => {
  e.preventDefault(); 
  e.stopPropagation();
}

export function StoreListItem(
  {
    iconFile,
    iconColor,
    index,
    store,
    isFullScreen,
    onPhoneClick,
    onDirectionClick
  }
) {
  const {
    streetAddress,
    city,
    state,
    zipCode,
    notes,
    phoneNumber,
    phoneLink,
    isMobile,
    distanceFromUser,
    googleMapsDirectionsUrl,
    latitude,
    longitude
  } = store;

  return (<div className={style.storeItemContainer}>
    <div className={classnames(style.storeIcon, isFullScreen ? style.storeIconFullScreen : null)}>
      {
        iconFile ?
          <div className={style.iconImage} style={{backgroundImage: `url(${iconFile.url})`}}/> :
          <div className={style.circleNumber} style={{backgroundColor: iconColor}}>
            {index + 1}
          </div>
      }
    </div>
    <div className={classnames(style.storeAddress, isFullScreen ? style.storeAddressFullScreen : null)}>
      <div>{streetAddress}</div>
      <div>{city} {state}, {zipCode}</div>
      {
        notes ? <div>{notes}</div> : <React.Fragment/>
      }
      {
        (Boolean(phoneNumber) && !isMobile) ?
          <div>{phoneNumber}</div> :
          <React.Fragment/>
      }
      <div className={style.distance}>{(distanceFromUser || 0).toFixed(2)} mi away</div>
    </div>
    <div className={style.contactMethods}>
      {
        phoneNumber ?
          <a
            href={phoneLink}
            style={{color: iconColor, ...(!onPhoneClick && {cursor: 'default'})}}
            target={onPhoneClick ? '_parent' : '_self'}
            title='Call'
            onClick={onPhoneClick ? onPhoneClick : previewClickHandler}
            data-testid={'store-list-phone'}
          >
            <i className={'fa-phone far'}/>
          </a> :
          <React.Fragment/>
      }
      <a
        data-lat-lon={`${latitude},${longitude}`}
        href={googleMapsDirectionsUrl}
        target={onDirectionClick ? '_blank' : '_self'}
        title='Directions'
        style={{color: iconColor, ...(!onDirectionClick && {cursor: 'default'})}}
        onClick={onDirectionClick ? onDirectionClick : previewClickHandler}
        data-testid={'store-list-direction'}
        rel="noreferrer"
      >
        <i className={'fas fa-map-marker-alt'}/>
      </a>
    </div>
  </div>);
}
