// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../phoenix-app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container--fJ3iX{display:flex;font-size:calc(var(--rootFontSize) * 1);flex-wrap:nowrap;justify-content:center}.icon--3_hZv{margin:0 calc(var(--rootFontSize) * 0.35)}", ""]);
// Exports
exports.locals = {
	"container": "container--fJ3iX",
	"icon": "icon--3_hZv"
};
module.exports = exports;
